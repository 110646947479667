<template>
  <section id="project-analysis">
    <h2 class="sr-only">Analysis</h2>
    <ul
      class="project-analysis__tabs-list"
      :aria-hidden="modalOpen"
      :inert="modalOpen"
    >
      <li
        v-for="item in tabs"
        :key="item"
        :class="[
          'project-analysis__tabs-list-item',
          {
            'project-analysis__tabs-list-item-active': activeTab === item
          }
        ]"
      >
        <router-link
          :to="{ name: `projectAnalysis${item}` }"
          class="project-analysis__tabs-list-item-btn"
          tag="button"
        >
          <VisibleText class="project-analysis__tabs-list-item-text">
            {{ item }}
          </VisibleText>
        </router-link>
      </li>
    </ul>
    <keep-alive>
      <router-view :prop-read-only="propReadOnly"></router-view>
    </keep-alive>
  </section>
</template>

<script>
// Mixins
import DatasetDetailsMixin from "@/utils/mixins/datasetDetailsMixin.js"

export default {
  name: "ProjectAnalysis",
  mixins: [DatasetDetailsMixin],
  props: {
    propReadOnly: {
      default: () => false,
      type: Boolean
    }
  },
  data() {
    return {
      tabs: ["Drivers", "Performance", "Benchmarking", "Text"]
    }
  },
  computed: {
    activeTab() {
      const tab = this.$route.path.split("/").pop()
      return tab.charAt(0).toUpperCase() + tab.substr(1).toLowerCase()
    },
    modalOpen() {
      return this.$store.getters["globalModule/getModalOpen"]
    }
  },
  async created() {
    // if user cannot create text analysis, remove from tabs
    if (!this.$pigeonline.permissions.hasPermission("create-analysis-text"))
      this.tabs.pop()
  },
  watch: {
    activeTab: {
      immediate: true,
      handler: function(val) {
        if (!val || val === "") return
        document.title = `${this.$store.getters["project/getProject"].name} - Analysis - ${val} | ${this.$theme.theme.pageTitle}`
      }
    }
  }
}
</script>
