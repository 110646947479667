<template>
  <div ref="uploading_files_article" class="library__article">
    <div v-if="isUserGuide">
      <p>
        <VisibleText>Go back to</VisibleText>
        <a
          href="javascript:void(0)"
          @click="$emit('new-page', 'GettingStarted')"
        >
          <VisibleText> Getting Started</VisibleText>
        </a>
      </p>
    </div>
    <div v-else>
      <p>
        <VisibleText>Go back to</VisibleText>
        <router-link to="/library">
          <VisibleText> Library</VisibleText>
        </router-link>
        ->
        <router-link to="/library/guide/getting-started-with-researchai">
          <VisibleText> Getting Started</VisibleText>
        </router-link>
      </p>
    </div>
    <h1><VisibleText>Uploading your data</VisibleText></h1>
    <p>
      <VisibleText>
        ResearchAI starts with your data. If you have surveys, CRM data,
        customer complaints, and more, importing it is easy.
      </VisibleText>
    </p>
    <p>
      <VisibleText>
        The process involves 4 simple steps
      </VisibleText>
      :
    </p>
    <ul class="library__list">
      <li>
        <a href="javascript:void(0)" @click="goto('goto--step1')">
          <VisibleText>
            Step 1 - Upload your file
          </VisibleText>
        </a>
      </li>
      <li>
        <a href="javascript:void(0)" @click="goto('goto--step2')">
          <VisibleText>
            Step 2 - Clean your data
          </VisibleText>
        </a>
      </li>
      <li>
        <a href="javascript:void(0)" @click="goto('goto--step3')">
          <VisibleText>
            Step 3 - Match to benchmarks (optional)
          </VisibleText>
        </a>
      </li>
      <li>
        <a href="javascript:void(0)" @click="goto('goto--step4')">
          <VisibleText>
            Step 4 - Define your data
          </VisibleText>
        </a>
      </li>
    </ul>
    <h2 ref="goto--step1">
      <VisibleText>
        Step 1 - Upload your file
      </VisibleText>
    </h2>
    <p>
      <VisibleText
        >To upload a survey, click 'datasets' when looking at the main page.
        This will take you to a list of all datasets uploaded by you or shared
        to you. You will also see a sample dataset. Note that free users are
        unable to upload new datasets.
      </VisibleText>
    </p>
    <img
      class="screenshot"
      src="/assets/userguide__upload01.png"
      style="width:60%"
      alt="screenshot of top nav with datasets circled"
    />
    <p>
      <VisibleText
        >For best results, use SPSS file format .sav (this can be exported from
        most survey software). This will make the next steps easier.
      </VisibleText>
    </p>
    <p>
      <VisibleText
        >If you do not have .sav, please download and fill in the following
        template:
      </VisibleText>
    </p>
    <button
      @click="download"
      class="dataset-labeller__download download_button"
    >
      <VisibleText>Download Template</VisibleText>
    </button>
    <h3><VisibleText>Using the template</VisibleText></h3>
    <p>
      <VisibleText
        >Respondents are in rows, and questions are in columns:
      </VisibleText>
    </p>
    <img
      class="screenshot"
      style="width:40%"
      src="/assets/userguide__upload02.png"
      alt="screenshot of excel template respondent row and question columns"
    />
    <p>
      <VisibleText>
        Data can be scale (such as 1 to 5), categorical (where a number
        represents one group of users, such as demographics), or text (such as a
        customer complaint).
      </VisibleText>
    </p>
    <p>
      <VisibleText
        >Add question wording to match your question code. If you do not have
        specific question codes, just add Q1, Q2, Q3 etc.
      </VisibleText>
    </p>
    <img
      class="screenshot"
      style="width:60%"
      src="/assets/userguide__upload03.png"
      alt="screenshot of excel template code and question wording"
    />
    <h2 ref="goto--step2">
      <VisibleText>Step 2 - Clean your data</VisibleText>
    </h2>
    <p>
      <VisibleText
        >The next step is to clean your data. The tool will guess your data
        types, but it won't always be correct. Please go through each survey
        question and verify.
      </VisibleText>
    </p>
    <img
      class="screenshot"
      style="width:60%"
      src="/assets/userguide__upload04.png"
      alt="screenshot of a question with data type circled"
    />
    <p>
      <VisibleText>You can also recode your values or label them.</VisibleText>
    </p>
    <h2 ref="goto--step3">
      <VisibleText>Step 3 - Match to benchmarks (Optional)</VisibleText>
    </h2>
    <p>
      <VisibleText
        >You can also compare yourself to peers. Set up an internal benchmarking
        service, or register for the ICCS's Public Sector benchmarking service,
        called CitizenFirst Analytics.
      </VisibleText>
    </p>
    <h3><VisibleText>What is a benchmark?</VisibleText></h3>
    <p>
      <VisibleText
        >You may have scores that are similar to questions in a basket of peers.
        Matching your scores to these benchmarking groups allows you to see how
        well you compare.
      </VisibleText>
    </p>
    <h4>
      <VisibleText
        >How can I see if my peers ask similar questions to me?
      </VisibleText>
    </h4>
    <p>
      <VisibleText
        >This tool has an automatic benchmark detector. Just select the
        benchmarking service you would like to compare your scores to, and click
        'get suggested matches'.
      </VisibleText>
    </p>
    <h2 ref="goto--step4">
      <VisibleText>
        Step 4 - Define your data
      </VisibleText>
    </h2>
    <p>
      <VisibleText
        >Lastly to ensure your research is robust, we ask questions about your
        dataset.
      </VisibleText>
    </p>
    <img
      class="screenshot"
      src="/assets/userguide__upload05.png"
      alt="screenshot of step 4 in dataset wizard"
      style="width:60%"
    />
    <p>
      <VisibleText
        >Tip: By correctly identifying your respondents, you will enable a
        comparison against peers with the same types of respondents.
      </VisibleText>
    </p>
    <p>
      <VisibleText
        >Your data is now ready for analysis! Based on the dataset you uploaded,
        and the cleaning, you may have unlocked a number of analytical options.
        The next guides will help you find insights in this data.
      </VisibleText>
    </p>
  </div>
</template>

<script>
import axios from "axios"
export default {
  name: "uploading_files",
  props: {
    isUserGuide: { type: Boolean }
  },
  methods: {
    goto(refName) {
      this.$refs[refName].scrollIntoView()
    },
    download() {
      axios({
        url: `${window.location.origin}/files/codebook-template.xlsx`,
        method: "GET",
        responseType: "blob"
      }).then(response => {
        const fileURL = window.URL.createObjectURL(
          new Blob([response.data], {
            type: "application/vnd.ms-excel"
          })
        )
        const fileLink = document.createElement("a")
        fileLink.href = fileURL
        fileLink.setAttribute("download", "codebook-template.xlsx")
        document.body.appendChild(fileLink)
        fileLink.click()
      })
    }
  }
}
</script>
