<template>
  <div class="tooltip">
    <slot name="title"></slot>
    <span
      class="tooltip__text default top"
      :style="{ display: isTextVisible ? 'grid' : 'none' }"
      v-show="isTextVisible"
    >
      <ButtonIconOnly
        icon="remove"
        id="close-tooltip"
        class="tooltip__close-btn"
        @click-handler="closeTooltip"
      >
        <VisibleText>close tooltip</VisibleText>
      </ButtonIconOnly>
      <slot></slot>
    </span>
  </div>
</template>

<script>
import ButtonIconOnly from "@/components/UI/Button/ButtonIconOnly.vue"

export default {
  name: "Tooltip",
  components: {
    ButtonIconOnly
  },
  props: {
    textVisible: {
      default: () => true,
      type: Boolean
    }
  },
  data() {
    return {
      isTextVisible: true
    }
  },
  methods: {
    closeTooltip() {
      this.isTextVisible = false
      this.$emit("close")
    }
  },
  watch: {
    textVisible: {
      immediate: true,
      handler: function(val) {
        this.isTextVisible = val
      }
    }
  }
}
</script>
