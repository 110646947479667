import { RATINGS_LANG } from "./conts.js"

export default {
  props: {
    propReadOnly: {
      default: () => false,
      type: Boolean
    }
  },
  data() {
    return {
      defaultSummaryResultsTable: [],
      summaryResultsTable: [],
      summaryResultsTableHeaders: []
    }
  },
  methods: {
    getClientIdsOfScaleQuestions() {
      return Object.keys(this.matchesDetails).reduce(
        (clientQuestionIds, clientQuestionId) => {
          let dataType = this.clientQuestions.find(
            q => q._id.$oid === clientQuestionId
          ).approved_data_type
          if (dataType === "SCALE_1_TO_5")
            clientQuestionIds.push(clientQuestionId)
          return clientQuestionIds
        },
        []
      )
    },
    async fetchSummaryResults() {
      try {
        const clientQuestionIds = this.getClientIdsOfScaleQuestions()
        if (clientQuestionIds.length === 0) return null
        const params = {
          project_id: this.project.id,
          data_set_id: this.dataset._id.$oid,
          client_questions_ids: clientQuestionIds,
          selected_segments: Object.keys(this.dataset.segments)
        }
        const response = await this.$services.BENCHMARKING_SERVICE.summaryResults(
          params
        )
        return response
      } catch (e) {
        throw new Error("benchmarkAnalysisMixin:summaryResults " + e.message)
      }
    },
    async fetchClientQuestionsDetails() {
      try {
        const params = {
          data_set_id: this.dataset._id.$oid,
          client_questions_ids: this.getClientIdsOfScaleQuestions()
        }
        const response = await this.$services.BENCHMARKING_SERVICE.clientQuestionsDetails(
          params
        )
        return response
      } catch (e) {
        throw new Error(
          "benchmarkAnalysisMixin:clientQuestionDetails " + e.message
        )
      }
    },
    async fetchDetailedResults() {
      try {
        const params = {
          project_id: this.project.id,
          data_set_id: this.dataset._id.$oid,
          client_questions_ids: this.getClientIdsOfScaleQuestions(),
          selected_segments: Object.keys(this.dataset.segments)
        }
        const response = await this.$services.BENCHMARKING_SERVICE.detailedResult(
          params
        )
        return response
      } catch (e) {
        throw new Error("benchmarkAnalysisMixin:detailedResults " + e.message)
      }
    },
    /** FIXME: cleanup this method **/
    async formatTables(summaryResults) {
      const formatAvgData = (avg, count) =>
        `${avg} 
        <span class="table__secondary-data">${count} ${this.translate(
          "benchmarks",
          this.$options.name
        )}</span>`
      const formatRatingData = (rating, ratingText, ratingClass) => {
        let summary = document.createElement("summary")
        summary.classList.add(`benchmarking__rating-${ratingClass}`)
        summary.appendChild(document.createTextNode(rating))

        let para = document.createElement("p")
        para.classList.add("benchmarkng__rating-details-p")
        para.appendChild(document.createTextNode(ratingText))

        let details = document.createElement("details")
        details.classList.add("benchmarking__rating-details")
        details.appendChild(summary)
        details.appendChild(para)

        return details.outerHTML
      }
      const ratings = RATINGS_LANG[this.$store.getters.getLanguage]

      // set table headers
      this.summaryResultsTableHeaders = [
        "question",
        "your organization",
        "rating",
        "all groups"
      ]
      this.summaryResultsTableHeaders = this.summaryResultsTableHeaders.concat(
        Object.keys(this.dataset.segments)
      )

      // get client question titles
      const clientQuestionToTitle = this.$store.getters[
        "datasetDetails/getClientQuestions"
      ].reduce((result, q) => {
        result[q._id.$oid] = q.question_title
        return result
      }, {})

      // loop through each question
      let _summaryResultsTable = []
      summaryResults.forEach(question => {
        let avgTemp = {}
        let avgAndCount = question.average_and_count_by_segments

        // question row header
        avgTemp[`question`] =
          Object.keys(this.matchesDetails).length > 0 &&
          Object.keys(this.matchesDetails).includes(question.client_question_id)
            ? this.matchesDetails[question.client_question_id]
                .global_question_title
            : ""

        // add question title if exists, if it exceeds 20 characters truncate it
        let questionTitle = clientQuestionToTitle[question.client_question_id]
        if (questionTitle.length > 0) {
          questionTitle =
            questionTitle.length > 20
              ? questionTitle.substring(0, 10) + "..."
              : questionTitle
          avgTemp["question"] = `${avgTemp["question"]} (${questionTitle})`
        }
        avgTemp["questionId"] = question.client_question_id

        // rating
        avgTemp[`ratingLetter`] = question.dynamic_summary[0]
        avgTemp[`ratingData`] = ratings[question.dynamic_summary[0]]
        avgTemp[`rating`] = formatRatingData(
          ratings[question.dynamic_summary[0]],
          question.dynamic_summary[1],
          question.dynamic_summary[0]
        )

        // users average
        avgTemp[`your organization`] = avgAndCount["User"][0]
          ? avgAndCount["User"][0].toFixed(2)
          : "n/a"

        // average of all datasets
        avgTemp[`all groups`] =
          avgAndCount["All"] && avgAndCount["All"][0]
            ? formatAvgData(
                avgAndCount["All"][0].toFixed(2),
                avgAndCount["All"][1]
              )
            : "n/a"

        // for each segment of the dataset, set average
        Object.keys(avgAndCount).forEach(segment => {
          if (
            !avgAndCount[segment][0] ||
            segment === "All" ||
            segment === "User"
          )
            return
          avgTemp[segment] = formatAvgData(
            avgAndCount[segment][0].toFixed(2),
            avgAndCount[segment][1]
          )
        })

        _summaryResultsTable.push(avgTemp)
      })

      this.defaultSummaryResultsTable = Array.from(_summaryResultsTable)
      this.summaryResultsTable = Array.from(_summaryResultsTable)
    }
  }
}
