<template>
  <DatasetLayout section-name="dataset-cleaner" title="Clean your data">
    <p class="dataset-cleaner__p">
      <VisibleText>Help us understand each question's data-type.</VisibleText>
      <HasPermission to="iccs">
        <VisibleText
          >Remember that the CitizenFirst analysis requires 5-point scale
          questions.
        </VisibleText>
      </HasPermission>
    </p>
    <div class="dataset__section-group" id="dataset-cleaner__content">
      <Tooltip id="data-type-tooltip" v-if="!isTooltipClosed">
        <VisibleText>Let us find data types for you!</VisibleText>
        <button
          class="dataset-benchmarks__get-matches-btn"
          @click="onClickGetTypes"
        >
          <VisibleText>Get suggested data types</VisibleText>
        </button>
      </Tooltip>
      <DatasetTable class="dataset__section-group-content" :read-only="true" />
      <button class="dataset__section-save-btn" @click="save">
        <VisibleText>Continue</VisibleText>
      </button>
    </div>
  </DatasetLayout>
</template>

<script>
// Components
import DatasetLayout from "@/components/Layouts/DatasetLayout.vue"
import DatasetTable from "../Components/DatasetTable/DatasetTable.vue"
import Tooltip from "@/components/UI/Tooltip.vue"

// Mixins
import DatasetMixin from "@/utils/mixins/datasetMixin.js"
import DatasetDetailsMixin from "@/utils/mixins/datasetDetailsMixin.js"

export default {
  name: "DatasetCleaner",
  mixins: [DatasetMixin, DatasetDetailsMixin],
  components: {
    DatasetLayout,
    DatasetTable,
    Tooltip
  },
  data() {
    return {
      getSuggestedDataType: false,
      isTooltipClosed: false
    }
  },
  async activated() {
    // setup onscroll listener
    document
      .getElementById("dataset-table-data")
      .addEventListener("scroll", this.handleScroll)
  },
  async deactivated() {
    // fetch client questions with updated data types
    if (this.dataset) {
      await this.updateClientQuestions(this.dataset._id.$oid)
    }
  },
  methods: {
    handleScroll() {
      if (document.getElementById("data-type-tooltip")) {
        let rect = document.getElementById("data-type").getBoundingClientRect()
        let isVisible = rect.top >= 0 && rect.bottom <= window.innerHeight

        if (!isVisible) {
          document.getElementById("data-type-tooltip").style.opacity = 0
        } else {
          document.getElementById("data-type-tooltip").style.opacity = 1
        }
      }
    },
    onClickGetTypes() {
      this.setToFetchSuggestedDataType(true)
      this.isTooltipClosed = true
    },
    async save() {
      // update project status if user hasnt completed this section yet
      if (
        this.$pigeonline.permissions.hasPermission("update-dataset") &&
        !this.datasetProject.status.datasetColumnDetailsCompleted
      ) {
        this.datasetProject.updateStatus("datasetColumnDetailsCompleted")
        this.saveDatasetProject(this.datasetProject)
      }
      this.setActiveSection("DatasetBenchmarks")
    }
  }
}
</script>
