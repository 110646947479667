<template>
  <div id="dataset-segments-questionnaire" class="dataset-segments__form-group">
    <ul
      class="dataset-segments__questionnaire-controls"
      aria-label="qustionnaire controls"
    >
      <li class="dataset-segments__questionnaire-controls__item">
        <button class="button" id="expand" @click="expand(true)">
          <VisibleText>expand all</VisibleText>
        </button>
      </li>
      <li class="dataset-segments__questionnaire-controls__item">
        <button class="button" id="collapse" @click="expand(false)">
          <VisibleText>collapse all</VisibleText>
        </button>
      </li>
    </ul>
    <fieldset
      v-for="(category, indx) in Object.keys(ID_TO_QUESTION)"
      :key="category"
      :id="category"
      class="form-fieldset-group"
    >
      <Accordion
        class="dataset-segments__questionnaire-accordion"
        title-element-type="legend"
        :icon="accordionIcons"
        :expand="accordionSate[indx]"
        @toggled="toggle(indx, $event)"
      >
        <template #heading>
          <VisibleText>{{ ID_TO_QUESTION[category] }}</VisibleText>
          <span class="complete-icon" v-if="isQuestionComplete(category)">
            <SvgIconMeaningful
              class="complete-icon-svg"
              icon="check"
              title="complete"
            />
          </span>
        </template>
        <DatasetSegmentsItem
          v-for="item in ID_TO_SEGMENTS[category]"
          :category="category"
          :item="item"
          :key="item.value"
          :segment-data="segments[item.value]"
          :read-only="readOnly"
          @checked="checked"
        />
        <DatasetSegmentsCustom
          class="dataset-segments__questionnaire-custom--gray"
          description="Enter keywords that describe your data."
          label="Other"
          :category="category"
          :read-only="readOnly"
          @add-tag="$_datasetSegmentsMixin_addSegment(true, category, $event)"
          @remove-tag="
            $_datasetSegmentsMixin_removeSegment(true, category, $event)
          "
        />
      </Accordion>
    </fieldset>
  </div>
</template>

<script>
// Consts
import {
  ID_TO_QUESTION,
  ID_TO_SEGMENTS
} from "@/components/Dataset/Utils/constsDatasetSegments.js"

// Components
import Accordion from "@/components/UI/Accordion.vue"
import DatasetSegmentsCustom from "./DatasetSegmentsCustom/DatasetSegmentsCustom.vue"
import DatasetSegmentsItem from "./DatasetSegmentsItem/DatasetSegmentsItem.vue"
import SvgIconMeaningful from "@/components/UI/Svg/SvgIconMeaningful.vue"

// Mixins
import DatasetSegmentsMixin from "./Mixins/datasetSegmentsMixin.js"
import DatasetMixin from "@/utils/mixins/datasetMixin.js"

export default {
  name: "DatasetSegmentsQuestionnaire",
  mixins: [DatasetSegmentsMixin, DatasetMixin],
  components: {
    Accordion,
    DatasetSegmentsCustom,
    DatasetSegmentsItem,
    SvgIconMeaningful
  },
  props: {
    segments: { type: Object },
    readOnly: {
      default: () => false,
      type: Boolean
    }
  },
  data() {
    return {
      ID_TO_QUESTION: ID_TO_QUESTION,
      ID_TO_SEGMENTS: ID_TO_SEGMENTS,
      accordionIcons: {
        expanded: "chevronUp",
        collapsed: "chevronDown"
      },
      accordionSate: []
    }
  },
  created() {
    // setup accordion state for each accordion
    const length = Object.keys(this.ID_TO_QUESTION).length
    this.accordionSate = new Array(length).fill(false)
  },
  methods: {
    checked(checked, segment, clientQuestionID, questionValue, category) {
      if (checked) {
        let segmentObject = {}
        if (clientQuestionID || questionValue) {
          segmentObject.client_question_id = clientQuestionID
          // value is not empty string or null (so we add value key)
          if (!(Number(questionValue) == 0)) {
            segmentObject.value = Number(questionValue)
          }
        }
        this.$_datasetSegmentsMixin_addSegment(
          false,
          category,
          segment,
          segmentObject
        )
      } else {
        this.$_datasetSegmentsMixin_removeSegment(false, category, segment)
      }
    },
    isQuestionComplete(category) {
      return (
        this.datasetProject &&
        this.datasetProject.progress.step5 &&
        this.datasetProject.progress.step5.indexOf(category) > -1
      )
    },
    toggle(indx, state) {
      this.accordionSate.splice(indx, 1, state)
    },
    expand(val) {
      const length = Object.keys(this.ID_TO_QUESTION).length
      this.accordionSate = new Array(length).fill(val)
    }
  }
}
</script>
