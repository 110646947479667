var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"reset-password__wrapper"},[_c('div',{staticClass:"reset-password__container"},[_c('h1',{staticClass:"reset-password__title"},[_c('VisibleText',[_vm._v("Reset your password")])],1),(_vm.message)?_c('div',{staticClass:"reset-password__message"},[_c('p',[_vm._v(_vm._s(_vm.message))]),_c('router-link',{attrs:{"to":"/login"}},[_c('VisibleText',[_vm._v("Go to login page")])],1)],1):_vm._e(),(_vm.isResetFormVisible)?_c('ResetPassword',{ref:"reset-password",staticClass:"reset-password__form",attrs:{"uidb64":_vm.uidb64,"token":_vm.token},on:{"success":_vm.success},scopedSlots:_vm._u([{key:"password",fn:function(ref){
        var password = ref.password;
        var errors = ref.errors;
        var passwordBlurCheck = ref.passwordBlurCheck;
        var update = ref.update;
return [_c('label',{staticClass:"auth-form__label",attrs:{"for":"password"}},[_c('VisibleText',[_vm._v("Password")]),_c('input',{staticClass:"auth-form__input",attrs:{"id":"password","type":"password","required":"","aria-required":"true","aria-invalid":!!errors.length,"aria-describedby":"password-desc"},domProps:{"value":password},on:{"input":function($event){return update('password', $event.target.value)},"blur":passwordBlurCheck}}),(
              !errors.includes(
                'Passwords should be at least 10 characters long'
              )
            )?_c('span',{class:{ valid: password.length >= 10 },attrs:{"id":"password-desc"}},[_c('VisibleText',[_vm._v("Passwords should be at least 10 characters long ")])],1):_vm._e()],1)]}},{key:"passwordConf",fn:function(ref){
        var passwordConf = ref.passwordConf;
        var errors = ref.errors;
        var passwordConfBlurCheck = ref.passwordConfBlurCheck;
        var update = ref.update;
return [_c('label',{staticClass:"auth-form__label",attrs:{"for":"password-conf"}},[_c('VisibleText',[_vm._v("Confirm Password")]),_c('input',{ref:"password-conf",staticClass:"auth-form__input",attrs:{"id":"password-conf","type":"password","required":"","aria-required":"true","aria-invalid":!!errors.length},domProps:{"value":passwordConf},on:{"input":function($event){return update('passwordConf', $event.target.value)},"blur":passwordConfBlurCheck}})],1)]}}],null,false,3787171735)}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }