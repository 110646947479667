<template>
  <div>
    <article id="project" v-if="project">
      <!--------------------------------------------
      |
      | HEADER
      |
      --------------------------------------------->
      <div class="project__top-bar" :aria-hidden="modalOpen" :inert="modalOpen">
        <InlineEditTextInput
          label="project title"
          input-id="project-title-input"
          input-name="project-title"
          :disable-editing="isProjectReadOnly"
          :input-value="project.name"
          @save="saveProjectTitle"
        >
          <h1 class="project__title">
            {{ project.name }}
          </h1>
        </InlineEditTextInput>
        <span class="project__date">
          <VisibleText>Last modified on </VisibleText>
          {{
            project.last_modified_on.$date
              | formatDateTime($store.getters.getLanguage)
          }}
        </span>
      </div>

      <!--------------------------------------------
      |
      | TABS AND ACTIONS
      |
      --------------------------------------------->
      <div
        class="project__top-bar-2"
        :aria-hidden="modalOpen"
        :inert="modalOpen"
      >
        <ProjectTabs :project="project" />
        <SaveToPdf
          :document="report"
          class="project__top-bar-2-btn project__export-btn"
          v-if="$route.name === 'report-edit' && report"
        >
          <ExportIcon class="svg-icon" />
          <VisibleText>Export To PDF</VisibleText>
        </SaveToPdf>
        <button
          class="project__top-bar-2-btn btn-default"
          v-if="isProjectOwner"
          @click="isShareModalVisible = true"
        >
          <SvgIconDecorative icon="share" />
          <VisibleText>Share Project</VisibleText>
        </button>
      </div>

      <!--------------------------------------------
      |
      | MAIN CONTENT
      | if dataset was deleted, only allow the report
      |
      --------------------------------------------->
      <div class="project__content" v-if="!dataset && errDatasetDeleted">
        <DatasetDeletedMessage
          v-if="!['report', 'report-edit'].includes($route.name)"
        />
        <template v-else>
          <router-view
            :prop-read-only="isProjectReadOnly"
            @updateProject="saveProject"
            @loaded="report = $event"
          ></router-view>
        </template>
      </div>

      <div class="project__content" v-if="dataset && datasetDetailsLoaded">
        <ProjectSettings
          v-show="!['report-new', 'report-edit'].includes($route.name)"
        />

        <MessageBlock
          class="project__demo-msg"
          message-type="information"
          v-if="(isReadOnly || isDemoUser) && $route.name !== 'report-edit'"
        >
          <VisibleText
            >This is a read-only project. Any changes you make will not be
            saved.
          </VisibleText>
        </MessageBlock>

        <DatasetSummary
          v-if="!['report-new', 'report-edit', 'report'].includes($route.name)"
          :aria-hidden="modalOpen"
          :inert="modalOpen"
        />

        <keep-alive exclude="PigeonDoc">
          <router-view
            :prop-read-only="isProjectReadOnly"
            @updateProject="saveProject"
            @loaded="loaded"
          ></router-view>
        </keep-alive>
      </div>
    </article>

    <!--------------------------------------------
      |
      | SHARE MODAL
      |
      --------------------------------------------->
    <ProjectShareModal
      :project="project"
      :dataset="datasetProject"
      :show="isShareModalVisible"
      @closeModal="closeModal"
    />
  </div>
</template>

<script>
// Components
import DatasetSummary from "@/components/Dataset/DatasetSummary.vue"
import DatasetDeletedMessage from "@/components/Project/ProjectDatasetDeletedMessage.vue"
import InlineEditTextInput from "@/components/UI/InlineEditTextInput.vue"
import ProjectTabs from "@/components/Project/ProjectTabs.vue"
import ProjectSettings from "@/components/Project/ProjectSettings/ProjectSettings.vue"
import ProjectShareModal from "@/components/Share/ShareProject.vue"
import { SaveToPdf } from "@pigeonline/pigeondoc"
import SvgIconDecorative from "@/components/UI/Svg/SvgIconDecorative.vue"
import MessageBlock from "@/components/UI/Message/MessageBlock.vue"

// Icons
import ExportIcon from "@/assets/icons/export.svg"

// Mixins
import ProjectMixin from "@/utils/mixins/projectMixin.js"
import DatasetDetailsMixin from "@/utils/mixins/datasetDetailsMixin.js"
import SharedStatusMixin from "@/utils/mixins/sharedStatusMixin.js"

// Utils
import { mapActions, mapGetters } from "vuex"
import {
  fetchDataset,
  fetchDatasetProjectByDatasetId
} from "@/components/Dataset/Utils/fetch.js"
import { fetchProject } from "@/components/Project/Utils/fetch.js"

export default {
  name: "Project",
  mixins: [ProjectMixin, DatasetDetailsMixin, SharedStatusMixin],
  components: {
    DatasetSummary,
    DatasetDeletedMessage,
    InlineEditTextInput,
    ProjectTabs,
    ProjectSettings,
    ProjectShareModal,
    SaveToPdf,
    SvgIconDecorative,
    MessageBlock,
    ExportIcon
  },
  data() {
    return {
      isShareModalVisible: false,
      errDatasetDeleted: false,
      report: null
    }
  },
  beforeRouteEnter(to, from, next) {
    if (to.params.projid) {
      Promise.all([fetchProject(to.params.projid)])
        .then(async project => {
          const dataset = await fetchDataset(project[0].datasets[0])
          if (dataset) {
            next(vm => vm.setData(null, project[0], dataset))
          } else {
            next(vm => vm.setData("dataset-deleted", project[0], {}))
          }
        })
        .catch(err => next(vm => vm.setData(err)))
    } else {
      next()
    }
  },
  beforeRouteLeave(to, from, next) {
    this.resetDatasetDetails()
    this.resetProject()
    this.resetConfirm()
    this.$store.dispatch("analysisText/resetAnalysisText")
    next()
  },
  mounted() {
    this.isShareModalVisible = this.$route.query.share == "true"
  },
  computed: {
    ...mapGetters("datasetWizard", {
      datasetProject: "getDatasetProject"
    }),
    isProjectReadOnly() {
      return this.isDemoUser || this.isReadOnly
    }
  },
  methods: {
    ...mapActions("datasetWizard", ["setDatasetProject"]),
    /**
     * when pigeondoc (report) is loaded add theme to class name
     * to apply the appropriate themeing
     * FIXME: temporary solution, need to apply themeing to pigondoc in future
     */
    loaded(report) {
      this.report = report
      const theme = this.$theme.theme.name
      const pigeondocWrapper = document.getElementsByClassName("pigeondoc")
      pigeondocWrapper[0].classList.add(`theme-${theme}`)
      document.title = `${this.project.name} - Report | ${this.$theme.theme.pageTitle}`
    },
    onFetchError() {
      this.setConfirmText({
        btn: "okay",
        title: "Error Occured",
        message:
          "Sorry, there was a problem fetching your project. Please try again."
      })
      this.setConfirmSourceComponent("project")
      this.setConfirmType("error")
      this.setConfirmIsVisible(true)
    },
    async setData(err, project, dataset) {
      if (err === "dataset-deleted") this.errDatasetDeleted = true
      else if (err) this.onFetchError()
      else {
        this.isReadOnly = this.checkIsReadOnly(project)
        await this.setProject(project)
        await this.setDataset(dataset)
        await this.loadDatasetDetails(dataset)
        await this.setDatasetProject(
          (await fetchDatasetProjectByDatasetId(dataset._id.$oid))[0]
        )
      }
    },
    async saveProjectTitle(text) {
      try {
        this.project.name = text
        this.saveProject(this.project)
      } catch (e) {
        throw new Error("Project.vue:saveProject " + e.message)
      }
    },

    /* Modal actions */
    closeModal() {
      this.isShareModalVisible = false
    }
  },
  watch: {
    errDatasetDeleted: function(isDeleted) {
      if (isDeleted) {
        document.title = `${this.project.name} | ${this.$theme.theme.pageTitle}`
      }
    }
  }
}
</script>
