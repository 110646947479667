<template>
  <div :class="['response-list-item', { 'is-pinned': isPinned }]">
    <span class="response-list-item__is-pinned-label" v-if="isPinned">
      <VisibleText>Pinned</VisibleText></span
    >
    <template v-if="!isHidden">
      <div class="response-list-item__header">
        <span class="response-list-item__header-title">
          <VisibleText>Respondent</VisibleText> {{ item.idx + 1 }}
        </span>
        <button
          class="response-list-item__header-btn"
          type="button"
          :aria-expanded="isRespondentInfoExpanded ? 'true' : 'false'"
          @click.stop="toggleRespondentInfo"
        >
          <SvgIconMeaningful
            :icon="isRespondentInfoExpanded ? icon.expanded : icon.collapsed"
            :class="[
              'response-list-item__header-btn-icon',
              { collapsed: !isRespondentInfoExpanded }
            ]"
            title="Show respondent info"
          />
        </button>
      </div>
      <RespondentInfo
        :response-id="item.id"
        :client-question-id="clientQuestionId"
        :show="isRespondentInfoExpanded"
        v-show="isRespondentInfoExpanded"
      />
      <UISelectable
        ref="ui-selectable"
        class="response-list-item__selectable-text"
        @excerpt="handleSelectableEvent('excerpt', $event)"
        @keyword="handleSelectableEvent('keyword', $event)"
        @note="handleSelectableEvent('note', $event)"
        @ban="handleSelectableEvent('ban', $event)"
        @search="handleSelectableEvent('search', $event)"
        @remove-keyword="handleSelectableEvent('remove-keyword', $event)"
        @remove-excerpt="handleSelectableEvent('remove-excerpt', $event)"
        @remove-note="handleSelectableEvent('remove-note', $event)"
        @tool-option-selected="$emit($event)"
      >
        <p class="highlighted-content" v-html="highlightedHTMLString" />
      </UISelectable>
      <div
        class="response-list-item__options sr-show-on-focus"
        tabindex="0"
        :aria-label="`Respondent ${item.idx + 1} options`"
      >
        <button
          type="button"
          class="response-list-item__options-btn"
          @click="$emit('toggle-response-pin', item.id)"
        >
          <SvgIconDecorative icon="pin" />
          <VisibleText>{{ isPinned ? "Unpin" : "Pin" }}</VisibleText>
        </button>
        <button
          type="button"
          class="response-list-item__options-btn"
          @click="confirmBanResponse"
        >
          <SvgIconDecorative icon="ban" />
          <VisibleText>Ban</VisibleText>
        </button>
        <button
          type="button"
          class="response-list-item__options-btn"
          @click="$emit('toggle-response-hide', item.id)"
        >
          <SvgIconDecorative icon="eye" />
          <VisibleText>Hide</VisibleText>
        </button>
      </div>
    </template>
    <div class="response-list-item__is-hidden" v-else>
      <button
        type="button"
        class="response-list-item__options-btn show-response-btn"
        @click="$emit('toggle-response-hide', item.id)"
      >
        <SvgIconDecorative icon="eye" />
        <VisibleText>Show hidden response</VisibleText>
      </button>
    </div>
  </div>
</template>

<script>
// Components
import SvgIconMeaningful from "@/components/UI/Svg/SvgIconMeaningful.vue"
import SvgIconDecorative from "@/components/UI/Svg/SvgIconDecorative.vue"
import RespondentInfo from "./ResponseListItemRespondentInfo.vue"
import UISelectable from "../UI/Selectable.vue"

// Mixins
import ConfirmMixin from "/src/utils/mixins/confirmMixin.js"

// Utils
import { CustomIntervalTree } from "../Utils/highlight.js"

// Consts
import { HIGHLIGHT_TYPES } from "../Utils/constants.js"

export default {
  name: "ResponseListItem",
  mixins: [ConfirmMixin],
  components: {
    SvgIconMeaningful,
    SvgIconDecorative,
    RespondentInfo,
    UISelectable
  },
  props: {
    item: {
      type: Object
    },
    clientQuestionId: {
      type: String
    },
    isPinned: {
      type: Boolean,
      default: () => false
    },
    isHidden: {
      type: Boolean,
      default: () => false
    },
    highlightItems: {
      type: Object,
      default: () => []
    }
  },
  data() {
    return {
      highlightedHTMLString: "",
      isComponentMounted: false,
      isRespondentInfoExpanded: false,
      isWrapMarkPending: false,
      icon: {
        collapsed: "arrowUp",
        expanded: "arrowDown"
      }
    }
  },
  computed: {
    highlightRanges: function() {
      return this.convertHighlightItemsToRanges(this.highlightItems)
    },
    highlightRangesTree: function() {
      return new CustomIntervalTree(this.highlightRanges).getTree()
    },
    textResponseContent: function() {
      return this.item && this.item.response
    },
    selectedTextQuestionId: function() {
      return this.$store.getters["analysisText/getSelectedTextQuestion"]._id
        .$oid
    },
    $UISelectable: function() {
      if (!this.isComponentMounted) return
      return this.$refs["ui-selectable"]
    }
  },
  mounted() {
    this.isComponentMounted = true
    this.highlightedHTMLString = this.createHighlightedHTMLString()
  },
  methods: {
    toggleRespondentInfo: function() {
      this.isRespondentInfoExpanded = !this.isRespondentInfoExpanded
    },
    handleSelectableEvent: function(event, selection) {
      if (typeof selection === "object") {
        this.$emit(event, {
          responseId: this.item.id,
          ...selection,
          source: {
            questionId: this.selectedTextQuestionId,
            responseText: this.textResponseContent
          }
        })
        return
      }
      this.$emit(event, selection)
    },
    confirmBanResponse: function() {
      if (this.propReadOnly) return
      this.setConfirmText({
        btn: "yes",
        title: "Confirm ban response?",
        message: "Are you sure you want to ban the selected response item?"
      })
      this.setConfirmType("delete")
      this.setConfirmSourceComponent("ResponseListItem")
      this.setConfirmStatus(false)
      this.setConfirmTarget(this.item.id)
      this.setConfirmIsVisible(true)
    },

    /* highlighting methods */
    convertHighlightItemsToRanges: function(items) {
      const ranges = []
      for (let [type, arr] of Object.entries(items)) {
        // continue if not a supported highlight type or arr is not an arry
        if (!Object.keys(HIGHLIGHT_TYPES).includes(type)) continue
        if (!arr || !Array.isArray(arr)) continue

        for (let el of arr) {
          if (typeof el === "object" && el.responseId === this.item.id) {
            ranges.push({
              begin: el.startIndex,
              end: el.startIndex + el.content.length,
              text: el.content,
              tooltipText: el.text, // note text
              type: type
            })
          } else if (typeof el === "string" && el.trim() !== "") {
            let re = new RegExp(el, "gi")
            let match
            while ((match = re.exec(this.textResponseContent)) !== null) {
              ranges.push({
                begin: match.index,
                end: match.index + el.length,
                text: el,
                type: type
              })
            }
          }
        }
      }
      return ranges
    },
    createHighlightedHTMLString() {
      const root = this.highlightRangesTree[0]
      const rootText = this.textResponseContent

      const processNode = (parentNode, startIndex = 0) => {
        let children = parentNode.children.sort((c1, c2) => c1.a - c2.a)

        // return text content if no or invalid children
        if (!Array.isArray(children) || children.length === 0) {
          return rootText.slice(parentNode.a, parentNode.b)
        }

        let string = ""
        for (let i = 0; i < children.length; i++) {
          let childNode = children[i]

          string += rootText.slice(startIndex, childNode.a)
          startIndex = childNode.a

          string +=
            "<mark class='main__highlight " +
            HIGHLIGHT_TYPES[childNode.data.type].className +
            " " +
            Array.from(childNode.meta.classNames).join(" ") +
            "' data-id='" +
            childNode.data.id +
            "' data-type='" +
            childNode.data.type +
            "' data-a='" +
            childNode.a +
            (childNode.data.tooltipText
              ? "' data-tooltip='" + childNode.data.tooltipText
              : "") +
            (childNode.meta.attrs.has("no-tabindex")
              ? "' data-tabindex='-1"
              : "") +
            "'>" +
            processNode(childNode, startIndex) +
            "</mark>"

          // move start index to end of child block
          startIndex = childNode.b

          if (i === children.length - 1) {
            // last node reached
            string += rootText.slice(childNode.b, parentNode.b)
            startIndex = parentNode.b
          }
        }

        return string
      }

      return processNode(root)
    }
  },
  watch: {
    confirmStatus: async function(val) {
      // only delete if status is true
      if (
        val &&
        this.confirmSourceComponent === "ResponseListItem" &&
        this.confirmType === "delete" &&
        this.confirmTarget === this.item.id
      ) {
        await this.$emit("ban-response", this.item.id)
      }
    },
    highlightItems: function(val) {
      if (!val || typeof val !== "object") return
      this.highlightedHTMLString = this.createHighlightedHTMLString()
      this.isWrapMarkPending = true
    },
    highlightedHTMLString: function(val) {
      if (!val || val === "") return
      this.$nextTick(() => {
        // wrap mark elements
        if (this.$UISelectable) {
          this.$UISelectable.wrapMark()
          this.isWrapMarkPending = false
        }
      })
    },
    $UISelectable: function(val) {
      if (!val) return
      this.$nextTick(() => {
        // wrap mark elements
        if (this.isWrapMarkPending) {
          this.$UISelectable.wrapMark()
          this.isWrapMarkPending = false
        }
      })
    }
  }
}
</script>
