import PerformanceAnalysisChart from "../helpers/performanceAnalysisChart.js"
import { HELPER_TRANSLATIONS, SECTION_TRANSLATIONS } from "../translations.js"
const lang = window.localStorage.getItem("apiLanguage") || "en"

const performanceSectionHeader = () => {
  const heading = {
    type: "heading",
    content: SECTION_TRANSLATIONS.performanceHeading[lang],
    id: "section-title-performance",
    meta: {
      level: "h1",
      pdfConfig: {
        pageBreak: "before"
      }
    }
  }
  const description = {
    type: "text",
    content: SECTION_TRANSLATIONS.performanceDesc[lang],
    id: "section-description-performance"
  }

  return [heading, description]
}

const performanceSection = (
  data,
  pChartType,
  cChartType,
  cChartNodes,
  cChartNodesTitles,
  uuid,
  index
) => {
  const performanceTitle = {
    type: "heading",
    content: `${SECTION_TRANSLATIONS.performanceTitle[lang]} ${index}`,
    id: "subsection-title-performance",
    meta: {
      level: "h2",
      report: {
        uuid: uuid, // unique subsection id for each performance analysis
        index: index // performance subsection index -- NOT to confuse with report index
      },
      pdfConfig: {
        pageBreak: "before"
      }
    }
  }
  const performanceChart = PerformanceAnalysisChart(
    pChartType,
    data,
    SECTION_TRANSLATIONS.performanceChart[lang]
  )
  let performance = [performanceTitle, performanceChart]

  if (
    cChartType &&
    cChartNodes &&
    Array.isArray(cChartNodes) &&
    cChartNodes.length > 0
  ) {
    const comparisonTitle = {
      type: "heading",
      content: "Comparison across segments",
      id: "subsubsection-title-performance",
      meta: {
        level: "h3"
      }
    }
    performance.push(comparisonTitle)

    /**
     * TODO: update pdf to support table styling,
     * set remove pdfConfig.ignore when complete
     **/
    const legendText =
      `<div class="comparison-analysis-legend"><strong>Legend</strong><br/>` +
      `<span class="gte">${HELPER_TRANSLATIONS.comparisonGreater[lang]}</span>` +
      `<span class="lt">${HELPER_TRANSLATIONS.comparisonLower[lang]}</span></div>`
    performance.push({
      type: "text",
      content: legendText,
      meta: {
        pdfConfig: {
          style: "comparison-analysis-legend",
          ignore: true
        }
      }
    })

    for (let i = 0; i < cChartNodes.length; i++) {
      performance = performance.concat([cChartNodes[i]])
    }
  }
  return performance
}

export default {
  performanceSectionHeader,
  performanceSection
}
