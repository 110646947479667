// Consts
import {
  HIGHLIGHT_TYPES,
  SELECTABLE_TOOL_OPTIONS,
  SELECTABLE_TOOL_OPTIONS_DEFAULT
} from "../Utils/constants.js"

// Utils
function removeElementsByClass(parent, className) {
  var elements = parent.getElementsByClassName(className)
  while (elements.length > 0) {
    elements[0].parentNode.removeChild(elements[0])
  }
}

export default {
  data() {
    return {
      x: 0,
      y: 0,
      showTools: false,
      selectedHighlightNode: null,
      selectedText: "",
      tooltip: {
        isVisible: false,
        tooltipText: "",
        x: 0,
        y: 0,
        source: null
      },
      isMounted: false
    }
  },
  computed: {
    selectableEl() {
      return this.$slots.default[0].elm
    },
    selectedThemeIndex() {
      return this.$store.getters["analysisText/getSelectedThemeIndex"]
    },
    selectedToolOptions() {
      let options = SELECTABLE_TOOL_OPTIONS_DEFAULT
      if (
        this.selectedHighlightNode &&
        this.selectedHighlightNode.dataset.type
      ) {
        const highlightType =
          HIGHLIGHT_TYPES[this.selectedHighlightNode.dataset.type]
        if (highlightType && highlightType.toolOptions) {
          options = highlightType.toolOptions
        }
      }
      return options.map(option => SELECTABLE_TOOL_OPTIONS[option])
    }
  },
  methods: {
    clipTools() {
      // handle show tools clipping
      if (this.x < this.$refs["selectable-tools"].offsetWidth / 2) {
        this.x = this.$refs["selectable-tools"].offsetWidth / 2 + 5
      }
    },
    closeTools() {
      this.showTools = false
      this.selectedHighlightNode = null
      this.selectedText = ""
      this.tooltip.isVisible = false

      this.resetHighlightNodes()
    },
    showSelectableTools(childNode, parentNode = null) {
      if (!parentNode) parentNode = this.$refs["selectable-container"]

      const parentPos = parentNode.getBoundingClientRect()
      const childPos = childNode.getBoundingClientRect()
      const top = childPos.top - parentPos.top
      const left = childPos.left - parentPos.left
      const width = childPos.width
      if (!width) {
        this.closeTools()
        return
      }
      this.x = left + width / 2
      this.y = top - 10
      this.showTools = true

      this.$nextTick(function() {
        // clip tools
        this.clipTools()
      })
    },
    showTooltip(targetNode, source = null) {
      let tooltipText = targetNode.dataset && targetNode.dataset.tooltip

      // do not show tooltip if tooltipText or targetNode not present
      if (!tooltipText || !targetNode) {
        this.tooltip.isVisible = false
        this.tooltip.source = source
        return
      }

      const parentPos = this.$refs[
        "selectable-container"
      ].getBoundingClientRect()
      const childPos = targetNode.getBoundingClientRect()
      const top = childPos.top - parentPos.top
      const left = childPos.left - parentPos.left
      const height = childPos.height
      const width = childPos.width
      if (!width) return

      this.tooltip.isVisible = true
      this.tooltip.tooltipText = tooltipText
      this.tooltip.x = left + width / 2
      this.tooltip.y = top + height + 10
      this.tooltip.source = source
    },
    wrapElement(el) {
      // already has the markup, skip it
      if (el.parentNode && el.parentNode.nodeName === "BUTTON") return

      let container = document.createElement("button")
      container.setAttribute("class", "highlighted-container")
      container.setAttribute("type", "button")
      container.setAttribute("aria-describedby", el.dataset.type)
      if (el.dataset.tabindex === "-1")
        container.setAttribute("tabindex", el.dataset.tabindex)
      container.addEventListener("click", this.onHighlightSelect, false)

      let clonedMark = el.cloneNode(true)
      // if (el.dataset.type === "theme_note") {
      //   clonedMark.addEventListener("mouseenter", this.onNoteMouseOver, false)
      //   clonedMark.addEventListener("mouseleave", this.onNoteMouseOut, false)
      // }
      container.appendChild(clonedMark)

      el.replaceWith(container)
    },
    wrapMark(parentElement = null) {
      if (!this.selectableEl) return
      if (!parentElement) {
        parentElement = this.selectableEl
      }
      const highlighted = parentElement.querySelectorAll(
        ":scope > .main__highlight"
      )
      for (let i = 0; i < highlighted.length; ++i) {
        // already has the markup, skip it
        if (
          highlighted[i].parentNode &&
          highlighted[i].parentNode.nodeName === "BUTTON"
        )
          return
        this.wrapMark(highlighted[i])
      }
      if (!this.selectableEl.isSameNode(parentElement)) {
        this.wrapElement(parentElement)
      }
    },

    /* selection methods */
    snapSelectionToWord() {
      var sel

      // Check for existence of window.getSelection() and that it has a
      // modify() method. IE 9 has both selection APIs but no modify() method.
      if (window.getSelection && (sel = window.getSelection()).modify) {
        sel = window.getSelection()
        if (!sel.isCollapsed) {
          // Detect if selection is backwards
          var range = document.createRange()
          range.setStart(sel.anchorNode, sel.anchorOffset)
          range.setEnd(sel.focusNode, sel.focusOffset)
          var backwards = range.collapsed
          range.detach()

          // modify() works on the focus of the selection
          var endNode = sel.focusNode,
            endOffset = sel.focusOffset
          sel.collapse(sel.anchorNode, sel.anchorOffset)

          var direction = []
          if (backwards) {
            direction = ["backward", "forward"]
          } else {
            direction = ["forward", "backward"]
          }

          sel.modify("move", direction[0], "character")
          sel.modify("move", direction[1], "word")
          sel.extend(endNode, endOffset)
          sel.modify("extend", direction[1], "character")
          sel.modify("extend", direction[0], "word")
        }
      } else if ((sel = document.selection) && sel.type != "Control") {
        var textRange = sel.createRange()
        if (textRange.text) {
          textRange.expand("word")
          // Move the end back to not include the word's trailing space(s),
          // if necessary
          while (/\s$/.test(textRange.text)) {
            textRange.moveEnd("character", -1)
          }
          textRange.select()
        }
      }
    },
    getSelectedTextInfo() {
      if (
        this.selectedHighlightNode &&
        this.selectedHighlightNode.firstChild &&
        this.selectedHighlightNode.firstChild.dataset &&
        this.selectedHighlightNode.firstChild.dataset.a
      ) {
        return {
          startIndex: this.selectedHighlightNode.firstChild.dataset.a,
          length: this.selectedHighlightNode.textContent.length,
          content: this.selectedHighlightNode.textContent
        }
      }

      const selection = window.getSelection()
      const selectionRange = selection.getRangeAt(0)
      let startIndex = selectionRange.startOffset
      let startContainer = selectionRange.startContainer
      while (!startContainer.previousSibling) {
        if (startContainer.parentNode.isEqualNode(this.selectableEl)) break
        startContainer = startContainer.parentNode
      }
      let prevNode = startContainer.previousSibling
      while (prevNode) {
        startIndex += prevNode.textContent.length
        while (!prevNode.previousSibling) {
          if (prevNode.parentNode.isEqualNode(this.selectableEl)) break
          prevNode = prevNode.parentNode
        }
        prevNode = prevNode.previousSibling
      }

      return {
        startIndex:
          startIndex +
          (selection.toString().length -
            selection.toString().trimStart().length), // add trimmed white spaces
        length: selection.toString().trim().length,
        content: selection.toString().trim()
      }
    },
    selectElement(event) {
      if (!event.target.classList.contains("main__highlight")) {
        return
      }
      var sel, range
      var el = event.target
      if (window.getSelection && document.createRange) {
        //Browser compatibility
        sel = window.getSelection()
        if (sel.toString() == "") {
          //no text selection
          range = document.createRange() //range object
          range.selectNodeContents(el) //sets Range
          sel.removeAllRanges() //remove all ranges from selection
          sel.addRange(range) //add Range to a Selection.
        }
      } else if (document.selection) {
        //older ie
        sel = document.selection.createRange()
        if (sel.text == "") {
          //no text selection
          range = document.body.createTextRange() //Creates TextRange object
          range.moveToElementText(el) //sets Range
          range.select() //make selection.
        }
      }
    },

    /* selection methods and events */
    resetHighlightNodes() {
      // remove hidden class from existing elements
      const hiddenNodes = this.selectableEl.querySelectorAll(".hidden")
      for (let i = 0; i < hiddenNodes.length; i++) {
        hiddenNodes[i].classList.remove("hidden")
      }

      // delete useless elements
      removeElementsByClass(this.selectableEl, "delete")
    },
    onHighlightSelect($e) {
      // reset highlight nodes to original form
      this.resetHighlightNodes()

      const targetNodeId = $e.target.dataset.id
      const highlightNodes = this.selectableEl.querySelectorAll(
        `[data-id='${targetNodeId}']`
      )
      if (highlightNodes.length > 1) {
        const targetNodeIndex = Array.from(highlightNodes).indexOf($e.target)
        const targetChildNodes = $e.target.cloneNode(true).childNodes
        $e.target.innerHTML = ""

        // intersecting highlights with parts
        for (let i = 0; i < highlightNodes.length; i++) {
          let clonedNode = highlightNodes[i].cloneNode(true)
          if (i === targetNodeIndex) {
            // append target child nodes as is
            $e.target.append(...targetChildNodes)
            continue
          }
          for (let cNode of Array.from(clonedNode.childNodes)) {
            // add child nodes from target's siblings
            if (cNode.nodeType === 3) {
              const span = document.createElement("span")
              span.textContent = cNode.nodeValue
              cNode = span
            }
            cNode.classList.add("delete")
            $e.target.appendChild(cNode)
          }
          highlightNodes[i].classList.add("hidden")
        }
      }
      this.selectedText = $e.target.textContent
      this.selectedHighlightNode = $e.target
      this.showSelectableTools($e.target)
      this.$nextTick(function() {
        this.$refs["selectable-tools"].focus()

        // select element
        this.selectElement($e)

        // show tooltip
        this.showTooltip($e.target)
      })
    },
    onMouseUp() {
      const selection = window.getSelection()

      // iterrupt mosue up event if the element clicked on was a highlighted word
      if (!selection.anchorNode || selection.anchorNode.nodeName === "MARK")
        return

      let startNode, endNode
      try {
        startNode = selection.getRangeAt(0).startContainer.parentNode
        endNode = selection.getRangeAt(0).endContainer.parentNode
      } catch (e) {
        this.closeTools()
        return
      }

      this.snapSelectionToWord()

      // selected content is not within the component
      if (
        selection.toString().trim() == "" ||
        !this.selectableEl.contains(startNode) ||
        !this.selectableEl.contains(endNode)
      ) {
        this.closeTools()
        return
      }

      this.selectedText = selection.toString()
      this.showSelectableTools(selection.getRangeAt(0), this.$el)
    },
    onNoteMouseOver($e) {
      if (this.tooltip.isVisible) return
      this.showTooltip($e.target, "mouseover")
    },
    onNoteMouseOut() {
      if (this.tooltip.isVisible && this.tooltip.source === "mouseover") {
        this.tooltip.isVisible = false
        this.tooltip.source = null
      }
    },
    stopPropagating($e) {
      $e.stopPropagation()
    }
  }
}
