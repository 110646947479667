<template>
  <span :style="{ height: height, width: width }" class="loadingSkeletonBox" />
</template>

<script>
export default {
  name: "LoadingSkeletonBox",
  props: {
    height: {
      default: "1em",
      type: String
    },
    width: {
      default: "100%",
      type: String
    }
  }
}
</script>
