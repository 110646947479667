// Components
import { CreateProject } from "@pigeonline/core"
import DatasetLayout from "@/components/Layouts/DatasetLayout.vue"
import Spinner from "@/components/UI/Spinner.vue"
import MessageBlock from "@/components/UI/Message/MessageBlock.vue"

// Icons
import UploadCloudIcon from "@/assets/icons/Datasets/upload-cloud.svg"

// Mixins
import DatasetMixin from "@/utils/mixins/datasetMixin"
import DatasetDetailsMixin from "@/utils/mixins/datasetDetailsMixin"

export default {
  name: "DatasetUploader",
  components: {
    CreateProject,
    DatasetLayout,
    DatasetUploaderReupload: () => import("./DatasetUploaderReupload.vue"),
    Spinner,
    MessageBlock,
    UploadCloudIcon
  },
  mixins: [DatasetMixin, DatasetDetailsMixin],
  data() {
    return {
      currDatasetProjectId: null,
      errMsg: {
        dataSheet: null,
        file: null,
        fileName: null
      },
      form: {
        file: null,
        sheets: null,
        selectedSheet: null,
        selectedCodebookSheet: null
      },
      uploadResponse: null,
      isCodebookUploaded: false,
      isDataChanged: false,
      isExcelMultiSheetUploaded: false,
      isLoading: false,
      isShowingReupload: false,
      isCodebookErr: false
    }
  },
  mounted() {
    // fix firefox bug where file upload focus is not recognized
    const fileInput = document.getElementById("dataset-file-upload")
    fileInput.addEventListener("focus", () => {
      fileInput.classList.add("has-focus")
    })
    fileInput.addEventListener("blur", () => {
      fileInput.classList.remove("has-focus")
    })
  },
  activated() {
    // if dataset exists set file name and disable upload
    if (Object.keys(this.dataset).length > 0) {
      this.$refs.file.disabled = true
      this.$refs["file-upload-label"].innerHTML = this.dataset.name
      this.$refs["file-label"].classList.add("disabled")
    }
  },
  deactivated() {
    // reset data change value
    this.isDataChanged = false
    if (!this.dataset) {
      this.$destroy()
    }
  },
  methods: {
    clearErrors() {
      this.errMsg = {
        dataSheet: null,
        file: null,
        fileName: null
      }
    },
    validate() {
      let firstErr = null
      if (!this.form.file) {
        this.errMsg.file = "Error: No file uploaded"
        this.setDeleteIncompleteUpload(this.currDatasetProjectId)
        firstErr = "file"
      }
      if (!this.datasetProject.name || this.datasetProject.name.length === 0) {
        this.errMsg.fileName = "Error: File name required"
        if (!firstErr) firstErr = "file-name"
      }
      if (
        (this.datasetProject.file_type === "xls" ||
          this.datasetProject.file_type === "xlsx") &&
        this.isExcelMultiSheetUploaded
      ) {
        if (!this.form.selectedSheet) {
          this.errMsg.dataSheet = "Error: No data sheet selected"
          if (!firstErr) firstErr = "select-sheet"
        }
      }
      if (firstErr) this.$refs[firstErr].focus()
      return !(firstErr && firstErr.length > 0)
    },
    /***********
     *
     * Form related helper methods
     *
     ***********/
    onFileChange() {
      // reset error
      this.clearErrors()

      // set file details
      this.form.file = this.$refs.file.files[0]
      this.datasetProject.name = this.$refs.file.files[0].name.substring(
        0,
        this.$refs.file.files[0].name.lastIndexOf(".")
      )
      this.datasetProject.file_type = this.$refs.file.files[0].name.substring(
        this.$refs.file.files[0].name.lastIndexOf(".") + 1,
        this.$refs.file.files[0].name.length
      )

      // change label to file name
      const $label = this.$refs["file-upload-label"]
      $label.innerHTML = this.$refs.file.files[0].name

      // if it is an excel sheet, user should select what sheet to use
      if (
        this.datasetProject.file_type === "xls" ||
        this.datasetProject.file_type === "xlsx"
      ) {
        this.processExcel()
      } else if (this.datasetProject.file_type === "sav") {
        this.isCodebookUploaded = true
      } else {
        this.isExcelMultiSheetUploaded = false
        this.selectedSheet = null
        this.selectedCodebookSheet = null
      }
    },
    onNameChange() {
      this.isDataChanged = true
      this.errMsg.fileName = null
      this.$refs["file-upload-label"].innerHTML = this.datasetProject.name
    },
    onSelectSheetChange() {
      if (this.form.selectedSheet) this.errMsg.dataSheet = null
    },

    /***********
     *
     * Dataset project methods
     *
     ***********/
    async datasetProjectCreated(project) {
      this.currDatasetProjectId = project.id
      this.setDatasetProject(project)

      if (!this.validate()) return

      // upload, or edit the dataset if it was a single excel sheet
      const response =
        Object.keys(this.dataset).length > 0
          ? await this.edit()
          : await this.uploadDataset()
      this.uploadResponse = response

      // get dataset id
      const datasetId =
        response && response.data_set_id
          ? response.data_set_id.$oid
          : this.dataset._id.$oid
      this.afterDatasetProjectCreated(datasetId)
    },
    datasetProjectCreationError() {
      if (!this.validate()) return
    },

    /***********
     *
     * File upload or edit methods
     *
     ***********/
    async codebookReuploaded(response) {
      if (response.non_empty_question_text_count > 0) {
        this.datasetProject.no_codebook_matches = false
        await this.saveDatasetProject(this.datasetProject)
      }
      this.setDataset(response.data_set_object)
      this.isShowingReupload = false
      this.isCodebookErr = false
      this.$router.push({
        name: "dataset",
        params: { id: this.datasetProject.dataset_id },
        query: { project_id: this.datasetProject.id, from: "datasetUploader" }
      })
    },
    /**
     * When an excel is uploaded there are two cases to consider
     * 1. multi-sheet: upload is canceled, the excel sheets are returned
     * 2. singel-sheet: upload is sucess and the dataset id is returned
     */
    async processExcel() {
      this.isLoading = true
      const response = await this.uploadDataset()
      if (response.sheet_names) {
        this.form.sheets = response.sheet_names
        // auto select if it follows template
        if (
          this.form.sheets.some(sheet =>
            ["Data Sheet", "Codebook Sheet"].includes(sheet)
          )
        ) {
          this.form.selectedSheet = "Data Sheet"
          this.form.selectedCodebookSheet = "Codebook Sheet"
        }
        this.isExcelMultiSheetUploaded = true
      } else {
        const dataset = await this.$services.DATASETS_SERVICE.dataset(
          response.data_set_id.$oid
        )
        this.setDataset(dataset)
      }
      this.isLoading = false
    },
    async uploadDataset() {
      let formData = new FormData()
      formData.append("file", this.form.file)
      formData.append("name", this.datasetProject.name)
      if (this.form.selectedSheet)
        formData.append("sheet_name", this.form.selectedSheet)
      if (this.form.selectedCodebookSheet) {
        this.isCodebookUploaded = true
        formData.append("code_book", this.form.file)
        formData.append("code_book_sheet_name", this.form.selectedCodebookSheet)
      }

      try {
        this.isLoading = true
        const response = await this.$services.DATASETS_SERVICE.upload(formData)
        this.isLoading = false
        return response.data
      } catch (e) {
        this.setDeleteIncompleteUpload(this.currDatasetProjectId)
        alert("There was an error uploading the file. Please try again")
        throw new Error("DatasetUploader:upload " + e.message)
      }
    },
    async edit() {
      try {
        const response = await this.editDataset()
        this.setDataset(response.data_set_object)
        return response
      } catch (e) {
        alert("There was an error uploading the file. Please try again")
        throw new Error("DatasetUploader:edit " + e.message)
      }
    },
    async afterDatasetProjectCreated(datasetId) {
      // update project status and add dataset id
      this.datasetProject.updateStatus("datasetUploadingCompleted")
      this.datasetProject.dataset_id = datasetId
      await this.saveDatasetProject(this.datasetProject)

      // set created on in dataset object FIXME: do we need this?
      await this.$services.DATASETS_SERVICE.update(datasetId, {
        created_on: this.datasetProject.last_modified_on.$date
      })

      // codebook matches were not found
      if (
        !this.isCodebookUploaded ||
        (this.uploadResponse &&
          this.uploadResponse.non_empty_question_text_count == 0)
      ) {
        this.isCodebookErr = true
        this.datasetProject.no_codebook_matches = true
        this.saveDatasetProject(this.datasetProject)
        this.setDataset(
          await this.$services.DATASETS_SERVICE.dataset(datasetId)
        )
        return
      }

      // update route to inprogress project route
      this.clearErrors()
      this.$router.push({
        name: "dataset",
        params: { id: this.datasetProject.dataset_id },
        query: { project_id: this.datasetProject.id, from: "datasetUploader" }
      })
    },

    /***********
     *
     * Navigation buttons
     *
     ***********/
    async cont() {
      if (
        !this.$pigeonline.permissions.hasPermission("update-dataset") ||
        this.readOnly
      ) {
        this.setActiveSection("DatasetCleaner")
        return
      }

      // if there was any changes to the dataset name, update the project
      if (this.isDataChanged) {
        await this.edit()
        this.saveDatasetProject(this.datasetProject)
      }
      // if they are ignoring the codebook matches error
      if (this.uploadResponse && this.isCodebookErr) {
        this.$router.push({
          name: "dataset",
          params: { id: this.datasetProject.dataset_id },
          query: { project_id: this.datasetProject.id, from: "datasetUploader" }
        })
      } else this.setActiveSection("DatasetCleaner")
    }
  }
}
