<template>
  <div class="table__toolbar">
    <!--------------------------------------
    | select box
    ---------------------------------------->
    <label
      class="form-label form-checkbox-label table__toolbar-checkbox-select"
    >
      <input
        class="table__toolbar-checkbox-select-input"
        type="checkbox"
        name="select-all-questions"
        id="select-all-questions"
        v-model="select"
      />
      <VisibleText>Select All</VisibleText>
    </label>

    <!--------------------------------------
    | delete
    ---------------------------------------->
    <button
      type="button"
      class="table__toolbar-btn"
      :aria-disabled="String(selectedTopicModels.length === 0)"
      @click="onClickDelete"
    >
      <SvgIconDecorative icon="delete" />
      <VisibleText>Delete</VisibleText>
    </button>

    <!--------------------------------------
    | bulk edit
    ---------------------------------------->
    <div class="table__toolbar-btn--right-align">
      <button
        class="table__toolbar-btn"
        @click="toggleIsEditing"
        v-show="!isEditing"
      >
        <SvgIconDecorative icon="edit-alt" />
        <VisibleText>Bulk Edit</VisibleText>
      </button>
      <button
        class="table__toolbar-btn"
        @click="toggleIsEditing"
        v-show="isEditing"
      >
        <SvgIconDecorative icon="check" />
        <VisibleText>Done</VisibleText>
      </button>
    </div>

    <button class="table__toolbar-btn--secondary" @click="$emit('add-theme')">
      <VisibleText>Add Theme</VisibleText>
    </button>
  </div>
</template>

<script>
import SvgIconDecorative from "@/components/UI/Svg/SvgIconDecorative.vue"
import ConfirmMixin from "@/utils/mixins/confirmMixin.js"
import customTopicModelService from "@/services/customTopicModelService.js"

export default {
  name: "CustomTopicModelEditorOptions",
  mixins: [ConfirmMixin],
  components: {
    SvgIconDecorative
  },
  props: {
    selectedTopicModels: {
      default: () => [],
      type: Array
    }
  },
  data() {
    return {
      CUSTOM_TOPIC_MODEL_SERVICE: new customTopicModelService(this.$pigeonline),
      select: false,
      isEditing: false
    }
  },
  methods: {
    onClickDelete() {
      if (this.selectedTopicModels.length === 0) return
      this.setConfirmText({
        btn: "delete model(s)",
        title: "delete topic model?"
      })
      this.setConfirmSourceComponent("topicModelEditor")
      this.setConfirmStatus(false)
      this.setConfirmTarget(this.selectedTopicModels)
      this.setConfirmType("delete")
      this.setConfirmIsVisible(true)
    },
    toggleIsEditing() {
      this.isEditing = !this.isEditing
      // finished editing, refresh list
      if (!this.isEditing) this.$emit("refresh-topic-models")
      this.$emit("toggle-editing")
    },
    async deleteTopicModels() {
      try {
        await Promise.all(
          this.selectedTopicModels.map(async modelID =>
            this.CUSTOM_TOPIC_MODEL_SERVICE.delete(modelID)
          )
        )
        this.$emit("deleted-themes")
      } catch (e) {
        throw Error(
          "CustomTopicModelEditorOptions:deleteTopicModels: " + e.message
        )
      }
    }
  },
  watch: {
    confirmStatus: async function(val) {
      if (
        val &&
        this.confirmSourceComponent === "topicModelEditor" &&
        this.confirmType === "delete"
      ) {
        await this.deleteTopicModels()
      }
    },
    select: function(val) {
      if (val) this.$emit("select")
      else this.$emit("deselect")
    }
  }
}
</script>
