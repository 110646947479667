<template>
  <div style="max-width: 90%">
    <Tagging
      class="tagging--gray tagging--margin--topnone"
      button-title="add question"
      @add="addExampleQuestion"
      v-if="!readOnly"
    >
      <span class="sr-only">
        {{ translate("Example Questions", $options.name) }}
      </span>
    </Tagging>
    <TaggingList
      class="tagging-list--margin--none tagging-list--block tagging-list--nobackground"
      button-title="remove question"
      :tags="$data.$_exampleQuestions"
      @remove="removeExampleQuestion"
    />
  </div>
</template>

<script>
// Components
import Tagging from "@/components/UI/Tagging/Tagging.vue"
import TaggingList from "@/components/UI/Tagging/TaggingList.vue"

// Services
import customTopicModelService from "@/services/customTopicModelService.js"

export default {
  name: "ExampleQuestions",
  components: {
    Tagging,
    TaggingList
  },
  props: {
    readOnly: {
      default: true,
      type: Boolean
    },
    exampleQuestions: {
      type: Array,
      required: true
    },
    oid: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      CUSTOM_TOPIC_MODEL_SERVICE: new customTopicModelService(this.$pigeonline),
      $_exampleQuestions: this.exampleQuestions
    }
  },
  methods: {
    async addExampleQuestion(question) {
      try {
        this.$data.$_exampleQuestions.push(question)
        await this.CUSTOM_TOPIC_MODEL_SERVICE.update(this.oid, {
          example_questions: this.$data.$_exampleQuestions
        })
      } catch (e) {
        throw new Error("ExampleQuestions:addExampleQuestions " + e.message)
      }
    },
    async removeExampleQuestion(question) {
      try {
        const indx = this.$data.$_exampleQuestions.indexOf(question)
        if (indx === -1) return
        this.$data.$_exampleQuestions.splice(indx, 1)
        await this.CUSTOM_TOPIC_MODEL_SERVICE.update(this.oid, {
          example_questions: this.$data.$_exampleQuestions
        })
      } catch (e) {
        throw new Error("ExampleQuestions:removeExampleQuestions " + e.message)
      }
    }
  }
}
</script>
