<template>
  <div class="response-list">
    <div
      :ref="responseListRef"
      class="response-list__wrapper"
      v-show="responses.length > 0"
    >
      <ResponseListItem
        tabindex="0"
        class="response-list__item"
        v-for="item in responsesPaginated"
        :key="item.idx"
        :item="item"
        :client-question-id="clientQuestionId"
        :is-pinned="pinnedComments.includes(item.id)"
        :is-hidden="hiddenComments.includes(item.id)"
        :highlight-items="getHighlightItemsMethod(item)"
        @excerpt="onExcerpt"
        @keyword="onKeyword"
        @note="onNote"
        @ban="onBan"
        @search="onSearch"
        @remove-keyword="$emit('remove-keyword', $event)"
        @remove-excerpt="$emit('remove-excerpt', $event)"
        @remove-note="$emit('remove-note', $event)"
        @toggle-response-pin="$emit('toggle-response-pin', $event)"
        @ban-response="$emit('ban-response', $event)"
        @toggle-response-hide="$emit('toggle-response-hide', $event)"
      />
      <div class="response-list__load-more">
        <button
          class="response-list__load-more-btn"
          v-if="isLoadMoreBtnVisible"
          @click.stop="handleLoadMoreEvent"
        >
          <VisibleText>Load more responses</VisibleText>
        </button>
        <VisibleText v-else>End of response list</VisibleText>
      </div>
    </div>
    <div class="response-list__empty-wrapper" v-show="responses.length === 0">
      <p
        class="response-list__empty-message"
        v-if="!showResponseListLoadingSpinner"
      >
        <VisibleText>
          No responses found. Please try changing the filters, use a new search
          keyword or select a different text question.
        </VisibleText>
      </p>
    </div>

    <!-- Modals -->
    <SelectedTextModal
      :type="selectedTextModalType"
      :selection="selection"
      :show="isSelectedTextModalVisible"
      @closeModal="closeModal"
    />
    <CreateNoteModal
      :selection="selection"
      :show="isCreateNoteModalVisible"
      @closeModal="closeModal"
    />
  </div>
</template>

<script>
// Components
import ResponseListItem from "./ResponseListItem.vue"

// Mixins
import SelectTextMixin from "../Mixins/selectTextMixin.js"
import ResponseListPaginationMixin from "../Mixins/responseListPaginationMixin.js"

export default {
  name: "ResponseList",
  mixins: [SelectTextMixin, ResponseListPaginationMixin],
  components: {
    ResponseListItem
  },
  props: {
    responses: {
      type: Array,
      default: () => []
    },
    clientQuestionId: {
      type: String
    },
    pinnedComments: {
      type: Array,
      default: () => []
    },
    hiddenComments: {
      type: Array,
      default: () => []
    },
    getHighlightItemsMethod: {
      type: Function
    }
  },
  computed: {
    /* paginated responses - depends on responseListPaginationMixin.js */
    responsesPaginated: function() {
      if (
        this.numResponsesToDisplay + this.pinnedComments.length <
        this.responses.length
      ) {
        return this.responses.slice(
          0,
          this.numResponsesToDisplay + this.pinnedComments.length
        )
      }
      return this.responses
    },
    sentimentsSortOrder: function() {
      return this.$store.getters["analysisText/getSentimentsSortOrder"]
    },
    showResponseListLoadingSpinner: function() {
      return this.$store.getters[
        "analysisText/getShowResponseListLoadingSpinner"
      ]
    },
    isLoadMoreBtnVisible: function() {
      return this.responsesPaginated.length < this.responses.length
    }
  },
  mounted() {
    this.isComponentMounted = true
  },
  methods: {
    handleLoadMoreEvent: function() {
      if (!this.isLoadMoreBtnVisible) return
      this.loadMore()
    }
  },
  watch: {
    responses: {
      immediate: true,
      handler: function(val) {
        if (!val || !Array.isArray(val)) return
        this.resetResponseListPagination()
      }
    },
    sentimentsSortOrder: function() {
      this.$refs[this.responseListRef].scrollTop = 0
    }
  }
}
</script>
