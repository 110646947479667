const HIGHLIGHT_TYPES = {
  // SENTIMENT
  "pos-keyword": {
    id: "pos-keyword",
    label: "Positive Keyword",
    className: "positive-sentiment",
    toolOptions: ["search", "keyword", "excerpt", "note", "ban"]
  },
  "neg-keyword": {
    id: "neg-keyword",
    label: "Negative Keyword",
    className: "negative-sentiment",
    toolOptions: ["search", "keyword", "excerpt", "note", "ban"]
  },

  // THEME
  "theme-keyword": {
    id: "theme-keyword",
    label: "Theme Keyword",
    className: "theme",
    toolOptions: ["search", "excerpt", "note", "ban", "remove-keyword"]
  },
  "theme-excerpt": {
    id: "theme-excerpt",
    label: "Theme Excerpt",
    className: "excerpt",
    toolOptions: ["keyword", "note", "ban", "remove-excerpt"]
  },
  "theme-note": {
    id: "theme-note",
    label: "Theme Note",
    className: "note",
    toolOptions: ["excerpt", "remove-note"]
  },

  // sort by themes (match keywords)
  "match-keyword": {
    id: "match-keyword",
    label: "Match Keyword",
    className: "match",
    toolOptions: ["keyword", "excerpt", "note", "ban"]
  },

  // SEARCH
  "search-keyword": {
    id: "search-keyword",
    label: "Search Keyword",
    className: "search",
    toolOptions: ["keyword", "excerpt", "note", "ban"]
  }
}

const SELECTABLE_TOOL_OPTIONS = {
  keyword: {
    id: "keyword",
    label: "Keyword"
  },
  excerpt: {
    id: "excerpt",
    label: "Excerpt"
  },
  note: {
    id: "note",
    label: "Note"
  },
  ban: {
    id: "ban",
    label: "Ban"
  },

  /* remove buttons */
  "remove-keyword": {
    id: "remove-keyword",
    label: "Remove keyword"
  },
  "remove-excerpt": {
    id: "remove-excerpt",
    label: "Remove excerpt"
  },
  "remove-note": {
    id: "remove-note",
    label: "Remove note"
  },

  /* search */
  search: {
    id: "search",
    label: "Search"
  }
}

const SELECTABLE_TOOL_OPTIONS_DEFAULT = [
  "search",
  "keyword",
  "excerpt",
  "note",
  "ban"
]

export {
  HIGHLIGHT_TYPES,
  SELECTABLE_TOOL_OPTIONS,
  SELECTABLE_TOOL_OPTIONS_DEFAULT
}
