<template>
  <div
    :class="['theme__popup', { show: showDialog }]"
    v-click-outside="forceClose"
  >
    <button type="button" class="open-btn" @click="onClickOpen">
      {{ excerpts.length }}
      <VisibleText>excerpts</VisibleText>
    </button>
    <DialogPopup
      class="theme__popup-dialog"
      :show="showDialog"
      @close="showDialog = false"
      v-if="excerpts.length > 0"
    >
      <template #header>
        {{ themeName }} - {{ translate("excerpts", $options.name) }}
      </template>
      <template #body>
        <ul class="theme__popup-list">
          <li
            v-for="(excerpt, index) in visibleExcerpts"
            :key="`${themeName}-excerpt-${index}`"
            class="item"
          >
            <p
              :class="[
                'text',
                { selected: selectedExcerptIndex === offsetByPage(index) }
              ]"
            >
              {{ excerpt.content }}
            </p>
            <button
              type="button"
              class="source-btn"
              @click="onClickSource(excerpt, offsetByPage(index))"
            >
              Source
            </button>
          </li>
        </ul>
        <div class="theme__popup-pagination">
          <span class="details">
            {{ translate("Page", $options.name) }} {{ pagination.page }}
            {{ translate("of", $options.name) }} {{ pagination.totalPages }}
          </span>
          <span class="actions">
            <button
              type="button"
              class="pagination-btn"
              :disabled="pagination.page === 1"
              @click="onClickPrevPage"
            >
              <VisibleText> Prev </VisibleText>
            </button>
            |
            <button
              type="button"
              class="pagination-btn"
              :disabled="pagination.page === pagination.totalPages"
              @click="onClickNextPage"
            >
              <VisibleText> Next </VisibleText>
            </button>
          </span>
        </div>
        <div
          class="theme__popup-source"
          v-show="sourceHighlightString.length > 0"
        >
          <p v-html="sourceHighlightString" class="highlighted-content"></p>
        </div>
      </template>
    </DialogPopup>
  </div>
</template>

<script>
import DialogPopup from "@/components/UI/DialogPopup.vue"

export default {
  name: "ExcerptsPopup",
  components: {
    DialogPopup
  },
  props: {
    themeName: {
      default: () => "theme",
      type: String
    },
    excerpts: {
      default: () => [],
      type: Array
    }
  },
  data() {
    return {
      selectedExcerptIndex: -1,
      showDialog: false,
      sourceHighlightString: "",
      visibleExcerpts: [],
      // pagination
      pagination: {
        page: 1,
        limit: 2, //limit of how many are shown in a page
        totalPages: 1,
        startIndx: 0,
        endIndx: 0
      }
    }
  },
  created() {
    this.setup()
  },
  methods: {
    offsetByPage(index) {
      return (this.pagination.page - 1) * this.pagination.limit + index
    },
    createHighlightedString(sourceText, start, end) {
      return (
        sourceText.slice(0, start) +
        "<mark>" +
        sourceText.slice(start, end) +
        "</mark>" +
        sourceText.slice(end, -1)
      )
    },
    forceClose() {
      if (this.showDialog) this.showDialog = false
    },
    onClickOpen() {
      if (this.excerpts.length > 0) this.showDialog = true
    },
    onClickNextPage() {
      if (this.pagination.page === this.pagination.totalPages) return
      this.pagination.page = this.pagination.page + 1
      this.setPaginationDetails(
        this.pagination.startIndx + this.pagination.limit,
        this.pagination.endIndx + this.pagination.limit
      )
    },
    onClickPrevPage() {
      if (this.pagination.page === 1) return
      this.pagination.page = this.pagination.page - 1
      this.setPaginationDetails(
        this.pagination.startIndx - this.pagination.limit,
        this.pagination.endIndx - this.pagination.limit
      )
    },
    onClickSource(item, index = null) {
      if (index > -1 && index < this.excerpts.length) {
        this.selectedExcerptIndex = index
      }
      if (!item.source) {
        this.sourceHighlightString = ""
        return
      }
      this.sourceHighlightString = this.createHighlightedString(
        item.source.responseText,
        item.startIndex,
        item.startIndex + item.content.length
      )
    },
    setPaginationDetails(start, end) {
      this.pagination.startIndx = start
      this.pagination.endIndx = end
      this.visibleExcerpts = this.excerpts.slice(start, end)
    },
    setup() {
      this.pagination.totalPages = Math.ceil(
        this.excerpts.length / this.pagination.limit
      )
      this.setPaginationDetails(0, this.pagination.limit)
      this.sourceHighlightString = ""
    }
  },
  watch: {
    excerpts: function(val) {
      if (val) this.setup()
    }
  }
}
</script>

<style scoped>
.theme__popup-dialog {
  max-width: 32rem;
  width: calc(100% * 3);
}
</style>
