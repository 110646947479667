// for global mixin
import language_cache from "@/components/Translation/LanguageCache.json"
import store from "@/store/store"

export default {
  methods: {
    checkTranslationCache(localEn, inputName) {
      const filteredCache =
        store.getters.getLanguage === "fr"
          ? this.checkLocalCache(String(localEn), inputName)
          : null
      return filteredCache ? filteredCache.text_fr : localEn
    },
    /**
     * Translate text
     * It checks local cache & if translation is not available it returns english
     * @param {String} localEn english text
     * @param {String} parentComponent of english text
     * @returns {String} translated text or english text
     */
    translate(localEn, parentComponent) {
      // only check cache if user sets the language
      const filteredCache =
        store.getters.getLanguage === "fr"
          ? this.checkLocalCache(localEn, parentComponent)
          : null
      return filteredCache ? filteredCache.text_fr : localEn
    },
    checkLocalCache(english, parentComponent) {
      // test strategies
      const testAll = (translation, english, parentComponent) => {
        translation.text_en.trim().toLowerCase() === english &&
          translation.parentComponent === parentComponent &&
          translation.uri === document.baseURI
      }
      const testParentOnly = (translation, english, parentComponent) => {
        translation.text_en.trim().toLowerCase() === english &&
          translation.parentComponent === parentComponent
      }
      const testUriOnly = (translation, english) => {
        translation.text_en.trim().toLowerCase() === english &&
          translation.uri === document.baseURI
      }
      const testEngOnly = (translation, english) =>
        translation.text_en.trim().toLowerCase() === english

      // filter and return first translation found
      const trimmedEng = english.trim().toLowerCase()
      return language_cache.find(
        translation =>
          testAll(translation, trimmedEng, parentComponent) ||
          testParentOnly(translation, trimmedEng, parentComponent) ||
          testUriOnly(translation, trimmedEng) ||
          testEngOnly(translation, trimmedEng)
      )
    }
  }
}
