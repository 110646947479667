<template>
  <div>
    <div
      v-for="(input, index) in questionWording"
      :key="`wording-${index}`"
      class="benchmark-manager__wording"
    >
      <input
        aria-label="question wording"
        class="form-input--margin--none input"
        type="text"
        :aria-describedby="ariaDescribedBy"
        :aria-invalid="err"
        :required="required"
        :ref="`input-${index}`"
        @input="onInputWording(input.lang, $event)"
      />
      <div class="select-wrapper">
        <select aria-label="language" class="select" v-model="input.lang">
          <option disabled value="" :data-parent="$options.name">
            {{ translate("language", $options.name) }}
          </option>
          <option
            v-for="lang in Object.keys(LANG_NAME)"
            :key="lang"
            :value="lang"
            :data-parent="$options.name"
            :disabled="question[LANG_CODE_TO_FIELD[lang]]"
          >
            {{ translate(LANG_NAME[lang], $options.name) }}
          </option>
        </select>
      </div>
      <ButtonIconOnly
        icon="remove"
        :id="`wording-remove-${index}`"
        @click-handler="removeWording(index)"
      >
        remove
      </ButtonIconOnly>
    </div>
    <button class="btn-secondary" style="padding-left: 0" @click="addWording">
      <slot name="btn-text">
        <VisibleText> Add language </VisibleText>
      </slot>
    </button>
  </div>
</template>

<script>
import ButtonIconOnly from "@/components/UI/Button/ButtonIconOnly.vue"
import _ from "lodash"
import { LANG_NAME } from "../../Utils/consts.js"

export default {
  name: "QuestionWording",
  components: {
    ButtonIconOnly
  },
  props: {
    ariaDescribedBy: {
      type: String
    },
    err: {
      default: "false",
      type: String
    },
    // initialize questionWording array
    initArray: {
      default: true,
      type: Boolean
    },
    question: {
      type: Object,
      required: true
    },
    required: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      LANG_NAME: LANG_NAME,
      LANG_CODE_TO_FIELD: {
        en: "question_text",
        fr: "question_text_fr"
      },
      initValues: {},
      questionWording: []
    }
  },
  created() {
    this.debouncedUpdateQuestionWording = _.debounce(
      this.updateQuestionWording,
      500
    )
    // initial wording values (save it so we dont modify them)
    Object.values(this.LANG_CODE_TO_FIELD).forEach(field => {
      if (this.question[field]) this.initValues[field] = this.question[field]
    })
  },
  computed: {
    disableAddBtn() {
      return (
        this.questionWording.length === 2 ||
        (this.question.question_text && this.question.question_text_fr)
      )
    }
  },
  methods: {
    addWording() {
      this.questionWording.push({ lang: "" })
    },
    removeWording(index) {
      this.question[
        this.LANG_CODE_TO_FIELD[this.questionWording[index].lang]
      ] = null
      this.questionWording.splice(index, 1)
    },
    onInputWording(lang, $e) {
      if (!lang) return
      this.question[this.LANG_CODE_TO_FIELD[lang]] = $e.target.value
    },
    updateQuestionWording() {
      let langs = this.questionWording.reduce((langs, curr, indx) => {
        if (!curr.lang) return langs
        langs[curr.lang] = indx
        return langs
      }, {})
      if (Object.keys(langs).length == 0) return
      for (const [code, field] of Object.entries(this.LANG_CODE_TO_FIELD)) {
        if (Object.keys(langs).includes(code)) {
          this.question[field] = this.$refs[`input-${langs[code]}`][0].value
          this.$emit("update-question")
        } else if (!this.initValues[field]) this.question[field] = null
      }
    }
  },
  watch: {
    questionWording: {
      deep: true,
      handler: function() {
        this.updateQuestionWording()
      }
    },
    initArray: {
      immediate: true,
      handler: function(init) {
        if (init) this.addWording()
      }
    }
  }
}
</script>
