<template>
  <section id="project-analysis-text-main-themes">
    <div class="themes__header">
      <ul class="themes__header-labels main__search-labels">
        <transition name="fade">
          <li v-show="isSearchMode">
            <span class="main__highlight search" id="themes-search-keyword">
              <VisibleText>Search keyword</VisibleText>
            </span>
          </li>
        </transition>
        <transition name="fade">
          <li
            v-show="
              selectedThemeIndex !== -1 &&
                highlightFilters.includes('theme-keyword')
            "
          >
            <span class="main__highlight theme" id="theme-keyword">
              {{ themeName }}
              <VisibleText>keyword</VisibleText>
            </span>
          </li>
        </transition>
        <transition name="fade">
          <li
            v-show="
              selectedThemeIndex !== -1 &&
                highlightFilters.includes('theme-excerpt')
            "
          >
            <span class="main__highlight excerpt" id="theme-excerpt">
              {{ themeName }}
              <VisibleText>excerpt</VisibleText>
            </span>
          </li>
        </transition>
        <transition name="fade">
          <li
            v-show="
              selectedThemeIndex !== -1 &&
                highlightFilters.includes('theme-note')
            "
          >
            <span class="main__highlight note" id="theme-note">
              {{ themeName }}
              <VisibleText>note</VisibleText>
            </span>
          </li>
        </transition>
        <transition name="fade">
          <li v-show="sortBy !== -1">
            <span class="main__highlight match" id="matched-keyword">
              {{ themes[sortBy] ? themes[sortBy].name : "Matched" }}
              <VisibleText>keyword</VisibleText>
            </span>
          </li>
        </transition>
      </ul>
      <div class="select-wrapper themes__sort-wrapper" style="display: none;">
        <label for="theme-sort-by" class="themes__sort-label">
          <VisibleText>Sort by: </VisibleText>
        </label>
        <select
          class="select themes__sort-options"
          id="theme-sort-by"
          v-model.number="sortBy"
        >
          <option value="-1" :data-parent="$options.name">
            {{ translate("Select a theme", $options.name) }}
          </option>
          <option
            :value="index"
            :data-parent="$options.name"
            :key="index"
            v-for="(theme, index) in themes"
            :disabled="index === selectedThemeIndex"
          >
            {{ theme.name }}
          </option>
        </select>
      </div>
    </div>
    <div class="themes__no-theme-selected" v-if="selectedThemeIndex === -1">
      <VisibleText>
        Please select a theme to view highlighted info.
      </VisibleText>
    </div>
    <ResponseList
      class="themes__response-list"
      :client-question-id="selectedTextQuestionId"
      :responses="textResponsesSorted"
      :pinned-comments="pinnedComments"
      :hidden-comments="hiddenComments"
      :get-highlight-items-method="getHighlightItems"
      @remove-keyword="onRemoveKeyword"
      @remove-excerpt="onRemoveExcerpt"
      @remove-note="onRemoveNote"
      @toggle-response-pin="toggleResponseItemPin"
      @ban-response="banResponseItem"
      @toggle-response-hide="toggleResponseItemHide"
    />
  </section>
</template>

<script>
// Components
import ResponseList from "./Components/ResponseList.vue"

// Mixins
import AnalysisTextMixin from "@/utils/mixins/analysisTextMixin.js"

export default {
  name: "ProjectTextAnalysisMainThemes",
  mixins: [AnalysisTextMixin],
  components: {
    ResponseList
  },
  props: {
    highlightFilters: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      sortBy: -1 /* sort by themes */
    }
  },
  created() {
    this.getHighlightItems = this.getHighlightItems.bind(this)
  },
  computed: {
    themeName() {
      return this.themes[this.selectedThemeIndex]
        ? this.themes[this.selectedThemeIndex].name
        : "Theme"
    },
    themeKeywords: function() {
      return (this.isThemeSelected
        ? this.themes[this.selectedThemeIndex].keywords.map(item =>
            item.trim().toLowerCase()
          )
        : []
      ).sort((a, b) => b.length - a.length)
    },
    themeExcerpts: function() {
      return this.isThemeSelected
        ? this.themes[this.selectedThemeIndex].excerpts
        : []
    },
    themeNotes: function() {
      return this.isThemeSelected
        ? this.themes[this.selectedThemeIndex].notes
        : []
    },
    /* sorted text responses by themes */
    textResponsesSorted: function() {
      const pinnedResponses = this.textResponsesFiltered.filter(el =>
        this.pinnedComments.includes(el.id)
      )
      const unpinnedResponses = this.textResponsesFiltered.filter(
        el => !this.pinnedComments.includes(el.id)
      )
      return [
        ...pinnedResponses,
        ...unpinnedResponses.sort(
          (a, b) => this.findResponseScore(b) - this.findResponseScore(a)
        )
      ]
    }
  },
  methods: {
    findResponseScore(response) {
      return (
        response.scores.themes[this.sortBy] ||
        response.scores.themes[this.selectedThemeIndex] ||
        0
      )
    },
    getHighlightItems(response) {
      let obj = {
        "theme-keyword": this.themeKeywords,
        "theme-excerpt": this.themeExcerpts.filter(
          el => el.responseId && el.responseId === response.id
        ),
        "theme-note": this.themeNotes.filter(
          el => el.responseId && el.responseId === response.id
        )
      }
      for (let key of Object.keys(obj)) {
        if (!this.highlightFilters.includes(key)) delete obj[key]
      }
      obj["match-keyword"] =
        this.sortBy > -1
          ? this.themes[this.sortBy].keywords.map(item =>
              item.trim().toLowerCase()
            )
          : []
      obj["search-keyword"] = response.keywords.search.map(el =>
        el.trim().toLowerCase()
      )
      return obj
    },
    async onRemoveKeyword(selection) {
      const keyword = selection.content.trim().toLowerCase()
      if (!this.themeKeywords.includes(keyword)) return

      this.setShowSpinner(true)
      let keywords = [...this.themeKeywords].filter(el => el !== keyword)

      // compute coverage
      let coverage = await this.computeCoverage(keywords)
      this.themes[this.selectedThemeIndex] = {
        ...this.themes[this.selectedThemeIndex],
        keywords,
        coverage
      }

      await this.saveThemes(this.themes)
      await this.setSelectedThemeResponses(await this.fetchThemeResponses())
      this.setShowSpinner(false)
    },
    async onRemoveExcerpt(selection) {
      if (!selection.responseId) return

      this.setShowSpinner(true)
      let excerpts = [...this.themeExcerpts].filter(
        el =>
          !(
            el.responseId === selection.responseId &&
            el.startIndex === selection.startIndex &&
            el.count === selection.count
          )
      )
      if (excerpts.length !== this.themeExcerpts.length) {
        this.themes[this.selectedThemeIndex].excerpts = excerpts
        await this.saveThemes(this.themes)
      }
      this.setShowSpinner(false)
    },
    async onRemoveNote(selection) {
      if (!selection.responseId) return

      this.setShowSpinner(true)
      let notes = [...this.themeNotes].filter(
        el =>
          !(
            el.responseId === selection.responseId &&
            el.startIndex === selection.startIndex &&
            el.count === selection.count
          )
      )
      if (notes.length !== this.themeNotes.length) {
        this.themes[this.selectedThemeIndex].notes = notes
        await this.saveThemes(this.themes)
      }
      this.setShowSpinner(false)
    }
  },
  watch: {
    search: function(val) {
      const searchString = val.searchString
      // reset sort
      if (searchString && searchString.trim() != "") this.sortBy = -1
    }
  }
}
</script>
