<template>
  <div>
    <MessageBlock
      message-type="warning"
      title="Benchmarks pending approval"
      v-if="statusBenchmarksPendingApproval"
    >
      <span> {{ numNotApproved }} out of {{ numTotal }} </span>
      questions you submitted to the benchmarking service have
      <strong>not</strong> been approved. Please wait for approval to save them
      to a report, or contact the owner of the benchmarking service.
    </MessageBlock>

    <MessageBlock
      message-type="information"
      title="Benchmarks not available"
      v-if="statusBenchmarksNotAvailable"
    >
      <template
        v-if="$pigeonline.permissions.hasPermission('benchmarking-group-iccs')"
      >
        <VisibleText>
          We could not find any scale questions in your data that match
          CitizenFirst benchmarking questions.
        </VisibleText>
      </template>
      <template v-else>
        <VisibleText>
          We could not find any scale questions in your data that match our
          benchmarking database.
        </VisibleText>
      </template>
      <VisibleText>
        To run benchmarking analysis, please edit your dataset and match scale
        questions to global questions (step 3).
      </VisibleText>
    </MessageBlock>

    <MessageBlock
      message-type="information"
      title="Benchmarks not available"
      v-if="statusNoScaleQuestions"
    >
      Your dataset does not have any scale questions. To run benchmarking
      analysis, please edit your dataset to include scale questions.
    </MessageBlock>

    <MessageBlock
      message-type="warning"
      title="Account not verified"
      v-if="statusOrgNotVerified"
    >
      You can view benchmark summaries but to save them to a report your
      organization must be verified.
    </MessageBlock>
  </div>
</template>

<script>
import MessageBlock from "@/components/UI/Message/MessageBlock.vue"

export default {
  name: "ProjectAnalysisBenchmarkingMessages",
  props: {
    statusBenchmarksPendingApproval: {
      deafult: () => false,
      type: Boolean
    },
    statusBenchmarksNotAvailable: {
      deafult: () => false,
      type: Boolean
    },
    statusNoScaleQuestions: {
      default: () => false,
      type: Boolean
    },
    statusOrgNotVerified: {
      default: () => false,
      type: Boolean
    },
    numNotApproved: {
      default: () => 0,
      type: Number
    },
    numTotal: {
      default: () => 0,
      type: Number
    }
  },
  components: {
    MessageBlock
  }
}
</script>
