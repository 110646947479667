// consts
import {
  TYPE_TO_READABLE,
  SCALE_TYPE_TO_READABLE
} from "@/utils/consts/constsDataTypes.js"

export default {
  data() {
    return {
      // readable / UI friendly version of data types
      TYPE_TO_READABLE: TYPE_TO_READABLE,
      SCALE_TYPE_TO_READABLE: SCALE_TYPE_TO_READABLE
    }
  },
  methods: {
    cleanAllValues(uniqueValues) {
      return uniqueValues.reduce((values, val) => {
        val.is_valid = true
        values.push(val)
        return values
      }, [])
    },
    cleanValuesForScale(valid, invalid) {
      valid.forEach(val => {
        val.is_valid = true
        // if we can parse an int from the value, save it as modified value
        if (isNaN(Number(val.value)) && !Number.isNaN(parseInt(val.value))) {
          val.modified_value = parseInt(val.value)
        }
      })
      invalid.forEach(val => {
        val.is_valid = false
      })
      return [...valid, ...invalid]
    },
    getValidScales(dataType) {
      let valid = []
      switch (dataType) {
        case "SCALE_1_TO_5":
          valid = [1, 2, 3, 4, 5]
          break
        case "SCALE_1_TO_3":
          valid = [1, 2, 3]
          break
        case "SCALE_1_TO_7":
          valid = [1, 2, 3, 4, 5, 6, 7]
          break
        case "SCALE_0_TO_11":
          valid = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
          break
      }
      return valid
    },
    /**
     * Clean values if it is scale, cat, or open ended
     * other types are not cleaned.
     */
    async $_dataTypeMixin_saveDataType(
      clientQuestion,
      dataType,
      suggestedDataType = null
    ) {
      // the same data type, no change
      if (clientQuestion.approved_data_type === dataType) return

      let scaleRange = null,
        uniqueValues = clientQuestion.unique_values

      // cleaning scale values
      if (Object.keys(this.SCALE_TYPE_TO_READABLE).includes(dataType)) {
        const vals = this.getValidScales(dataType)
        const valid = clientQuestion.unique_values.filter(item =>
          vals.includes(parseInt(item.value, 10))
        )
        const invalid = clientQuestion.unique_values.filter(
          item => !vals.includes(parseInt(item.value, 10))
        )
        scaleRange = this.SCALE_TYPE_TO_READABLE[dataType]
        uniqueValues = this.cleanValuesForScale(valid, invalid)
      }

      // cleaning all values
      if (Object.keys(this.TYPE_TO_READABLE).includes(dataType)) {
        scaleRange = null
        uniqueValues = this.cleanAllValues(clientQuestion.unique_values)
      }

      const data = {
        approved_data_type: dataType,
        suggested_data_type:
          suggestedDataType || clientQuestion.suggested_data_type,
        scale_range: scaleRange,
        unique_values: uniqueValues
      }

      try {
        const updatedClientQ = await this.$services.DATASETS_SERVICE.updateClientQuestion(
          clientQuestion._id.$oid,
          data
        )
        this.$store.dispatch(
          "datasetDetails/updateClientQuestion",
          updatedClientQ.client_question_object
        )
      } catch (e) {
        throw new Error("DatasetTableDataType " + e.message)
      }
    }
  }
}
