<template>
  <div class="create-modal__dataset-wrapper">
    <label
      v-for="dataset in datasets"
      :key="dataset.id"
      :for="dataset.id"
      :class="[
        'form-radio-label',
        'create-modal__dataset-label',
        selectedDataset === dataset.dataset_id ? 'selected' : ''
      ]"
    >
      <input
        class="form-radio-input create-modal__dataset-input"
        type="radio"
        :name="dataset.name"
        :id="dataset.id"
        :value="dataset.dataset_id"
        :disabled="dataset.currStatusText() == 'in progress'"
        v-model="selectedDataset"
      />
      <span class="form-radio-selector create-modal__dataset-selector"></span>
      <Card
        :class="[
          'create-modal__dataset-info',
          { disabled: dataset.currStatusText() === 'in progress' }
        ]"
        title-tag="span"
        sub-title-tag="span"
      >
        <template #title>
          {{ dataset.name }}
        </template>
        <template #subtitle>
          {{ dataset.last_modified_on.$date | formatDate }}
        </template>
        <span
          class="status create-modal__dataset-status"
          :class="dataset.currStatusText() | replaceSpaceWithDash"
        >
          {{ translate(dataset.currStatusText(), $options.name) }}
        </span>
      </Card>
    </label>
  </div>
</template>

<script>
// Components
import Card from "@/components/UI/Card.vue"

export default {
  name: "ProjectsCreateModalDatasets",
  components: {
    Card
  },
  props: {
    datasets: {
      default: () => [],
      type: Array
    },
    preSelected: {
      default: () => null,
      type: String
    }
  },
  data() {
    return {
      selectedDataset: null
    }
  },
  async created() {
    if (this.preSelected) this.selectedDataset = this.preSelected
  },
  watch: {
    selectedDataset(val) {
      this.$emit("selectedDataset", val)
    },
    preSelected(val) {
      this.selectedDataset = val
    }
  }
}
</script>
