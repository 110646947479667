<template>
  <div class="library__article">
    <div v-if="isUserGuide">
      <p>
        <VisibleText>Go back to</VisibleText>
        <a
          href="javascript:void(0)"
          @click="$emit('new-page', 'GettingStarted')"
        >
          <VisibleText> Getting Started</VisibleText>
        </a>
      </p>
    </div>
    <div v-else>
      <p>
        <VisibleText>Go back to</VisibleText>
        <router-link to="/library">
          <VisibleText> Library</VisibleText>
        </router-link>
        ->
        <router-link to="/library/guide/getting-started-with-researchai">
          <VisibleText>Getting Started</VisibleText>
        </router-link>
      </p>
    </div>
    <h1>
      <VisibleText>
        Conducting basic analysis, like prediction and segmentation
      </VisibleText>
    </h1>
    <p>
      <VisibleText>
        You have successfully uploaded data and are now ready for analysis.
      </VisibleText>
    </p>
    <ul class="library__list">
      <li>
        <a href="javascript:void(0)" @click="goto('goto--connect')">
          <VisibleText>Connect a new project to a dataset</VisibleText>
        </a>
      </li>
      <li>
        <a href="javascript:void(0)" @click="goto('goto--drivers')">
          <VisibleText>What is drivers analysis?</VisibleText>
        </a>
      </li>
      <li>
        <a href="javascript:void(0)" @click="goto('goto--segments')">
          <VisibleText>What is segmentation analysis?</VisibleText>
        </a>
      </li>
      <li>
        <a href="javascript:void(0)" @click="goto('goto--benchmarking')">
          <VisibleText>Conducting benchmarking analysis</VisibleText>
        </a>
      </li>
    </ul>
    <h2 ref="goto--connect">
      <VisibleText>Connect a new project to a dataset</VisibleText>
    </h2>
    <p>
      <VisibleText>To begin, create a new project.</VisibleText>
    </p>
    <img
      class="screenshot"
      style="width:60%;"
      src="/assets/userguide__analysis01.png"
      alt="screenshot of projects page with create new project button circled"
    />
    <p>
      <VisibleText
        >Next, you have to connect your project to a cleaned dataset.
      </VisibleText>
    </p>
    <img
      class="screenshot"
      src="/assets/userguide__analysis02.png"
      alt="screenshot of a dataset selected and connect dataset circled"
      style="width:60%"
    />
    <p>
      <VisibleText
        >You can now see your connected data. To run analysis, click this tab:
      </VisibleText>
    </p>
    <img
      class="screenshot"
      src="/assets/userguide__analysis03.png"
      alt="screenshot of project tabs with analysis circled"
      style="width:60%"
    />
    <p>
      <VisibleText
        >The tool will have automatically suggested analysis based on the type
        of data you uploaded.
      </VisibleText>
    </p>
    <div class="figure__vertical--card">
      <h4><VisibleText>Prediction using Drivers Analysis</VisibleText></h4>
      <VisibleText>
        Requires scale questions, like responses between 1 to 5:
      </VisibleText>
      <div class="figure__vertical--indent">
        <p>
          <VisibleText
            >This will find out what aspects of service are most likely to drive
            an outcome like client satisfaction or willingness to recommend to
            others.
          </VisibleText>
        </p>
      </div>
    </div>
    <div class="figure__vertical--card">
      <h4><VisibleText>Benchmarking Analysis</VisibleText></h4>
      <VisibleText
        >Requires you to connect questions to questions in a benchmarking
        service:
      </VisibleText>
      <div class="figure__vertical--indent">
        <p>
          <VisibleText>This will compare your scores to peers.</VisibleText>
        </p>
      </div>
    </div>
    <div class="figure__vertical--card">
      <h4><VisibleText>Text Analysis</VisibleText></h4>
      <VisibleText>Requires text questions.</VisibleText>
      <div class="figure__vertical--indent">
        <p>
          <VisibleText
            >This will help you find themes in your text.
          </VisibleText>
        </p>
      </div>
    </div>
    <h2 ref="goto--drivers">
      <VisibleText>What is drivers analysis?</VisibleText>
    </h2>
    <p>
      <VisibleText
        >Drivers analysis is an important tool to help you prioritize where to
        focus your energies. Our tool is running a statistical analysis called
      </VisibleText>
      <a href="https://en.wikipedia.org/wiki/Regression_analysis">
        <VisibleText>regression</VisibleText></a
      >
      <VisibleText
        >. This will let us see which questions in your dataset predict
        important outcomes. These questions are called drivers of satisfaction.
      </VisibleText>
    </p>
    <h3>
      <VisibleText>Setting what you are trying to predict</VisibleText>
    </h3>
    <p>
      <VisibleText
        >The tool may automatically find an outcome question like 'Overall
        satisfaction' to run predictions. This is also called a “dependent
        variable”.
      </VisibleText>
    </p>
    <img
      class="screenshot"
      src="/assets/userguide__analysis04.png"
      alt="screenshot of dependent variable table"
    />
    <p>
      <VisibleText
        >If it cannot find one, add your own. You can predict what drives any
        scale question. Common outcome measures include:
      </VisibleText>
    </p>
    <ul class="library__list">
      <li><VisibleText>Overall satisfaction</VisibleText></li>
      <li>
        <VisibleText>Willingness to recommend (Net Promoter Score)</VisibleText>
      </li>
      <li><VisibleText>Willingness to buy</VisibleText></li>
      <li>
        <VisibleText>
          Indexes which combine scores together, such as employee engagement, or
          the (ICCS Index)
        </VisibleText>
      </li>
    </ul>
    <h3>Setting your predictors</h3>
    <p>
      <VisibleText
        >The tool will add all other scale questions. Feel free to modify this
        list until it makes sense to you. You are looking to add anything that
        could predict your outcome. These are also called "independent
        variables".
      </VisibleText>
    </p>
    <img
      class="screenshot"
      src="/assets/userguide__analysis05.png"
      alt="screenshot of independent variable table with modify independent variable button circled"
    />
    <h3><VisibleText>Interpreting results</VisibleText></h3>
    <p>
      <VisibleText>
        This will create a chart that shows the most important drivers of your
        outcome. The numbers represent how much of an impact they have, where 1
        is full impact, and therefore fully important, and where 0 is zero
        impact, or therefore will not drive improvements in your outcome. The
        higher the importance, the more a change in it either up or down will
        lead to a change in your outcome variable, up or down.
      </VisibleText>
    </p>
    <p>
      <VisibleText>
        In this example, 'being informed of what I needed to do' drives 15% of
        overall satisfaction.
      </VisibleText>
    </p>
    <img
      class="screenshot"
      src="/assets/userguide__drivers.png"
      alt="screenshot of the first chart from a drivers analysis"
      style="width:50%;"
    />
    <p>
      <VisibleText>
        There will also be a priority matrix. This will create a quadrant called
        ‘area to improve'. This means the item predicts increases or decreases
        in your outcome variable, but also that your performance on this score
        is low.
      </VisibleText>
    </p>
    <p>
      <VisibleText>
        In the below example, 'being informed of what I needed to do' is not
        only driving satisfaction, but the performance is less than half of
        average performance across all measures. You should focus on improving
        this if you want to increase satisfaction
      </VisibleText>
    </p>
    <p>
      <VisibleText>
        Sometimes you will not see a score in the 'improve' category. You may
        see scores in the 'protect' category, which means while they are
        important drivers, you are already doing well. You should protect your
        performance because dropping in this score will decrease satisfaction
        faster than other scores.
      </VisibleText>
    </p>
    <img
      class="screenshot"
      src="/assets/userguide__prioritymatrix.png"
      alt="screenshot of the second chart from a drivers analysis"
      style="width:60%;"
    />
    <h3><VisibleText>Saving drivers analysis</VisibleText></h3>
    <p>
      <VisibleText
        >If the analysis yielded interesting insights, you can save this to your
        final report by clicking "save to report".
      </VisibleText>
    </p>
    <img
      class="screenshot"
      src="/assets/userguide__analysis06.png"
      alt="screenshot of drivers analysis with save to report button circled"
    />
    <h2 ref="goto--segments">
      <VisibleText>What is segmentation analysis?</VisibleText>
    </h2>
    <p>
      <VisibleText>
        Using the tab called “performance” you can create simple charts that
        show how well you are doing across a range of scale questions.
      </VisibleText>
    </p>
    <img
      class="screenshot"
      src="/assets/userguide__performancechart.png"
      alt="screenshot of a performance chart for a range of scale questions"
      style="width:50%;"
    />
    <p>
      <VisibleText>
        The real power to this tool becomes looking for specific segments that
        are performing poorly or exceptionally well compared to others. After
        adding scale questions, click “get suggested comparisons”.
      </VisibleText>
    </p>
    <img
      class="screenshot"
      src="/assets/userguide__generatebutton.png"
      alt="screenshot of a button that activates segmentation analysis"
      style="width:25%;"
    />
    <p>
      <VisibleText>
        This will automatically look through all categories set during the
        uploading stage. For example, those who answered “Over 55” to the
        question “age” may be more upset compared to the general population.
        This will appear in the form of a red square. You can click on it to
        reveal more information.
      </VisibleText>
    </p>
    <p>
      <VisibleText>
        In the below example, those that answered "2" to the question "S1" are
        more upset with the question "staff knowledge" than the rest of the
        survey participants.
      </VisibleText>
    </p>
    <img
      class="screenshot"
      src="/assets/userguide__segmentation.png"
      alt="screenshot of a segmentation heatmap"
      style="width:60%;"
    />
    <h2 ref="goto--benchmarking">
      <VisibleText>Conducting benchmarking analysis</VisibleText>
    </h2>
    <p>
      <VisibleText
        >Running benchmarking is even easier. If there are any matches to a
        benchmarking group, summary results should immediately appear.
      </VisibleText>
    </p>
    <img
      class="screenshot"
      src="/assets/userguide__analysis07.png"
      alt="screenshot of benchmarking analysis"
    />
    <p>
      <VisibleText
        >Like before, hit "save to report" to save your benchmarking results.
      </VisibleText>
    </p>
    <img
      class="screenshot"
      style="width:25%;"
      src="/assets/userguide__analysis08.png"
      alt="screenshot of save to report button"
    />
    <p>
      <VisibleText
        >Tip: Inside the report you will find many more details about your
        benchmarking.
      </VisibleText>
    </p>
    <img
      class="screenshot"
      style="width:50%;"
      src="/assets/userguide__analysis09.png"
      alt="screenshot of benchmarking details chart"
    />
  </div>
</template>

<script>
export default {
  name: "basic_analysis",
  props: {
    isUserGuide: { type: Boolean }
  },
  methods: {
    goto(refName) {
      this.$refs[refName].scrollIntoView()
    }
  }
}
</script>
