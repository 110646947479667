<template>
  <svg
    class="svg-icon"
    :aria-hidden="ariaHidden"
    :focusable="focusable.toString()"
  >
    <slot></slot>
    <use :xlink:href="`#${icon}`" :aria-hidden="ariaHiddenUse.toString()" />
  </svg>
</template>

<script>
export default {
  name: "SvgIcon",
  props: {
    ariaHidden: {
      default: () => false,
      type: Boolean
    },
    ariaHiddenUse: {
      default: () => false,
      type: Boolean
    },
    focusable: {
      default: () => true,
      type: Boolean
    },
    icon: {
      type: String,
      required: true
    }
  }
}
</script>
