<template>
  <section id="benchmark-manager">
    <div class="section__top-bar">
      <h1 class="section__top-bar-title">
        <router-link to="/benchmark-manager">
          <VisibleText>Benchmark Manager</VisibleText>
        </router-link>
      </h1>
      <div
        class="section__top-bar-actions-wrapper"
        v-if="$route.name === 'benchmarkManager'"
      >
        <CreateProject
          class="btn-default"
          :project="newGroup"
          @projectCreated="newGroupCreated"
          @projectCreationError="newGroupCreationError"
        >
          <VisibleText>Create New Group</VisibleText>
        </CreateProject>
      </div>
    </div>
    <keep-alive>
      <router-view></router-view>
    </keep-alive>
  </section>
</template>

<script>
// Components
import { CreateProject } from "@pigeonline/core"

// Models
import BenchmarkGroup from "@/models/BenchmarkGroup.js"

export default {
  name: "BenchmarkManager",
  components: {
    CreateProject
  },
  data() {
    return {
      newGroup: null
    }
  },
  created() {
    this.newGroup = new BenchmarkGroup()
    this.newGroup.setCreatedBy(
      this.$store.getters["user/getProfile"].fullname,
      this.$store.getters["user/getOrganization"].organization_name
    )
    document.title = `Benchmark Manager | ${this.$theme.theme.pageTitle}`
  },
  methods: {
    newGroupCreated(group) {
      this.$router.push({
        name: "benchmarkGroupEdit",
        params: { id: group.id }
      })
    },
    newGroupCreationError() {
      alert("Sorry there was an error creating a new group.")
    }
  }
}
</script>
