<template>
  <div>
    <!--------------------------------------
    | read only
    ---------------------------------------->
    <template v-if="readOnly && clientQuestion.approved_data_type">
      <VisibleText>
        {{
          TYPE_TO_READABLE[clientQuestion.approved_data_type] ||
            SCALE_TYPE_TO_READABLE[clientQuestion.approved_data_type]
        }}
      </VisibleText>
    </template>

    <!--------------------------------------
    | editing
    ---------------------------------------->
    <template v-if="!readOnly">
      <VisibleText class="dataset-table__type-noresponses" v-if="noResponses">
        No responses. Setting data type unavailable.
      </VisibleText>
      <div
        :class="[
          'dataset-table__type-select select-wrapper',
          { 'dataset-table__type-select--withicon': withicon }
        ]"
      >
        <label
          class="sr-only"
          :for="`edit-data-type-${clientQuestion._id.$oid}`"
          :data-parent="$options.name"
        >
          {{ translate("select data type", $options.name) }}
        </label>
        <select
          :class="['select', { 'dataset-table__type-unknown': unknown }]"
          :id="`edit-data-type-${clientQuestion._id.$oid}`"
          :disabled="noResponses"
          v-model="dataType"
          tabindex="-1"
        >
          <option disabled value="" :data-parent="$options.name">
            {{ translate("select type", $options.name) }}
          </option>
          <optgroup label="Data Types">
            <option
              v-for="type in Object.keys(TYPE_TO_READABLE)"
              :key="type"
              :value="type"
              :data-parent="$options.name"
            >
              {{ translate(TYPE_TO_READABLE[type], $options.name) }}
            </option>
          </optgroup>
          <optgroup label="Scale Types">
            <option
              v-for="scale in Object.keys(SCALE_TYPE_TO_READABLE)"
              :key="scale"
              :value="scale"
              :data-parent="$options.name"
            >
              {{ translate(SCALE_TYPE_TO_READABLE[scale]) }}
            </option>
          </optgroup>
        </select>
        <SvgIconMeaningful
          class="dataset-table__type-icon"
          icon="question"
          title="unknown data type"
          v-if="unknown"
        />
        <SvgIconMeaningful
          class="dataset-table__type-icon"
          icon="warning"
          title="no responses"
          v-if="noResponses"
        />
      </div>
    </template>
  </div>
</template>

<script>
// Components
import SvgIconMeaningful from "@/components/UI/Svg/SvgIconMeaningful.vue"

// Mixins
import DatasetDetailsMixin from "@/utils/mixins/datasetDetailsMixin.js"
import DataTypeMixin from "@/components/Dataset/Mixins/datatypeMixin.js"

export default {
  name: "DataType",
  mixins: [DatasetDetailsMixin, DataTypeMixin],
  components: {
    SvgIconMeaningful
  },
  props: {
    clientQuestion: {
      default: () => {},
      type: Object,
      required: true
    },
    readOnly: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      dataType: "",
      unknown: false,
      validTypes: []
    }
  },
  created() {
    this.setDataType()
    this.validTypes = [
      ...Object.keys(this.SCALE_TYPE_TO_READABLE),
      ...Object.keys(this.TYPE_TO_READABLE)
    ]
  },
  computed: {
    toFetchSuggestedDataType() {
      return this.$store.getters["datasetWizard/getToFetchSuggestedDataType"]
    },
    noResponses() {
      return this.clientQuestion.unique_values.length === 0
    },
    withicon() {
      return this.noResponses || this.unknown
    }
  },
  methods: {
    setDataType() {
      if (
        this.clientQuestion.approved_data_type &&
        this.clientQuestion.approved_data_type.length > 0
      )
        this.dataType = this.clientQuestion.approved_data_type
    },
    async fetchSuggestedType() {
      if (this.noResponses) return
      const suggestedObj = await this.fetchSuggestedTypes(
        this.dataset._id.$oid,
        [this.clientQuestion._id.$oid]
      )
      const suggested = Object.entries(suggestedObj)
      /**
       * If CAT_OR_SCALE, suggested or current type that is not included
       * in our valid types - set as unknown match
       * TODO: more complex type warnings (ie. its 1_TO_11 is same as 0_to_10)
       * If there is no approved data type, assume suggestion is correct
       */
      if (
        suggested[0][1] === "CAT_OR_SCALE" ||
        !this.validTypes.includes(suggested[0][1]) ||
        (this.clientQuestion.approved_data_type &&
          !this.validTypes.includes(this.clientQuestion.approved_data_type))
      ) {
        this.clientQuestion.suggested_data_type = suggested[0][1]
        this.unknown = true
      } else if (!this.clientQuestion.approved_data_type) {
        this.clientQuestion.suggested_data_type = suggested[0][1]
        this.dataType = suggested[0][1]
      }
    }
  },
  watch: {
    dataType: {
      immediate: false,
      handler: function() {
        // can update data type if you have permission or its different from approved type
        if (
          !this.readOnly &&
          this.$pigeonline.permissions.hasPermission("update-dataset") &&
          this.dataType != this.clientQuestion.approved_data_type &&
          !["CAT_OR_SCALE"].includes(this.dataType)
        ) {
          this.$_dataTypeMixin_saveDataType(
            this.clientQuestion,
            this.dataType,
            this.clientQuestion.suggested_data_type
          )
          this.unknown = false
        }
      }
    },
    "clientQuestion.approved_data_type": function(val) {
      if (val && val.length > 0) {
        this.dataType = val
        this.unknown = false
      }
    },
    toFetchSuggestedDataType: function(val) {
      if (val) this.fetchSuggestedType()
    }
  }
}
</script>
