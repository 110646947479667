import { BaseService, axios } from "@pigeonline/core"

export default class DatasetsService extends BaseService {
  constructor(core) {
    super(core)

    // define the api url
    this.URL = `${this.BASE_URL}/data_sets`
  }

  /**
   * Upload a new dataset
   * @returns {(Object|Array)} dataset object
   */
  async upload(data) {
    try {
      const response = await axios.post(`${this.URL}/`, data, {
        headers: {
          Authorization: this.core.getAuthHeader(),
          "Content-Type": "multipart/form-data"
        }
      })
      return response
    } catch (e) {
      throw new Error("datasetsService.js:upload " + e.message)
    }
  }

  /**
   * Edit a dataset
   * @returns {(Object|Array)} dataset object
   */
  async edit(id, data) {
    try {
      const response = await axios.put(`${this.URL}/${id}`, data, {
        headers: {
          Authorization: this.core.getAuthHeader(),
          "Content-Type": "multipart/form-data"
        }
      })
      return response.data
    } catch (e) {
      throw new Error("datasetsService.js:edit " + e.message)
    }
  }

  /**
   * Update a dataset
   * @returns {(Object)} dataset object
   */
  async update(id, data) {
    try {
      const response = await axios.patch(`${this.URL}/${id}`, data, {
        headers: this.getAuthHeader()
      })
      return response.data
    } catch (e) {
      throw new Error("datasetsService.js:update " + e.message)
    }
  }

  /**
   * Delete a dataset
   * @returns {String} success message
   */
  async delete(id) {
    try {
      const response = await axios.delete(`${this.URL}/${id}`, {
        headers: this.getAuthHeader()
      })
      return response.data
    } catch (e) {
      throw new Error("datasetsService.js:delete " + e.message)
    }
  }

  /**
   * Get all datasets
   * @returns {(Object|Array)} dataset object or array of dataset objects
   */
  async datasets() {
    try {
      const response = await axios.get(`${this.URL}/`, {
        headers: this.getAuthHeader()
      })
      return response.data
    } catch (e) {
      throw new Error("datasetsService.js:datasets " + e.message)
    }
  }

  /**
   * Get details of a particular dataset
   * @param {String} id ID of dataset
   * @returns {(Object|Array)} dataset object or array of dataset objects
   */
  async dataset(id, params = {}) {
    try {
      let config = {}
      config["headers"] = this.getAuthHeader()
      config["params"] = params ? params : ""
      const response = await axios.get(`${this.URL}/${id}`, config)
      return response.data
    } catch (e) {
      throw new Error("datasetsService.js:dataset " + e.message)
    }
  }

  /**
   * Create new global question
   * @param {String} data new global question
   * @returns {Object} global_question
   */
  async newGlobalQuestion(data) {
    try {
      const response = await axios.post(`${this.URL}/global_questions`, data)
      return response.data
    } catch (e) {
      throw new Error("datasetsService.js:newGlobalQuestion " + e.message)
    }
  }

  /**
   * Get global questions (or a specific question) based on params
   * @param {String} [id] of globbal question to fetch
   * @param {String} [params] paramteres to filter by
   * @returns {(Object|Array)} object or array of global_questions
   */
  async globalQuestions(id, params = {}) {
    try {
      let config = {}
      config["headers"] = this.getAuthHeader()
      config["params"] = params ? params : ""

      let url = `${this.URL}/global_questions${id ? `/${id}` : ""}`

      const response = await axios.get(url, config)
      return response.data
    } catch (e) {
      throw new Error("datasetsService.js:globalQuestions " + e.message)
    }
  }

  async updateGlobalQuestion(id, data) {
    try {
      const response = await axios.patch(
        `${this.URL}/global_questions/${id}`,
        data,
        {
          headers: this.getAuthHeader()
        }
      )
      return response.data
    } catch (e) {
      throw new Error("datasetsService.js:updateGlobalQuestion " + e.message)
    }
  }

  /**
   * Delete global question
   * @param {String} id of global question
   */
  async deleteGlobalQuestion(id) {
    try {
      const response = await axios.delete(`${this.URL}/global_questions/${id}`)
      return response.data
    } catch (e) {
      throw new Error("datasetsService.js:newGlobalQuestion " + e.message)
    }
  }

  /**
   * Get client questions (or a specific question) based on params
   * @param {String} [id] of client question to fetch
   * @param {String} [params] paramteres to filter by
   * @returns {(Object|Array)} object or array of client_questions
   */
  async clientQuestions(id, params = {}) {
    try {
      let config = {}
      config["headers"] = this.getAuthHeader()
      config["params"] = params ? params : ""
      let url = `${this.URL}/client_questions${id ? `/${id}` : ""}`

      const response = await axios.get(url, config)
      return response.data
    } catch (e) {
      throw new Error("datasetsService.js:clientQuestions " + e.message)
    }
  }

  /**
   * Update a client questions
   * @param {String} id of client question to fetch
   * @param {String} data client question data
   * @returns {(Object|Array)} client_question object
   */
  async updateClientQuestion(id, data) {
    try {
      const response = await axios.patch(
        `${this.URL}/client_questions/${id}`,
        data
      )
      return response.data
    } catch (e) {
      if (e.response.status === 304) return e
      throw new Error("datasetsService.js:updateClientQuestion " + e.message)
    }
  }

  /**
   * Update a client questions
   * @param {String} id of client question to fetch
   * @param {String} data client question data
   * @returns {(Object|Array)} client_question object
   */
  async editClientQuestion(id, data) {
    try {
      delete data._id
      const response = await axios.put(
        `${this.URL}/client_questions/${id}`,
        data
      )
      return response.data
    } catch (e) {
      throw new Error("datasetsService.js:clientQuestions " + e.message)
    }
  }

  /**
   * Delete client question
   * @param {String} id of global question
   */
  async deleteClientQuestion(id) {
    try {
      const response = await axios.delete(`${this.URL}/client_questions/${id}`)
      return response.data
    } catch (e) {
      throw new Error("datasetsService.js:deleteClientQuestion " + e.message)
    }
  }
}
