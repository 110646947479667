var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:['dataset-segments__tag-item', { disabled: _vm.isDisabled }]},[_c('span',{staticClass:"dataset-segments__tag-item-title"},[(_vm.allowRemove)?_c('button',{staticClass:"dataset-segments__list-remove",attrs:{"title":"remove"},on:{"click":function($event){return _vm.$emit('remove')}}},[_c('RemoveIcon',{staticClass:"dataset-segments__list-remove-icon"})],1):_vm._e(),_c('strong',[_vm._v(_vm._s(_vm.translate(_vm.segmentName, _vm.$options.name)))]),(_vm.segmentDetailsAvailable)?_c('span',{staticClass:"dataset-segments__tag-item-count"},[_vm._v(" "+_vm._s(_vm.segmentDetails[_vm.segmentName] || 0)+" "),_c('VisibleText',[_vm._v(" benchmarks")])],1):_vm._e()]),_c('span',{staticClass:"dataset-segments__tag-item-options"},[(
        !!_vm.segments[_vm.segmentName] && _vm.segments[_vm.segmentName].client_question_id
      )?_c('span',{class:[
        'dataset-segments__tag-item-options-btn',
        { selected: !_vm.isDisabled }
      ]},[_c('VisibleText',[_vm._v("some respondents")])],1):_c('span',{class:[
        'dataset-segments__tag-item-options-btn',
        { selected: !_vm.isDisabled }
      ]},[_c('VisibleText',[_vm._v("all respondents")])],1)]),(!!_vm.segments[_vm.segmentName] && _vm.segments[_vm.segmentName].client_question_id)?_c('span',{staticClass:"dataset-segments__list-seg-details"},[_c('VisibleText',[_vm._v("Those who responeded to")]),_vm._v(" : \" "+_vm._s(_vm.getClientQuestion(_vm.segments[_vm.segmentName]).question_title)+" - "+_vm._s(_vm.getClientQuestion(_vm.segments[_vm.segmentName]).question_text)+" \" "),_c('VisibleText',[_vm._v(" and answered")]),_vm._v(" : \" "+_vm._s(_vm.segments[_vm.segmentName].value)+" \" "),_c('VisibleText',[_vm._v("are in this segment.")])],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }