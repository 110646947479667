<template>
  <section id="project-report">
    <div class="project-report-message">
      <VisibleText>Your report is currently empty.</VisibleText>
      <p>
        <VisibleText>To begin, please go to the </VisibleText>
        <router-link :to="path" class="project-report__link">
          <span class="project-report__link-text">
            <VisibleText> analysis tab </VisibleText>
          </span>
        </router-link>
        <VisibleText
          >to conduct one-click analysis on your dataset.
        </VisibleText>
      </p>
    </div>
  </section>
</template>

<script>
export default {
  name: "ProjectReport",
  props: {
    datasetId: { type: String }
  },
  created() {
    document.title = `${this.$store.getters["project/getProject"].name} - Report | ${this.$theme.theme.pageTitle}`
  },
  computed: {
    path() {
      return `/project/${this.$route.params.projid}/analysis`
    }
  }
}
</script>
