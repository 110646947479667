<template>
  <section class="account-next-steps">
    <h1 class="account-next-steps__title title" v-if="title">
      <VisibleText>Finish setting up your account</VisibleText>
      <small class="text-muted">{{ title }}</small>
    </h1>
    <router-view></router-view>
  </section>
</template>

<script>
export default {
  name: "accountNextSteps",
  data() {
    return {
      titles: {
        accountNextStepsProfile: "Profile",
        accountNextStepsAccountType: "Account Type",
        accountNextStepsOrganization: "Organization"
      }
    }
  },
  created() {
    this.$watch(
      "isAccountNextStepsDisabled",
      function(newVal) {
        if (!newVal) return
        this.$router.push({ path: "/" })
      }.bind(this)
    )
  },
  computed: {
    title: function() {
      return this.titles[this.$route.name] || null
    },
    isAccountNextStepsDisabled: function() {
      return this.$store.getters["user/getAccountNextStepsDisabled"]
    }
  }
}
</script>
