<template>
  <form class="dataset-benchmarks__select" @submit.prevent>
    <fieldset class="form-fieldset-group" id="dataset-benchmark-group">
      <legend class="form-fieldset-group__legend">
        <VisibleText>Choose a benchmark group</VisibleText>
      </legend>
      <label
        v-for="group in benchmarkGroups"
        :key="group.id"
        :for="group.id"
        class="form-radio-label dataset-benchmarks__select-label"
      >
        <input
          class="form-radio-input"
          type="radio"
          :name="group.name"
          :id="group.id"
          :value="group.id"
          v-model="selectedGroup"
        />
        <span class="form-radio-selector"></span>
        {{ group.name }}
      </label>
    </fieldset>
    <button
      type="submit"
      class="dataset-benchmarks__select-next-btn"
      @click="selectBenchmarkGroup"
    >
      <VisibleText>Continue</VisibleText>
    </button>
    <button
      type="button"
      class="dataset-benchmarks__select-skip-btn"
      @click="skip"
    >
      <VisibleText>Skip this step</VisibleText>
    </button>
  </form>
</template>

<script>
// Models
import BenchmarkGroup from "@/models/BenchmarkGroup.js"

// Mixins
import DatasetMixin from "@/utils/mixins/datasetMixin.js"
import DatasetDetailsMixin from "@/utils/mixins/datasetDetailsMixin.js"

export default {
  name: "DatasetBenchmarkSelectGroup",
  mixins: [DatasetMixin, DatasetDetailsMixin],
  data() {
    return {
      benchmarkGroups: [],
      selectedGroup: null
    }
  },
  async created() {
    this.benchmarkGroups = await this.fetchBenchmarkGroups()
  },
  methods: {
    async fetchBenchmarkGroups() {
      let groups = await this.$pigeonline.projects.get(BenchmarkGroup)
      // do not have permission to access iccs benchmark group
      if (
        !this.$pigeonline.permissions.hasPermission("benchmarking-group-iccs")
      ) {
        groups = groups.filter(group => group.official_group !== "iccs")
      }
      return groups
    },
    async selectBenchmarkGroup() {
      const groupDetails = await this.$pigeonline.projects.getByID(
        BenchmarkGroup,
        this.selectedGroup
      )

      this.datasetProject.status.benchmarksSkipped = false
      this.saveDatasetProject(this.datasetProject)
      this.setBenchmarkGroup(groupDetails)
      this.setGlobalQuestions(
        await this.$services.DATASETS_SERVICE.globalQuestions(null, {
          benchmark_group: this.selectedGroup
        })
      )
      await this.updateDataset(this.dataset._id.$oid, {
        benchmark_group: groupDetails.id
      })
      // segments rely on updated dataset details saved by updateDataset()
      this.setSegmentDetails(await this.fetchSegmentDetails(groupDetails.id))
      this.$emit("benchmark-group-selected")
    },
    skip() {
      this.datasetProject.updateStatus("benchmarksSkipped")
      this.saveDatasetProject(this.datasetProject)
      this.setSegmentDetails({})
      this.setActiveSection("DatasetSegments")
    }
  }
}
</script>
