<template>
  <div id="project-analysis-text-theme">
    <section
      class="theme__container"
      :aria-hidden="modalOpen"
      :inert="modalOpen"
    >
      <h3 class="theme__title"><VisibleText>Saved themes</VisibleText></h3>

      <button
        class="theme__auto-generate"
        @click="isAutoGenerateModalVisible = true"
      >
        <VisibleText>Auto-generate themes</VisibleText>
      </button>

      <!----- NO SAVED THEMES MESSAGE ---->
      <MessageBlock
        message-type="information"
        title="No themes found"
        v-if="isThemeListEmpty"
      >
        Auto-generate a theme or create a new one.
      </MessageBlock>

      <!----- THEME LIST ---->
      <div class="theme__list" v-else>
        <div
          class="theme__list-row-wrapper"
          v-for="(theme, index) in themes"
          :key="index"
        >
          <div class="theme__list-row">
            <span
              :class="[
                'theme__list-row-title',
                { selected: index === selectedThemeIndex }
              ]"
            >
              <InputEditable
                :value="theme.name"
                :inputClass="'theme__list-row-title-input'"
                :select="() => toggleTheme(index)"
                :uuid="`saved-themes-${index}`"
                @update="updateThemeTitle(index, $event)"
              />
            </span>
            <div class="theme__list-row-info">
              <!-- FIXME: update data that is passed down -->
              <KeywordsPopup
                :theme-name="theme.name"
                :keywords="theme.keywords"
              />
              <ExcerptsPopup
                :theme-name="theme.name"
                :excerpts="theme.excerpts"
              />
              <NotesPopup :theme-name="theme.name" :notes="theme.notes" />
            </div>
            <div class="theme__list-row-coverage">
              <span class="theme__list-row-coverage-title">
                <VisibleText>Coverage</VisibleText>
              </span>
              <span class="theme__list-row-coverage-percent"
                >{{ theme.coverage.percent | toFixed(1) }}%</span
              >
              <span class="theme__list-row-coverage-num-responses">
                {{
                  `(${theme.coverage.num_responses} / ${textResponses.length})`
                }}
              </span>
            </div>
            <SentimentCharts
              :single-stack-data="
                generateSingleStackData(
                  theme.sentiment.keywords_sentiment,
                  theme.name
                )
              "
              :pos-neg-data="
                generatePosNegData(
                  theme.sentiment.respondent_sentiment,
                  theme.name
                )
              "
            />
          </div>
          <div class="theme__list-options">
            <button
              :class="[
                'theme__list-options-btn-show',
                { selected: index === selectedThemeIndex }
              ]"
              @click="toggleTheme(index)"
            >
              <VisibleText>
                {{
                  index === selectedThemeIndex ? "Show all" : "Show this only"
                }}
              </VisibleText>
            </button>
          </div>
        </div>
      </div>

      <!----- THEME ACTIONS ---->
      <div class="theme__helper-container">
        <p class="theme__helper-text"></p>
        <div class="theme__helper-options">
          <button
            type="button"
            class="theme__helper-options-btn"
            @click="isEditModalVisible = true"
          >
            <SvgIconDecorative icon="edit-alt" />
            <VisibleText>Edit themes</VisibleText>
          </button>
          <button
            type="button"
            class="theme__helper-options-btn"
            @click="isBannedKeywordsModalVisible = true"
          >
            <SvgIconDecorative icon="ban" />
            <VisibleText>Show banned keywords</VisibleText>
          </button>
        </div>
      </div>
    </section>

    <!----- MODALS---->
    <BannedKeywordsModal
      :show="isBannedKeywordsModalVisible"
      @closeModal="closeModal"
      v-if="!isThemeListEmpty"
    />
    <ThemesEditModal :show="isEditModalVisible" @closeModal="closeModal" />
    <ThemesAutoGenerateModal
      v-if="isAutoGenerateModalVisible"
      :show="isAutoGenerateModalVisible"
      @closeModal="closeModalAutoGenerate"
    />
  </div>
</template>

<script>
// Components
import InputEditable from "./ProjectAnalysisTextMain/UI/InputEditable.vue"
import SentimentCharts from "./ProjectAnalysisTextMain/Components/SentimentCharts.vue"
import SvgIconDecorative from "@/components/UI/Svg/SvgIconDecorative.vue"
import MessageBlock from "@/components/UI/Message/MessageBlock.vue"
import { PigeonDocNodeModel } from "@pigeonline/pigeondoc"

import ExcerptsPopup from "./Components/ExcerptsPopup.vue"
import KeywordsPopup from "./Components/KeywordsPopup.vue"
import NotesPopup from "./Components/NotesPopup.vue"

// Modal
import ThemesAutoGenerateModal from "./ProjectAnalysisTextMain/Modals/ThemesAutoGenerate.vue"
import ThemesEditModal from "./ProjectAnalysisTextMain/Modals/ThemesEdit.vue"
import BannedKeywordsModal from "./ProjectAnalysisTextMain/Modals/BannedKeywords.vue"

// Mixins
import AnalysisTextMixin from "@/utils/mixins/analysisTextMixin.js"

export default {
  name: "ProjectAnalysisTextTheme",
  mixins: [AnalysisTextMixin],
  components: {
    InputEditable,
    ThemesAutoGenerateModal,
    ThemesEditModal,
    BannedKeywordsModal,
    SentimentCharts,
    SvgIconDecorative,
    MessageBlock,
    // popups
    ExcerptsPopup,
    KeywordsPopup,
    NotesPopup
  },
  data() {
    return {
      isEditModalVisible: false,
      isAutoGenerateModalVisible: false,
      isBannedKeywordsModalVisible: false
    }
  },
  computed: {
    isActive() {
      return this.currentState
    },

    checkedValue: {
      get() {
        return this.defaultState
      },
      set(newValue) {
        this.currentState = newValue
      }
    },
    isThemeListEmpty: function() {
      return (
        this.themes && Array.isArray(this.themes) && this.themes.length == 0
      )
    }
  },
  methods: {
    toggleTheme(index) {
      if (index === this.selectedThemeIndex) {
        this.setSelectedThemeIndex(-1)
      } else {
        this.setSelectedThemeIndex(index)
      }
    },
    closeModal() {
      this.isEditModalVisible = false
      this.isBannedKeywordsModalVisible = false
    },
    closeModalAutoGenerate() {
      this.isAutoGenerateModalVisible = false
    },

    // sentiment chart methods
    calcRespondentSentimentTotals(data) {
      return data.reduce(
        (totals, data) => {
          if (data > -0.05 && data < 0.05) totals.neu++
          if (data >= 0.05) totals.pos++
          if (data <= -0.05) totals.neg++
          return totals
        },
        { neu: 0, pos: 0, neg: 0 }
      )
    },
    generateSingleStackData: function(data, themeName) {
      if (!data || typeof data != "object") {
        data = {
          neg_percent: 0,
          pos_percent: 0,
          neu_percent: 0
        }
      }
      const description =
        `The chart represents sentiment of keywords in` +
        `theme <em>${themeName}</em>. It shows ${data.neg_percent.toFixed(
          2
        )}%` +
        ` are negative, ${data.neu_percent.toFixed(2)}% are neutral, and ` +
        `${data.pos_percent.toFixed(2)}% are positive.`
      return new PigeonDocNodeModel({
        type: "horizontalSingleStackBarChart",
        content: {
          data: [
            {
              label: "negative",
              value: data.neg_percent,
              fillColor: "#cc6677"
            },
            {
              label: "neutral",
              value: data.neu_percent,
              fillColor: "#eee"
            },
            {
              label: "positive",
              value: data.pos_percent,
              fillColor: "#117733"
            }
          ]
        },
        meta: {
          axisLabels: {
            x: "score",
            y: "sentiment"
          },
          description: description
        }
      })
    },
    generatePosNegData: function(data, themeName) {
      if (!data || !Array.isArray(data)) data = []
      const SCALE_DOWN_FACTOR = Math.ceil(
        Math.max(...this.themes.map(theme => theme.coverage.num_responses)) / 30
      )
      const totals = this.calcRespondentSentimentTotals(data)
      const description =
        `The chart shows sentiments for each respondent ` +
        `in theme <em>${themeName}</em>. It shows ${totals.neg.toFixed(2)} ` +
        `responses have a negative sentiment, ${totals.neu.toFixed(2)} ` +
        `are netural, and ${totals.pos.toFixed(2)} are positive.`
      return new PigeonDocNodeModel({
        type: "positiveNegativeBarChartSmall",
        content: {
          data: data.filter((_, index) => index % SCALE_DOWN_FACTOR == 0),
          meta: {
            positiveColour: "#117733",
            negativeColour: "#cc6677",
            neutralColour: "#eee"
          }
        },
        meta: {
          description: description
        }
      })
    }
  }
}
</script>
