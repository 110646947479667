var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"section__content",attrs:{"id":"benchmark-manager-groups"}},[_c('ListGrid',{staticClass:"benchmark-manager__groups",attrs:{"custom-grid-style":{
      gridTemplateColumns: '1fr 1fr 0.5fr 0.5fr 1fr 0.5fr 3rem'
    },"headers":_vm.headers,"list":_vm.benchmarkGroups},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var item = ref.item;
    var index = ref.index;
return [_c('ListGridItem',{attrs:{"col":"0","headers":_vm.headers,"row":index}},[(_vm.isOwner(item.owner))?_c('router-link',{staticClass:"benchmark-manager__groups-item-name",attrs:{"to":{ name: 'benchmarkGroupEdit', params: { id: item.id } }}},[_vm._v(" "+_vm._s(item.name)+" ")]):[_vm._v(_vm._s(item.name))]],2),_c('ListGridItem',{attrs:{"col":"1","headers":_vm.headers,"row":index}},[_c('span',{staticClass:"benchmark-manager__groups-item-created"},[_vm._v(" "+_vm._s(item.created_by.name)+" ")]),_c('span',{staticClass:"benchmark-manager__groups-item-org"},[_vm._v(" "+_vm._s(item.created_by.organization)+" ")])]),_c('ListGridItem',{attrs:{"col":"2","headers":_vm.headers,"row":index}},[_vm._v(" "+_vm._s(item.questions)+" ")]),_c('ListGridItem',{staticClass:"benchmark-manager__groups-item-approved",attrs:{"col":"3","headers":_vm.headers,"row":index}},[_vm._v(" "+_vm._s(item.approved_matches)+" ")]),_c('ListGridItem',{attrs:{"col":"4","headers":_vm.headers,"row":index}},[_c('VisibleText',{staticClass:"benchmark-manager__groups-item-accesslevel"},[_vm._v(" "+_vm._s(_vm.ACCESS_TYPE_TO_DESC[item.access_type].name)+" ")]),_c('VisibleText',{staticClass:"benchmark-manager__groups-item-accesslevel-desc"},[_vm._v(" "+_vm._s(_vm.ACCESS_TYPE_TO_DESC[item.access_type].desc)+" ")])],1),_c('ListGridItem',{class:{ 'non-editable-cascade': !_vm.isOwner(item.owner) },attrs:{"col":"5","headers":_vm.headers,"row":index}},[_c('button',{class:[
            'toggle-btn',
            item.auto_approve ? 'toggle-btn-on' : 'toggle-btn-off'
          ],on:{"click":function($event){return _vm.toggleAutoApprove(item.id, index)}}},[_c('VisibleText',{staticClass:"toggle-btn-on-txt"},[_vm._v(" on ")]),_c('VisibleText',{staticClass:"toggle-btn-off-txt"},[_vm._v(" off ")])],1)]),_c('ListGridItem',{staticClass:"benchmark-manager__groups-item-delete",staticStyle:{"overflow":"unset"},attrs:{"col":"6","headers":_vm.headers,"row":index}},[(_vm.isOwner(item.owner))?_c('ButtonIconOnly',{attrs:{"icon":"delete","id":("delete-" + (item.id))},on:{"click-handler":function($event){return _vm.deleteGroup(item.id, index)}}},[_c('VisibleText',[_vm._v("delete")]),_vm._v(" "+_vm._s(item.name)+" ")],1):_vm._e()],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }