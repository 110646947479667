import { HELPER_TRANSLATIONS } from "../translations"
const lang = window.localStorage.getItem("apiLanguage") || "en"

/**
 * Retrieve methodology table data
 * @param {Object} data (methodology) data retrieved from /behcnarmking/report
 * @returns {Object} pigeondoc table node
 */
const getTableData = (data, orgs) => {
  // because we want it in a specific order, explicity list columns
  const columnNames = ["ID", "Date Collected", "Sample Size", "Response Rate"]
  let rowNames = []

  // reduce to object readable by pigeondoc data node
  let tableData = data.reduce((tableDataRows, item) => {
    let row = []
    columnNames.forEach(col => {
      if (col === "ID") {
        rowNames.push(orgs[item.data_set_id] || "Your org")
      } else if (col === "Date Collected") {
        row.push(item.date_collected || "n/a")
      } else if (col === "Sample Size") {
        row.push(item["average_valid_sample_size"])
      } else if (col === "Response Rate") {
        row.push(item.response_rate || "n/a")
      }
    })
    tableDataRows.push(row)
    return tableDataRows
  }, [])

  return {
    columnNames: HELPER_TRANSLATIONS.methodologyCols[lang],
    rowNames: rowNames,
    data: tableData
  }
}

/*******************************
 *
 * Generate nodes for Survey Methodology section
 * @param {*} data (methodology) data retrieved from /benchmarking/report
 * @returns array of pigeondoc nodes
 *
 *******************************/
const SurveyMethodologyTable = (data, orgs) => {
  const tableData = getTableData(data, orgs)
  const table = {
    type: "table",
    content: { data: tableData },
    meta: {
      caption: HELPER_TRANSLATIONS.methodologyTableCaption[lang],
      lang: lang
    }
  }

  return table
}

export default SurveyMethodologyTable
