<template>
  <div ref="getting_started_article" class="library__article">
    <div v-if="!isUserGuide">
      <p>
        <VisibleText>Go to</VisibleText>
        <router-link to="/library">
          <VisibleText> Library</VisibleText>
        </router-link>
      </p>
    </div>
    <h1>
      <VisibleText>Getting started</VisibleText>
    </h1>
    <p>
      <VisibleText
        >Welcome to ResearchAI! Let's go from data to insights in clicks.
      </VisibleText>
    </p>
    <h2><VisibleText>What is ResearchAI?</VisibleText></h2>
    <p>
      <VisibleText
        >ResearchAI is a tool where you can upload organizational data and get
        directed towards industry reports in clicks.
      </VisibleText>
    </p>
    <h3><VisibleText>What kind of data?</VisibleText></h3>
    <p>
      <VisibleText>
        Many leading organization collect information like customer interactions
        or employee experiences from surveys, CRM software, customer complaints
        forms/emails, and more.
      </VisibleText>
    </p>
    <h3><VisibleText>Why ResearchAI?</VisibleText></h3>
    <p>
      <VisibleText>
        Unlocking insights from these can be expensive, time-consuming or
        require expertise.
      </VisibleText>
    </p>
    <p>
      <VisibleText>
        ResearchAI tries to make analysis accessible. Find delighted or upset
        segments. Predict important outcomes using drivers analysis. Find hidden
        themes in your text using our qualitative research tools. Then, share
        your reports with colleagues or customers.
      </VisibleText>
    </p>
    <h3 style="text-align: center;">
      <VisibleText>The process from start to finish</VisibleText>
    </h3>
    <div class="figure__horizontal">
      <div class="figure__horizontal__outside-square">
        <div class="figure__horizontal__outside-square__text">
          <VisibleText>Dataset View</VisibleText>
        </div>
        <div class="figure__horizontal--square">
          <VisibleText>Upload data</VisibleText>
        </div>
        <div class="figure__horizontal--arrow">→</div>
        <div class="figure__horizontal--square">
          <VisibleText>Clean data</VisibleText>
        </div>
      </div>
      <div class="figure__horizontal__outside-square">
        <div class="figure__horizontal--arrow">→</div>
        <div class="figure__horizontal__outside-square__text">
          <VisibleText>
            Project View
          </VisibleText>
        </div>
        <div class="figure__horizontal--square">
          <VisibleText>Analyze</VisibleText>
        </div>
        <div class="figure__horizontal--arrow">→</div>
        <div class="figure__horizontal--square">
          <VisibleText>Save and share</VisibleText>
        </div>
      </div>
    </div>
    <p><VisibleText>Learn more about</VisibleText>:</p>
    <ul class="library__list">
      <li>
        <a
          href="javascript:void(0)"
          v-if="isUserGuide"
          @click="$emit('new-page', 'UploadingFiles')"
        >
          <VisibleText>
            Uploading your data
          </VisibleText>
        </a>
        <div v-else>
          <router-link to="/library/guide/uploading-files-into-researchai">
            <VisibleText>Uploading your data</VisibleText>
          </router-link>
        </div>
      </li>
      <li>
        <a
          href="javascript:void(0)"
          v-if="isUserGuide"
          @click="$emit('new-page', 'BasicAnalysis')"
        >
          <VisibleText>
            Conducting basic analysis, like prediction and segmentation
          </VisibleText>
        </a>
        <div v-else>
          <router-link to="/library/guide/basic-analysis-in-researchai">
            <VisibleText>
              Conducting basic analysis, like prediction and segmentation
            </VisibleText>
          </router-link>
        </div>
      </li>
      <li>
        <a
          href="javascript:void(0)"
          v-if="isUserGuide"
          @click="$emit('new-page', 'BenchmarkingAnalysis')"
        >
          <VisibleText>
            What is benchmarking analysis?
          </VisibleText>
        </a>
        <div v-else>
          <router-link to="/library/guide/what-is-benchmarking-in-researchai">
            <VisibleText>
              What is benchmarking analysis?
            </VisibleText>
          </router-link>
        </div>
      </li>
      <li>
        <a
          href="javascript:void(0)"
          v-if="isUserGuide"
          @click="$emit('new-page', 'ThematicAnalysis')"
        >
          <VisibleText>What is thematic analysis?</VisibleText>
        </a>
        <div v-else>
          <router-link
            to="/library/guide/what-is-thematic-analysis-in-researchai"
          >
            <VisibleText>
              What is thematic analysis?
            </VisibleText>
          </router-link>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "getting_started",
  props: {
    isUserGuide: { type: Boolean }
  }
}
</script>
