import Vue from "vue"
import BenchmarkingProject from "@/models/BenchmarkingProjectModel.js"

const fetchProject = async projectId => {
  try {
    const response = await Vue.prototype.$pigeonline.projects.getByID(
      BenchmarkingProject,
      projectId
    )
    return response
  } catch (e) {
    throw new Error("ProjectMixin.js:fetchProject " + e.message)
  }
}

export { fetchProject }
