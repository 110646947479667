import SummaryTable from "../helpers/summaryTable"
import { SECTION_TRANSLATIONS } from "../translations.js"
const lang = window.localStorage.getItem("apiLanguage") || "en"

const benchmarkSection = (data, columnNames, overallSummary) => {
  const benchmarkSummaryIconSrc = "/files/ReportIcons/benchmarkSummary.svg"
  let benchmarkSummaryHeading = `<img src="${benchmarkSummaryIconSrc}" class="report__header-icon" alt=""/>\
  ${SECTION_TRANSLATIONS.benchmarkHeading[lang]}`

  return [
    {
      type: "heading",
      content: benchmarkSummaryHeading,
      id: "section-title-benchmark-summary",
      meta: {
        level: "h1",
        pdfConfig: {
          pageBreak: "before"
        }
      }
    },
    {
      type: "text",
      content: overallSummary,
      id: "benchmark-summary-overall"
    },
    {
      type: "table",
      content: {
        data: SummaryTable(data, columnNames)
      },
      meta: {
        caption: SECTION_TRANSLATIONS.benchmarkCaption[lang],
        fixedColumnsIndx: [0, 1, 2], // repeats these colummns when tables overflow in PDFs
        lang: lang,
        pdfConfig: {
          widths: ["auto", "auto", "*", 95, 95, 95, 95]
        }
      },
      id: "benchmark-summary-table"
    }
  ]
}

export default benchmarkSection
