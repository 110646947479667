import DriversAnalysisChart from "../helpers/driversAnalysisChart.js"
import PriorityMatrixChart from "../helpers/priorityMatrixChart.js"
import { LABEL_TRANSLATIONS, SECTION_TRANSLATIONS } from "../translations.js"
const lang = window.localStorage.getItem("apiLanguage") || "en"

const getDriversDetailsText = data => {
  const str = SECTION_TRANSLATIONS.driversDetailsText[lang]
  const str1 = ` ${data.estimator_name.replace(/_/g, " ") || "regression"}. `
  const str2 = data.dynamic_summary.drivers_summary
  return str.concat(str1).concat(str2)
}

const getMatrixDetailsText = data => {
  return `${data.dynamic_summary.priority_matrix_summary}`
}

const driversSection = (title, data, uuid) => {
  const driversTitle = {
    type: "heading",
    content: `${title} ${LABEL_TRANSLATIONS.drivers[lang]}`,
    id: "section-subtitle-drivers",
    meta: {
      level: "h2",
      driversUUID: uuid
    }
  }
  const driversDetailsText = getDriversDetailsText(data)
  const topDriversDetails = {
    type: "text",
    content: driversDetailsText,
    id: "drivers-top-details"
  }
  const driversChart = DriversAnalysisChart(title, data)
  const drivers = [driversTitle, topDriversDetails, driversChart]

  const matrixDetails = {
    type: "text",
    content: getMatrixDetailsText(data),
    id: "drivers-matrix-details",
    meta: {
      pdfConfig: {
        pageBreak: "before"
      }
    }
  }
  const matrixChart = PriorityMatrixChart(title, data)
  const matrix = [matrixDetails, matrixChart]

  return [...drivers, ...matrix]
}

export default driversSection
