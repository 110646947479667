<template>
  <article id="project-analysis-text" v-if="dataset" style="position: relative">
    <Spinner
      :is-loading="showSpinner"
      :shouldFocusMessage="false"
      style="z-index: 10003; position: absolute;"
    />
    <h2 class="sr-only"><VisibleText>Text Analysis</VisibleText></h2>
    <MessageBlock
      message-type="information"
      title="No text responses"
      v-if="showNoTextResponsesMessage"
    >
      No text responses found. Please try a different dataset.
    </MessageBlock>

    <ProjectAnalysisTextTheme v-if="selectedTextQuestion" />
    <ProjectAnalysisTextMain v-if="selectedTextQuestion" />
  </article>
</template>

<script>
// Components
import ProjectAnalysisTextTheme from "./ProjectAnalysisTextTheme.vue"
import ProjectAnalysisTextMain from "./ProjectAnalysisTextMain/ProjectAnalysisTextMain.vue"
import MessageBlock from "@/components/UI/Message/MessageBlock.vue"

// Mixins
import ProjectMixin from "@/utils/mixins/projectMixin.js"
import DatasetDetailsMixin from "@/utils/mixins/datasetDetailsMixin.js"
import AnalysisTextMixin from "@/utils/mixins/analysisTextMixin.js"

export default {
  name: "ProjectAnalysisText",
  mixins: [ProjectMixin, DatasetDetailsMixin, AnalysisTextMixin],
  components: {
    ProjectAnalysisTextMain,
    ProjectAnalysisTextTheme,
    MessageBlock
  },
  computed: {
    appLang() {
      return this.$store.getters["getLanguage"]
    },
    datasetId: function() {
      return this.dataset._id.$oid
    },
    showNoTextResponsesMessage: function() {
      return (
        this.textQuestions &&
        Array.isArray(this.textQuestions) &&
        this.textQuestions.length === 0 &&
        !this.showSpinner
      )
    }
  },
  created() {
    this.setDatasetId(this.dataset._id.$oid)

    if (this.dataset._id.$oid) {
      // HOTFIX: to prevent errors caused due to null field
      if (this.project.textAnalysis == null) {
        this.project.textAnalysis = {}
      }

      // load state from project store obj
      const loadStateFromObj = (key, setMethod) => {
        if (!this.project.textAnalysis[key]) {
          this.project.textAnalysis[key] = []
        }
        setMethod(this.project.textAnalysis[key])
      }
      loadStateFromObj("themes", this.setThemes)
      loadStateFromObj("bannedKeywords", this.setBannedKeywords)
      loadStateFromObj("bannedComments", this.setBannedComments)
      loadStateFromObj("pinnedComments", this.setPinnedComments)
      loadStateFromObj("hiddenComments", this.setHiddenComments)

      // ban keyword event handler
      this.$root.$on("ban-keyword", this.banKeyword)
    }
  },
  beforeDestroy() {
    this.$root.$off("ban-keyword", this.banKeyword)
  },
  watch: {
    appLang: async function(val) {
      if (!val) return
      this.updateSelectedTextQuestionResponses()
    },
    selectedThemeIndex: async function(val) {
      this.setSelectedThemeResponses([])
      this.setSelectedResponseIds([])

      // handle null theme index
      if (val == -1) return
      this.setActiveTab("Themes")

      this.fetchThemeResponses()
        .then(data => {
          this.setSelectedThemeResponses(data)
        })
        .catch(e => {
          console.error("ProjectAnalysisText.vue:: " + e)
        })
    },
    clientQuestions: {
      immediate: true,
      handler: function(val) {
        if (!val || !Array.isArray(val) || val.length === 0) return
        this.setTextQuestions(
          val.filter(c => c.approved_data_type === "OPEN_ENDED")
        )
      }
    },
    textQuestions: {
      immediate: true,
      handler: function(val) {
        if (!val || !Array.isArray(val) || val.length === 0) return

        // select a text question
        this.selectTextQuestion(val[0])
      }
    }
  }
}
</script>
