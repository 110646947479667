<template>
  <div>
    <section id="datasets" :aria-hidden="modalOpen" :inert="modalOpen">
      <div class="datasets__search-bar center-align-parent">
        <SearchBar
          name="searchBar__data"
          class="center-align-child"
          placeholder="Search any dataset"
          @search="search"
        />
      </div>
      <div class="section__top-bar">
        <h1 class="section__top-bar-title">
          <VisibleText>Datasets</VisibleText>
        </h1>
        <HasPermission to="create-dataset">
          <div class="section__top-bar-actions-wrapper">
            <button class="btn-default" @click="upload">
              <SvgIconDecorative icon="upload" />
              <VisibleText>Upload New Data</VisibleText>
            </button>
            <button
              class="datasets__actions-delete-btn"
              @click="onClickDeleteDataset"
            >
              <SvgIconDecorative icon="delete" />
              <VisibleText>Delete</VisibleText>
            </button>
          </div>
          <MessageInline
            class="section__top-bar-message"
            style="display: block"
            message-type="error"
            :message="msgNoDatasetSelected"
          />
        </HasPermission>
      </div>
      <div class="section__content" style="padding-top: 2rem">
        <DatasetsTable
          :query-term="queryTerm"
          :selected="selected"
          :keyDatasetsTable="keyDatasetsTable"
          @updateSelected="updateSelected"
          @share-dataset="shareDataset"
        />
      </div>
    </section>
    <DatasetShareModal
      :dataset="shareDatasetProject"
      :show="isShareModalVisible"
      @closeModal="closeModal"
    />
  </div>
</template>

<script>
// Components
import DatasetShareModal from "@/components/Share/ShareDataset.vue"
import DatasetsTable from "@/components/Datasets/DatasetsTable.vue"
import SearchBar from "@/components/UI/SearchBar.vue"
import SvgIconDecorative from "@/components/UI/Svg/SvgIcon"

// Mixins
import ConfirmMixin from "@/utils/mixins/confirmMixin.js"
import DatasetDetailsMixin from "@/utils/mixins/datasetDetailsMixin.js"
import SharedStatusMixin from "@/utils/mixins/sharedStatusMixin.js"
import MessageInline from "@/components/UI/Message/MessageInline.vue"

// Helpers
import { mapActions } from "vuex"
import { onDeleteUpdateGlobalQuestionBenchmarkMatchCount } from "@/components/Dataset/Utils/helpers.js"

import BenchmarkingDataset from "@/models/BenchmarkingDatasetModel.js"

export default {
  name: "Datasets",
  mixins: [ConfirmMixin, DatasetDetailsMixin, SharedStatusMixin],
  components: {
    DatasetShareModal,
    DatasetsTable,
    SearchBar,
    SvgIconDecorative,
    MessageInline
  },
  data() {
    return {
      queryTerm: null,
      selected: [],
      msgNoDatasetSelected: "",
      keyDatasetsTable: Date.now(),
      // sharing
      isSavingShared: false,
      isShareModalVisible: false,
      shareModalKey: Date.now(),
      shareDatasetProject: null
    }
  },
  beforeRouteLeave(to, from, next) {
    this.resetConfirm()
    next()
  },
  created() {
    document.title = `Datasets | ${this.$theme.theme.pageTitle}`
  },
  computed: {
    modalOpen() {
      return this.$store.getters["globalModule/getModalOpen"]
    }
  },
  methods: {
    ...mapActions("globalModule", ["setDatasetProjects"]),
    onClickDeleteDataset() {
      if (this.selected.length == 0) {
        this.msgNoDatasetSelected = "No dataset selected"
        return
      }

      this.msgNoDatasetSelected = ""
      this.setConfirmText({
        btn: "Delete Dataset(s)",
        message:
          "You will not be able to recover your data or update your report once it is deleted.",
        title: "Delete dataset?"
      })
      this.setConfirmType("delete")
      this.setConfirmSourceComponent("datasets")
      this.setConfirmStatus(false)
      this.setConfirmIsVisible(true)
    },
    async onSuccessfulDelete() {
      this.setConfirmText({
        btn: "okay",
        title: "Successfully deleted dataset(s)"
      })
      this.setConfirmType("success")
      this.setConfirmStatus(false)
      this.setConfirmIsVisible(true)

      this.setDatasetProjects(
        await this.$pigeonline.projects.get(BenchmarkingDataset)
      )
      this.updateSelected([])
      this.keyDatasetsTable = Date.now()
    },
    onErrorDelete() {
      this.$store.dispatch("loader/setLoading", false)
      this.setConfirmText({
        btn: "Okay",
        title: "Error Deleting Dataset",
        message:
          "Sorry, there was an error deleting your dataset. Please try again or contact administrators if error persists."
      })
      this.setConfirmType("error")
      this.setConfirmIsVisible(true)
    },
    search(queryTerm) {
      this.queryTerm = queryTerm
    },
    updateSelected(selected) {
      this.selected = selected
    },
    upload() {
      this.$router.push({ name: "dataset" })
    },
    /**
     * When deleting a dataset, need to update benchmark matches count in the
     * global question object
     */
    async deleteDataset() {
      try {
        this.$store.dispatch("loader/setLoading", true)
        for (const toDelete of this.selected) {
          // update matches count if benchmark matching step is completed
          if (
            toDelete.dataset_proj.status.benchmarksApproved ||
            toDelete.dataset_proj.status.benchmarksSent
          ) {
            const benchmarks = await this.fetchBenchmarks(toDelete.dataset_id)
            for (const benchmark of benchmarks) {
              const globalQ = await this.$services.DATASETS_SERVICE.globalQuestions(
                benchmark.global_question_id
              )
              if (globalQ.matches) {
                await onDeleteUpdateGlobalQuestionBenchmarkMatchCount(
                  this.$services.DATASETS_SERVICE,
                  globalQ,
                  benchmark
                )
              }
            }
          }
          await this.$pigeonline.projects.delete(toDelete.project_id)
          await this.DATASETS_SERVICE.delete(toDelete.dataset_id)
        }
        this.$store.dispatch("loader/setLoading", false)
      } catch (e) {
        this.onErrorDelete()
        throw new Error("Datasets.vue:del " + e.message)
      }
    },
    // save shared dataset
    shareDataset(dataset) {
      if (!dataset) return
      this.shareDatasetProject = Object.assign(
        Object.create(Object.getPrototypeOf(dataset)),
        dataset
      )
      this.isShareModalVisible = true
      this.shareModalKey = Date.now()
    },
    closeModal() {
      this.isShareModalVisible = false
    }
  },
  watch: {
    confirmStatus: async function(val) {
      if (
        val &&
        this.confirmSourceComponent === "datasets" &&
        this.confirmType === "delete"
      ) {
        await this.deleteDataset()
        this.onSuccessfulDelete()
      }
    }
  }
}
</script>
