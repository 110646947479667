<template>
  <div class="library__wrapper">
    <div v-if="activeArticle === ''">
      <h1 class="library__h1">
        <VisibleText>
          PigeonLine's ResearchAI library
        </VisibleText>
      </h1>
      <div class="translation-manager__search-bar center-align-parent">
        <SearchBar
          class="center-align-child"
          placeholder="Filter articles by name"
          @search="search"
        />
      </div>
      <form class="library__article-filters">
        <div class="library__article-filters__title">
          <VisibleText>
            Filter by
          </VisibleText>
          :
        </div>
        <div class="library__article-filters__filter">
          <input
            type="checkbox"
            id="typeFeatured"
            name="typeFeatured"
            v-model="filterOptions.showOnlyFeatured"
            aria-label="Show only featured articles"
          />
          <label for="typeFeatured">
            <VisibleText>
              Featured
            </VisibleText>
          </label>
        </div>
        <div class="library__article-filters__filter">
          <input
            type="checkbox"
            id="typeGuide"
            name="typeGuide"
            v-model="filterOptions.showOnlyGuide"
            aria-label="Show only guides"
          />
          <label for="typeGuide">
            <VisibleText>
              Guides
            </VisibleText>
          </label>
        </div>
      </form>
      <ul
        v-for="article in filteredArticles"
        :key="article.title"
        class="library__articles"
      >
        <li>
          <router-link :to="article.url" class="library__articles__card">
            <div class="library__articles__card__content">
              <VisibleText>{{ article.title }}</VisibleText>
            </div>
            <div v-for="(tag, key) in article.tag" :key="key">
              <div
                class="library__articles__card__content library__articles__card__content__tag"
              >
                <VisibleText>{{ tag }}</VisibleText>
              </div>
            </div>
            <div
              class="library__articles__card__content library__articles__card__content__lang"
            >
              <SvgIconDecorative icon="translate" style="height: 1rem" />
              <div v-for="(lang, key) in article.lang" :key="key">
                {{ lang }}
              </div>
            </div>
          </router-link>
        </li>
      </ul>
    </div>
    <keep-alive>
      <component :is="activeArticle"></component>
    </keep-alive>
  </div>
</template>

<script>
import SearchBar from "@/components/UI/SearchBar.vue"

// Articles
import PrivacyPolicy from "@/components/Library/PrivacyPolicy.vue"
import GettingStarted from "@/components/Library/Guide/GettingStarted.vue"
import UploadingFiles from "@/components/Library/Guide/UploadingFiles.vue"
import BasicAnalysis from "@/components/Library/Guide/BasicAnalysis.vue"
import BenchmarkingAnalysis from "@/components/Library/Guide/BenchmarkingAnalysis.vue"
import ThematicAnalysis from "@/components/Library/Guide/ThematicAnalysis.vue"
import WhatsNew15 from "@/components/Library/Feature/WhatsNew15.vue"

import SvgIconDecorative from "@/components/UI/Svg/SvgIconDecorative.vue"

export default {
  name: "library",
  components: {
    SearchBar,
    PrivacyPolicy,
    GettingStarted,
    UploadingFiles,
    BasicAnalysis,
    BenchmarkingAnalysis,
    ThematicAnalysis,
    WhatsNew15,
    SvgIconDecorative
  },
  data() {
    return {
      articles: [
        {
          title: "What's new with version 1.5 of ResearchAI",
          description:
            "Introducing exciting new updates to ResearchAI, especially around qualitative text analysis. Custom thematic analysis at your fingertips",
          tag: ["featured"],
          lang: ["en"],
          url: "/library/feature/whats-new-1-5-researchai",
          componentName: "WhatsNew15",
          isActive: false
        },
        {
          title: "Getting started with ResearchAI",
          description:
            "A guide for getting started with PigeonLine's ResearchAI",
          tag: ["guide"],
          lang: ["en"],
          url: "/library/guide/getting-started-with-researchai",
          componentName: "GettingStarted",
          isActive: false
        },
        {
          title: "Uploading and cleaning your data in ResearchAI",
          description:
            "A guide for uploading and cleaning data like surveys, customer feedback, and text",
          tag: ["guide"],
          lang: ["en"],
          url: "/library/guide/uploading-files-into-researchai",
          componentName: "UploadingFiles",
          isActive: false
        },
        {
          title:
            "Conducting basic analysis, like prediction and segmentation in ResearchAI",
          description:
            "A guide for conducting automated analysis like prediction (regression) and segmentation (crosstabs)",
          tag: ["guide"],
          lang: ["en"],
          url: "/library/guide/basic-analysis-in-researchai",
          componentName: "BasicAnalysis",
          isActive: false
        },
        {
          title: "What is benchmarking analysis in ResearchAI?",
          description:
            "A guide for conducting benchmarking analysis, compare yourself anonymously to peers",
          tag: ["guide"],
          lang: ["en"],
          url: "/library/guide/what-is-benchmarking-in-researchai",
          componentName: "BenchmarkingAnalysis",
          isActive: false
        },
        {
          title: "What is thematic analysis in ResearchAI?",
          description:
            "A deeper look at ResearchAI's automated text and topic analysis. Instant and easy qualitative analysis.",
          tag: ["guide"],
          lang: ["en"],
          url: "/library/guide/what-is-thematic-analysis-in-researchai",
          componentName: "ThematicAnalysis",
          isActive: false
        },
        {
          title: "PigeonLine's ResearchAI | Privacy policy",
          description: "PigeonLine's Privacy Policy",
          tag: [],
          lang: ["en"],
          url: "/library/privacy-policy",
          componentName: "PrivacyPolicy",
          isActive: false
        }
      ],
      activeArticle: "",
      activeTitle: "PigeonLine's ResearchAI library",
      activeDescription: "A catalog of ResearchAI articles and guides",
      query: {
        term: ""
      },
      filterOptions: {
        showOnlyGuide: false,
        showOnlyFeatured: false
      }
    }
  },
  metaInfo() {
    return {
      title: this.activeTitle,
      meta: [
        {
          vmid: "description",
          name: "description",
          content: this.activeDescription
        }
      ]
    }
  },
  computed: {
    filteredArticles() {
      let filtered = this.articles
      if (filtered) {
        let taglist = []
        let y = 0
        if (this.filterOptions.showOnlyGuide) {
          for (y = 0; y < filtered.length; y++) {
            if (filtered[y].tag) {
              if (filtered[y].tag.includes("guide")) {
                taglist.push(filtered[y])
              }
            }
          }
          filtered = taglist
        }
        if (this.filterOptions.showOnlyFeatured) {
          y = 0
          for (y = 0; y < filtered.length; y++) {
            if (filtered[y].tag) {
              if (filtered[y].tag.includes("featured")) {
                taglist.push(filtered[y])
              }
            }
          }
          filtered = taglist
        }
      }
      if (this.query.term.length > 0) {
        let searchlist = []
        for (var i = 0; i < filtered.length; i++) {
          if (
            filtered[i].title
              .toLowerCase()
              .includes(this.query.term.toLowerCase())
          ) {
            searchlist.push(filtered[i])
          }
        }
        filtered = searchlist
      }
      return filtered
    }
  },
  mounted() {
    window.scrollTo(0, 0)
    this.checkURL()
  },
  watch: {
    $route() {
      this.checkURL()
    }
  },
  methods: {
    checkURL() {
      this.activeArticle = ""
      let matches = 0
      for (let i = 0; i < this.articles.length; i++) {
        if (window.location.href.includes(this.articles[i].url)) {
          this.articles[i].isActive = true
          this.activeTitle = this.articles[i].title
          if (this.articles[i].description) {
            this.activeDescription = this.articles[i].description
          }
          this.activeArticle = this.articles[i].componentName
          matches = matches + 1
        } else {
          this.articles[i].isActive = false
        }
      }
      if (!matches > 0) {
        this.activeTitle = "PigeonLine's ResearchAI library"
        this.activeDescription = "A catalog of ResearchAI articles and guides"
      }
      window.scrollTo(0, 0)
    },
    search(query) {
      this.query.term = query
    }
  }
}
</script>
