<template>
  <Modal
    class="make-comparisons"
    :uid="`make-comparisons-${updateKey}`"
    :key="updateKey"
    :show="show"
    :reset-listener="resetListener"
    @close="$emit('close-modal')"
  >
    <template v-slot:header>
      <VisibleText>{{ headings[type] }}</VisibleText>
    </template>
    <TableQuestions
      ref="questions-table"
      :caption="captions[type]"
      :questionsList="dataPreprocessed"
      :shouldDisplayValues="shouldDisplayValues[type]"
      @updated="tableUpdated"
    />
    <template v-slot:footer>
      <button
        class="make-comparisons__confirm-select-btn"
        @click="confirmSelection"
        :disabled="isConfirmSelectDisabled"
      >
        <SvgIconDecorative
          icon="check"
          class="make-comparisons__confirm-select-btn-icon"
        />
        <VisibleText>Confirm selection</VisibleText>
      </button>
    </template>
  </Modal>
</template>

<script>
// Components
import Modal from "@/components/UI/Modal.vue"
import SvgIconDecorative from "@/components/UI/Svg/SvgIconDecorative.vue"
import TableQuestions from "../Components/TableQuestions.vue"

import _ from "lodash"

export default {
  name: "MakeComparisons",
  components: {
    Modal,
    SvgIconDecorative,
    TableQuestions
  },
  props: {
    type: {
      type: String,
      default: "own"
    },
    data: {
      type: Array
    },
    show: {
      default: () => false,
      type: Boolean
    }
  },
  data() {
    return {
      dataPreprocessed: [],
      isConfirmSelectDisabled: true,
      headings: {
        own: "Make your own comparisons",
        suggested: "Get suggested comparisons"
      },
      captions: {
        own:
          "You can compare your question across segments or comparitors. Maybe one group out-performs another.",
        suggested:
          "Maybe the results to this question changes according to how respondents answer other questions.\n Select a range of categorical questions and we will automatically find interesting stories."
      },
      shouldDisplayValues: {
        own: true,
        suggested: false
      },
      updateKey: Date.now(),
      resetListener: false
    }
  },
  mounted() {
    this.preprocessData()
  },
  methods: {
    preprocessData() {
      this.dataPreprocessed = _.cloneDeep(this.data).map(
        function(el) {
          el.options.disabled = el.approved_data_type
            ? !this.isCategory(el)
            : true
          el.valuesForComparison = this.valuesOf(el)
          return el
        }.bind(this)
      )
    },
    isCategory(question) {
      return (
        question.approved_data_type &&
        question.approved_data_type.match(/(CAT)+/)
      )
    },
    valuesOf(question) {
      return question.unique_values
        .filter(el => el.is_valid)
        .map(el => el.value)
    },
    tableUpdated() {
      this.isConfirmSelectDisabled =
        this.$refs["questions-table"].selectedQuestions.length == 0
    },
    confirmSelection(selected) {
      if (!selected || !Array.isArray(selected)) {
        selected = this.$refs["questions-table"].selectedQuestions
      }
      this.$emit(
        "confirmSelection",
        selected.map(el => [el.id, el.question_title, el.valuesForComparison])
      )
    }
  },
  watch: {
    show: function(val) {
      if (!val) return
      this.isConfirmSelectDisabled = true
      this.updateKey = Date.now()
      this.resetListener = !this.resetListener
      this.preprocessData()

      if (this.type == "suggested") {
        this.confirmSelection(
          this.dataPreprocessed.filter(el => this.isCategory(el))
        )
      }
    }
  }
}
</script>
