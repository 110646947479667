import Vue from "vue"
import Vuex from "vuex"

Vue.use(Vuex)

export default {
  state() {
    return {
      translations: null,
      setLanguage: "en",
      setLanguageDebug: "off"
    }
  },
  getters: {
    getTranslations: state => state.translations,
    getLanguage: state => state.setLanguage,
    getLanguageDebug: state => state.setLanguageDebug
  },
  mutations: {
    SET_TRANSLATIONS(state, payload) {
      Vue.set(state, "translations", payload)
    },
    RESET_TRANSLATIONS(state) {
      Vue.set(state, "project", null)
    },
    EDIT_TRANSLATION(state, payload) {
      const index = state.translations.findIndex(item => {
        return payload.fetched_id === item.fetched_id
      })
      state.translations.splice(index, 1, payload)
    },
    EDIT_LANGUAGE(state, payload) {
      Vue.set(state, "setLanguage", payload)
    },
    EDIT_LANGUAGE_DEBUG(state, payload) {
      Vue.set(state, "setLanguageDebug", payload)
    }
  },
  actions: {
    async setTranslations({ commit }, payload) {
      await commit("SET_TRANSLATIONS", payload)
    },
    resetTranslations({ commit }) {
      commit("RESET_TRANSLATIONS")
    },
    async editTranslation({ commit }, payload) {
      await commit("EDIT_TRANSLATION", payload)
    },
    async setLanguage({ commit }, payload) {
      await commit("EDIT_LANGUAGE", payload)
    },
    async setLanguageDebug({ commit }, payload) {
      await commit("EDIT_LANGUAGE_DEBUG", payload)
    }
  }
}
