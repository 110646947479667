<template>
  <DatasetLayout
    section-name="dataset-segments"
    title="Define Your Data"
    v-if="datasetSegments"
  >
    <DatasetSegmentsFormICCS
      @save="save"
      :read-only="readOnly"
      :state="state"
      :validate-survey-details="validateSurveyDetails"
      v-if="isICCSGroup"
    />
    <DatasetSegmentsForm
      @save="save"
      @skip="skip"
      :read-only="readOnly"
      :state="state"
      v-else
    />
  </DatasetLayout>
</template>

<script>
// Components
import DatasetLayout from "@/components/Layouts/DatasetLayout.vue"
import DatasetSegmentsForm from "./DatasetSegmentsForm/DatasetSegmentsForm.vue"
import DatasetSegmentsFormICCS from "./DatasetSegmentsForm/DatasetSegmentsFormICCS.vue"

// Mixins
import DatasetMixin from "@/utils/mixins/datasetMixin.js"
import DatasetDetailsMixin from "@/utils/mixins/datasetDetailsMixin.js"

const REQ_FIELDS = ["surveyStartDate", "surveyEndDate", "rate"]

export default {
  name: "DatasetSegments",
  mixins: [DatasetMixin, DatasetDetailsMixin],
  components: {
    DatasetLayout,
    DatasetSegmentsForm,
    DatasetSegmentsFormICCS
  },
  data() {
    return {
      state: {
        isError: false,
        isModified: false,
        isSaving: false,
        isSaved: false
      },
      validateSurveyDetails: false // toggler
    }
  },
  async activated() {
    // set dataset project step5 progress
    if (!this.datasetProject.progress.step5) {
      this.datasetProject.progress.step5 = []
      await this.saveDatasetProject(this.datasetProject)
    }
  },
  computed: {
    datasetSegments() {
      return this.dataset.segments
    },
    datasetSurveyDetails() {
      return this.dataset.survey_details
    },
    // use ICCS form if license is iccs or user selected iccs benchmark group
    isICCSGroup() {
      return (
        ["iccs", "iccs-admin"].includes(
          this.$store.getters["user/getLicense"].name
        ) ||
        (this.benchmarkGroup && this.benchmarkGroup.official_group === "iccs")
      )
    }
  },
  methods: {
    /***********
     *
     * Edit dataset segments
     *
     ***********/
    async editSegments() {
      const data = {
        segments: this.dataset.segments,
        survey_details: this.dataset.survey_details
      }
      await this.updateDataset(this.dataset._id.$oid, data)

      // set project status
      this.datasetProject.updateStatus("datasetSegmentsCompleted")
      this.saveDatasetProject(this.datasetProject)
    },
    async save() {
      if (this.readOnly) return
      if (!this.validate()) {
        this.state.isError = true
        return
      }
      if (this.state.isModified) {
        this.state.isError = false
        this.state.isSaving = true

        // save segments and project
        await this.editSegments()
        this.state.isSaving = false
        this.state.isSaved = true
        this.state.isModified = false
        this.removeUnsavedChanges("step 4")
      }
    },
    skip() {
      this.datasetProject.updateStatus("datasetSegmentsSkipped")
      this.saveDatasetProject(this.datasetProject)
    },
    /***********
     *
     * Helper methods
     *
     ***********/
    destruct(obj, keys) {
      return keys.reduce((a, c) => ({ ...a, [c]: obj[c] }), {})
    },
    validate() {
      // v1.5.0 only iccs has required fields
      if (!this.isICCSGroup) return true
      const requiredDetails = this.destruct(
        this.dataset.survey_details,
        REQ_FIELDS
      )

      // every value is not null, undefied, false, or empty string
      if (Object.values(requiredDetails).every(d => !(!d && d !== 0))) {
        this.datasetProject.progress.step5.push("about-survey")
        this.saveDatasetProject(this.datasetProject)
        return true
      } else {
        this.validateSurveyDetails = true
        return false
      }
    }
  },
  watch: {
    datasetSegments: function() {
      this.state.isModified = true
      this.addUnsavedChanges("step 4")
    },
    datasetSurveyDetails: {
      deep: true,
      handler: function(value) {
        if (!value || typeof value === "undefined") return
        this.state.isModified = true
        // rate and responseRateNa are tied, if one is set the other must be reset
        if (value.rate) {
          this.dataset.survey_details.responseRateNa = false
        } else if (value.responseRateNa) {
          this.dataset.survey_details.rate = 0
        }
      }
    }
  }
}
</script>
