import Vue from "vue"
import App from "./App.vue"
import router from "./router/router"
import store from "./store/store"

import PigeonLineVue from "@pigeonline/core"
import VueThemes from "@pigeonline/vue-themes"
import VuePermissions from "@pigeonline/vue-permissions"
import VueAnalytics from "@pigeonline/vue-analytics"
import Services from "./services/services"

import filters from "@/utils/filters"

import iccsTheme from "@/styles/themes/iccs/iccs"
import researchAiTheme from "@/styles/themes/research-ai/research-ai"
import eiryamTheme from "@/styles/themes/eiryam/eiryam"

// Third party packages
import VueMeta from "vue-meta"

// register third party packages
Vue.use(VueMeta)

// register PL packages
const BASE_URL = process.env.VUE_APP_BE_PORT
  ? `${process.env.VUE_APP_BE_URL}:${process.env.VUE_APP_BE_PORT}`
  : process.env.VUE_APP_BE_URL
Vue.use(PigeonLineVue, {
  baseUrl: BASE_URL,
  plugins: [VuePermissions]
})
Vue.prototype.$services = new Services(Vue.prototype.$pigeonline)

// register themes
let activeTheme = researchAiTheme
if (window.location.hostname.includes("citizenfirst")) {
  activeTheme = iccsTheme
} else if (window.location.hostname.includes("eiryam")) {
  activeTheme = eiryamTheme
}
Vue.use(VueThemes, {
  baseUrl: BASE_URL,
  theme: activeTheme
})

// Translation tools
import VisibleText from "@/components/Translation/VisibleText"
import translationMixin from "@/utils/mixins/translationMixin"
Vue.mixin(translationMixin)
Vue.component("VisibleText", VisibleText)

// register analytics
Vue.use(VueAnalytics, {
  id: "UA-LOCALHOST",
  baseUrl: BASE_URL,
  router: router
})

//register filters
Object.keys(filters).forEach(key => Vue.filter(key, filters[key]))

// register custom directive
Vue.directive("click-outside", {
  bind: function(el, binding, vnode) {
    el.clickOutsideEvent = function(event) {
      // click was outside the el and his childrens
      if (!(el == event.target || el.contains(event.target))) {
        // and if it did, call method provided in attribute value
        vnode.context[binding.expression](event)
      }
    }
    document.body.addEventListener("click", el.clickOutsideEvent)
  },
  unbind: function(el) {
    document.body.removeEventListener("click", el.clickOutsideEvent)
  }
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app")
