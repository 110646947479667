<template>
  <span>
    <!--------------------------------------
     | read only
    ---------------------------------------->
    <template v-if="readOnly">
      <span>
        {{ clientQuestion.question_text }}
      </span>
    </template>

    <!--------------------------------------
    | editing
    ---------------------------------------->
    <template v-else>
      <div class="dataset-table__edit-wrapper">
        <label
          class="sr-only"
          :for="`edit-question-text-${clientQuestion._id.$oid}`"
        >
          <VisibleText>edit question text</VisibleText>
        </label>
        <textarea
          tabindex="-1"
          class="dataset-table__edit-textarea"
          rows="5"
          cols="18"
          name="question-text"
          :id="`edit-question-text-${clientQuestion._id.$oid}`"
          v-model="text"
        ></textarea>
        <VisibleText class="dataset-table__edit-status">
          {{ savingStatus }}
        </VisibleText>
      </div>
    </template>
  </span>
</template>

<script>
// Services
import DatasetsService from "@/services/datasetsService.js"

import _ from "lodash"

export default {
  name: "DatasetTableclientQuestion",
  props: {
    clientQuestion: {
      default: () => {},
      type: Object,
      required: true
    },
    index: {
      default: () => null,
      type: Number,
      required: true
    },
    readOnly: {
      default: () => true,
      type: Boolean
    }
  },
  data() {
    return {
      DATASETS_SERVICE: new DatasetsService(this.$pigeonline),
      isSaving: false,
      isSaved: false,
      text: ""
    }
  },
  created() {
    this.saveText = _.debounce(this.saveText, 1000)
  },
  computed: {
    savingStatus() {
      if (this.isSaving) return "saving..."
      if (this.isSaved) return "saved"
      return ""
    }
  },
  methods: {
    async saveText() {
      try {
        const questionText = { question_text: this.text }
        const updatedQuestion = await this.DATASETS_SERVICE.updateClientQuestion(
          this.clientQuestion._id.$oid,
          questionText
        )
        this.$emit(
          "updateClientQuestion",
          this.index,
          updatedQuestion.client_question_object
        )
        this.isSaving = false
        this.isSaved = true

        // update dataset project status
        const datasetProject = this.$store.getters[
          "datasetWizard/getDatasetProject"
        ]
        if (datasetProject.no_codebook_matches) {
          datasetProject.no_codebook_matches = false
          const updatedProject = await this.$pigeonline.projects.update(
            datasetProject
          )
          this.$store.dispatch(
            "datasetWizard/setDatasetProject",
            updatedProject
          )
        }
      } catch (e) {
        throw new Error("DatasetTableclientQuestion.question_text " + e.message)
      }

      setTimeout(() => {
        this.isSaved = false
      }, 6000)
    }
  },
  watch: {
    "clientQuestion.question_text": {
      immediate: true,
      handler(val) {
        if (val.length > 0) this.text = val
      }
    },
    text: function() {
      if (this.readOnly) return
      this.isSaving = true
      this.saveText()
    }
  }
}
</script>
