export default {
  computed: {
    isDemoUser: function() {
      if (!this.$store.getters["user/profile"]) return false
      const group_tag = this.profile.group_tag
      return typeof group_tag === "string"
        ? group_tag === "demo_user"
        : group_tag.includes("demo_user")
    }
  }
}
