<template>
  <div class="account-type__content">
    <p class="account-type__description sub-title">
      <VisibleText>What kind of account would you like?</VisibleText>
    </p>
    <form class="account-type__form" @submit.prevent="save">
      <label for="checkbox__demo" class="account-type__form-radio">
        <input
          id="checkbox__demo"
          type="radio"
          name="account-type"
          v-model="checked"
          value="demo"
        />
        <div class="clickable-box"></div>
        <p class="account-type__form-radio-label">
          <VisibleText>Free account (Demo)</VisibleText>
        </p>
      </label>
      <label for="checkbox__organization" class="account-type__form-radio">
        <input
          id="checkbox__organization"
          type="radio"
          name="account-type"
          v-model="checked"
          value="organization"
        />
        <div class="clickable-box"></div>
        <p class="account-type__form-radio-label">
          <VisibleText>Connect to my organization</VisibleText>
        </p>
      </label>
      <button
        class="account-type__form-btn"
        type="submit"
        :disabled="checked == null"
      >
        <VisibleText>Continue</VisibleText>
      </button>
    </form>
  </div>
</template>

<script>
import UserMixin from "@/utils/mixins/userMixin.js"

export default {
  name: "accountNextStepsAccountType",
  mixins: [UserMixin],
  data() {
    return {
      checked: null
    }
  },
  mounted() {
    if (this.isDemoUser) {
      this.checked = "demo"
    }
  },
  methods: {
    save: function() {
      // TODO: set user group

      if (this.checked === "demo") {
        this.$router.push({ name: "accountNextStepsCongratulations" })
      } else {
        this.$router.push({ name: "accountNextStepsOrganization" })
      }
    }
  }
}
</script>
