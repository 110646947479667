<template>
  <dl class="key-value">
    <div class="key-value__item">
      <dt><VisibleText>Owner</VisibleText></dt>
      <dd>
        {{ benchmarkGroup.created_by.name }},
        {{ benchmarkGroup.created_by.organization }}
      </dd>
    </div>
    <div class="key-value__item">
      <dt><VisibleText>Questions</VisibleText></dt>
      <dd>
        {{ benchmarkGroup.questions }}
      </dd>
    </div>
    <div class="key-value__item">
      <dt><VisibleText>Approved matches</VisibleText></dt>
      <dd>
        {{ benchmarkGroup.approved_matches }}
      </dd>
    </div>
    <div class="key-value__item">
      <dt><VisibleText>Auto approve</VisibleText></dt>
      <dd>
        <button
          :class="[
            'toggle-btn',
            benchmarkGroup.auto_approve ? 'toggle-btn-on' : 'toggle-btn-off'
          ]"
          @click="onClickAutoApprove()"
        >
          <VisibleText class="toggle-btn-on-txt">
            on
          </VisibleText>
          <VisibleText class="toggle-btn-off-txt">
            off
          </VisibleText>
        </button>
      </dd>
    </div>
    <!-- editable -->
    <div class="key-value__item" style="max-width: unset;">
      <dt><VisibleText>Access Level</VisibleText></dt>
      <dd>
        <select
          aria-label="access level"
          class="select"
          id="select-access-level"
          v-model="benchmarkGroup.access_type"
        >
          <option
            v-for="level in Object.keys(ACCESS_TYPE_TO_DESC)"
            :key="level"
            :value="level"
            :data-parent="$options.name"
          >
            {{ translate(ACCESS_TYPE_TO_DESC[level].name, $options.name) }} -
            {{ translate(ACCESS_TYPE_TO_DESC[level].desc, $options.name) }}
          </option>
        </select>
      </dd>
    </div>
  </dl>
</template>

<script>
// Consts
import { ACCESS_TYPE_TO_DESC } from "../Utils/consts.js"

// Models
import BenchmarkGroup from "@/models/BenchmarkGroup.js"

export default {
  name: "BenchmarkManagerEditorDetails",
  components: {},
  props: {
    readOnly: {
      default: false,
      type: Boolean
    },
    benchmarkGroup: {
      type: BenchmarkGroup,
      required: true
    }
  },
  data() {
    return {
      ACCESS_TYPE_TO_DESC: ACCESS_TYPE_TO_DESC
    }
  },
  methods: {
    onClickAutoApprove() {
      this.benchmarkGroup.auto_approve = !this.benchmarkGroup.auto_approve
    }
  }
}
</script>
