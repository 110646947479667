<template>
  <div class="table__toolbar">
    <Spinner
      complete-message="questions successfully saved"
      message="saving questions"
      :is-loading="isLoading"
    />
    <!--------------------------------------
    | select box
    ---------------------------------------->
    <label
      for="select-all-questions"
      class="form-label form-checkbox-label table__toolbar-checkbox-select"
      :data-parent="$options.name"
    >
      <input
        class="table__toolbar-checkbox-select-input"
        type="checkbox"
        name="select-all-questions"
        id="select-all-questions"
        v-model="select"
      />
      {{ translate("Select All", $options.name) }}
    </label>

    <!--------------------------------------
    | select data type
    ---------------------------------------->
    <div
      class="dataset-table__options-select-type"
      v-show="selectedClientQuestions.length > 0"
    >
      <SvgIconDecorative icon="analysis" />
      <SelectDataType
        id="table-options"
        :sr-only-label="true"
        @set-data-type="setDataType"
      >
        <VisibleText>data type</VisibleText>
      </SelectDataType>
    </div>

    <!--------------------------------------
    | copy details
    ---------------------------------------->
    <CopyDetailsOptions
      :too-many-questions="selectedClientQuestions.length > 1"
      v-show="selectedClientQuestions.length > 0"
    />

    <!--------------------------------------
    | delete
    ---------------------------------------->
    <button
      type="button"
      class="table__toolbar-btn"
      @click="onClickDelete"
      v-show="selectedClientQuestions.length > 0"
    >
      <SvgIconDecorative icon="delete" />
      <VisibleText>Delete</VisibleText>
    </button>

    <!--------------------------------------
    | bulk edit
    ---------------------------------------->
    <div class="table__toolbar-btn--right-align" v-if="!isDemoUser">
      <button
        class="table__toolbar-btn"
        @click="toggleIsEditing"
        v-show="!isEditing"
      >
        <SvgIconDecorative icon="edit-alt" />
        <VisibleText>Bulk Edit</VisibleText>
      </button>
      <button
        class="table__toolbar-btn"
        @click="toggleIsEditing"
        v-show="isEditing"
      >
        <SvgIconDecorative icon="check" />
        <VisibleText>Done</VisibleText>
      </button>
    </div>
  </div>
</template>

<script>
// Components
import CopyDetailsOptions from "./CopyDetailsOptions.vue"
import SelectDataType from "@/components/Dataset/Components/DatasetSelectType.vue"
import Spinner from "@/components/UI/Spinner.vue"
import SvgIconDecorative from "@/components/UI/Svg/SvgIconDecorative.vue"

// Mixins
import ConfirmMixin from "@/utils/mixins/confirmMixin.js"
import DatasetDetailsMixin from "@/utils/mixins/datasetDetailsMixin.js"
import DataTypeMixin from "@/components/Dataset/Mixins/datatypeMixin.js"
import UserTypeMixin from "@/utils/mixins/userTypeMixin.js"

export default {
  name: "DatasetTableOptions",
  mixins: [ConfirmMixin, DatasetDetailsMixin, DataTypeMixin, UserTypeMixin],
  components: {
    CopyDetailsOptions,
    SelectDataType,
    Spinner,
    SvgIconDecorative
  },
  props: {
    selectedClientQuestions: {
      default: () => [],
      type: Array
    }
  },
  data() {
    return {
      select: false,
      isEditing: false,
      isLoading: false
    }
  },
  computed: {
    confirmStatus() {
      return this.$store.getters["confirm/getStatus"]
    }
  },
  methods: {
    onClickDelete() {
      this.setConfirmText({
        btn: "Delete Question(s)",
        message:
          "You will not be able to recover your data once it is deleted.",
        title: "Delete question?"
      })
      this.setConfirmType("delete")
      this.setConfirmSourceComponent("datasetTable")
      this.setConfirmStatus(false)
      this.setConfirmIsVisible(true)
    },
    toggleIsEditing() {
      this.isEditing = !this.isEditing
      this.$emit("toggle-editing")
    },
    async deleteQuestions() {
      this.isLoading = true
      await Promise.all(
        this.selectedClientQuestions.map(async clientQuestionId => {
          await this.DATASETS_SERVICE.deleteClientQuestion(clientQuestionId)
        })
      )
      await this.updateClientQuestions(this.dataset._id.$oid)
      this.$emit("deselect")
      this.isLoading = false
    },
    async setDataType(dataType) {
      if (this.selectedClientQuestions.length == 0) return
      this.isLoading = true
      await Promise.all(
        this.selectedClientQuestions.map(async clientQuestionId => {
          const clientQ = this.clientQuestions.find(
            q => q._id.$oid === clientQuestionId
          )
          await this.$_dataTypeMixin_saveDataType(clientQ, dataType)
        })
      )
      this.$emit("deselect")
      this.isLoading = false
    }
  },
  watch: {
    confirmStatus: async function(val) {
      if (
        val &&
        this.confirmSourceComponent === "datasetTable" &&
        this.confirmType === "delete"
      ) {
        await this.deleteQuestions()
      }
    },
    select: function(val) {
      if (val) this.$emit("select")
      else this.$emit("deselect")
    }
  }
}
</script>
