<template>
  <section class="dataset-summary" v-if="dataset">
    <Accordion
      class="dataset-summary__accordion"
      title-element-type="h2"
      icon-location="right"
    >
      <template #heading>
        {{ dataset.name }}
      </template>
      <section>
        <h3 class="dataset-summary__details-section-title">
          <SvgIconDecorative icon="information" />
          <VisibleText>Dataset Details</VisibleText>
        </h3>
        <div class="dataset-summary__details">
          <div class="dataset-summary__details-wrapper">
            <VisibleText class="dataset-summary__details-title">
              Owner
            </VisibleText>
            <span> {{ owner }} </span>
          </div>
          <div class="dataset-summary__details-wrapper">
            <VisibleText class="dataset-summary__details-title">
              Survey Start Date
            </VisibleText>
            <span>
              {{
                dataset.survey_details
                  ? dataset.survey_details.surveyEndDate
                  : "n/a"
              }}
            </span>
          </div>
          <div class="dataset-summary__details-wrapper">
            <VisibleText class="dataset-summary__details-title">
              Survey End Date
            </VisibleText>
            <span>
              {{
                dataset.survey_details
                  ? dataset.survey_details.surveyStartDate
                  : "n/a"
              }}
            </span>
          </div>
          <div class="dataset-summary__details-wrapper">
            <VisibleText class="dataset-summary__details-title">
              Response Rate
            </VisibleText>
            <span>
              {{
                dataset.survey_details && dataset.survey_details.rate
                  ? dataset.survey_details.rate
                  : "n/a"
              }}
            </span>
          </div>
        </div>
      </section>
      <section
        class="dataset-summary__tags"
        v-if="Object.keys(dataset.segments).length > 0"
      >
        <h3 class="dataset-summary__details-section-title">
          <SvgIconDecorative icon="tag" />
          <VisibleText>Tags</VisibleText>
        </h3>
        <DatasetSegmentsList :segments="dataset.segments" />
      </section>
    </Accordion>
  </section>
</template>

<script>
// Components
import Accordion from "@/components/UI/Accordion.vue"
import DatasetSegmentsList from "@/components/Dataset/DatasetSegments/DatasetSegmentsList.vue"
import SvgIconDecorative from "@/components/UI/Svg/SvgIconDecorative.vue"

// Mixins
import DatasetDetailsMixin from "@/utils/mixins/datasetDetailsMixin.js"

// Services
import ProfilesService from "@/services/profilesService.js"

export default {
  name: "DatasetSummary",
  mixins: [DatasetDetailsMixin],
  components: {
    Accordion,
    DatasetSegmentsList,
    SvgIconDecorative
  },
  data() {
    return {
      PROFILES_SERVICE: new ProfilesService(this.$pigeonline),
      users: []
    }
  },
  async created() {
    this.users = await this.fetchUsers()
  },
  computed: {
    owner() {
      const userDetails = this.users.find(
        user => user.id === this.dataset.created_by
      )
      return userDetails ? userDetails.email : "n/a"
    }
  },
  methods: {
    async fetchUsers() {
      try {
        const response = await this.PROFILES_SERVICE.users()
        return response
      } catch (e) {
        throw new Error("BenchmarkManagerMatches:fetchUsers " + e.message)
      }
    }
  }
}
</script>
