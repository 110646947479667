<template>
  <div class="add-questions">
    <MessageBlock
      class="add-questions__no-scale-questions"
      message-type="information"
      title="No scale questions"
      v-if="questionsList.length == 0"
    >
      Sorry, there are no scale questions present in the dataset.
    </MessageBlock>
    <template v-else>
      <TableQuestions
        class="add-questions__table-selected"
        :caption="`Selected question/s (${questionsSelected.length})`"
        :questions-list="questionsSelected"
        :enable-sort-by-col-header="false"
        v-if="questionsSelected.length > 0"
        @updated="tableUpdated"
      />
      <TableQuestions
        class="add-questions__table-select"
        :caption="`Select a question(s) to add to performance measure.`"
        :questions-list="questionsRemaining"
        @updated="tableUpdated"
      />
    </template>
  </div>
</template>

<script>
// Components
import MessageBlock from "@/components/UI/Message/MessageBlock.vue"
import TableQuestions from "./TableQuestions.vue"

export default {
  name: "AddQuestions",
  components: {
    MessageBlock,
    TableQuestions
  },
  props: {
    questionsList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      questions: []
    }
  },
  computed: {
    questionsSelected: function() {
      return this.questionsList.filter(el => el.options.selected)
    },
    questionsRemaining: function() {
      return this.questionsList.filter(el => !el.options.selected)
    }
  },
  methods: {
    tableUpdated: function() {
      this.$nextTick(
        function() {
          this.$forceUpdate()
          this.$emit("updated")
        }.bind(this)
      )
    }
  }
}
</script>
