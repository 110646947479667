<template>
  <div class="list-grid-wrapper" role="grid">
    <div role="rowgroup">
      <div class="list-grid-header-wrapper" role="row" :style="gridStyle">
        <div
          v-for="header in headers"
          class="list-grid-header"
          role="columnheader"
          :class="header | replaceSpaceWithDash"
          :id="header | replaceSpaceWithDash"
          :key="header"
        >
          <SortPopup
            v-if="sortConfig && sortConfig[header]"
            :sort-config="sortConfig[header]"
            :sort-title="header"
            @sort="$emit('sort', $event)"
          />
          <VisibleText v-else> {{ header }} </VisibleText>
        </div>
      </div>
    </div>
    <div class="list-grid-items" role="rowgroup">
      <div
        v-for="(item, index) in list"
        role="row"
        :key="`list-row-${index}`"
        :style="gridStyle"
        :class="['list-grid-item-wrapper', `list-grid-item-${index}`]"
      >
        <slot :item="item" :index="index">
          {{ item }}
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
import SortPopup from "@/components/UI/Sort/SortPopup.vue"

export default {
  name: "ListGrid",
  components: {
    SortPopup
  },
  props: {
    headers: {
      default: () => [],
      type: Array
    },
    list: {
      default: () => [],
      type: Array,
      required: true
    },
    customGridStyle: {
      default: () => null,
      type: Object
    },
    sortConfig: {
      type: Object
    }
  },
  computed: {
    gridStyle() {
      const defaultStyle = {
        gridTemplateColumns: `repeat(${this.headers.length}, minmax(auto, 1fr))`
      }
      return this.customGridStyle || defaultStyle
    }
  }
}
</script>
