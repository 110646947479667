export default {
  /**
   * Computes a simple hash value of a string
   * @param {String} a string value whose hash needs to be computed
   */
  hashValue: s => {
    return s
      .toLowerCase()
      .split("")
      .reduce(function(a, b) {
        a = (a << 5) - a + b.charCodeAt(0)
        return a & a
      }, 0)
      .toString()
  }
}
