/**
 * An object representing a benchmarking project
 * a project is complete:
 * - if bechmarksSkipped is true and datasetSegmentsCompleted is true
 * - if benchmarksSkipped is false and benchmarksApproved is true and datastSegmentsCompleted is true
 * @typedef {Object} Project
 *
 * schema_version changes:
 * 2 - customSegments changes from a String to an object
 */

import { ProjectModel } from "@pigeonline/core"

const CURR_VERSION = "2"

export default class BenchmarkingDataset extends ProjectModel {
  constructor(data = {}) {
    super(data)

    this.dataset_id = null
    this.file_type = null
    this.no_codebook_matches = null
    this.status = {
      datasetUploadingCompleted: false,
      datasetSegmentsCompleted: false,
      datasetSegmentsSkipped: false,
      benchmarksSent: false,
      datasetColumnDetailsCompleted: false,
      benchmarksApproved: false,
      benchmarksSkipped: false
    }
    this.progress = {}
    this.customSegments = {}
    this.schema_version = null

    this.setProps(data)
    this.setVersion(data)
    this.setBenchmarkGroup(data)
  }

  /**
   * Get the project type
   * @returns {String} string representation of this project type
   */
  static PROJECT_TYPE() {
    return "service_benchmarking_dataset"
  }

  /**
   * Adds a custom tag to customSegments object
   * @param {String} category UI category for this tag
   * @param {String} tag segment
   */
  addCustomSegment(category, tag) {
    if (!tag) return
    if (!this.customSegments[category]) this.customSegments[category] = []
    if (this.customSegments[category].includes(tag)) return
    this.customSegments[category].push(tag)
  }

  /**
   * Removes a custom tag to customSegments object
   * @param {String} category UI category for this tag
   * @param {String} tag segment
   */
  removeCustomSegment(category, tag) {
    if (!tag || !this.customSegments[category]) return
    const indx = this.customSegments[category].indexOf(tag)
    if (indx === -1) return
    this.customSegments[category].splice(indx, 1)
  }

  /**
   * Revieves a string representation of the current status
   * @returns {String} current project status
   */
  currStatusText() {
    if (
      !this.status.datasetUploadingCompleted ||
      !this.status.datasetColumnDetailsCompleted
    ) {
      return "in progress"
    } else {
      // skipped or completed step 4 & skipped or completed step 3
      if (
        (this.status.datasetSegmentsCompleted ||
          this.status.datasetSegmentsSkipped) &&
        (this.status.benchmarksApproved || this.status.benchmarksSkipped)
      ) {
        return "complete"
      }
    }
    return "in progress"
  }

  /**
   * Returns if benchmarks are pending approval
   * @returns {Boolean} pending
   */
  isBenchmarksPending() {
    return (
      !this.status.benchmarksSkipped &&
      this.status.benchmarksSent &&
      !this.status.benchmarksApproved
    )
  }

  /**
   * Sets benchmark group used for benchmarking.
   * For bwkds compatibility.
   * Beta version of the benchmark manager had benchmark_group saved in a
   * dataset project but v >= 1.3.0 of the app saves it in the dataset object instead.
   * @param {String} data of project
   * for benchmarking.
   */
  setBenchmarkGroup(data) {
    if (data.benchmark_group) this.benchmark_group = data.benchmark_group
  }

  /**
   * Sets schema_version field.
   * If field does not exist it is version 1, dont set anything
   * If it exists, set to saved version
   * else set to CURR_VERSION
   * @param {Object} data of project
   */
  setVersion(data) {
    if (data.schema_version) this.schema_version = data.schema_version
    else this.schema_version = CURR_VERSION
  }

  /**
   * Updates the status of the project by setting status[status] to true
   * (mark status as complete)
   * @param {String} status name of status
   */
  updateStatus(status) {
    this.status[status] = true
  }
}
