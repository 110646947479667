// Components
import Spinner from "@/components/UI/Spinner.vue"

// Helpers
import { mapActions, mapGetters } from "vuex"

// Mixins
import ConfirmMixin from "@/utils/mixins/confirmMixin.js"
import UserTypeMixin from "@/utils/mixins/userTypeMixin.js"

export default {
  mixins: [ConfirmMixin, UserTypeMixin],
  components: {
    Spinner
  },
  computed: {
    ...mapGetters("project", {
      project: "getProject",
      projectUpdatedPerformanceType: "getProjectUpdatedPerformanceType"
    }),
    ...mapGetters("user", {
      profile: "getProfile",
      valid: "getValid"
    }),
    ...mapGetters("globalModule", {
      modalOpen: "getModalOpen"
    }),
    ...mapGetters("loader", { loading: "getLoading" }),
    isProjectOwner() {
      if (!this.profile || !this.project) return false
      return this.project.owner == this.profile.django_ref_id
    },
    projectPerformanceType() {
      if (!this.project) return null
      if (
        this.project.settings.performanceType &&
        this.project.settings.performanceType.selected
      )
        return this.project.settings.performanceType.selected
      return null
    },
    statusOrgNotVerified() {
      return !this.valid && !this.isDemoUser
    }
  },
  methods: {
    ...mapActions("confirm", [
      "setConfirmIsVisible",
      "setConfirmStatus",
      "setConfirmSourceComponent",
      "setConfirmTarget",
      "setConfirmText",
      "setConfirmType",
      "resetConfirm"
    ]),
    ...mapActions("project", [
      "setProject",
      "setProjectUpdatedPerformanceType",
      "resetProject"
    ]),
    ...mapActions("loader", ["setLoading"]),
    /**
     * Fetching and saving project
     * note that vue-core converts projects to apporpriate model so
     * response is of BenchmarkingProject type.
     */
    async saveProject(project) {
      try {
        this.setProject(await this.$pigeonline.projects.update(project))
      } catch (e) {
        throw new Error("ProjectMixin.js:saveProject " + e.message)
      }
    },
    /**
     * Helpers
     */
    updateProjectDataset(datasetID) {
      this.project.addDataset(datasetID)
      this.project.updateStatus("datasetSelected")
      this.saveProject(this.project)
    }
  }
}
