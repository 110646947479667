import BenchmarkingService from "@/services/benchmarkingService.js"
import DatasetsService from "@/services/datasetsService.js"
import DriversService from "@/services/driversService.js"
import PerformanceService from "@/services/performanceService.js"
import ProfilesService from "@/services/profilesService.js"
import TextService from "@/services/textService.js"

class Services {
  constructor(core) {
    this.BENCHMARKING_SERVICE = new BenchmarkingService(core)
    this.DATASETS_SERVICE = new DatasetsService(core)
    this.DRIVERS_SERVICE = new DriversService(core)
    this.PERFORMANCE_SERVICE = new PerformanceService(core)
    this.PROFILES_SERVICE = new ProfilesService(core)
    this.TEXT_SERVICE = new TextService(core)
  }
}
export default Services
