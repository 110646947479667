<template>
  <div class="projects__nav">
    <LinkIconOnly
      class="projects__nav-item"
      icon="report"
      :href="`/project/${project.id}/report/${project.report || 'empty'}`"
      :id="`${project.id}-report`"
    >
      <VisibleText>View</VisibleText> {{ project.name }}
      <VisibleText> report</VisibleText>
    </LinkIconOnly>

    <LinkIconOnly
      class="projects__nav-item"
      icon="analysis"
      :href="`/project/${project.id}/analysis/drivers`"
      :id="`${project.id}-analysis`"
    >
      <VisibleText>View </VisibleText> {{ project.name }}
      <VisibleText> analysis</VisibleText>
    </LinkIconOnly>

    <LinkIconOnly
      class="projects__nav-item"
      :href="`/project/${project.id}`"
      :id="`${project.id}-edit`"
      icon="edit"
    >
      <span><VisibleText>Edit </VisibleText>{{ project.name }}</span>
    </LinkIconOnly>

    <LinkIconOnly
      class="projects__nav-item"
      icon="share"
      :href="`/project/${project.id}?share=true`"
      :id="`${project.id}-share`"
      v-if="isOwner"
    >
      <VisibleText>Share </VisibleText>{{ project.name }}
    </LinkIconOnly>

    <ButtonIconOnly
      class="projects__nav-item"
      icon="delete"
      style="padding-top: 0.25rem"
      :id="`${project.id}-del`"
      @click-handler="confirmDelete"
      v-if="isOwner"
    >
      <VisibleText>Delete </VisibleText>{{ project.name }}
    </ButtonIconOnly>
  </div>
</template>

<script>
import ButtonIconOnly from "@/components/UI/Button/ButtonIconOnly.vue"
import LinkIconOnly from "@/components/UI/Link/LinkIconOnly.vue"

export default {
  name: "ProjectsItemNav",
  components: {
    ButtonIconOnly,
    LinkIconOnly
  },
  props: {
    project: { type: Object }
  },
  computed: {
    confrimStatus() {
      return this.$store.getters["confirm/getStatus"]
    },
    datasetsParams() {
      return this.project.datasets ? `id: ${this.project.datasets[0]}` : ""
    },
    isOwner() {
      return (
        this.project.owner ==
        this.$store.getters["user/getProfile"].django_ref_id
      )
    }
  },
  methods: {
    confirmDelete() {
      this.$store.dispatch("confirm/setConfirmTarget", this.project.id)
      this.$store.dispatch("confirm/setConfirmIsVisible", true)
      this.$store.dispatch("confirm/setConfirmText", {
        btn: "delete project",
        message: "This will also delete your report.",
        title: "delete project?"
      })
      this.$store.dispatch("confirm/setConfirmType", "delete")
      this.$store.dispatch("confirm/setConfirmSourceComponent", "projects")
    },
    async deleteProject() {
      try {
        if (this.project.report)
          this.$pigeonline.projects.delete(this.project.report)
        await this.$pigeonline.projects.delete(this.project.id)
        // TODO: reload only the past projects component
        this.$router.go()
      } catch (e) {
        throw new Error("ProjectList:del " + e.message)
      }
    }
  },
  watch: {
    confrimStatus: async function(val) {
      const targetProject = this.$store.getters["confirm/getTarget"]
      const sourceComponent = this.$store.getters["confirm/getSourceComponent"]
      if (
        val &&
        targetProject === this.project.id &&
        sourceComponent === "projects"
      ) {
        await this.deleteProject()
      }
    }
  }
}
</script>
