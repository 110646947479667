import { HELPER_TRANSLATIONS, LABEL_TRANSLATIONS } from "../translations"
const lang = window.localStorage.getItem("apiLanguage") || "en"

const replaceSpaceWithDash = words => {
  return words.replace(/\s/g, "-")
}

/**
 * Format values of the summary tables
 * @param {Number} value the value of a column in the summary table
 * @returns {Number} value formatted to 2 decimal places or
 *  "N/A if value is NaN
 */
const formatValue = value => {
  if (isNaN(value) || !value) return "N/A"
  else return value.toFixed(2)
}

/**
 * Format values of response distribution table for a client question
 * @param {Object} uniqueVals response values of client questions
 * @returns formatted response distribution table
 */
const getResponseTableData = uniqueVals => {
  const total = Object.values(uniqueVals).reduce((total, c) => total + c)
  const sortedData = Object.entries(uniqueVals)
    .sort(([v1], [v2]) => +v2 - +v1)
    .reduce((sorted, [response, value]) => {
      const percent = formatValue((100 * value) / total)
      const row = []
      row.push(value)
      row.push(`${percent} %`)
      sorted.set(response, row)
      return sorted
    }, new Map())
  sortedData.set("Total", [total])
  const tableData = {
    rowNames: [...sortedData.keys()],
    data: [...sortedData.values()]
  }
  return tableData
}

const getBarCharData = details => {
  const sortedData = Object.keys(details).sort((a, b) => b - a)
  return sortedData.reduce((data, response) => {
    let obj = {}
    obj["label"] = response // response (1 | 2 | 3 etc.)
    obj["value"] = details[response] // count
    data.push(obj)
    return data
  }, [])
}

/**
 * Get benchmark bar chart data by question
 * @param {Object} data detailed_results object
 * @param {String} question question id we want to filter for
 * @returns {Array} benchmarking bar chart data for question
 */
const getBenchmarkData = (data, question) => {
  return data.find(d => d.client_question_id === question)
}

/**
 * Format benchmark group table data
 * @param {Array} unformatted benchmark group objects
 * @param {String} group name of benchmark group, null if its for "all group" section
 * @param {Boolean} withid include "id" column in table row
 * @param {String} rank of "your org". possible values are [A, B, C]
 * @returns {Array} of formatted benchmark group table data
 */

const RANK_TO_COLOUR = {
  A: "#BDD8B8",
  B: "#fff0cf",
  C: "#e6b2a9"
}
const RANK_TO_INFO = {
  A: "your average ranks in the top third of all averages.",
  B: "your average ranks in the middle third of all averages.",
  C: "your average ranks in the bottom third of all averages."
}

const formatBenchmarkGroupData = (
  unformatted,
  group = null,
  withid = null,
  rank = null
) => {
  return unformatted.map(dataset => {
    let row = []
    let allSegments = dataset.all_segments.map(s => s.toLowerCase()).join(", ")
    let item = group ? dataset.segments_mean[group] : dataset

    // add style & info to ranked rows
    if (rank && dataset.is_user) {
      row = [
        {
          value: formatValue(item.mean),
          style: { backgroundColor: RANK_TO_COLOUR[rank] }
        },
        {
          value: item.rank,
          style: { backgroundColor: RANK_TO_COLOUR[rank] }
        },
        {
          value: formatValue(item.percentile),
          style: { backgroundColor: RANK_TO_COLOUR[rank] }
        },
        allSegments
      ]
      if (withid) {
        row.unshift({
          value: dataset.org_id,
          style: {
            backgroundColor: RANK_TO_COLOUR[rank],
            textDecoration: "underline wavy"
          },
          info: RANK_TO_INFO[rank]
        })
      }
    }
    // add non-ranked rows
    else {
      row = [
        formatValue(item.mean),
        item.rank,
        formatValue(item.percentile),
        allSegments
      ]
      if (withid) row.unshift(dataset.org_id)
    }
    return row
  })
}

/**
 * Generate the "top and lowest performers" summary table for a benchmark group
 * @param {Array} benchmarkGroupData objects
 * @param {String} group name of benchmark group, null if for "all group" section
 * @returns {Object} Pigeondoc formatted table object
 */
const getPerformersTable = (benchmarkGroupData, group = null) => {
  let performersData = [],
    lowest = null,
    org = null,
    rowNames = [],
    tableData = []

  if (benchmarkGroupData.length <= 3) {
    performersData = formatBenchmarkGroupData(
      benchmarkGroupData,
      group,
      true,
      "A"
    )
    tableData.push(performersData)
    rowNames = [LABEL_TRANSLATIONS.topPerformers[lang]]
  }

  if (benchmarkGroupData.length > 3) {
    let indx = benchmarkGroupData.findIndex(data => data.is_user)

    // org is in top 3
    if (indx < 3) {
      performersData = formatBenchmarkGroupData(
        benchmarkGroupData.slice(0, 3),
        group,
        true,
        "A"
      )
      tableData.push(performersData)
      rowNames = [
        LABEL_TRANSLATIONS.topPerformers[lang],
        LABEL_TRANSLATIONS.lowestPerformers[lang]
      ]
    }
    // org is lowest, add top 3
    else if (indx === benchmarkGroupData.length - 1) {
      performersData = formatBenchmarkGroupData(
        benchmarkGroupData.slice(0, 3),
        group,
        true
      )
      tableData.push(performersData)
      rowNames = [
        LABEL_TRANSLATIONS.topPerformers[lang],
        LABEL_TRANSLATIONS.yourOrg[lang]
      ]
    }
    // org is somewhere in the middle
    else {
      performersData = formatBenchmarkGroupData(
        benchmarkGroupData.slice(0, 3),
        group,
        true
      )
      org = formatBenchmarkGroupData(
        [benchmarkGroupData.find(data => data.is_user)],
        group,
        true,
        "B"
      )
      tableData.push(performersData)
      tableData.push(org[0])
      rowNames = [
        LABEL_TRANSLATIONS.topPerformers[lang],
        LABEL_TRANSLATIONS.yourOrg[lang],
        LABEL_TRANSLATIONS.lowestPerformers[lang]
      ]
    }

    // add the lowest performer
    lowest = formatBenchmarkGroupData(
      [benchmarkGroupData[benchmarkGroupData.length - 1]],
      group,
      true,
      "C"
    )
    tableData.push(lowest[0])
  }

  let performersTable = {
    type: "table",
    content: {
      data: {
        columnNames: HELPER_TRANSLATIONS.qByQTopPerfsCols[lang],
        rowNames: rowNames,
        data: tableData
      }
    },
    id: `b-group-perf-table-${group ? replaceSpaceWithDash(group) : "all"}`,
    meta: {
      caption: HELPER_TRANSLATIONS.qByQTopPerfCaption[lang],
      lang: lang,
      state: "visible"
    }
  }

  return performersTable
}

/**
 * Construct vertical bar chart nodes for all organization comparisons for a
 * specific quesiton.
 * @param {Array} averageValues average_values array for a particular question
 * @param {String} questionHeading question title and wording used as a heading
 * @param {String} questionTitle question title
 * @param {Array} allAvg mean_and_count_by_segment[All]
 * @returns {Array} pigeondoc nodes: bar chart and two tables
 */
const getAllGroupsChart = (
  averageValues,
  questionHeading,
  questionTitle,
  allAvg
) => {
  //undefined all groups
  if (!allAvg) return []
  //none or one organization in this group
  if (allAvg[1] < 2) return []

  //sort by mean
  averageValues.sort((a, b) => (a.mean < b.mean ? 1 : -1))

  // all groups chart
  let chart = {
    type: "verticalBarChart",
    content: {
      title: `<span class='q-group-b-chart-title'><strong class='q-group-b-chart-title-strong'>${HELPER_TRANSLATIONS.qByQChartAllTitle[lang]}</strong> ${questionHeading}</span>`,
      data: []
    },
    meta: {
      headingLevel: "h3",
      primaryBarColour: "#c6cdd2",
      highlightBarColour: "#6A88AA",
      meanLineColour: "#9F200A",
      pdfConfig: {
        pageBreak: "before"
      }
    },
    id: `b-group-chart-all-${replaceSpaceWithDash(questionTitle)}`
  }

  // all groups table
  let table = {
    type: "table",
    content: {
      data: {
        columnNames: HELPER_TRANSLATIONS.qByQAllPerfCols[lang],
        rowNames: averageValues.map(group => group.org_id),
        data: []
      }
    },
    id: `b-group-table-all-${replaceSpaceWithDash(questionTitle)}`,
    meta: {
      caption: HELPER_TRANSLATIONS.qByQChartAllPerf[lang],
      lang: lang,
      state: "hidden",
      pdfConfig: {
        ignore: true
      }
    }
  }

  averageValues.forEach(dataset => {
    // chart data
    let chartObj = {}
    chartObj["value"] = formatValue(dataset.mean)
    chartObj["label"] = dataset.org_id
    if (dataset.is_user) chartObj["highlight"] = true
    chart.content.data.push(chartObj)

    // table data
    table.content.data.data.push([
      formatValue(dataset.mean),
      dataset.rank,
      formatValue(dataset.percentile),
      dataset.all_segments.map(s => s.toLowerCase()).join(", ")
    ])
  })

  // all groups performers table (summary table)
  let performersTable = getPerformersTable(averageValues)

  // all groups average
  let groupAvgDetails = {
    type: "text",
    content: `<span><strong>${
      LABEL_TRANSLATIONS.overallAvg[lang]
    }</strong> ${formatValue(allAvg[0])}</span><br/>
    <span><strong>${HELPER_TRANSLATIONS.qByQTotalBenchmarks[lang]}</strong>: ${
      allAvg[1]
    }</span>`,
    id: `b-group-avg-details-${replaceSpaceWithDash(questionTitle)}`
  }

  return [chart, groupAvgDetails, performersTable, table]
}

/**
 * Construct vertical bar chart nodes for benchmarking group comparisons
 * (section 3.3). A question is compared against each group.
 * @param {Array} data average_values array for a particular question, each
 * item in the array contains details of a dataset comparator [details of an organization to compare to]
 * @param {Object} means overall means for each benchmark group
 * @param {String} question question wording and title
 * @param {Object} segments client (the dataset being used to generate the report) segments
 * @returns {Array} array of vertical bar chart pigeondoc nodes
 * (one bar chart for each segment group)
 */
const getBenchmarksBySegment = (data, means, question, segments) => {
  let nodes = []

  Object.keys(segments).forEach(group => {
    // check if dataset/organization has data for this segment (group)
    let benchmarkGroupData = data.filter(avgValuesObj =>
      Object.keys(avgValuesObj.segments_mean).includes(group)
    )

    // FIXME: v1.5.1 -- BC requested we dont include so removing this is temp untill v1.6.0 report updates
    // if only the current users data is available, add a no comparables msg
    if (benchmarkGroupData.length < 2) {
      // nodes.push({
      //   type: "heading",
      //   content: `<span class='q-group-b-chart-title'><strong class='q-group-b-chart-title-strong'>${group}</strong> ${question}</span>`,
      //   id: `b-group-no-comparables-h-${replaceSpaceWithDash(group)}`,
      //   meta: {
      //     level: "h3",
      //     pdfConfig: {
      //       pageBreak: "before"
      //     }
      //   }
      // })
      // nodes.push({
      //   type: "text",
      //   content: HELPER_TRANSLATIONS.qByQNoOrg[lang],
      //   id: `b-group-no-comparables-p-${replaceSpaceWithDash(group)}`
      // })
      return
    }

    benchmarkGroupData.sort((a, b) =>
      a.segments_mean[group].mean < b.segments_mean[group].mean ? 1 : -1
    )

    // reduce to pigeondoc bar chart node
    let benchmarkGroupDataObj = benchmarkGroupData.reduce((d, i) => {
      let obj = {}
      obj["value"] = formatValue(i.segments_mean[group].mean)
      obj["label"] = i.org_id
      if (i.is_user) obj["highlight"] = true
      d.push(obj)
      return d
    }, [])

    let chart = {
      type: "verticalBarChart",
      content: {
        title: `<span class='q-group-b-chart-title'><strong class='q-group-b-chart-title-strong'>${group}</strong> ${question}</span>`,
        data: benchmarkGroupDataObj
      },
      meta: {
        headingLevel: "h3",
        primaryBarColour: "#c6cdd2",
        highlightBarColour: "#6A88AA",
        meanLineColour: "#9F200A",
        pdfConfig: {
          pageBreak: "before"
        }
      },
      id: `b-group-chart-${replaceSpaceWithDash(group)}`
    }
    nodes.push(chart)

    if (means[group]) {
      let groupAvgDetails = {
        type: "text",
        content: `<span><strong>${
          LABEL_TRANSLATIONS.overallAvg[lang]
        }</strong> ${formatValue(means[group][0])}</span><br/>
        <span><strong>${
          HELPER_TRANSLATIONS.qByQTotalBenchmarks[lang]
        }</strong>: ${means[group][1]}</span>`,
        id: `b-group-avg-details-${replaceSpaceWithDash(group)}`
      }
      nodes.push(groupAvgDetails)
    }

    // setup performers table (summary table)
    let performersTable = getPerformersTable(benchmarkGroupData, group)
    nodes.push(performersTable)

    let groupTable = {
      type: "table",
      content: {
        data: {
          columnNames: HELPER_TRANSLATIONS.qByQAllPerfCols[lang],
          rowNames: benchmarkGroupData.map(group => group.org_id),
          data: formatBenchmarkGroupData(benchmarkGroupData, group)
        }
      },
      id: `b-group-table-${replaceSpaceWithDash(group)}`,
      meta: {
        caption: HELPER_TRANSLATIONS.qByQChartAllPerf[lang],
        state: "hidden",
        lang: lang,
        pdfConfig: {
          ignore: true
        }
      }
    }
    nodes.push(groupTable)
  })
  return nodes
}

/*******************************
 *
 * Generate nodes required for question by question section (section 3)
 * @param {Object} userQuestionDetails client_questions_analysis
 * @param {Object} fullDetails detailed_results response
 *
 ******************************/
const QuestionByQuesiton = (
  userQuestionDetails,
  matchesDetails,
  segments,
  fullDetails,
  clientQuestions
) => {
  let data = []

  // sort according to client questions in datasetDetails store
  const clientQuestionToID = clientQuestions.map(q => q._id.$oid)
  const sortedResults = clientQuestionToID.reduce((sorted, id) => {
    let summaryObj = userQuestionDetails.find(
      obj => obj.client_question_id === id
    )
    if (summaryObj) sorted.push(summaryObj)
    return sorted
  }, [])

  sortedResults.forEach((question, indx) => {
    let globalMatch = matchesDetails[question.client_question_id] || "q"
    let questionHeading = `${globalMatch.global_question_title}: ${globalMatch.global_question_text} (${question.question_title})`
    let questionWithDashes = replaceSpaceWithDash(
      globalMatch.global_question_title
    )
    let header = {
      type: "heading",
      content: questionHeading,
      id: `q-group-heading-${questionWithDashes}`,
      meta: {
        level: "h2",
        pdfConfig: {
          pageBreak: "before",
          style: "q-group-heading"
        }
      }
    }

    // remove page break for first question
    if (indx === 0) delete header.meta.pdfConfig.pageBreak

    // possible responses of each question, used as labels (1, 2, 3, 4, 5 etc)
    const uniqueVals = question["unique_values_count"]
    if (Object.keys(uniqueVals).length == 0) return

    // get benchmark data for this specific question (a object in detailed_results)
    const benchmarkData = getBenchmarkData(
      fullDetails,
      question.client_question_id
    )

    // question average score TODO: add this to the backend response of client_questions_analysis
    const avg = formatValue(benchmarkData.mean_and_count_by_segment.User[0])
    let avgText = {
      type: "text",
      content: `<strong>${LABEL_TRANSLATIONS.avgScore[lang]}:</strong> ${avg}`,
      id: `q-group-avg-${questionWithDashes}`
    }

    // question reponses table
    const tableData = getResponseTableData(uniqueVals)
    let scoreTable = {
      type: "table",
      content: {
        data: {
          columnNames: HELPER_TRANSLATIONS.qByQResponseTableCols[lang],
          rowNames: tableData.rowNames,
          data: tableData.data
        }
      },
      meta: {
        caption: "Response Distribution",
        lang: lang,
        pdfConfig: {
          size: "width-50",
          widths: ["auto", "auto", 200]
        }
      },
      id: `q-group-table-${questionWithDashes}`,
      layout: "document-node--width-50"
    }

    // ueser question response bar chart
    const barChartData = getBarCharData(uniqueVals)
    let barChart = {
      type: "horizontalBarChart",
      content: {
        title: `<span class='pdf-hidden-text sr-only'>${HELPER_TRANSLATIONS.qByQResponseSummary[lang]}</span>`,
        data: barChartData
      },
      meta: {
        headingLevel: "span",
        primaryBarColour: "#6A88AA",
        axisLabels: {
          x: LABEL_TRANSLATIONS.count[lang],
          y: LABEL_TRANSLATIONS.response[lang]
        },
        pdfConfig: {
          chartWidth: 360,
          size: "width-50"
        }
      },
      id: `q-group-chart-${questionWithDashes}`,
      layout: "document-node--width-50"
    }

    // generate "all groups(organizations)" bar chart for this question
    const allGroupsChart = getAllGroupsChart(
      benchmarkData.average_values,
      questionHeading,
      globalMatch.global_question_text,
      benchmarkData.mean_and_count_by_segment["All"]
    )

    // generate bar charts for each benchmark group (for this question)
    const benchmarksByGroup = getBenchmarksBySegment(
      benchmarkData.average_values,
      benchmarkData.mean_and_count_by_segment,
      questionHeading,
      segments
    )

    data = data.concat([header, avgText, scoreTable, barChart])
    if (allGroupsChart.length > 0) data = data.concat(allGroupsChart)
    data = data.concat(benchmarksByGroup)
  })

  return data
}

export default QuestionByQuesiton
