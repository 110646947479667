import Vue from "vue"

export default {
  namespaced: true,
  state() {
    return {
      clickedClose: false,
      isVisible: false,
      status: false, //false = canceled, true = confirmed
      sourceComponent: null, //keep track of which component the confirmation is coming from
      target: null, //keep track of the target object or id
      text: {
        message: "Are you sure you want to continue?",
        title: "Confirm?",
        btn: "continue"
      },
      type: "error"
    }
  },
  getters: {
    getClickedClose: state => state.clickedClose,
    getIsVisible: state => state.isVisible,
    getStatus: state => state.status,
    getSourceComponent: state => state.sourceComponent,
    getTarget: state => state.target,
    getText: state => state.text,
    getType: state => state.type
  },
  mutations: {
    SET_CLICKED_CLOSE(state, payload) {
      Vue.set(state, "clickedClose", payload)
    },
    SET_IS_VISIBLE(state, payload) {
      Vue.set(state, "isVisible", payload)
    },
    SET_STATUS(state, payload) {
      Vue.set(state, "status", payload)
    },
    SET_SOURCE_COMPONENT(state, payload) {
      Vue.set(state, "sourceComponent", payload)
    },
    SET_TARGET(state, payload) {
      Vue.set(state, "target", payload)
    },
    SET_TEXT(state, payload) {
      Vue.set(state, "text", payload)
    },
    SET_TYPE(state, payload) {
      Vue.set(state, "type", payload)
    },
    RESET_CONFIRM(state) {
      const textInitState = {
        message: "Are you sure you want to continue?",
        title: "Confirm?",
        btn: "confirm"
      }
      Vue.set(state, "clickedClose", false)
      Vue.set(state, "isVisible", false)
      Vue.set(state, "status", false)
      Vue.set(state, "sourceComponent", null)
      Vue.set(state, "target", null)
      Vue.set(state, "text", textInitState)
      Vue.set(state, "type", "error")
    }
  },
  actions: {
    async setConfirmClickedClose({ commit }, payload) {
      await commit("SET_CLICKED_CLOSE", payload)
    },
    async setConfirmIsVisible({ commit }, payload) {
      await commit("SET_IS_VISIBLE", payload)
    },
    async setConfirmStatus({ commit }, payload) {
      await commit("SET_STATUS", payload)
    },
    async setConfirmSourceComponent({ commit }, payload) {
      await commit("SET_SOURCE_COMPONENT", payload)
    },
    async setConfirmTarget({ commit }, payload) {
      await commit("SET_TARGET", payload)
    },
    async setConfirmText({ commit }, payload) {
      await commit("SET_TEXT", payload)
    },
    async setConfirmType({ commit }, payload) {
      await commit("SET_TYPE", payload)
    },
    resetConfirm({ commit }) {
      commit("RESET_CONFIRM")
    }
  }
}
