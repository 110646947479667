<template>
  <header>
    <div class="logo">
      <router-link to="/" exact aria-label="home">
        <theme-image
          image="header"
          class="logo-image"
          aria-hidden="true"
          focusable="false"
        ></theme-image>
      </router-link>
    </div>
    <template v-if="isAuthenticated">
      <HamburgerIcon
        id="hamburger-icon"
        @click="isNavExpanded = !isNavExpanded"
      />
      <nav
        aria-label="primary"
        class="navigation"
        :class="{ 'navigation__wrapper-active': isNavExpanded }"
      >
        <ul class="navigation__inner">
          <li
            :class="[
              'navigation__item',
              { 'navigation__item-active': isActiveProjectsLink }
            ]"
          >
            <router-link to="/projects" class="navigation__item-link">
              <VisibleText>Projects</VisibleText>
            </router-link>
          </li>
          <li
            :class="[
              'navigation__item',
              { 'navigation__item-active': isActiveDatasetsLink }
            ]"
          >
            <router-link to="/datasets" class="navigation__item-link">
              <VisibleText>Datasets</VisibleText>
            </router-link>
          </li>
          <li
            :class="['navigation__item', 'navigation__lang']"
            id="lang-select-nav"
          >
            <label for="lang-select">
              <SvgIconDecorative icon="translate" style="height: 1.25rem" />
              <span class="sr-only"><VisibleText>language</VisibleText></span>
            </label>
            <select
              class="select"
              id="lang-select"
              @change="changeLanguage($event)"
            >
              <option
                value="en"
                :data-parent="$options.name"
                :selected="$store.getters['getLanguage'] === 'en'"
              >
                {{ translate("English", $options.name) }}
              </option>
              <option
                value="fr"
                :data-parent="$options.name"
                :selected="$store.getters['getLanguage'] === 'fr'"
              >
                {{ translate("French", $options.name) }}
              </option>
            </select>
          </li>
          <li :class="['navigation__item']" id="user-guide-nav">
            <button
              class="guideLink"
              @click="isUserGuideExpanded = !isUserGuideExpanded"
              v-analytics.click="
                () =>
                  $analytics.event({
                    type: 'BUTTON_CLICK',
                    value: 'nav item - help/guide'
                  })
              "
            >
              <SvgIconDecorative
                icon="questionCircle"
                style="height: 1.25rem"
              />
              <VisibleText>Help/Guide</VisibleText>
            </button>
          </li>
          <li
            :class="[
              'navigation__item',
              {
                'navigation__item-active': $route.name === 'accountProfile'
              }
            ]"
            id="profile-nav"
            v-click-outside="closeAccountSubmenu"
          >
            <button
              aria-haspopup="true"
              class="navigation__toggle"
              id="profile-nav-btn"
              ref="profile-nav"
              :aria-expanded="isAccountSubmenuExpanded.toString()"
              @click="toggleAccountSubmenu"
            >
              <span class="sr-only">
                <VisibleText>show my account submenu</VisibleText>
              </span>
              <AccountIcon
                aria-hidden="true"
                focusable="false"
                id="profile-nav-icon"
              />
              <SvgIconDecorative icon="chevronDown" style="width: 1rem" />
            </button>
            <transition name="fade">
              <div
                id="profile-nav-items"
                role="menu"
                ref="profile-nav-submenu"
                v-show="isAccountSubmenuExpanded"
              >
                <router-link
                  to="/account/profile"
                  class="profile-nav__item"
                  role="menuitem"
                  tabindex="-1"
                  @click.native="closeAccountSubmenu"
                  @keydown.native="focusNextItem"
                >
                  <VisibleText>Profile</VisibleText>
                </router-link>
                <HasPermission to="benchmarking-manager">
                  <router-link
                    to="/benchmark-manager"
                    class="profile-nav__item"
                    role="menuitem"
                    tabindex="-1"
                    @click.native="closeAccountSubmenu"
                    @keydown.native="focusNextItem"
                  >
                    <VisibleText>Benchmark Manager</VisibleText>
                  </router-link>
                </HasPermission>
                <router-link
                  to="/custom-topic-model"
                  class="profile-nav__item"
                  role="menuitem"
                  tabindex="-1"
                  @click.native="closeAccountSubmenu"
                  @keydown.native="focusNextItem"
                >
                  <VisibleText>Custom Topic Model</VisibleText>
                </router-link>
                <button
                  class="profile-nav__item"
                  id="change_language"
                  role="menuitem"
                  type="button"
                  @click="onClickTranslationChecker()"
                  @keydown="focusNextItem"
                  v-if="hasPigeonPermission"
                >
                  <VisibleText>
                    Translation checker {{ changeLangDebugStatus }}
                  </VisibleText>
                </button>
                <router-link
                  to="/logout"
                  class="profile-nav__item"
                  id="logout"
                  role="menuitem"
                  tabindex="-1"
                  @keydown.native="focusNextItem"
                >
                  <VisibleText>Logout</VisibleText>
                </router-link>
              </div>
            </transition>
          </li>
        </ul>
      </nav>
    </template>
    <UserGuide
      v-bind:status="isUserGuideExpanded"
      @close="isUserGuideExpanded = false"
    ></UserGuide>
  </header>
</template>

<script>
import { mapGetters, mapActions } from "vuex"

// Icons
import HamburgerIcon from "@/assets/icons/hamburger.svg"
import AccountIcon from "@/assets/icons/profile.svg"
import UserGuide from "@/components/UserGuide/UserGuide.vue"
import SvgIconDecorative from "@/components/UI/Svg/SvgIconDecorative.vue"

export default {
  name: "Header",
  components: {
    HamburgerIcon,
    AccountIcon,
    UserGuide,
    SvgIconDecorative
  },
  props: {
    isAuthenticated: { type: Boolean }
  },
  data() {
    return {
      isNavExpanded: false,
      isAccountSubmenuExpanded: false,
      isUserGuideExpanded: false,
      languageSelectOpen: false,
      changeLangDebugStatus: "on"
    }
  },
  computed: {
    ...mapGetters("user", {
      profile: "getProfile"
    }),
    isActiveDatasetsLink() {
      const validNames = ["datasets", "dataset"]
      return validNames.includes(this.$route.name)
    },
    isActiveProjectsLink() {
      const validNames = [
        "projects",
        "project",
        "data",
        "analysis",
        "report",
        "report-edit"
      ]
      return validNames.includes(this.$route.name)
    },
    hasPigeonPermission() {
      if (this.profile) {
        if (this.profile.group_tag.includes("pigeon")) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    }
  },
  methods: {
    ...mapActions(["setLanguage", "setLanguageDebug"]),
    /**
     * Navigation keyboard helpers
     */
    focusNextItem($e) {
      switch ($e.key) {
        case "Down":
        case "ArrowDown":
          if ($e.target.nextElementSibling) {
            $e.target.nextElementSibling.focus()
          } else {
            this.$refs["profile-nav-submenu"].firstElementChild.focus()
          }
          break
        case "Up":
        case "ArrowUp":
          if ($e.target.previousElementSibling) {
            $e.target.previousElementSibling.focus()
          } else {
            this.$refs["profile-nav-submenu"].lastElementChild.focus()
          }
          break
        case "Esc":
        case "Escape":
          this.$refs["profile-nav"].focus()
          this.isAccountSubmenuExpanded = false
          break
        case "Tab":
          this.isAccountSubmenuExpanded = false
          break
        default:
          return
      }
      $e.preventDefault()
    },
    // custom directive v-click-outside needs a method
    closeAccountSubmenu() {
      this.isAccountSubmenuExpanded = false
    },
    toggleAccountSubmenu() {
      this.isAccountSubmenuExpanded = !this.isAccountSubmenuExpanded
    },
    onClickTranslationChecker() {
      this.setLanguageDebug(this.changeLangDebugStatus)
      this.changeLangDebugStatus =
        this.$store.getters["getLanguageDebug"] === "on" ? "off" : "on"
    },
    async changeLanguage(language) {
      const profile = await this.$pigeonline.profile.save({
        languagePreference: language.target.value
      })
      this.$store.dispatch("user/setProfile", profile)
    },
    setLangValue(language) {
      if (!language) return
      // store language to localStorage and vue store
      this.setLanguage(language)
      window.localStorage.setItem("apiLanguage", language)
    }
  },
  watch: {
    "profile.languagePreference": {
      handler: function(langVal) {
        const val = langVal ? langVal : "en"
        this.setLangValue(val)
      }
    },
    isAccountSubmenuExpanded: function() {
      this.$refs["profile-nav"].setAttribute(
        "aria-expanded",
        this.isAccountSubmenuExpanded
      )
      if (this.isAccountSubmenuExpanded) {
        this.$nextTick(() =>
          this.$refs["profile-nav-submenu"].firstElementChild.focus()
        )
      }
    }
  }
}
</script>
