import { mapActions, mapGetters } from "vuex"

// Components
import Spinner from "@/components/UI/Spinner.vue"

// Utils
import hash from "@/utils/hash.js"

// Lib
import _ from "lodash"

const API_ERROR_MESSAGE =
  "An error occured processing your request. Please try again later."

const CACHE_RESPONSES_WITH_SENTIMENTS = {}
const CACHE_RESPONSES_THEME = {}
const CACHE_COVERAGE = {}
const CACHE_SENTIMENT = {}

export default {
  components: {
    Spinner
  },
  data() {
    return {
      requiresRefetchingThemeResponses: false
    }
  },
  computed: {
    // services
    DATASETS_SERVICE() {
      return this.$services.DATASETS_SERVICE
    },
    TEXT_SERVICE() {
      return this.$services.TEXT_SERVICE
    },
    modalRef: function() {
      return `modal-${this._uid}`
    },
    ...mapGetters("project", {
      project: "getProject"
    }),
    ...mapGetters("globalModule", {
      modalOpen: "getModalOpen"
    }),
    ...mapGetters("analysisText", {
      _activeTab: "getActiveTab",
      _datasetId: "getDatasetId",
      textQuestions: "getTextQuestions",
      selectedTextQuestion: "getSelectedTextQuestion",
      selectedTextQuestionResponses: "getSelectedTextQuestionResponses",
      themes: "getThemes",
      selectedThemeIndex: "getSelectedThemeIndex",
      selectedThemeResponses: "getSelectedThemeResponses",
      sentimentsSortOrder: "getSentimentsSortOrder",
      selectedResponseIds: "getSelectedResponseIds",
      search: "getSearch",
      bannedKeywords: "getBannedKeywords",
      bannedComments: "getBannedComments",
      pinnedComments: "getPinnedComments",
      hiddenComments: "getHiddenComments",
      showSpinner: "getShowSpinner",
      showResponseListLoadingSpinner: "getShowResponseListLoadingSpinner"
    }),
    isSearchMode: function() {
      return this.search.searchString.trim() !== ""
    },
    isThemeSelected: function() {
      return this.selectedThemeIndex !== -1
    },
    isVisFilterActivated: function() {
      return this.selectedResponseIds.length > 0
    },
    isActiveTabSentiment: function() {
      return this._activeTab == "Sentiment"
    },
    searchResponseIds: function() {
      return this.search.searchResults.map(sItem => sItem[0].id)
    },
    selectedTextQuestionId: function() {
      if (!this.selectedTextQuestion) return undefined
      return this.selectedTextQuestion._id.$oid
    },
    selectedThemeResponseIds: function() {
      return this.selectedThemeResponses.map(tItem => tItem[0].id)
    },
    textResponses: function() {
      return this.selectedTextQuestionResponses
        .filter(item => !this.bannedComments.includes(item.id))
        .map(
          function(item) {
            // array of keywords (by each theme) present in a response
            const themeKeywords = this.themes.map(theme =>
              theme.keywords
                .map(k => k.toLowerCase())
                .filter(k => item.response.toLowerCase().includes(k))
            )

            // array of search match keywords
            const searchResultItem = this.search.searchResults[
              this.searchResponseIds.indexOf(item.id)
            ]
            const searchKeywords =
              (searchResultItem &&
                searchResultItem[0].matching_reasons
                  .map(item =>
                    String(item)
                      .trim()
                      .toLowerCase()
                  )
                  .sort((a, b) => b.length - a.length)) ||
              []
            const searchScore = (searchResultItem && searchResultItem[1]) || 0

            return {
              idx: item.idx, // 0-index value of a response
              id: item.id, // response oid
              response: item.response, // response text
              keywords: {
                search: searchKeywords,
                sentiment: {
                  pos: item.sentiment.posKeywords,
                  neg: item.sentiment.negKeywords
                },
                themes: themeKeywords
              },
              scores: {
                search: searchScore,
                sentiment: item.sentiment.score,
                themes: themeKeywords.map((kws, index) =>
                  this.themes[index] && this.themes[index].keywords.length !== 0
                    ? kws.length / this.themes[index].keywords.length
                    : 0
                )
              }
            }
          }.bind(this)
        )
    },
    textResponsesFilteredWithoutVisSelection: function() {
      let responses = [...this.textResponses]

      // when a theme is selected
      if (this.isThemeSelected) {
        responses = responses.filter(rItem =>
          this.selectedThemeResponseIds.includes(rItem.id)
        )
      }

      // active search mode
      if (this.isSearchMode) {
        responses = responses.filter(rItem =>
          this.searchResponseIds.includes(rItem.id)
        )
      }

      return responses
    },
    textResponsesFiltered: function() {
      let responses = this.textResponsesFilteredWithoutVisSelection

      // visualisation: selection/filter
      if (this.isVisFilterActivated) {
        responses = responses.filter(rItem =>
          this.selectedResponseIds.includes(rItem.id)
        )
      }

      return responses
    },
    resultText: function() {
      let responseCount = this.textResponsesFiltered.length
      let responseQuantifier = `<i>${
        this.isVisFilterActivated ? "filtered" : "all"
      }</i>`
      let responseText = ""
      if (this.isSearchMode && this.isThemeSelected) {
        responseText = `${this.translate(
          "Showing results from",
          this.$options.name
        )} ${responseQuantifier} text responses in theme [<span style="font-style: italic;">${
          this.themes[this.selectedThemeIndex].name
        }</span>].`
      }
      if (this.isSearchMode && !this.isThemeSelected) {
        responseText = `${this.translate(
          "Showing results from",
          this.$options.name
        )} ${responseQuantifier} text responses.`
      }

      if (!this.isSearchMode && this.isThemeSelected) {
        responseText = `Showing ${responseQuantifier} text responses from theme [<span style="font-style: italic;">${
          this.themes[this.selectedThemeIndex].name
        }</span>].`
      }

      if (!this.isSearchMode && !this.isThemeSelected) {
        responseText = `Showing ${responseQuantifier} text responses.`
      }
      return responseText + ` ${responseCount} results found. `
    }
  },
  methods: {
    getModalRef() {
      return this.$refs[this.modalRef]
    },
    showModalMessage(messageType, message) {
      this.getModalRef() && this.getModalRef().showMessage(messageType, message)
    },
    ...mapActions("project", ["setProject"]),
    ...mapActions("analysisText", [
      "setActiveTab",
      "setDatasetId",
      "setTextQuestions",
      "setSelectedTextQuestion",
      "setSelectedTextQuestionResponses",
      "setThemes",
      "setSelectedThemeIndex",
      "setSelectedThemeResponses",
      "setSentimentsSortOrder",
      "setSelectedResponseIds",
      "setSearch",
      "setShowSpinner",
      "setShowResponseListLoadingSpinner",
      "setBannedKeywords",
      "setBannedComments",
      "setPinnedComments",
      "setHiddenComments",
      "resetAnalysisText",
      "resetSearch"
    ]),

    /* Local storage */
    getApiLanguage() {
      return window.localStorage.getItem("apiLanguage") || ""
    },

    /* Utils */
    deepCloneObj(obj) {
      // deep clones an object using JSON stringify (data loss might occur)
      if (Array.isArray(obj)) {
        return obj.map(item => JSON.parse(JSON.stringify(item)))
      } else if (typeof obj == "object") {
        return JSON.parse(JSON.stringify(obj))
      }
    },

    arrayEquals(array1, array2) {
      return JSON.stringify(array1.sort()) === JSON.stringify(array2.sort())
    },

    isEmpty(theme) {
      return (
        !theme ||
        (theme.keywords.length === 0 &&
          theme.excerpts.length === 0 &&
          theme.notes.length === 0)
      )
    },

    getResponse(responseId) {
      return this.selectedTextQuestionResponses.filter(
        item => item.id === responseId
      )[0]
    },

    filterBannedKeywords(keywords) {
      return keywords.filter(
        el => this.bannedKeywords.indexOf(el.trim().toLowerCase()) === -1
      )
    },

    /* Alert confirmation modal helper */
    _alert(
      message,
      title,
      confirmType,
      confirmSourceComponent,
      btnText = "Okay"
    ) {
      this.setConfirmText({
        btn: btnText,
        title: title,
        message: message
      })
      this.setConfirmType(confirmType)
      this.setConfirmSourceComponent(confirmSourceComponent)
      this.setConfirmIsVisible(true)
    },

    /**
     * Ban keyword
     * @param {String} keyword to ban
     * @return None
     */
    banKeyword(keyword) {
      if (this.bannedKeywords.includes(keyword.trim().toLowerCase())) {
        this._alert("Keyword already banned!", "Error", "error", "banKeyword")
        return
      }

      // update store
      const bannedKeywordsOld = [...this.bannedKeywords]
      this.setBannedKeywords([...this.bannedKeywords, keyword])

      // update project
      this.project["textAnalysis"]["bannedKeywords"] = this.bannedKeywords
      this.prepareThemes(this.deepCloneObj(this.themes))
        .then(themes => {
          this.project["textAnalysis"]["themes"] = themes
          return this.$pigeonline.projects
            .update(this.project)
            .then(project => {
              this.setProject(project)
              this.setThemes(project["textAnalysis"]["themes"])
              this._alert(
                "Keyword banned successfully.",
                "Success",
                "success",
                "banKeyword"
              )
            })
        })
        .catch(e => {
          this._alert(API_ERROR_MESSAGE, "Error", "error", "banKeyword")
          this.setBannedKeywords(bannedKeywordsOld) // restore banned keywords
          throw new Error("analysisTextMixin.js:banKeyword: " + e)
        })
    },

    /**
     * Generate themes based on project and client_question id.
     * @return [Object] list of themes
     */
    async generateThemes() {
      try {
        const response = await this.TEXT_SERVICE.keywords({
          project_id: this.project.id,
          data_set_id: this._datasetId,
          client_question_id: this.selectedTextQuestion._id.$oid
        })

        const _map = async function(theme) {
          const keywords = this.filterBannedKeywords(
            theme[1].keywords.map(item => item[0])
          )
          return {
            name: theme[0],
            keywords: keywords,
            coverage: theme[1].coverage,
            sentiment: await this.computeSentiment(keywords),
            excerpts: [],
            notes: []
          }
        }.bind(this)
        return await Promise.all(Object.entries(response).map(theme => _map(theme))) // eslint-disable-line
      } catch (e) {
        console.error(
          "ProjectAnalysisTextTheme.vue:generateThemes: " + e.message
        ) // eslint-disable-line
      }
    },

    /**
     * Fetch text responses for a client question with sentiment score
     * @param None
     * @return [Object] list of text responses
     */
    fetchTextResponsesWithSentiments() {
      if (!this.selectedTextQuestionId) return Promise.resolve([])

      const hashKey = this.selectedTextQuestionId + this.getApiLanguage()
      const cacheResponse = CACHE_RESPONSES_WITH_SENTIMENTS[hashKey]
      if (cacheResponse) return Promise.resolve(cacheResponse)

      this.setShowResponseListLoadingSpinner(true)
      return this.TEXT_SERVICE.sentiments({
        client_question_id: this.selectedTextQuestionId
      }).then(response => {
        CACHE_RESPONSES_WITH_SENTIMENTS[hashKey] = response
        this.setShowResponseListLoadingSpinner(false)
        return response.map(function(item, index) {
          return {
            idx: index,
            id: item[0].id,
            response: item[0].response,
            sentiment: {
              score: item[1],
              posKeywords: item[2].pos_words_list.map(el =>
                el.trim().toLowerCase()
              ),
              negKeywords: item[2].neg_word_list.map(el =>
                el.trim().toLowerCase()
              )
            }
          }
        })
      })
    },

    /**
     * Fetch theme responses from the api for a specific theme
     * @param None
     * @return [Object] list of theme responses
     */
    fetchThemeResponses() {
      const payload = {
        client_question_id: this.selectedTextQuestionId,
        project_id: this.project.id,
        theme_name: this.themes[this.selectedThemeIndex].name,
        keywords: this.themes[this.selectedThemeIndex].keywords
      }
      const hashKey = hash.hashValue(
        JSON.stringify(payload) + this.getApiLanguage()
      )
      const cacheResponse = CACHE_RESPONSES_THEME[hashKey]
      if (cacheResponse) {
        return Promise.resolve(cacheResponse)
      }
      this.setShowResponseListLoadingSpinner(true)
      return this.TEXT_SERVICE.filterTheme(payload).then(response => {
        CACHE_RESPONSES_THEME[hashKey] = response
        this.setShowResponseListLoadingSpinner(false)
        return response
      })
    },

    /**
     * Update theme title for given index
     * @param {Int} theme index
     * @param {String} theme title
     * @return None
     */
    updateThemeTitle(themeIndex, title) {
      let themes = this.deepCloneObj(this.themes)
      if (typeof title !== "string" || title.trim() === "") {
        title = "unnamed theme"
      }
      let themeTitles = themes.map(item => item.name.toLowerCase())
      themeTitles.splice(themeIndex, 1)

      let count = 1
      let loop = themeTitles.includes(title)
      while (loop) {
        if (themeTitles.includes(title + count)) {
          count += 1
          continue
        }
        title = title + count
        break
      }
      themes[themeIndex].name = title

      this.saveThemes(themes)
    },

    /**
     * Preprocess themes a.k.a. compute coverage and sentiment
     * @param {Object} themes dict
     * @return {Object} preprocess themes dict
     */
    prepareThemes: async function(themes, forceCompute = false) {
      for (let i = 0; i < themes.length; i++) {
        if (!themes[i]) continue

        // recompute coverage if keywords array differs from original
        let keywords = Array.from(
          new Set(this.filterBannedKeywords(themes[i].keywords))
        )
        if (
          forceCompute ||
          !this.arrayEquals(
            keywords,
            this.themes[i] ? this.themes[i].keywords : []
          )
        ) {
          themes[i].coverage = await this.computeCoverage(
            keywords,
            forceCompute
          )
          themes[i].sentiment = await this.computeSentiment(
            keywords,
            forceCompute
          )

          // set refetch to true if selected theme modified
          if (this.selectedThemeIndex === i) {
            this.requiresRefetchingThemeResponses = true
          }
        }
        themes[i].keywords = keywords
        themes[i].excerpts = _.uniqWith(
          themes[i].excerpts.filter(el => el.responseId),
          _.isEqual
        )
      }
      return themes.filter(theme => !this.isEmpty(theme))
    },

    /**
     * Save themes to backend after preprocessing
     * @param {Object} new themes dict
     * @return None
     */
    async saveThemes(newValue, forceCompute = false) {
      this.showModalMessage(null, null) // remove any existing modal message

      // clone new value and retain old one
      let oldThemes = this.deepCloneObj(this.themes)
      return this.prepareThemes(this.deepCloneObj(newValue), forceCompute)
        .then(newThemes => {
          this.setThemes(newThemes)
          this.project["textAnalysis"]["themes"] = newThemes

          return this.$pigeonline.projects
            .update(this.project)
            .then(response => {
              if (response && response.id) {
                this.setProject(response)
                if (this.selectedThemeIndex >= newThemes.length) {
                  this.setSelectedThemeIndex(-1) // reset theme
                  this.setSelectedThemeResponses([])
                }
              }
            })
        })
        .catch(e => {
          // revert the changes
          this.setThemes(oldThemes)
          this.project["textAnalysis"]["themes"] = oldThemes
          throw new Error("analysisTextMixin.js:saveThemes: " + e.message)
        })
    },

    /**
     * Computes coverage for a list of keywords
     * @param [String] list of keywords
     * @return {Float} coverage
     */
    computeCoverage(keywords, forceCompute = false) {
      const keywordsFiltered = [...this.filterBannedKeywords(keywords)].sort()
      if (keywordsFiltered.length === 0) return 0
      const hashKey = hash.hashValue(
        this.selectedTextQuestionId +
          keywordsFiltered.join(":") +
          this.getApiLanguage()
      )
      const cacheResponse = !forceCompute && CACHE_COVERAGE[hashKey]
      if (cacheResponse) {
        return Promise.resolve(cacheResponse)
      }
      return this.TEXT_SERVICE.coverage({
        project_id: this.project.id,
        client_question_id: this.selectedTextQuestionId,
        search_keywords: keywordsFiltered
      }).then(response => {
        CACHE_COVERAGE[hashKey] = response
        return response
      })
    },

    /**
     * Compute sentiment score for a list of keywords
     * @param [String] list of keywords
     * @return {Object} sentiment score dict
     */
    computeSentiment(keywords, forceCompute = false) {
      const keywordsFiltered = [...this.filterBannedKeywords(keywords)].sort()
      if (keywordsFiltered.length === 0) return 0
      const hashKey = hash.hashValue(
        this.selectedTextQuestionId +
          keywordsFiltered.join(":") +
          this.getApiLanguage()
      )
      const cacheResponse = !forceCompute && CACHE_SENTIMENT[hashKey]
      if (cacheResponse) {
        return Promise.resolve(cacheResponse)
      }
      return this.TEXT_SERVICE.sentiment({
        project_id: this.project.id,
        client_question_id: this.selectedTextQuestionId,
        keywords: keywordsFiltered
      }).then(response => {
        CACHE_SENTIMENT[hashKey] = response
        return response
      })
    },

    generateSentimentText(score) {
      return score >= 0
        ? `${(score * 100).toFixed(1)}% positive sentiment`
        : `${(score * -100).toFixed(1)}% negative sentiment`
    },

    /**
     * Select a text question (set as active)
     * @param [Object] question object
     */
    async selectTextQuestion(question) {
      await this.setSelectedTextQuestion(question)
      this.updateSelectedTextQuestionResponses()
    },

    /**
     * Update themes by recomputing coverage/sentiment score (if necessary)
     * @param None
     */
    updateThemesData() {
      let themes = this.project.textAnalysis && this.project.textAnalysis.themes
      if (!themes || !Array.isArray(themes) || themes.length === 0) return

      let isUpdateRequired = false

      // update themes data
      Promise.all(
        themes
          .filter(theme => theme != null)
          .map(
            function(theme) {
              const apiPromises = [Promise.resolve(true), Promise.resolve(true)]
              const keywords = this.filterBannedKeywords(theme.keywords)

              // update theme keywords
              theme.keywords = [...keywords]

              // recompute sentiment
              if (!theme.sentiment || typeof theme.sentiment != "object") {
                // API: text/sentiments/custom_string
                apiPromises[0] = Promise.resolve(
                  this.computeSentiment(keywords)
                )
              }

              // recompute coverage
              if (!theme.coverage || typeof theme.coverage != "object") {
                // API: text/word_coverage
                apiPromises[1] = Promise.resolve(this.computeCoverage(keywords))
              }

              return Promise.all(apiPromises).then(results => {
                if (typeof results[0] === "object") theme.sentiment = results[0]
                if (typeof results[1] === "object") theme.converage = results[1]
                if (results.some(r => typeof r === "object"))
                  isUpdateRequired = true
                return Promise.resolve(theme)
              })
            }.bind(this)
          )
      ).then(themes => {
        if (isUpdateRequired) this.saveThemes(themes)
      })
    },

    /**
     * Update text question responses of a selected question
     * @param None
     */
    updateSelectedTextQuestionResponses() {
      if (!this.selectedTextQuestion) return
      this.fetchTextResponsesWithSentiments().then(data => {
        this.setSelectedTextQuestionResponses(data)
      })

      // Update themes data if changes present
      this.updateThemesData()
    },

    /** Response list item methods **/
    toggleResponseItemPin(responseId) {
      const pinnedComments = [...this.pinnedComments]
      const pinnedComments__ORIGINAL = _.clone(pinnedComments)
      if (pinnedComments.includes(responseId)) {
        pinnedComments.splice(pinnedComments.indexOf(responseId), 1)
      } else {
        pinnedComments.push(responseId)
      }

      // update project
      this.setPinnedComments(pinnedComments)
      this.project["textAnalysis"]["pinnedComments"] = pinnedComments
      this.$pigeonline.projects
        .update(this.project)
        .then(response => {
          this.setProject(response)
        })
        .catch(e => {
          // revert changes
          this.setPinnedComments(pinnedComments__ORIGINAL)
          this._alert(
            API_ERROR_MESSAGE,
            "Error",
            "error",
            "toggleResponseItemPin"
          )
          throw new Error("analysisTextMixin.js:toggleResponseItemPin: " + e)
        })
        .finally(() => {
          this.setShowResponseListLoadingSpinner(false)
        })
    },
    banResponseItem(responseId) {
      const bannedComments = [...this.bannedComments]
      const bannedComments__ORIGINAL = _.clone(bannedComments)

      if (bannedComments.includes(responseId)) return

      // update project
      this.setBannedComments([...bannedComments, responseId])
      this.TEXT_SERVICE.banComment({
        project_id: this.project.id,
        client_question_id: this.selectedTextQuestion._id.$oid,
        response_id: responseId
      })
        .then(() => {
          // update store
          this.project.textAnalysis.bannedComments = this.bannedComments

          // recompute coverage/sentiment and save themes
          this.saveThemes(this.themes, true)
        })
        .catch(e => {
          // revert changes
          this.setBannedComments(bannedComments__ORIGINAL)
          this._alert(API_ERROR_MESSAGE, "Error", "error", "banResponseItem")
          throw new Error("analysisTextMixin.js:banResponseItem: " + e)
        })
        .finally(() => {
          this.setShowResponseListLoadingSpinner(false)
        })
    },
    toggleResponseItemHide(responseId) {
      const hiddenComments = [...this.hiddenComments]
      const hiddenComments__ORIGINAL = _.clone(hiddenComments)
      if (hiddenComments.includes(responseId)) {
        hiddenComments.splice(hiddenComments.indexOf(responseId), 1)
      } else {
        hiddenComments.push(responseId)
      }

      // update project
      this.setHiddenComments(hiddenComments)
      this.project["textAnalysis"]["hiddenComments"] = hiddenComments
      this.$pigeonline.projects
        .update(this.project)
        .then(response => {
          this.setProject(response)
        })
        .catch(e => {
          // revert changes
          this.setHiddenComments(hiddenComments__ORIGINAL)
          this._alert(
            API_ERROR_MESSAGE,
            "Error",
            "error",
            "toggleResponseItemHide"
          )
          throw new Error("analysisTextMixin.js:toggleResponseItemHide: " + e)
        })
    }
  }
}
