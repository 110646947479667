export default function($e, element) {
  const focusable = element.querySelectorAll(
    '[tabindex]:not([tabindex="-1"]), a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), input[type="password"]:not([disabled]), select:not([disabled])'
  )
  let firstFocusable = focusable[0]
  let lastFocusable = focusable[focusable.length - 1]

  if ($e.defaultPrevented) {
    return
  }

  // shift + tab
  if ($e.shiftKey && $e.key == "Tab") {
    if (document.activeElement === firstFocusable) {
      lastFocusable.focus()
      $e.preventDefault()
    }
  }
  // tab
  else if ($e.key == "Tab") {
    if (document.activeElement === lastFocusable) {
      firstFocusable.focus()
      $e.preventDefault()
    }
  }

  // esc
  if ($e.key == "Escape" || $e.key == "Esc") {
    element.hidden = "hidden"
    $e.preventDefault()
  }
}
