<template>
  <section id="project-analysis-benchmarking">
    <Spinner :is-loading="isLoading" :message="spinnerMessage" />
    <h2 class="project-analysis__header-title">
      <VisibleText>Benchmarking</VisibleText>
    </h2>

    <p class="benchmarking__desc">
      <VisibleText
        >How does your organization compare to your peers? Benchmarks summarize
        averages or outliers based on segments of your dataset. For each
        question in your dataset that matches with our global database, we
        calculate the average and compare your score to each group. This
        indicates how organizations are performing in that group of service
        delivery.
      </VisibleText>
    </p>

    <BenchmarkingMessages
      :status-benchmarks-pending-approval="statusBenchmarksPendingApproval"
      :status-benchmarks-not-available="statusBenchmarksNotAvailable"
      :status-no-scale-questions="statusNoScaleQuestions"
      :status-org-not-verified="statusOrgNotVerified"
      :num-Not-Approved="numNotApprovedBenchmarks"
      :num-Total="numTotalBenchmarks"
    />

    <article
      class="project-analysis-item__wrapper"
      v-if="statusBenchmarksAvailable"
    >
      <div class="project-analysis-item__header-wrapper">
        <h3 class="project-analysis-item__header-wrapper-title">
          {{ translate("overall averages", $options.name) }} (
          {{
            translate(
              this.projectPerformanceType || "Scale-Average",
              $options.name
            )
          }}
          )
        </h3>
        <HasPermission to="create-report">
          <button
            class="project-analysis-item__header-wrapper-btn benchmarking__save-btn"
            @click="save()"
            :disabled="statusDisableSaveToReport"
          >
            <SvgIconDecorative
              icon="report"
              class="project-analysis-item__header-wrapper-icon"
            />
            <VisibleText>Save To Report</VisibleText>
          </button>
        </HasPermission>
      </div>

      <Table
        class="benchmarking__table benchmarking__summary-results-table"
        id="benchmarking-table"
        :caption="'Benchmarking Averages'"
        :hide-caption="true"
        :row-data="summaryResultsTable"
        :column-headers="summaryResultsTableHeaders"
        :row-keys="summaryResultsTableHeaders"
        :sortConfig="sortConfig"
        @sort="sort"
      />
    </article>
  </section>
</template>

<script>
// Components
import BenchmarkingMessages from "./ProjectAnalysisBenchmarkingMessages.vue"
import SvgIconDecorative from "@/components/UI/Svg/SvgIconDecorative.vue"
import Table from "@/components/UI/Table.vue"

// Mixins
import BenchmarkAnalysisMixin from "./Mixins/benchmarkAnalysisMixin.js"
import DatasetDetailsMixin from "@/utils/mixins/datasetDetailsMixin.js"
import ProjectMixin from "@/utils/mixins/projectMixin.js"
import ProjectReportMixin from "@/utils/mixins/projectReportMixin.js"

// Models
import BenchmarkingDataset from "@/models/BenchmarkingDatasetModel"

const SORT_BY = {
  ascending: 1,
  descending: -1
}
export default {
  name: "ProjectAnalysisBenchmarking",
  mixins: [
    BenchmarkAnalysisMixin,
    DatasetDetailsMixin,
    ProjectMixin,
    ProjectReportMixin
  ],
  components: {
    BenchmarkingMessages,
    SvgIconDecorative,
    Table
  },
  data() {
    return {
      benchmarksApproved: true,
      benchmarksSkipped: false,
      numNotApprovedBenchmarks: 0,
      numTotalBenchmarks: 0,
      isLoading: false,
      // prevent saving to report untill we know dataset status
      isDatasetStatusLoading: false,
      statusNoScaleMatches: false, //no 5point questions matched with CMT
      savedPerformanceType: "",
      spinnerMessage: null,
      sortConfig: {
        question: {
          options: ["default", "ascending", "descending"],
          selected: "default"
        },
        rating: {
          options: [
            "asc (outstanding - no org)",
            "desc (no org - outstanding)"
          ],
          selected: "asc (outstanding - no org)"
        }
      }
    }
  },
  async created() {
    this.isLoading = true
    this.spinnerMessage = "loading benchmarks, please wait"
    await this.getSummaryResultsAndFormatTable()
    this.isLoading = false
    this.getDatasetStatus()
  },
  computed: {
    statusDisableSaveToReport() {
      return (
        this.statusOrgNotVerified ||
        this.statusBenchmarksPendingApproval ||
        this.isDatasetStatusLoading ||
        this.propReadOnly
      )
    },
    statusBenchmarksPendingApproval() {
      return !this.benchmarksApproved && !this.benchmarksSkipped
    },
    statusBenchmarksNotAvailable() {
      return (
        (!this.benchmarksApproved &&
          this.benchmarksSkipped &&
          !this.statusNoScaleQuestions) ||
        this.statusNoScaleMatches
      )
    },
    statusNoScaleQuestions() {
      const scaleQuestions = this.clientQuestions.filter(
        question => question.approved_data_type == "SCALE_1_TO_5"
      )
      return scaleQuestions.length === 0
    },
    statusBenchmarksAvailable() {
      return (
        (this.benchmarksApproved || this.statusBenchmarksPendingApproval) &&
        !this.statusNoScaleMatches
      )
    },
    title() {
      return `Overall Averages (${this.projectPerformanceType ||
        "scale average"})`
    }
  },
  methods: {
    async getDatasetStatus() {
      try {
        this.isDatasetStatusLoading = true
        const response = await this.$pigeonline.projects.get(
          BenchmarkingDataset,
          {
            dataset_id: this.dataset._id.$oid
          }
        )
        this.benchmarksApproved = response[0].status.benchmarksApproved
        this.benchmarksSkipped = response[0].status.benchmarksSkipped
        this.isDatasetStatusLoading = false
      } catch (e) {
        throw new Error(
          "ProjectAnalysisBenchmarking:getDatasetStatus " + e.message
        )
      }

      if (!this.benchmarksApproved) {
        this.numNotApprovedBenchmarks = this.benchmarks.filter(
          b => !b.approved
        ).length
        this.numTotalBenchmarks = this.benchmarks.length
      }
    },
    async getSummaryResultsAndFormatTable() {
      this.savedPerformanceType = this.projectPerformanceType || "scale-average"
      const summaryResults = await this.fetchSummaryResults()
      // no summary results because there are no 5point scales that are matched to benchmark quesitons
      if (!summaryResults) this.statusNoScaleMatches = true
      this.$store.dispatch("project/setBenchmarkSummaryResults", summaryResults)
      if (summaryResults) this.formatTables(summaryResults.summary_table)
    },
    /************
     *
     * Sort functions
     *
     ***********/
    sort(args) {
      this.sortConfig[args.sortTitle].selected = args.option
      this[`sort${args.sortTitle}`](args.option)
    },
    sortquestion(option) {
      if (option === "default")
        this.summaryResultsTable = Array.from(this.defaultSummaryResultsTable)
      this.summaryResultsTable.sort(
        (a, b) => SORT_BY[option] * a.question.localeCompare(b.question)
      )
    },
    sortrating(option) {
      let direction = option.includes("asc") ? 1 : -1
      this.summaryResultsTable.sort(
        (a, b) => direction * a.ratingLetter.localeCompare(b.ratingLetter)
      )
    },
    /************
     *
     * Confirmation modals
     *
     ***********/
    onSuccessfulSave() {
      this.setConfirmText({
        btn: "okay",
        title: "Benchmarks Saved",
        message: "Benchmarks successfully saved to report."
      })
      this.setConfirmType("success")
      this.setConfirmSourceComponent("benchmarking")
      this.setConfirmIsVisible(true)
    },
    /************
     *
     * Report generaton related functions
     *
     ***********/
    async save() {
      if (
        this.statusOrgNotVerified ||
        this.statusBenchmarksPendingApproval ||
        this.isDatasetStatusLoading
      )
        return

      this.saveToReport()

      if (!this.project.analysisCompleted) {
        this.project.updateStatus("analysisCompleted")
        this.project.benchmarkAnalysis = [] //TODO: allow customization of benchmark table
        this.saveProject(this.project)
      }
    },
    async saveToReport() {
      try {
        this.isLoading = true
        this.spinnerMessage = "saving to report, please wait"
        const organization = this.$store.getters["user/getOrganization"]
          ? this.$store.getters["user/getOrganization"].organization_name
          : ""
        await this.createReportIfEmpty(
          this.$options.filters.formatDate(this.project.last_modified_on),
          this.dataset.name,
          organization
        )

        // get report data
        const clientQuestionDetails = await this.fetchClientQuestionsDetails()
        const details = await this.fetchDetailedResults()

        // sort client question details by summary results table
        const idSortOrder = this.summaryResultsTable.map(o => o.questionId)
        const idToIndex = clientQuestionDetails.reduce((arr, detail, indx) => {
          arr[detail.client_question_id] = indx
          return arr
        }, {})
        const sortedClientQuestionDetails = idSortOrder.map(
          id => clientQuestionDetails[idToIndex[id]]
        )

        // update report
        await this.updateReportWithBenchmarking(
          this.project.report,
          this.summaryResultsTable,
          this.summaryResultsTableHeaders,
          sortedClientQuestionDetails,
          this.matchesDetails,
          this.dataset.segments,
          details,
          this.$store.getters["project/getBenchmarkSummaryResults"]
        )

        this.onSuccessfulSave()
        this.isLoading = false
      } catch (e) {
        console.error(e)
        throw new Error("ProjectAnalysisBenchmarking:saveToReport " + e.message)
      }
    }
  },
  watch: {
    projectUpdatedPerformanceType: async function(type) {
      // update only if the updated performance type is the same as the saved type
      if (type && type !== this.savedPerformanceType) {
        await this.getSummaryResultsAndFormatTable()
      }
    }
  }
}
</script>
