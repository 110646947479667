<template>
  <div class="register-demo__content">
    <Spinner :is-loading="showSpinner" :message="spinnerMessage" />
    <div>
      <router-link to="/login">
        <VisibleText>Go to login page</VisibleText>
      </router-link>
    </div>
    <h1 class="register-demo__title">
      <VisibleText>Create a demo account</VisibleText>
    </h1>
    <section class="register-demo__content">
      <MessageBlock
        message-type="information"
        class="register-demo__groups-unavailable"
        v-if="isDemoGroupsEmpty"
      >
        There are no demo groups active at present. Please try again later or
        contact admin.
      </MessageBlock>
      <template v-else>
        <div class="register-demo__registered" v-if="registered">
          <MessageBlock class="success-message" message-type="information">
            New demo account created successfully. Please use the credentials
            below to log in.
          </MessageBlock>
          <div class="credentials__wrapper">
            <div class="credentials__content">
              <p class="credentials__row">
                <span class="label">
                  <VisibleText>Group ID</VisibleText>
                </span>
                <span class="value">{{ picked }}</span>
              </p>
              <p class="credentials__row">
                <span class="label">
                  <VisibleText>Email</VisibleText>
                </span>
                <span class="value">{{ registered.email }}</span>
              </p>
              <p class="credentials__row">
                <span class="label">
                  <VisibleText>Password</VisibleText>
                </span>
                <span class="value">{{ registered.password }}</span>
              </p>
              <a
                href="#"
                class="credentials__save-link"
                @click.prevent="saveCredentialsAsText"
                ><VisibleText>Save credentials as a text file</VisibleText></a
              >
            </div>
          </div>
        </div>
        <form class="register-demo__form" v-else>
          <p class="register-demo__form-legend">
            <VisibleText
              >Please select the demo group below to create a demo account.
            </VisibleText>
          </p>
          <div
            class="register-demo__form-input"
            v-for="(group, index) in demoGroups"
            :key="index"
          >
            <label
              class="register-demo__form-input-label"
              :for="`input-${index}`"
            >
              <h3 class="title">{{ group.title }}</h3>
              <span class="sub-title">{{ group.group_id }}</span>
            </label>
            <input
              class="register-demo__form-input-radio"
              type="radio"
              :id="`input-${index}`"
              :value="group.group_id"
              v-model="picked"
            />
          </div>
          <button
            class="register-demo__form-submit-btn"
            :disabled="!picked"
            @click.prevent="createDemoAccount"
          >
            <VisibleText>Create account</VisibleText>
          </button>
        </form>
      </template>
    </section>
  </div>
</template>

<script>
import axios from "axios"

// Components
import Spinner from "@/components/UI/Spinner.vue"
import MessageBlock from "@/components/UI/Message/MessageBlock.vue"

export default {
  name: "registerDemo",
  components: {
    Spinner,
    MessageBlock
  },
  data() {
    return {
      demoGroups: [],
      picked: null,
      registered: null,
      showSpinner: false,
      spinnerMessage: "loading..."
    }
  },
  computed: {
    isDemoGroupsEmpty: function() {
      return this.demoGroups.length == 0
    }
  },
  async mounted() {
    this.demoGroups = await this.fetchDemoGroups()

    const groupID = this.$route.query.group_id
    const group = groupID && this.demoGroups.find(el => el.group_id == groupID)
    if (group) {
      this.demoGroups = [group]
      this.picked = groupID
    }
  },
  methods: {
    fetchDemoGroups: async function() {
      this.showSpinner = true
      try {
        const res = await axios.get(
          `${this.$pigeonline.options.baseUrl}/rauth/demo-groups/`
        )
        return res.data.filter(g => g.is_active)
      } catch (e) {
        alert(
          "An error occured processing your request. Please try again later."
        )
        console.error("RegisterDemo.vue:fetchDemoGroups:: " + e)
      } finally {
        this.showSpinner = false
      }
    },
    async createDemoAccount() {
      this.showSpinner = true
      try {
        const res = await axios.post(
          `${this.$pigeonline.options.baseUrl}/rauth/demo-register/`,
          { group_id: this.picked }
        )
        this.registered = res.data
      } catch (e) {
        alert(
          "An error occured processing your request. Please try again later."
        )
        console.error("RegisterDemo.vue:createDemoAccount:: " + e)
      } finally {
        this.showSpinner = false
      }
    },
    saveCredentialsAsText() {
      let currentDate = new Date().toUTCString()
      let downloadText = `Group ID: ${this.picked}\nEmail: ${this.registered.email}\nPassword: ${this.registered.password}\n\nGenerated on ${currentDate}.`
      let filename = `demo-credentials-${currentDate}`
      var blob = new Blob([downloadText], { type: "text/plain;charset=utf-8;" })
      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveBlob(blob, filename)
      } else {
        var elem = window.document.createElement("a")
        elem.href = window.URL.createObjectURL(blob)
        elem.download = filename
        document.body.appendChild(elem)
        elem.click()
        document.body.removeChild(elem)
      }
    }
  }
}
</script>
