<template>
  <section class="reset-password__wrapper">
    <div class="reset-password__container">
      <h1 class="reset-password__title">
        <VisibleText>Reset your password</VisibleText>
      </h1>
      <div class="reset-password__message" v-if="message">
        <p>{{ message }}</p>
        <router-link to="/login">
          <VisibleText>Go to login page</VisibleText>
        </router-link>
      </div>
      <ResetPassword
        ref="reset-password"
        class="reset-password__form"
        :uidb64="uidb64"
        :token="token"
        @success="success"
        v-if="isResetFormVisible"
      >
        <template
          v-slot:password="{
            password,
            errors,
            passwordBlurCheck,
            update
          }"
        >
          <label for="password" class="auth-form__label">
            <VisibleText>Password</VisibleText>
            <input
              id="password"
              :value="password"
              @input="update('password', $event.target.value)"
              type="password"
              class="auth-form__input"
              required
              aria-required="true"
              :aria-invalid="!!errors.length"
              aria-describedby="password-desc"
              @blur="passwordBlurCheck"
            />
            <span
              id="password-desc"
              v-if="
                !errors.includes(
                  'Passwords should be at least 10 characters long'
                )
              "
              :class="{ valid: password.length >= 10 }"
            >
              <VisibleText
                >Passwords should be at least 10 characters long
              </VisibleText>
            </span>
          </label>
        </template>

        <template
          v-slot:passwordConf="{
            passwordConf,
            errors,
            passwordConfBlurCheck,
            update
          }"
        >
          <label for="password-conf" class="auth-form__label">
            <VisibleText>Confirm Password</VisibleText>
            <input
              id="password-conf"
              ref="password-conf"
              :value="passwordConf"
              @input="update('passwordConf', $event.target.value)"
              type="password"
              class="auth-form__input"
              required
              aria-required="true"
              :aria-invalid="!!errors.length"
              @blur="passwordConfBlurCheck"
            />
          </label>
        </template>
      </ResetPassword>
    </div>
  </section>
</template>

<script>
import axios from "axios"
import { ResetPassword } from "@pigeonline/core"

export default {
  name: "resetPassword",
  components: {
    ResetPassword
  },
  data() {
    return {
      uidb64: null,
      email: null,
      token: null,
      password: "",
      passwordConf: "",
      errors: {
        password: [],
        passwordConf: []
      },
      message: null,
      isResetFormVisible: false
    }
  },
  async created() {
    this.uidb64 = this.$route.query.uidb64
    this.email = this.$route.query.email
    this.token = this.$route.query.token

    if (this.uidb64 && this.token) {
      try {
        await axios.post(
          `${this.$pigeonline.options.baseUrl}/rauth/reset-password`,
          {
            uidb64: this.uidb64,
            token: this.token,
            validate: true
          }
        )
        this.isResetFormVisible = true
      } catch (e) {
        const error = e.response && e.response.data
        if (error && error.code && error.code === "uid_token_invalid") {
          this.message =
            "Invalid password reset link. The link might be expired. Please request a new one."
        } else {
          this.message =
            "There was some error in verifying your password reset link."
        }
      }
    }
  },
  methods: {
    success: function() {
      this.isResetFormVisible = false
      this.message =
        "Your password was changed successfully. Please proceed to login."
    }
  }
}
</script>
