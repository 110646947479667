<template>
  <section class="account__wrapper">
    <div class="account__container">
      <Spinner :is-loading="loading" :message="'loading...'" />
      <router-view></router-view>
    </div>
  </section>
</template>

<script>
import Spinner from "@/components/UI/Spinner.vue"

export default {
  name: "account",
  components: {
    Spinner
  },
  created() {
    document.title = `Account | ${this.$theme.theme.pageTitle}`
  },
  computed: {
    loading: function() {
      return this.$store.getters["user/getLoading"]
    }
  }
}
</script>
