<template>
  <dl class="key-value">
    <div class="key-value__item">
      <dt><VisibleText>Owner</VisibleText></dt>
      <dd>
        {{ topicModelGroup.created_by.name }},
        {{ topicModelGroup.created_by.organization }}
      </dd>
    </div>
    <div class="key-value__item">
      <dt><VisibleText>Themes</VisibleText></dt>
      <dd>
        {{ topicModelGroup.themes.length }}
      </dd>
    </div>
    <div class="key-value__item">
      <dt><VisibleText>Used In</VisibleText></dt>
      <dd>
        {{ topicModelGroup.used_in.length || 0 }}
        <VisibleText> projects </VisibleText>
      </dd>
    </div>
    <!-- editable -->
    <div class="key-value__item custom-topic-model-editor__source">
      <dt><VisibleText>Source</VisibleText></dt>
      <dd>
        <input
          aria-label="name"
          class="form-input--width--100 form-input--margin--none"
          type="text"
          id="source"
          placeholder="name"
          v-model="topicModelGroup.source.name"
        />
      </dd>
      <dd>
        <input
          aria-label="url"
          class="form-input--width--100 form-input--margin--none"
          type="text"
          id="source-url"
          placeholder="url of source"
          v-model="topicModelGroup.source.url"
        />
      </dd>
    </div>
    <div class="key-value__item">
      <dt><VisibleText>Access Level</VisibleText></dt>
      <dd>
        <select
          aria-label="access level"
          class="select"
          id="select-access-level"
          v-model="topicModelGroup.access_type"
        >
          <option
            v-for="level in Object.keys(ACCESS_TYPE_TO_DESC)"
            :key="level"
            :value="level"
            :data-parent="$options.name"
          >
            {{ translate(ACCESS_TYPE_TO_DESC[level].name, $options.name) }} -
            {{ translate(ACCESS_TYPE_TO_DESC[level].desc, $options.name) }}
          </option>
        </select>
      </dd>
    </div>
    <div class="key-value__item">
      <dt><VisibleText>Language</VisibleText></dt>
      <dd>
        <select
          aria-label="language"
          class="select"
          id="select-access-level"
          v-model="topicModelGroup.lang"
        >
          <option
            v-for="lang in Object.keys(LANG_NAME)"
            :key="lang"
            :value="lang"
            :data-parent="$options.name"
          >
            {{ translate(LANG_NAME[lang], $options.name) }}
          </option>
        </select>
      </dd>
    </div>
    <div class="key-value__item">
      <dt><VisibleText>Tags</VisibleText></dt>
      <dd>
        <TaggingList :tags="topicModelGroup.tags" @remove="removeTag" />
        <button
          class="btn-secondary"
          style="padding-top: 0"
          @click="isNewTagShowing = true"
        >
          <VisibleText>add tag</VisibleText>
        </button>

        <template v-if="isNewTagShowing">
          <input
            aria-label="new tag"
            class="form-input--width--100 inline-edit-text__input"
            type="text"
            @keydown.enter.prevent="addTag"
            v-model="newTag"
          />
          <ButtonIconOnly
            class="inline-edit-text__action-btn"
            icon="check"
            id="inline-edit-save"
            @click-handler="addTag"
          >
            <VisibleText>save</VisibleText>
          </ButtonIconOnly>
          <ButtonIconOnly
            class="inline-edit-text__action-btn"
            icon="remove"
            id="inline-edit-cancel"
            @click-handler="isNewTagShowing = false"
          >
            <VisibleText>cancel</VisibleText>
          </ButtonIconOnly>
        </template>
      </dd>
    </div>
  </dl>
</template>

<script>
import ButtonIconOnly from "@/components/UI/Button/ButtonIconOnly.vue"
import TaggingList from "@/components/UI/Tagging/TaggingList.vue"
import CustomTopicModelGroup from "../Models/CustomTopicModelGroup.js"
import { LANG_NAME, ACCESS_TYPE_TO_DESC } from "../Utils/consts.js"

export default {
  name: "CustomTopicModelEditorDetails",
  components: {
    ButtonIconOnly,
    TaggingList
  },
  props: {
    readOnly: {
      default: false,
      type: Boolean
    },
    topicModelGroup: {
      type: CustomTopicModelGroup,
      required: true
    }
  },
  data() {
    return {
      ACCESS_TYPE_TO_DESC: ACCESS_TYPE_TO_DESC,
      LANG_NAME: LANG_NAME,
      isNewTagShowing: false,
      newTag: ""
    }
  },
  methods: {
    addTag() {
      this.topicModelGroup.tags.push(this.newTag)
      this.newTag = ""
    },
    removeTag(tag) {
      const indx = this.topicModelGroup.tags.indexOf(tag)
      if (indx === -1) return
      this.topicModelGroup.tags.splice(indx, 1)
    }
  }
}
</script>
