import IntervalTree from "@flatten-js/interval-tree"

/* Pops largest node (with max range) from the interval tree */
IntervalTree.prototype.popLargestNode = function() {
  if (this.size === 0) return null

  let largest = this.items.sort((a, b) => b.value.size() - a.value.size())[0]
  this.remove(largest.key, largest.value)
  return largest
}

/* Custom Interval class */
class Interval {
  constructor(a, b, data = null) {
    this.a = a // start
    this.b = b // end
    this.data = data
    this.children = []
    this.meta = {
      attrs: new Set(),
      classNames: new Set()
    }
  }
}

Interval.prototype.size = function() {
  return this.b - this.a
}

Interval.prototype.isLeftExceededBy = function(interval) {
  return interval.a < this.a
}

Interval.prototype.isRightExceededBy = function(interval) {
  return interval.b > this.b
}

Interval.prototype.isRangeEqual = function(interval) {
  return interval.a === this.a && interval.b === this.b
}

Interval.prototype.contains = function(interval) {
  return !this.isLeftExceededBy(interval) && !this.isRightExceededBy(interval)
}

Interval.prototype.overlapsWith = function(interval) {
  if (this.isLeftExceededBy(interval) && interval.b <= this.a) return false
  if (this.isRightExceededBy(interval) && interval.a >= this.b) return false
  return true
}

Interval.prototype.toString = function() {
  return `[${this.a}-${this.b}]`
}

Interval.prototype.valueOf = function() {
  return `[${this.a}-${this.b}]--${this.data.id || Math.random()}`
}

/* Custom Interval Tree */
// Converts highlight ranges to nested interval tree
class CustomIntervalTree {
  constructor(ranges) {
    this.intervals = this.convertRangesToIntervals(ranges)
    this.intervalTree = new IntervalTree()

    // make tree
    this.constructIntervalTree()
    this.tree = this.makeTree()
  }

  convertRangesToIntervals(ranges) {
    return ranges.map(
      range =>
        new Interval(range.begin, range.end, {
          id: range.id || (Math.random() * 10 ** 9).toFixed(0),
          ...range
        })
    )
  }

  constructIntervalTree() {
    this.intervals.map(intv => this.addInterval(intv, this.intervalTree))
  }

  getTree() {
    return this.tree
  }

  createNode(intv) {
    return { key: [intv.a, intv.b], value: intv }
  }

  addInterval(intv, tree) {
    if (!intv || !tree || intv.size() === 0) return
    let intvNode = this.createNode(intv)
    tree.insert(intvNode.key, intvNode.value)
  }

  removeInterval(intv, tree) {
    if (!intv || !tree) return
    tree.remove([intv.a, intv.b], intv)
  }

  popLargestOverlappingInterval(parentIntv) {
    if (this.intervalTree.size === 0) return null
    let largest =
      this.intervalTree
        .search(
          [parentIntv.a, parentIntv.b],
          function(childIntv) {
            if (childIntv.size() === 0) return
            if (!parentIntv.overlapsWith(childIntv)) return
            if (
              parentIntv.isRangeEqual(childIntv) &&
              parentIntv.data.id === childIntv.data.id
            )
              return
            return childIntv
          }.bind(this)
        )
        .filter(intv => !!intv)
        .sort((a, b) => b.size() - a.size())[0] || null

    if (largest) {
      this.removeInterval(largest, this.intervalTree)
    }

    return largest
  }

  findChildren(parentIntv) {
    if (parentIntv.size() === 0) return []

    let children = []
    let childIntv

    this.removeInterval(parentIntv, this.intervalTree)

    while ((childIntv = this.popLargestOverlappingInterval(parentIntv))) {
      if (parentIntv.contains(childIntv)) {
        // contains whole child
        childIntv.children = this.findChildren(childIntv)
        children.push(childIntv)
      } else {
        let intv1, intv2
        // intersection
        if (parentIntv.isLeftExceededBy(childIntv)) {
          intv1 = new Interval(childIntv.a, parentIntv.a, {
            ...childIntv.data
          })
          intv2 = new Interval(parentIntv.a, childIntv.b, {
            ...childIntv.data
          })

          /** styles meta **/
          parentIntv.meta.classNames.add("x-lp") // remove left padding of parent
          intv1.meta.classNames.add("x-rp2")
          intv1.meta.classNames.add("x-rb")
          intv2.meta.classNames.add("x-lp2")
          intv2.meta.classNames.add("x-lb")
          intv2.meta.attrs.add("no-tabindex")

          this.addInterval(intv1, this.intervalTree)
          intv2.children = this.findChildren(intv2)
          children.push(intv2)
        }
        if (parentIntv.isRightExceededBy(childIntv)) {
          intv1 = new Interval(childIntv.a, parentIntv.b, {
            ...childIntv.data
          })
          intv2 = new Interval(parentIntv.b, childIntv.b, {
            ...childIntv.data
          })

          /** styles meta **/
          parentIntv.meta.classNames.add("x-rp") // remove right padding of parent
          intv1.meta.classNames.add("x-rp2")
          intv1.meta.classNames.add("x-rb")
          intv2.meta.classNames.add("x-lp2")
          intv2.meta.classNames.add("x-lb")
          intv1.meta.attrs.add("no-tabindex")

          this.addInterval(intv2, this.intervalTree)
          intv1.children = this.findChildren(intv1)
          children.push(intv1)
        }
      }
    }

    return children
  }

  makeTree() {
    // add root container [0, maxLength]
    const maxLength = 1000
    const rootIntv = new Interval(0, maxLength, { id: "root" })
    rootIntv.children = this.findChildren(rootIntv)
    return [rootIntv]
  }
}

export { Interval, CustomIntervalTree }

window.CustomIntervalTree = CustomIntervalTree
