<template>
  <MessageBlock class="banner-msg" message-type="warning">
    <!-- account pending verification -->
    <template v-if="statusName === 'notVerified'">
      <VisibleText>
        Your account is pending verification. Some features may be unavailable.
        We will contact you by email once we have verified your organization's
        details.
      </VisibleText>
    </template>

    <!--- account setup complete -->
    <template v-if="statusName === 'setupIncomplete'">
      <VisibleText>
        Your account setup is not complete. Please
      </VisibleText>
      <router-link :to="{ name: 'accountNextSteps' }">
        {{ translate("finish setting up your account.", $options.name) }}
      </router-link>
    </template>

    <!--- account upgrade needed -->
    <template v-if="statusName === 'upgrade'">
      <VisibleText>
        You are currently using a demo verison. To access all features, please
      </VisibleText>
      <router-link :to="{ name: 'accountNextStepsAccountType' }">
        {{ translate("connect to your organization", $options.name) }}
      </router-link>
      <VisibleText>
        ,or to learn more on how to upgrade your account contact
      </VisibleText>
      <a
        href="mailto: michal.dziong@iccs-isac.org"
        v-if="$theme.theme.name === 'iccs'"
      >
        michal.dziong@iccs-isac.org
      </a>
      <a href="mailto: researchai@pigeonline.io" v-else>
        researchai@pigeonline.io
      </a>
    </template>
  </MessageBlock>
</template>

<script>
import MessageBlock from "@/components/UI/Message/MessageBlock.vue"
export default {
  name: "AccountMessages",
  props: {
    statusName: {
      default: () => "",
      type: String
    }
  },
  components: {
    MessageBlock
  }
}
</script>
