import { onDeleteUpdateGlobalQuestionBenchmarkMatchCount } from "@/components/Dataset/Utils/helpers.js"

export default {
  methods: {
    async $_benchmarksMixin_deleteBenchmarkMatch(benchmarkId) {
      try {
        const benchmark = this.benchmarks.find(b => (b._id.$oid = benchmarkId))
        // decrese number of approved matches in global question
        const globalQ = await this.$services.DATASETS_SERVICE.globalQuestions(
          benchmark.global_question_id
        )
        if (globalQ.matches) {
          onDeleteUpdateGlobalQuestionBenchmarkMatchCount(
            this.$services.DATASETS_SERVICE,
            globalQ,
            benchmark
          )
        }
        await this.$services.BENCHMARKING_SERVICE.deleteBenchmark(benchmarkId)
      } catch (e) {
        throw new Error(
          "datasetDetailsMixin:deleteBenchmarkMatch " + e.messages
        )
      }
    },
    async $_benchmarksMixin_updateMatch(
      clientQuestion,
      selectedGlobalQuestionId
    ) {
      // delete current benchmark
      await this.$_benchmarksMixin_deleteBenchmarkMatch(
        clientQuestion.benchmarking_id
      )

      // update client question based on version
      const clientQ = await this.updateClientQuestionBenchmarkedMatches(
        clientQuestion,
        selectedGlobalQuestionId
      )

      // create new benchmark
      let recommended = ""
      if (!this.clientQuestionIsVersion(clientQuestion, "1"))
        recommended = clientQuestion.benchmarked_global_question.recommended
      await this.$_benchmarksMixin_newMatch(
        clientQ,
        selectedGlobalQuestionId,
        recommended
      )
    },
    async $_benchmarksMixin_newMatch(
      clientQuestion,
      selectedGlobalQuestionId,
      recommendedGlobalQuestionId
    ) {
      const response = await this.newBenchmarkMatch(
        clientQuestion,
        selectedGlobalQuestionId,
        recommendedGlobalQuestionId
      )
      this.updateDatasetProjectStatus("benchmarksSent")
      this.updateGlobalQuestionMatchesCounts(selectedGlobalQuestionId)

      // update client question store
      const clientQ = await this.$services.DATASETS_SERVICE.clientQuestions(
        clientQuestion._id.$oid
      )
      this.updateClientQuestion(clientQ)

      // update store values
      this.reloadBenchmarks()

      return response
    },
    /************
     *
     * Helpers
     *
     ***********/
    clientQuestionIsVersion(clientQuestion, versionNumber = "1") {
      return (
        Boolean(
          clientQuestion.schema_version &&
            clientQuestion.schema_version == versionNumber
        ) || versionNumber === "1"
      )
    },
    async newBenchmarkMatch(
      clientQuestion,
      selectedGlobalQuestionId,
      recommendedGlobalQuestionId
    ) {
      try {
        const data = {
          client_question_id: clientQuestion._id.$oid,
          global_question_id: selectedGlobalQuestionId,
          recommended_global_question_id: recommendedGlobalQuestionId,
          approved: this.$store.getters["datasetWizard/getBenchmarkGroup"]
            .auto_approve,
          auto_approved: this.$store.getters["datasetWizard/getBenchmarkGroup"]
            .auto_approve,
          owner: {
            user: this.$store.getters["user/getProfile"].django_ref_id,
            organization: this.$store.getters["user/getOrganization"]
              .organization_name
          }
        }
        const response = await this.$services.BENCHMARKING_SERVICE.request(data)
        return response
      } catch (e) {
        throw new Error("datasetDetailsMixin:newBenchmarkMatch " + e)
      }
    },
    /**
     * update global question matches counts
     * depending on benchmark group config
     **/
    updateGlobalQuestionMatchesCounts(globalQuestionId) {
      let globalQ = this.globalQuestions.find(
        q => q._id.$oid == globalQuestionId
      )
      if (this.$store.getters["datasetWizard/getBenchmarkGroup"].auto_approve) {
        this.updateDatasetProjectStatus("benchmarksApproved")
        globalQ.matches.approved += 1
        this.$services.DATASETS_SERVICE.updateGlobalQuestion(globalQ._id.$oid, {
          matches: globalQ.matches
        })
      } else {
        globalQ.matches.pending += 1
        this.$services.DATASETS_SERVICE.updateGlobalQuestion(globalQ._id.$oid, {
          matches: globalQ.matches
        })
      }
    },
    async updateClientQuestionBenchmarkedMatches(clientQuestion, selected) {
      if (!this.clientQuestionIsVersion(clientQuestion, "1"))
        return clientQuestion
      const benchmarked = { benchmarked_global_question_id: selected }
      const response = await this.$services.DATASETS_SERVICE.updateClientQuestion(
        clientQuestion._id.$oid,
        benchmarked
      )
      return response.client_question_object
    }
  }
}
