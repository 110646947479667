/**
 * An object representing a benchmark group
 * @typedef {Object} CustomTopicModelGroup
 */

import { ProjectModel } from "@pigeonline/core"

export default class CustomTopicModelGroup extends ProjectModel {
  constructor(data = {}) {
    super(data)

    this.created_by = {}
    this.lang = "en"
    this.name = "new benchmark group"
    this.themes = []
    this.used_in = []
    this.source = {
      name: "",
      url: ""
    }
    this.tags = []

    this.setProps(data)
  }

  /**
   * Get the project type
   * @returns {String} string representation of this project type
   */
  static PROJECT_TYPE() {
    return "custom_topic_model_group"
  }

  /**
   * Add id of new theme/topic model linked to this group
   * @param {String} themeID mondo id of new topic model
   */
  addNewTheme(themeID) {
    this.themes.unshift(themeID)
  }

  setCreatedBy(name, organization) {
    this.created_by = {
      name: name,
      organization: organization
    }
  }
}
