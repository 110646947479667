import { axios, BaseService } from "@pigeonline/core"

export default class mailerService extends BaseService {
  constructor(core) {
    super(core)

    // define the api url
    this.URL = `${this.BASE_URL}/mailer`
  }
  async sendContactUs(data) {
    try {
      const response = await axios.post(`${this.URL}/send`, data, {
        skipAuthHeaders: true
      })
      return response.data
    } catch (e) {
      throw new Error("mailerService.js:sendContactUs " + e.message)
    }
  }
}
