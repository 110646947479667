import Vue from "vue"
import Vuex from "vuex"

/**
 * import all modules
 * if you want to register a new module import it in ./modules/modules.store.js
 */
import modules from "./modules/modulesStore.js"

Vue.use(Vuex)

export default new Vuex.Store({
  modules
})
