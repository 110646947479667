<template>
  <div class="project-settings" v-click-outside="closeMenu">
    <button
      aria-haspopup="true"
      class="project-settings__btn"
      id="project-settings-btn"
      ref="project-settings"
      :aria-expanded="isMenuExpanded.toString()"
      @click="isMenuExpanded = !isMenuExpanded"
    >
      <span class="title">
        <VisibleText>Project Settings</VisibleText>
      </span>
      <SvgIconDecorative icon="chevronDown" />
    </button>
    <transition name="fade">
      <div
        class="project-settings__menu"
        id="project-settings-menu"
        role="menu"
        ref="project-settings-menu"
        v-show="isMenuExpanded"
      >
        <button
          class="project-settings__menu-item"
          role="menuitem"
          @click="setActiveModal('SetPerformanceMeasure')"
          @keydown="focusNextItem"
        >
          <VisibleText>Performance measure</VisibleText>
        </button>
        <button
          class="project-settings__menu-item"
          role="menuitem"
          @click="setActiveModal('SetPerformanceType')"
          @keydown="focusNextItem"
        >
          <VisibleText>Performance type</VisibleText>
        </button>
      </div>
    </transition>

    <!-- Modals -->
    <SetPerformanceMeasure
      :show="isModalVisible.SetPerformanceMeasure"
      :project="project"
      :dataset-id="dataset._id.$oid"
      :table-data="clientQuestions"
      :state="state"
      :message-type="messageType"
      @close-modal="closeModal"
      @save-performance-measure="savePerformanceMeasure($event)"
      @updated="resetState"
    />
    <SetPerformanceType
      :project="project"
      :show="isModalVisible.SetPerformanceType"
      :state="state"
      :message-type="messageType"
      @close-modal="closeModal"
      @save-performance-type="savePerformanceType($event)"
      @updated="resetState"
    />

    <!-- Spinner -->
    <Spinner :is-loading="isLoading" message="saving" />
  </div>
</template>

<script>
// Components
import SvgIconDecorative from "@/components/UI/Svg/SvgIconDecorative.vue"
import SetPerformanceMeasure from "./Modals/SetPerformanceMeasure.vue"
import SetPerformanceType from "./Modals/SetPerformanceType.vue"

// Mixins
import DatasetDetailsMixin from "@/utils/mixins/datasetDetailsMixin.js"
import ProjectMixin from "@/utils/mixins/projectMixin.js"

export default {
  name: "ProjectSettings",
  mixins: [DatasetDetailsMixin, ProjectMixin],
  components: {
    SvgIconDecorative,
    SetPerformanceMeasure,
    SetPerformanceType
  },
  data() {
    return {
      isMenuExpanded: false,
      isModalVisible: {
        SetPerformanceType: false,
        SetPerformanceMeasure: false
      },
      isLoading: false,
      state: {
        isSaved: false,
        isError: false,
        text: ""
      }
    }
  },
  computed: {
    messageType() {
      if (this.state.isError) return "error"
      else if (this.state.isSaved) return "success"
      return ""
    }
  },
  methods: {
    closeMenu: function() {
      this.isMenuExpanded = false
    },
    setActiveModal: function(type) {
      this.closeModal()
      this.isModalVisible[type] = true
    },
    resetState: function() {
      this.state = {
        isSaved: false,
        isError: false,
        text: ""
      }
    },
    closeModal: function() {
      Object.keys(this.isModalVisible).map(
        type => (this.isModalVisible[type] = false)
      )
      this.resetState()
    },
    onSaveError() {
      this.state.isSaved = false
      this.state.isError = true
      this.state.text = "There was an error saving changes. Please try again."
    },
    onSaveSuccess(text) {
      this.state.isError = false
      this.state.isSaved = true
      this.state.text = text
    },
    /**
     * Saving
     */
    savePerformanceMeasure: async function(selectedClientQuestionsIds) {
      if (
        !selectedClientQuestionsIds ||
        !Array.isArray(selectedClientQuestionsIds)
      ) {
        throw new Error("`selectedClientQuestionsIds` must be an array.")
      }

      this.isLoading = true
      try {
        this.project.settings.performanceMeasure = {
          clientQuestionsIds: selectedClientQuestionsIds
        }
        await this.saveProject(this.project)
        this.onSaveSuccess("Performance measure saved successfully.")
      } catch (e) {
        this.onSaveError()
        console.error(
          "ProjectSettings.vue:savePerformanceMeasure:: " + e.message
        )
      } finally {
        this.isLoading = false
      }
    },
    savePerformanceType: async function(selectedType) {
      this.isLoading = true
      try {
        this.project.settings.performanceType = {
          selected: selectedType
        }
        await this.saveProject(this.project)
        this.setProjectUpdatedPerformanceType(selectedType)
        this.onSaveSuccess("Performance type saved successfully.")
      } catch (e) {
        this.onSaveError()
        console.error("ProjectSettings.vue:savePerformanceTypes:: " + e.message)
      } finally {
        this.isLoading = false
      }
    },
    /**
     * Navigation keyboard helpers
     */
    focusNextItem($e) {
      switch ($e.key) {
        case "Down":
        case "ArrowDown":
          if ($e.target.nextElementSibling) {
            $e.target.nextElementSibling.focus()
          } else {
            this.$refs["project-settings-menu"].firstElementChild.focus()
          }
          break
        case "Up":
        case "ArrowUp":
          if ($e.target.previousElementSibling) {
            $e.target.previousElementSibling.focus()
          } else {
            this.$refs["project-settings-menu"].lastElementChild.focus()
          }
          break
        case "Esc":
        case "Escape":
          this.$refs["project-settings"].focus()
          this.isMenuExpanded = false
          break
        case "Tab":
          this.isMenuExpanded = false
          break
        default:
          return
      }
      $e.preventDefault()
      $e.stopPropagation()
    }
  },
  watch: {
    isMenuExpanded: function() {
      if (this.isMenuExpanded) {
        this.$nextTick(() =>
          this.$refs["project-settings-menu"].firstElementChild.focus()
        )
      }
    }
  }
}
</script>
