import { axios, OrganizationModel } from "@pigeonline/core"
import { mapActions, mapGetters } from "vuex"
import permissions from "@/permissions.js"

// crypto-js
import CryptoJS from "crypto-js"

export default {
  computed: {
    ...mapGetters("user", {
      profile: "getProfile",
      organization: "getOrganization",
      license: "getLicense",
      notifications: "getNotifications",
      isValid: "getValid",
      isComplete: "getComplete",
      isAccountNextStepsDisabled: "getAccountNextStepsDisabled",
      loading: "getLoading"
    }),
    organizationWithDepartmentName: function() {
      if (this.organization.department_name) {
        return `${this.organization.organization_name} [${this.organization.department_name}]`
      }
      return this.organization.organization_name
    },
    isAdmin: function() {
      if (!this.profile) return false
      const group_tag = this.profile.group_tag
      return typeof group_tag === "string"
        ? group_tag === "admin"
        : group_tag.includes("admin")
    },
    isDemoUser: function() {
      if (!this.profile) return false
      const group_tag = this.profile.group_tag
      return typeof group_tag === "string"
        ? group_tag === "demo_user"
        : group_tag.includes("demo_user")
    }
  },
  methods: {
    ...mapActions("user", [
      "setProfile",
      "setOrganization",
      "setLicense",
      "setNotifications",
      "setValid",
      "setComplete",
      "setAccountNextStepsDisabled",
      "setLoading",
      "resetUser"
    ]),
    async setUserDetails() {
      const profile = await this.$pigeonline.profile.get()
      this.setProfile(profile)

      // decrypt and save licensing info
      if (profile.license && profile.license != "") {
        await this.setLicense(this.decrypt(profile.license, profile._id.$oid))
        this.setLicensePermissions()
      }
      const org = profile.organization_ref_id
        ? await this.$pigeonline.organizations.getByID(
            OrganizationModel,
            profile.organization_ref_id
          )
        : null

      await Promise.all([
        this.setOrganization(org),
        this.setValid(),
        this.setComplete(),
        this.setAccountNextStepsDisabled()
      ])
    },
    async setUserNotifications() {
      try {
        const res = await axios.get(
          this.$pigeonline.options.baseUrl + "/notifications/"
        )
        this.setNotifications(res.data)
      } catch (e) {
        throw new Error("userMixin.vue:setUserNotifications: " + e.message)
      }
    },
    setLicensePermissions() {
      const license = (this.license && this.license.name) || "free"
      let licensePermissions

      // single license
      if (typeof license === "string") {
        licensePermissions = permissions[license]
      }

      // multiple licenses
      if (Array.isArray(license)) {
        licensePermissions = license.reduce(
          (acc, curr) => [...acc, ...(permissions[curr] || [])],
          []
        )
      }

      // set license permissions
      this.$pigeonline.permissions.setPermissions(
        licensePermissions || permissions["free"]
      )
    },
    decrypt(licenseKey, passphrase) {
      var ciphertext = CryptoJS.enc.Base64.parse(licenseKey)

      // split iv and ciphertext
      var iv = ciphertext.clone()
      iv.sigBytes = 16
      iv.clamp()
      ciphertext.words.splice(0, 4) // delete 4 words = 16 bytes
      ciphertext.sigBytes -= 16

      var key = CryptoJS.enc.Utf8.parse(passphrase)

      // decryption
      var decrypted = CryptoJS.AES.decrypt({ ciphertext: ciphertext }, key, {
        iv: iv,
        mode: CryptoJS.mode.CFB
      })
      return JSON.parse(decrypted.toString(CryptoJS.enc.Utf8))
    }
  }
}
