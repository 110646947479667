<template>
  <div
    class="dialog"
    role="dialog"
    ref="trap-container"
    tabindex="-1"
    :aria-labelledby="`header-${uid}`"
    :hidden="!show"
    :id="`${uid}-container`"
  >
    <div class="dialog-header">
      <component class="modal-header" :is="headerTag" :id="`header-${uid}`">
        <slot name="header"></slot>
      </component>
      <slot name="close">
        <ButtonIconOnly
          icon="remove"
          :id="`close-${uid}`"
          class="close-btn"
          @click-handler="close"
        >
          <VisibleText>close</VisibleText>
        </ButtonIconOnly>
      </slot>
    </div>

    <div class="dialog-body">
      <slot name="body" :uid="uid">
        Dialolg body
      </slot>
    </div>
  </div>
</template>

<script>
import ButtonIconOnly from "@/components/UI/Button/ButtonIconOnly.vue"
import KeyboardTrapMixin from "@/utils/mixins/keyboardTrapMixin.js"

export default {
  name: "DialogPopup",
  mixins: [KeyboardTrapMixin],
  components: {
    ButtonIconOnly
  },
  props: {
    headerTag: {
      default: () => "h2",
      type: String
    },
    show: {
      default: () => false,
      type: Boolean,
      required: true
    },
    // toggle to update keydown event listeners
    // useful for when dialog re-renders or view updates
    resetListener: {
      default: () => false,
      type: Boolean
    }
  },
  data() {
    return {
      uid: this.$pigeonline.createUUID()
    }
  },
  mounted() {
    this.setInert = false
  },
  methods: {
    close() {
      this.$emit("close")
    }
  },
  watch: {
    resetListener: function() {
      this.resetKeydownListener()
    }
  }
}
</script>
