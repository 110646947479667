import { axios, BaseService } from "@pigeonline/core"

export default class TextService extends BaseService {
  constructor(core) {
    super(core)

    // define the api url
    this.URL = `${this.BASE_URL}/analysis/text`
  }

  /**
   * Fetch keywords for an OPEN_ENDED client question
   * @param {Object} request
   * @param {String} request.client_question_id
   * @param {String} request.data_set_id data set ID of client questions
   * @returns {Object} key value pair of theme: word list
   */
  async keywords(request) {
    try {
      const response = await axios.post(`${this.URL}/keywords`, request, {
        headers: this.getAuthHeader()
      })
      return response.data
    } catch (e) {
      throw new Error("textService.js:keywords " + e.message)
    }
  }

  /**
   * Compute coverage on an OPEN_ENDED client question
   * @param {Object} request
   * @param {String} request.client_question_id
   * @param {String} request.project_id
   * @param [String] request.search_keywords set of keywords to compute coverage on
   * @returns {Float}
   */
  async coverage(request) {
    try {
      const response = await axios.post(`${this.URL}/word_coverage`, request, {
        headers: this.getAuthHeader()
      })
      return response.data
    } catch (e) {
      throw new Error("textService.js:coverage " + e.message)
    }
  }

  /**
   * Compute sentiment of a list of keywords
   * @param {Object} request
   * @param [String] request.keywords set of keywords to compute sentiment on
   * @returns {Float}
   */
  async sentiment(request) {
    try {
      const response = await axios.post(
        `${this.URL}/sentiments/custom_string`,
        request,
        {
          headers: this.getAuthHeader()
        }
      )
      return response.data
    } catch (e) {
      throw new Error("textService.js:sentiment " + e.message)
    }
  }

  /**
   * Fetch word associations for a user_string of an OPEN_ENDED client question
   * @param {Object} request
   * @param {String} request.user_string
   * @param {String} request.client_question_id
   * @param {String} request.data_set_id data set ID of client questions
   * @returns {List} of word associations
   */
  async wordAssociations(request) {
    try {
      const response = await axios.post(
        `${this.URL}/word_association`,
        request,
        {
          headers: this.getAuthHeader()
        }
      )
      return response.data
    } catch (e) {
      throw new Error("textService.js:wordAssociations " + e.message)
    }
  }

  /**
   * Search API: Simple
   * @param {Object} request
   * @param {String} request.search_string
   * @param {String} request.client_question_id
   * @param {String} request.data_set_id data set ID of client questions
   * @returns {List} of words sorted by match strength
   */
  async searchSimple(request) {
    try {
      const response = await axios.post(`${this.URL}/search_simple`, request, {
        headers: this.getAuthHeader()
      })
      return response.data
    } catch (e) {
      throw new Error("textService.js:searchSimple " + e.message)
    }
  }

  /**
   * Search API: Advanced
   * @param {Object} request
   * @param {String} request.search_string
   * @param {String} request.client_question_id
   * @param {String} request.data_set_id data set ID of client questions
   * @returns {List} of words sorted by match strength
   */
  async searchAdvanced(request) {
    try {
      const response = await axios.post(
        `${this.URL}/search_advanced`,
        request,
        {
          headers: this.getAuthHeader()
        }
      )
      return response.data
    } catch (e) {
      throw new Error("textService.js:searchAdvanced " + e.message)
    }
  }

  /**
   * Fetch sentiments OPEN_ENDED client question
   * @param {Object} request
   * @param {String} request.client_question_id
   * @returns [Object] all text respones with their sentiments
   */
  async sentiments(request) {
    try {
      const response = await axios.post(`${this.URL}/sentiments`, request, {
        headers: this.getAuthHeader()
      })
      return response.data
    } catch (e) {
      throw new Error("textService.js:sentiments " + e.message)
    }
  }

  /**
   * Fetch sentiments OPEN_ENDED client question
   * @param {Object} request
   * @param {String} request.client_question_id
   * @param {String} request.theme_name
   * @returns [Object] all text respones belonging to a theme
   */
  async filterTheme(request) {
    try {
      const response = await axios.post(`${this.URL}/filter_theme`, request, {
        headers: this.getAuthHeader()
      })
      return response.data
    } catch (e) {
      throw new Error("textService.js:filterTheme " + e.message)
    }
  }

  /**
   * Ban a comment/response from open_ended question
   * @param {Object} request
   * @param {String} request.project_id
   * @param {String} request.client_question_id
   * @param {String} request.response_id
   * @returns [String] 200 OK success
   */
  async banComment(request) {
    try {
      const response = await axios.post(`${this.URL}/comment_ban`, request, {
        headers: this.getAuthHeader()
      })
      return response.data
    } catch (e) {
      throw new Error("textService.js:banComment " + e.message)
    }
  }

  /**
   * Fetch respondent info for a client question response id
   * @param {Object} request
   * @param {String} request.project_id
   * @param {String} request.client_question_id
   * @param {String} request.response_id
   * @returns [String] 200 OK success
   */
  async respondentInfo(request) {
    try {
      const response = await axios.post(
        `${this.URL}/respondent_info`,
        request,
        {
          headers: this.getAuthHeader()
        }
      )
      return response.data
    } catch (e) {
      throw new Error("textService.js:respondentInfo " + e.message)
    }
  }
}
