<template>
  <div>
    <div class="loading__top-bar-overlay" v-show="isLong">
      <div class="loading__top-bar-overlay-text">
        <VisibleText>loading</VisibleText>...
      </div>
    </div>
    <div class="loading__top-bar-wrapper" v-show="isLoading">
      <div class="loading__top-bar" :style="width"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LoadingTopBar",
  props: {
    isLoading: {
      isLoading: Boolean,
      default: false
    }
  },
  data() {
    return {
      isLong: false,
      progress: 0 //width of the topbar
    }
  },
  computed: {
    width() {
      return `width: ${this.progress}%`
    }
  },
  methods: {
    start() {
      let start = 0
      let end = 100
      let duration = 1000
      let framerate = duration / 10

      let currProgress = this.progress
      let toAdd = ((end - start) * framerate) / duration

      let interval = setInterval(() => {
        currProgress += toAdd

        // got to 60 but not yet finished loading, slow it down
        if (Math.round(currProgress) == 60) {
          framerate = 2000 / 10
        }

        // completed
        if (!this.isLoading) {
          this.progress = 100
          this.reset()
          clearInterval(interval)
          return
        }

        // got to 90 but not yet finished, dont increment progress & show overlay
        if (Math.round(currProgress) < 89) {
          this.progress = Math.round(currProgress)
          this.isLong = true
        }
      }, framerate)
    },
    reset() {
      this.isLong = false
      this.progress = 0
    }
  },
  watch: {
    isLoading: function(val) {
      if (val) this.start()
    }
  }
}
</script>
