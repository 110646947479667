<template>
  <Table
    class="questions__table"
    :caption="caption"
    :hide-caption="false"
    :row-data="questions"
    :column-headers="columnHeaders"
    :sort-config="sortConfig"
    @sort="sortLocaleCompare"
  >
    <template slot-scope="{ row }">
      <td class="td-question-select">
        <input
          type="checkbox"
          class="questions__table-checkbox"
          :id="`select-chkbox-${id}-${row.id}`"
          :name="`select-chkbox-${id}-${row.id}`"
          :checked="row.options.selected"
          @click.prevent="toggleSelect(row)"
        />
        <label :for="`select-chkbox-${id}-${row.id}`" class="sr-only">
          select {{ row.question_title }}
        </label>
      </td>
      <td class="td-question-title" data-label="title">
        <span class="questions__table-title">
          {{ row.question_title }}
        </span>
      </td>
      <td class="td-question-wording" data-label="wording">
        <span class="questions__table-wording">
          {{ row.question_text }}
        </span>
      </td>
      <td class="td-question-data-type" data-label="data type">
        <VisibleText class="questions__table-data-type">
          {{ DATA_TYPE_TO_READABlE[row.approved_data_type] }}
        </VisibleText>
      </td>
    </template>
  </Table>
</template>

<script>
// Components
import Table from "@/components/UI/Table.vue"

import { DATA_TYPE_TO_READABlE } from "@/utils/consts/constsDataTypes.js"

export default {
  name: "TableQuestions",
  components: {
    Table
  },
  props: {
    caption: {
      type: String,
      default: () => ""
    },
    questionsList: {
      type: Array,
      default: () => []
    },
    enableSortByColHeader: {
      type: Boolean,
      default: () => true
    }
  },
  data() {
    return {
      questions: [],
      DATA_TYPE_TO_READABlE: DATA_TYPE_TO_READABlE,
      COL_HEADERS: ["Question", "Wording", "Data Type"],
      COL_HEADERS_TO_ATTRIBUTES: {
        Question: "question_title",
        Wording: "question_text",
        "Data Type": "approved_data_type"
      },
      sortableOrder: {
        ascending: 1,
        descending: -1
      },
      sortConfig: {
        Question: {
          options: ["ascending", "descending"],
          selected: ""
        },
        Wording: {
          options: ["ascending", "descending"],
          selected: ""
        },
        "Data Type": {
          options: ["ascending", "descending"],
          selected: ""
        }
      }
    }
  },
  computed: {
    id() {
      return this._uid
    },
    columnHeaders() {
      return ["", ...this.COL_HEADERS]
    }
  },
  mounted() {
    this.questions = [...this.questionsList]
  },
  methods: {
    select(question) {
      question.options.selected = true
      this.$emit("updated", true)
    },
    toggleSelect(question) {
      question.options.selected = !question.options.selected
      this.$emit("updated", true)
    },
    sortLocaleCompare(args) {
      this.sortConfig[args.sortTitle].selected = args.option
      this.questions = [...this.questionsList].sort(
        (a, b) =>
          this.sortableOrder[args.option] *
          a[this.COL_HEADERS_TO_ATTRIBUTES[args.sortTitle]].localeCompare(
            b[this.COL_HEADERS_TO_ATTRIBUTES[args.sortTitle]]
          )
      )
    }
  },
  watch: {
    questionsList: function(val) {
      if (!val || !Array.isArray(val)) return
      this.questions = [...val]
    }
  }
}
</script>
