import Vue from "vue"
import Vuex from "vuex"
import BenchmarkingDataset from "@/models/BenchmarkingDatasetModel.js"

Vue.use(Vuex)

export default {
  namespaced: true,
  state() {
    return {
      activeSection: null,
      benchmarkGroup: null,
      copyingDetails: {
        isCopyingDetails: false,
        methodName: "copyValAndLabel"
      },
      datasetProject: new BenchmarkingDataset({ name: null }),
      editingBenchmark: null,
      deleteIncompleteUpload: false,
      toFetchSuggestedDataType: false,
      unsavedChanges: []
    }
  },
  getters: {
    getActiveSection: state => state.activeSection,
    getBenchmarkGroup: state => state.benchmarkGroup,
    getCopyingDetails: state => state.copyingDetails,
    getDatasetProject: state => state.datasetProject,
    getEditingBenchmark: state => state.editingBenchmark,
    getDeleteIncompleteUpload: state => state.deleteIncompleteUpload,
    getToFetchSuggestedDataType: state => state.toFetchSuggestedDataType,
    getUnsavedChanges: state => state.unsavedChanges
  },
  mutations: {
    SET_ACTIVE_SECTION(state, payload) {
      Vue.set(state, "activeSection", payload)
    },
    SET_BENCHMARK_GROUP(state, payload) {
      Vue.set(state, "benchmarkGroup", payload)
    },
    SET_COPYING_DETAILS(state, payload) {
      Vue.set(state, "copyingDetails", payload)
    },
    SET_EDITING_BENCHMARK(state, payload) {
      Vue.set(state, "editingBenchmark", payload)
    },
    SET_DATASET_PROJECT(state, payload) {
      Vue.set(state, "datasetProject", payload)
    },
    SET_UNSAVED_CHANGES(state, payload) {
      Vue.set(state, "unsavedChanges", payload)
    },
    SET_DELETE_INCOMPLETE_UPLOAD(state, payload) {
      Vue.set(state, "deleteIncompleteUpload", payload)
    },
    SET_TO_FETCH_SUGGESTED_DATA_TYPE(state, payload) {
      Vue.set(state, "toFetchSuggestedDataType", payload)
    },
    RESET_DATASET_WIZARD(state) {
      Vue.set(state, "activeSection", null)
      Vue.set(state, "benchmarkGroup", null)
      Vue.set(state, "copyingDetails", {
        isCopyingDetails: false,
        methodName: "copyValAndLabel"
      })
      Vue.set(state, "datasetProject", new BenchmarkingDataset({ name: null }))
      Vue.set(state, "editingBenchmark", null)
      Vue.set(state, "unsavedChanges", [])
      Vue.set(state, "deleteIncompleteUpload", null)
      Vue.set(state, "toFetchSuggestedDataType", null)
    }
  },
  actions: {
    async setActiveSection({ commit }, payload) {
      await commit("SET_ACTIVE_SECTION", payload || "DatasetUploader")
    },
    async setBenchmarkGroup({ commit }, payload) {
      await commit("SET_BENCHMARK_GROUP", payload)
    },
    async setCopyingDetails({ commit }, payload) {
      await commit("SET_COPYING_DETAILS", payload)
    },
    async setEditingBenchmark({ commit }, payload) {
      await commit("SET_EDITING_BENCHMARK", payload)
    },
    async setDatasetProject({ commit }, payload) {
      await commit("SET_DATASET_PROJECT", payload)
    },
    async setUnsavedChanges({ commit }, payload) {
      await commit("SET_UNSAVED_CHANGES", payload)
    },
    async setDeleteIncompleteUpload({ commit }, payload) {
      await commit("SET_DELETE_INCOMPLETE_UPLOAD", payload)
    },
    async setToFetchSuggestedDataType({ commit }, payload) {
      await commit("SET_TO_FETCH_SUGGESTED_DATA_TYPE", payload)
    },
    async addUnsavedChanges({ commit, state }, payload) {
      if (!state.unsavedChanges.includes(payload))
        state.unsavedChanges.push(payload)
      await commit("SET_UNSAVED_CHANGES", state.unsavedChanges)
    },
    async removeUnsavedChanges({ commit, state }, payload) {
      const spliced = Array.from(state.unsavedChanges)
      spliced.splice(spliced.indexOf(payload), 1)
      await commit("SET_UNSAVED_CHANGES", spliced)
    },
    resetDatasetWizard({ commit }) {
      commit("RESET_DATASET_WIZARD")
    }
  }
}
