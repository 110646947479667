<template>
  <div class="library__article">
    <p>
      <VisibleText>Go to </VisibleText>
      <router-link to="/library">
        <VisibleText>Library</VisibleText>
      </router-link>
    </p>
    <h1><VisibleText>What's new with version 1.5</VisibleText>?</h1>
    <p>
      <VisibleText>
        We have launched a new version of ResearchAI! We are a young tool, but
        you can see we are innovating at a rapid pace thanks to your support and
        input. New updates include:
      </VisibleText>
    </p>
    <h2><VisibleText>Uploading your data</VisibleText></h2>
    <ul class="library__list">
      <li><VisibleText>You can now drop and delete questions</VisibleText></li>
      <li><VisibleText>You can now recode scale values</VisibleText></li>
      <li>
        <VisibleText>
          It's easier to define your data and apply tags
        </VisibleText>
      </li>
    </ul>
    <h2><VisibleText>Analysis</VisibleText></h2>
    <ul class="library__list">
      <li>
        <VisibleText>
          You can now create an overall performance score to track across the
          app
        </VisibleText>
      </li>
      <li>
        <VisibleText>
          You can now combine multiple questions into an index (also called a
          composite measure)
        </VisibleText>
      </li>
      <li>
        <VisibleText>
          All charts can now be viewed in tabular form
        </VisibleText>
      </li>
    </ul>
    <h2><VisibleText>Qualitative analysis</VisibleText></h2>
    <ul class="library__list">
      <li><VisibleText>Significant performance improvements</VisibleText></li>
      <li>
        <VisibleText>Improved user experience and easier filtering</VisibleText>
      </li>
      <li><VisibleText>You can now drop/hide/pin respondents</VisibleText></li>
      <li>
        <VisibleText>You can now add excerpts (quotes) to a theme</VisibleText>
      </li>
      <li>
        <VisibleText>You can now add author notes to a theme</VisibleText>
      </li>
      <li>
        <VisibleText>You can now add author notes to a theme</VisibleText>
      </li>
      <li>
        <VisibleText>
          View theme sentiment by keyword and sentiment by respondent
        </VisibleText>
      </li>
      <li>
        <VisibleText>You can now build your own custom models</VisibleText>
      </li>
    </ul>
    <h2><VisibleText>Overall</VisibleText></h2>
    <ul class="library__list">
      <li>
        <VisibleText>
          Significant accessibility and data navigation improvements
        </VisibleText>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "whats_new_1.5"
}
</script>
