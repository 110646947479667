<template>
  <section>
    <Modal
      header-tag="h1"
      :uid="`contact-us-modal-${uuid}`"
      :show="isContactUsExpanded"
      :resetListener="resetListener"
      :wider-format="true"
      @close="$emit('close')"
    >
      <template #header><VisibleText>Contact us</VisibleText></template>
      <template slot="close">
        <form
          class="user-guide__form"
          id="contact_us_do_not_show_form"
          @submit.prevent="close_and_save"
        >
          <ButtonIconOnly
            icon="remove"
            :id="`close-${uuid}`"
            class="modal-close-btn"
            @click-handler="close_and_save"
          >
            <VisibleText>close</VisibleText>
          </ButtonIconOnly>
        </form>
      </template>
      <div class="contactUs__wrapper">
        <p>
          <VisibleText
            >Do you have a question? Your input and questions mean a lot to us.
            Fill in what you can and we'll get back to you right away.
          </VisibleText>
        </p>
        <form class="contactUs__container">
          <div class="contactUs__inputContainer">
            <label for="name"><VisibleText>Name</VisibleText>:</label>
            <br />
            <input
              class="contactUs__input"
              type="text"
              id="name"
              name="name"
              v-model="name"
            />
          </div>
          <div class="contactUs__inputContainer">
            <label for="phone"><VisibleText>Phone</VisibleText>:</label>
            <br />
            <input
              class="contactUs__input"
              type="text"
              id="phone"
              name="phone"
              v-model="phone"
            />
          </div>
          <div class="contactUs__inputContainer">
            <label for="email"><VisibleText>Email</VisibleText>:</label>
            <br />
            <input
              class="contactUs__input"
              type="text"
              id="email"
              name="email"
              v-model="email"
            />
          </div>
          <div class="contactUs__inputContainer">
            <label for="message"><VisibleText>Message</VisibleText>:</label>
            <br />
            <textarea
              class="contactUs__textArea"
              id="message"
              v-model="message"
            ></textarea>
          </div>
          <div class="contactUs--content-right">
            <button class="landing-page__button" @click.prevent="sendForm">
              <VisibleText>Send</VisibleText>
            </button>
          </div>
        </form>
      </div>
    </Modal>
  </section>
</template>

<script>
import Modal from "@/components/UI/Modal.vue"
import MailerService from "@/services/mailerService.js"
import ButtonIconOnly from "@/components/UI/Button/ButtonIconOnly.vue"

export default {
  name: "ContactUs",
  components: {
    Modal,
    ButtonIconOnly
  },
  props: ["status"],
  data() {
    return {
      MAILER_SERVICE: new MailerService(this.$pigeonline),
      newStatus: false,
      firstLoad: true,
      resetListener: false,
      name: "",
      email: "",
      phone: "",
      message: ""
    }
  },
  methods: {
    close_and_save() {
      this.isContactUsExpanded = false
      this.$emit("close")
    },
    async sendForm() {
      try {
        await this.MAILER_SERVICE.sendContactUs({
          subject: "Contact Us - A Message Has Been Received.",
          message: `
  From: ${this.name} <${this.email}>
  Phone: ${this.phone}
  Message: ${this.message}

  The request was initiated from following URL - ${window.location.hostname}
          `,
          to: ["cody.d@pigeonline.io"]
        })
        alert(
          "Your message has been sent successfully. A representative will be in touch with you soon.\n\nThank you."
        )
      } catch (e) {
        alert(
          "There was an error processing your request. Please contact info@pigeonline.io or try again later."
        )
        throw new Error("ContactUs.vue:sendForm:: " + e)
      }
    }
  },
  computed: {
    isContactUsExpanded: {
      get() {
        return this.newStatus
      },
      set(value) {
        this.newStatus = value
      }
    },
    uuid() {
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(
        c
      ) {
        var r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8
        return v.toString(16)
      })
    }
  },
  watch: {
    status(newVal) {
      this.newStatus = newVal
    }
  }
}
</script>
