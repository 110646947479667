<template>
  <Modal uid="dataset-uploader-template" :show="show" @close="$emit('close')">
    <template #header>
      <VisibleText>Dataset Template</VisibleText>
    </template>
    <VisibleText>The template contains two sheets.</VisibleText>
    <div class="dataset-uploader__template-figures">
      <figure>
        <img
          class="dataset-uploader__template-img"
          src="/assets/userguide__upload02.png"
          alt="data-sheet-example"
        />
        <figcaption>
          <strong><VisibleText>Sheet One:</VisibleText></strong>
          <VisibleText
            >Data - Each row is a respondent and columns are questions. Column
            headers refer to question codes, column one is the respondent id and
            other columns are question responses.
          </VisibleText>
        </figcaption>
      </figure>
      <figure>
        <img
          class="dataset-uploader__template-img"
          src="/assets/userguide__upload03.png"
          alt="codebook-sheet-example"
        />
        <figcaption>
          <strong><VisibleText>Sheet Two:</VisibleText></strong>
          <VisibleText
            >Codebook - Add question wording to match your question code. If you
            do not have specific question codes, just add Q1, Q2, Q3 etc. as
            column headers.
          </VisibleText>
        </figcaption>
      </figure>
    </div>
    <button
      @click="downloadTemplate"
      class="dataset-uploader__template-download-btn"
    >
      <VisibleText>Download Template</VisibleText>
    </button>
  </Modal>
</template>

<script>
import Modal from "@/components/UI/Modal.vue"
import axios from "axios"

export default {
  name: "DatasetUploaderTemplateModal",
  components: {
    Modal
  },
  props: {
    show: {
      default: () => false,
      type: Boolean
    }
  },
  methods: {
    downloadTemplate() {
      axios({
        url: `${window.location.origin}/files/codebook-template.xlsx`,
        method: "GET",
        responseType: "blob"
      }).then(response => {
        const fileURL = window.URL.createObjectURL(
          new Blob([response.data], {
            type: "application/vnd.ms-excel"
          })
        )
        const fileLink = document.createElement("a")
        fileLink.href = fileURL
        fileLink.setAttribute("download", "codebook-template.xlsx")
        document.body.appendChild(fileLink)
        fileLink.click()
      })
    }
  }
}
</script>
