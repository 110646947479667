<template>
  <section>
    <Modal
      header-tag="h1"
      :uid="`user-guide-modal-${uuid}`"
      :show="isUserGuideExpanded"
      :resetListener="resetListener"
      @close="$emit('close')"
    >
      <template #header>
        <VisibleText>User Guide</VisibleText>
      </template>
      <template slot="close">
        <form
          class="user-guide__form"
          id="user_guide_do_not_show_form"
          @submit.prevent="close_and_save"
        >
          <div class="user-guide__header">
            <input
              type="checkbox"
              class="user-guide__checkbox"
              id="landingPageAutoClose"
              v-model="vModels.uiPreferences.tutorial.landingPageAutoClose"
            />
            <label for="landingPageAutoClose" class="user-guide__form-label">
              <VisibleText>Do not show again</VisibleText>
            </label>
          </div>
          <ButtonIconOnly
            icon="remove"
            id="close-user-guide-modal"
            button-type="submit"
            class="modal-close-btn"
            @click-handler="close_and_save"
          >
            <VisibleText>close</VisibleText>
          </ButtonIconOnly>
        </form>
      </template>

      <keep-alive>
        <component
          id="userGuideModal"
          :is="activeArticle"
          :isUserGuide="true"
          @new-page="newPage"
        ></component>
      </keep-alive>
    </Modal>
  </section>
</template>

<script>
import Modal from "@/components/UI/Modal.vue"

// Mixin
import UserMixin from "@/utils/mixins/userMixin"
import ButtonIconOnly from "@/components/UI/Button/ButtonIconOnly.vue"

// Packages
import axios from "axios"

// Content
import GettingStarted from "@/components/Library/Guide/GettingStarted.vue"
import UploadingFiles from "@/components/Library/Guide/UploadingFiles.vue"
import BasicAnalysis from "@/components/Library/Guide/BasicAnalysis.vue"
import BenchmarkingAnalysis from "@/components/Library/Guide/BenchmarkingAnalysis.vue"
import ThematicAnalysis from "@/components/Library/Guide/ThematicAnalysis.vue"

export default {
  name: "UserGuide",
  mixins: [UserMixin],
  components: {
    ButtonIconOnly,
    Modal,
    GettingStarted,
    UploadingFiles,
    BasicAnalysis,
    BenchmarkingAnalysis,
    ThematicAnalysis
  },
  props: ["status"],
  data() {
    return {
      newStatus: false,
      guideTopic: 0,
      topicPage: 1,
      isLastPage: false,
      firstLoad: true,
      resetListener: false,
      activeArticle: "GettingStarted",
      vModels: {
        uiPreferences: {
          tutorial: {
            landingPageAutoClose: ""
          }
        }
      }
    }
  },
  mounted() {
    this.$watch(
      "profile",
      function(newVal) {
        if (!newVal) return
        this.setValue("landingPageAutoClose", newVal.landingPageAutoClose)
        this.checkAutoLoad()
      },
      { deep: true, immediate: true }
    )
  },
  methods: {
    checkAutoLoad() {
      if (
        this.vModels.uiPreferences.tutorial.landingPageAutoClose &&
        this.firstLoad
      ) {
        this.firstLoad = false
        this.isUserGuideExpanded = false
        this.$emit("close")
      } else if (
        !this.vModels.uiPreferences.tutorial.landingPageAutoClose &&
        this.firstLoad
      ) {
        this.firstLoad = false
        this.isUserGuideExpanded = true
      }
    },
    newPage(e) {
      this.activeArticle = e
      document.getElementById("userGuideModal").scrollIntoView()
    },
    download() {
      axios({
        url: `${window.location.origin}/files/codebook-template.xlsx`,
        method: "GET",
        responseType: "blob"
      }).then(response => {
        const fileURL = window.URL.createObjectURL(
          new Blob([response.data], {
            type: "application/vnd.ms-excel"
          })
        )
        const fileLink = document.createElement("a")
        fileLink.href = fileURL
        fileLink.setAttribute("download", "codebook-template.xlsx")
        document.body.appendChild(fileLink)
        fileLink.click()
      })
    },
    setValue(property, val) {
      if (val) {
        this.vModels.uiPreferences.tutorial[property] = val
      }
    },
    async close_and_save() {
      try {
        const profile = await this.$pigeonline.profile.save({
          landingPageAutoClose: this.vModels.uiPreferences.tutorial
            .landingPageAutoClose
        })
        this.setProfile(profile)
        this.isUserGuideExpanded = false
        this.$emit("close")
      } catch (e) {
        alert(
          "An error occured when processing your request. Please try again later"
        )
        this.isUserGuideExpanded = false
        this.$emit("close")
        return
      }
    }
  },
  computed: {
    isUserGuideExpanded: {
      get() {
        return this.newStatus
      },
      set(value) {
        this.newStatus = value
      }
    },
    uuid() {
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(
        c
      ) {
        var r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8
        return v.toString(16)
      })
    }
  },
  watch: {
    status(newVal) {
      this.newStatus = newVal
    },
    guideTopic(index) {
      if (index === 0)
        this.$nextTick(() => document.getElementById("getting-started").focus())
    }
  }
}
</script>
