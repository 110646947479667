import Vue from "vue"
import Vuex from "vuex"

Vue.use(Vuex)

const DEFAULTS = {
  activeTab: "Sentiment",
  datasetId: null,

  // text client questions
  textQuestions: null,
  selectedTextQuestion: null,
  selectedTextQuestionResponses: [],

  // themes and responses
  themes: [],
  selectedThemeIndex: -1,
  selectedThemeResponses: [],

  // sort orders
  sentimentsSortOrder: -1, // default - negative sentiments

  // Response IDs to filter
  selectedResponseIds: [],

  // search
  search: {
    searchString: "",
    isSearching: false,
    alsoFound: [],
    searchResults: [],
    excludeAssociations: false,
    wordAssociations: [],
    isFetchingWordAssociations: false
  },

  // banned keywords + comments
  bannedKeywords: [],
  bannedComments: [],

  // pin comments
  pinnedComments: [],

  // hide comments
  hiddenComments: [],

  // loading spinner
  showSpinner: false,
  showResponseListLoadingSpinner: false
}

export default {
  namespaced: true,
  state() {
    return JSON.parse(JSON.stringify(DEFAULTS))
  },
  getters: {
    getActiveTab: state => state.activeTab,
    getDatasetId: state => state.datasetId,
    getTextQuestions: state => state.textQuestions,
    getSelectedTextQuestion: state => state.selectedTextQuestion,
    getSelectedTextQuestionResponses: state =>
      state.selectedTextQuestionResponses,
    getThemes: state => state.themes,
    getSelectedThemeIndex: state => state.selectedThemeIndex,
    getSelectedThemeResponses: state => state.selectedThemeResponses,
    getSelectedTheme: state => state.selectedTheme,
    getSentimentsSortOrder: state => state.sentimentsSortOrder,
    getSelectedResponseIds: state => state.selectedResponseIds,
    getExcerpts: state => state.excerpts,
    getNotes: state => state.notes,
    getSearch: state => state.search,
    getBannedKeywords: state => state.bannedKeywords,
    getBannedComments: state => state.bannedComments,
    getPinnedComments: state => state.pinnedComments,
    getHiddenComments: state => state.hiddenComments,
    getShowSpinner: state => state.showSpinner,
    getShowResponseListLoadingSpinner: state =>
      state.showResponseListLoadingSpinner
  },
  mutations: {
    SET_ACTIVE_TAB(state, payload) {
      Vue.set(state, "activeTab", payload)
    },
    SET_DATASET_ID(state, payload) {
      Vue.set(state, "datasetId", payload)
    },
    SET_TEXT_QUESTIONS(state, payload) {
      Vue.set(state, "textQuestions", payload)
    },
    SET_SELECTED_TEXT_QUESTION(state, payload) {
      Vue.set(state, "selectedTextQuestion", payload)
    },
    SET_SELECTED_TEXT_QUESTION_RESPONSES(state, payload) {
      Vue.set(state, "selectedTextQuestionResponses", payload)
    },
    SET_THEMES(state, payload) {
      Vue.set(state, "themes", payload)
    },
    SET_SELECTED_THEME_INDEX(state, payload) {
      Vue.set(state, "selectedThemeIndex", payload)
    },
    SET_SELECTED_THEME_RESPONSES(state, payload) {
      Vue.set(state, "selectedThemeResponses", payload)
    },
    SET_SENTIMENTS_SORT_ORDER(state, payload) {
      Vue.set(state, "sentimentsSortOrder", payload)
    },
    SET_SELECTED_RESPONSE_IDS(state, payload) {
      Vue.set(state, "selectedResponseIds", payload)
    },
    SET_EXCERPTS(state, payload) {
      Vue.set(state, "excerpts", payload)
    },
    SET_NOTES(state, payload) {
      Vue.set(state, "notes", payload)
    },
    SET_SEARCH(state, payload) {
      Vue.set(state, "search", { ...state.search, ...payload })
    },
    SET_BANNED_KEYWORDS(state, payload) {
      Vue.set(state, "bannedKeywords", payload)
    },
    SET_BANNED_COMMENTS(state, payload) {
      Vue.set(state, "bannedComments", payload)
    },
    SET_PINNED_COMMENTS(state, payload) {
      Vue.set(state, "pinnedComments", payload)
    },
    SET_HIDDEN_COMMENTS(state, payload) {
      Vue.set(state, "hiddenComments", payload)
    },
    SET_SHOW_SPINNER(state, payload) {
      Vue.set(state, "showSpinner", payload)
    },
    SET_SHOW_RESPONSE_LIST_LOADING_SPINNER(state, payload) {
      Vue.set(state, "showResponseListLoadingSpinner", payload)
    },
    RESET_ANALYSIS_TEXT(state) {
      Vue.set(state, "datasetId", DEFAULTS.datasetId)
      Vue.set(state, "textQuestions", DEFAULTS.textQuestions)
      Vue.set(state, "selectedTextQuestion", DEFAULTS.selectedTextQuestion)
      Vue.set(
        state,
        "selectedTextQuestionResponses",
        DEFAULTS.selectedTextQuestionResponses
      )
      Vue.set(state, "themes", DEFAULTS.themes)
      Vue.set(state, "selectedThemeIndex", DEFAULTS.selectedThemeIndex)
      Vue.set(state, "selectedThemeResponses", DEFAULTS.selectedThemeResponses)
      Vue.set(state, "sentimentsSortOrder", DEFAULTS.sentimentsSortOrder)
      Vue.set(state, "selectedResponseIds", DEFAULTS.selectedResponseIds)
      Vue.set(state, "search", DEFAULTS.search)
      Vue.set(state, "bannedKeywords", DEFAULTS.bannedKeywords)
      Vue.set(state, "bannedComments", DEFAULTS.bannedComments)
      Vue.set(state, "pinnedComments", DEFAULTS.pinnedComments)
      Vue.set(state, "hiddenComments", DEFAULTS.hiddenComments)
      Vue.set(state, "showSpinner", DEFAULTS.showSpinner)
      Vue.set(
        state,
        "showResponseListLoadingSpinner",
        DEFAULTS.showResponseListLoadingSpinner
      )
    },
    RESET_SEARCH(state) {
      Vue.set(state, "search", DEFAULTS.search)
    }
  },
  actions: {
    setActiveTab({ commit }, payload) {
      commit("SET_ACTIVE_TAB", payload)
    },
    setDatasetId({ commit }, payload) {
      commit("SET_DATASET_ID", payload)
    },
    setTextQuestions({ commit }, payload) {
      commit("SET_TEXT_QUESTIONS", payload)
    },
    setSelectedTextQuestion({ commit }, payload) {
      commit("SET_SELECTED_TEXT_QUESTION", payload)
    },
    setSelectedTextQuestionResponses({ commit }, payload) {
      commit("SET_SELECTED_TEXT_QUESTION_RESPONSES", payload)
    },
    setThemes({ commit }, payload) {
      commit("SET_THEMES", payload)
    },
    setSelectedThemeIndex({ commit }, payload) {
      commit("SET_SELECTED_THEME_INDEX", payload)
    },
    setSelectedThemeResponses({ commit }, payload) {
      commit("SET_SELECTED_THEME_RESPONSES", payload)
    },
    setSentiments({ commit }, payload) {
      commit("SET_SENTIMENTS", payload)
    },
    setSentimentsSortOrder({ commit }, payload) {
      commit("SET_SENTIMENTS_SORT_ORDER", payload)
    },
    setSelectedResponseIds({ commit }, payload) {
      commit("SET_SELECTED_RESPONSE_IDS", payload)
    },
    setSearch({ commit }, payload) {
      commit("SET_SEARCH", payload)
    },
    setShowSpinner({ commit }, payload) {
      commit("SET_SHOW_SPINNER", payload)
    },
    setShowResponseListLoadingSpinner({ commit }, payload) {
      commit("SET_SHOW_RESPONSE_LIST_LOADING_SPINNER", payload)
    },
    setBannedKeywords({ commit }, payload) {
      if (!Array.isArray(payload)) return
      commit(
        "SET_BANNED_KEYWORDS",
        payload.map(el => el.trim().toLowerCase())
      )
    },
    setBannedComments({ commit }, payload) {
      if (!Array.isArray(payload)) return
      commit("SET_BANNED_COMMENTS", payload)
    },
    setPinnedComments({ commit }, payload) {
      if (!Array.isArray(payload)) return
      commit("SET_PINNED_COMMENTS", payload)
    },
    setHiddenComments({ commit }, payload) {
      if (!Array.isArray(payload)) return
      commit("SET_HIDDEN_COMMENTS", payload)
    },
    resetAnalysisText({ commit }) {
      commit("RESET_ANALYSIS_TEXT")
    },
    resetSearch({ commit }) {
      commit("RESET_SEARCH")
    }
  }
}
