// Components
import Modal from "@/components/UI/Modal.vue"
import ThemeList from "../Components/ThemeList.vue"

// Mixins
import AnalysisTextMixin from "@/utils/mixins/analysisTextMixin.js"

// Library
import _ from "lodash"

export default {
  data() {
    return {
      themeList: [],
      unsavedChanges: false,
      unwatchThemeListListener: null,
      updateKey: Date.now(),
      resetListener: false,
      shouldFocusLastTheme: false,
      shouldAutoSelectLastTheme: false
    }
  },
  mixins: [AnalysisTextMixin],
  components: {
    Modal,
    ThemeList
  },
  computed: {
    themeListRef: function() {
      return `theme-list-${this._uid}`
    }
  },
  mounted() {
    this.reset()
  },
  methods: {
    getThemeListRef() {
      return this.$refs[this.themeListRef]
    },
    closeModal() {
      if (this.unsavedChanges) {
        if (
          !confirm(
            "You have unsaved changes. Are you sure you want to continue?"
          )
        ) {
          return
        }
      }
      this.unsavedChanges = false
      this.$emit("closeModal")

      // refetch theme responses
      if (
        this.selectedThemeIndex !== -1 &&
        this.requiresRefetchingThemeResponses
      ) {
        this.fetchThemeResponses().then(res => {
          this.setSelectedThemeResponses(res)
        })
      }
    },

    save: async function() {
      // save text into selected theme
      const selection = this.getThemeListRef().selection
      const selected = this.getThemeListRef().selected
      const type = this.getThemeListRef().type
      const text =
        (selection && selection.content && selection.content.trim()) || ""
      const isSelectMode = text !== "" && selected >= 0
      if (isSelectMode) {
        if (type === "excerpt") {
          if (
            this.themeList[selected][`${type}s`].filter(el =>
              _.isEqual(el, selection)
            ).length === 0
          ) {
            this.themeList[selected][`${type}s`].push(selection)
          }
        } else {
          this.themeList[selected][`${type}s`].push(text)
        }
      }

      this.setShowSpinner(true)

      // save themes
      this.saveThemes(this.themeList)
        .then(() => {
          // success
          this.showModalMessage(
            "success",
            "Your changes have been saved successfully."
          )
          this.unsavedChanges = false

          // close modal if save coming from select or auto create mode
          if (isSelectMode) this.closeModal()
        })
        .catch(e => {
          // error
          this.showModalMessage(
            "error",
            "An error occured processing your request. Please try again later."
          )
          console.error("themeListMixin.js:save: " + e)
        })
        .finally(() => {
          this.setShowSpinner(false)
        })
    },

    /* Theme methods */
    getValidatedThemeTitle(newTitle, oldTitle, themeTitles) {
      if (typeof newTitle !== "string" || newTitle.trim() === "") {
        newTitle = "unnamed theme"
      }
      themeTitles.splice(themeTitles.indexOf(oldTitle.trim().toLowerCase()), 1)

      let count = 1
      let loop = themeTitles.includes(newTitle.trim().toLowerCase())
      while (loop) {
        if (themeTitles.includes(newTitle.trim().toLowerCase() + count)) {
          count += 1
          continue
        }
        newTitle = newTitle + count
        break
      }
      return newTitle
    },
    updateThemeTitle(theme, title, themeTitles) {
      if (!themeTitles) {
        themeTitles = this.themeList.map(item => item.name.trim().toLowerCase())
      }
      theme.name = this.getValidatedThemeTitle(title, theme.name, themeTitles)

      // force update
      this.$forceUpdate()
    },
    createTheme(title = "unnamed theme", keywords = []) {
      this.shouldFocusLastTheme = true
      this.shouldAutoSelectLastTheme = true
      this.themeList = [
        ...this.themeList,
        {
          name: "",
          keywords: keywords,
          coverage: {},
          excerpts: [],
          sentiment: {},
          notes: []
        }
      ]
      this.updateThemeTitle(this.themeList[this.themeList.length - 1], title)
    },
    reset() {
      if (this.unwatchThemeListListener) this.unwatchThemeListListener()

      this.themeList = _.cloneDeep(this.themes)
      this.unsavedChanges = false
      this.updateKey = Date.now()
      this.resetListener = !this.resetListener
      this.shouldFocusLastTheme = false
      this.shouldAutoSelectLastTheme = false

      this.unwatchThemeListListener = this.$watch(
        "themeList",
        function(val) {
          if (!val) return
          this.unsavedChanges = true
        }.bind(this),
        {
          deep: true
        }
      )
    }
  },
  watch: {
    themes: function(val) {
      if (val) this.reset()
    },
    show: function(val) {
      if (val) this.reset()
    },
    unsavedChanges: function(val) {
      if (val) {
        // remove inline message block
        this.getModalRef() && this.getModalRef().removeMessage()
      }
    }
  }
}
