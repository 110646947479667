import benchmarkSection from "./benchmarkSection"
import headerSection from "./headerSection"
import questionByQuestionSection from "./questionByQuesitonSection"
import driversSection from "./driversSection"
import performanceSection from "./performanceSection"
import surveyMethodologySection from "./surveyMethodologySection"

export default {
  benchmarkSection,
  headerSection,
  questionByQuestionSection,
  driversSection,
  performanceSection,
  surveyMethodologySection
}
