import { mapActions, mapGetters } from "vuex"

export default {
  props: {
    readOnly: {
      default: () => false,
      type: Boolean
    }
  },
  computed: {
    ...mapGetters("datasetWizard", {
      activeSection: "getActiveSection",
      benchmarkGroup: "getBenchmarkGroup",
      copyingDetails: "getCopyingDetails",
      datasetProject: "getDatasetProject",
      editingBenchmark: "getEditingBenchmark",
      deleteIncompleteUpload: "getDeleteIncompleteUpload",
      toFetchSuggestedDataType: "getToFetchSuggestedDataType",
      unsavedChanges: "getUnsavedChanges"
    }),
    ...mapGetters("user", {
      profile: "getProfile"
    })
  },
  methods: {
    ...mapActions("datasetWizard", [
      "setActiveSection",
      "setBenchmarkGroup",
      "setCopyingDetails",
      "setDatasetProject",
      "setEditingBenchmark",
      "setUnsavedChanges",
      "setDeleteIncompleteUpload",
      "setToFetchSuggestedDataType",
      "addUnsavedChanges",
      "removeUnsavedChanges",
      "resetDatasetWizard"
    ]),
    async editDataset(codebook = null, segments = null, surveyDetails = null) {
      let formData = new FormData()
      formData.append("name", this.datasetProject.name)
      formData.append(
        "segments",
        segments
          ? JSON.stringify(segments)
          : JSON.stringify(
              this.$store.getters["datasetDetails/getDataset"].segments
            )
      )
      formData.append(
        "empty_question_text_count",
        this.$store.getters["datasetDetails/getDataset"]
          .empty_question_text_count || 0
      )
      formData.append(
        "non_empty_question_text_count",
        this.$store.getters["datasetDetails/getDataset"]
          .non_empty_question_text_count || 0
      )

      // add codebook
      if (codebook) {
        if (codebook.sheet)
          formData.append("code_book_sheet_name", codebook.sheet)
        formData.append("code_book", codebook.file)
      }

      // add survey details
      if (surveyDetails)
        formData.append("survey_details", JSON.stringify(surveyDetails))

      try {
        const response = await this.$services.DATASETS_SERVICE.edit(
          this.$store.getters["datasetDetails/getDataset"]._id.$oid,
          formData
        )
        return response
      } catch (e) {
        throw new Error("DatasetMixin:edit " + e.message)
      }
    },
    /************
     *
     * Methods to handle project object
     *
     ***********/
    async saveDatasetProject(project) {
      try {
        this.setDatasetProject(await this.$pigeonline.projects.update(project))
      } catch (e) {
        throw new Error("DatasetMixin:saveDatasetProject " + e.message)
      }
    }
  }
}
