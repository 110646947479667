import { BaseService, axios } from "@pigeonline/core"

export default class driversService extends BaseService {
  constructor(core) {
    super(core)

    // define the api url
    this.URL = `${this.BASE_URL}/analysis/drivers`
  }
  /**
   * Fetch drivers analysis data to generate drivers analysis charts.
   * @param {Array} request.data_set_ids array of dataset IDs
   * @param {Object} request.dependent_variable_ids {<data_set_id> : <dependent_variable_id>}
   * @param {Object} request.independent_variables_ids {<data_set_id>: [list of independent variables]}
   * @returns {Array} object with 2d array values where each item is data related to independent variables (client_questions),
   * except the last item which describes segment data
   *
   * Example Response:
   *  {
   *    num_drivers: [
   *    [0.8253579064660553, "Q54", "I would speak positively to others about my service experience", 3.467032967032967],
   *    [0.17464209353394466, "Q19", "In the end, I received the service I was seeking", 4.1321296676241275],
   *    ]
   *  }
   *
   * ex = response.num_drivers
   * ex[0][0] = coeffcient, higher the coefficient the greater "driver" of dependent variable
   * ex[0][1] = client_question title
   * ex[0][2] = client_question text
   * ex[0][3] = client_question mean
   *
   */
  async driversAnalysis(request) {
    try {
      const response = await axios.post(
        `${this.URL}/drivers_analysis`,
        request,
        {
          headers: this.getAuthHeader()
        }
      )
      return response
    } catch (e) {
      throw e.response
    }
  }

  /**
   * Fetch suggested data types for each client question
   * @param {Object} request
   * @param {Array} request.client_question_ids array of client question ids
   * @param {String} request.data_set_id data set ID of client questions
   * @returns {Object} key value pair of client_question_id: suggested-type
   *
   * suggested types:
   * OPEN_ENDEND
   * NUMERIC
   * ID
   * SCALE
   * SCALE_1_TO_5
   * CAT
   * CAT_OR_SCALE
   */
  async dataType(request) {
    try {
      const response = await axios.post(`${this.URL}/data_type`, request, {
        headers: this.getAuthHeader()
      })
      return response.data
    } catch (e) {
      throw new Error("driversService.js:dataType " + e.message)
    }
  }

  async suggestedVariables(data) {
    try {
      let response = await axios.post(`${this.URL}/variables_suggester`, data)
      return response.data
    } catch (e) {
      if (e && e.response.status) {
        return e.response.status
      } else {
        throw new Error("driversService:suggestedVariables " + e.message)
      }
    }
  }

  /**
   * Computes cronbach alpha for selected client question ids
   * @param {Object} request
   * @param {Array} request.client_question_ids array of client question ids
   * @param {String} request.data_set_id data set ID of client questions
   * @returns {Object}
   *
   * Example response: { "coefficient": 0.9917355371900827, "evaluation": "Strong" }
   */
  async variableReliability(request) {
    try {
      let response = await axios.post(
        `${this.URL}/variable_reliability`,
        request,
        {
          headers: this.getAuthHeader()
        }
      )
      return response.data
    } catch (e) {
      throw new Error("driversService:variableReliability " + e.message)
    }
  }
}
