<template>
  <section id="project-analysis-text-main-sentiment">
    <div class="sentiment__header">
      <ul class="sentiment__header-labels main__search-labels">
        <transition name="fade">
          <li v-show="isSearchMode">
            <span class="main__highlight search" id="search-keyword">
              <VisibleText>Search keyword</VisibleText>
            </span>
          </li>
        </transition>
        <transition name="fade">
          <li v-show="highlightFilters.includes('pos-keyword')">
            <span class="main__highlight positive-sentiment" id="pos-keyword">
              <VisibleText>Positive keyword</VisibleText>
            </span>
          </li>
        </transition>
        <transition name="fade">
          <li v-show="highlightFilters.includes('neg-keyword')">
            <span class="main__highlight negative-sentiment" id="neg-keyword">
              <VisibleText>Negative keyword</VisibleText>
            </span>
          </li>
        </transition>
      </ul>
      <div class="select-wrapper sentiment__sort-wrapper">
        <label for="sentiment-sort-by" class="sentiment__sort-label">
          <VisibleText>Sort by:</VisibleText>
        </label>
        <select
          id="sentiment-sort-by"
          class="select sentiment__sort-options"
          v-model.number="sortBy"
        >
          <option value="-1" :data-parent="$options.name">
            {{ translate("Negative sentiment", $options.name) }}
          </option>
          <option value="0" :data-parent="$options.name">
            {{ translate("Default", $options.name) }}
          </option>
          <option value="1" :data-parent="$options.name">
            {{ translate("Positive sentiment", $options.name) }}
          </option>
        </select>
      </div>
    </div>
    <ResponseList
      class="sentiment__response-list"
      :client-question-id="selectedTextQuestionId"
      :responses="textResponsesSorted"
      :pinned-comments="pinnedComments"
      :hidden-comments="hiddenComments"
      :get-highlight-items-method="getHighlightItems"
      @toggle-response-pin="toggleResponseItemPin"
      @ban-response="banResponseItem"
      @toggle-response-hide="toggleResponseItemHide"
    />
  </section>
</template>

<script>
// Components
import ResponseList from "./Components/ResponseList.vue"

// Mixins
import AnalysisTextMixin from "@/utils/mixins/analysisTextMixin.js"

export default {
  name: "ProjectTextAnalysisMainSentiment",
  mixins: [AnalysisTextMixin],
  components: {
    ResponseList
  },
  props: {
    highlightFilters: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    sortBy: {
      get: function() {
        return this.sentimentsSortOrder
      },
      set: function(val) {
        this.setSentimentsSortOrder(val)
      }
    },
    /* sorted text responses by sentiment type */
    textResponsesSorted: function() {
      const pinnedResponses = this.textResponsesFiltered.filter(el =>
        this.pinnedComments.includes(el.id)
      )
      const unpinnedResponses = this.textResponsesFiltered.filter(
        el => !this.pinnedComments.includes(el.id)
      )
      if (this.sortBy !== 0) {
        return [
          ...pinnedResponses,
          ...unpinnedResponses.sort(
            (a, b) =>
              this.sortBy *
              (this.findResponseScore(b) - this.findResponseScore(a))
          )
        ]
      }
      return [...pinnedResponses, ...unpinnedResponses]
    }
  },
  methods: {
    findResponseScore(response) {
      return response.scores.sentiment || 0
    },
    getHighlightItems(response) {
      if (!this.activeTab === "Sentiment") return {}
      let obj = {
        "pos-keyword": response.keywords.sentiment.pos.map(el =>
          el.trim().toLowerCase()
        ),
        "neg-keyword": response.keywords.sentiment.neg.map(el =>
          el.trim().toLowerCase()
        )
      }
      for (let key of Object.keys(obj)) {
        if (!this.highlightFilters.includes(key)) delete obj[key]
      }
      obj["search-keyword"] = response.keywords.search.map(el =>
        el.trim().toLowerCase()
      )
      return obj
    }
  },
  watch: {
    search: function(val) {
      const searchString = val.searchString
      // reset sort
      if (searchString && searchString.trim() != "") this.sortBy = 0
    }
  }
}
</script>
