import { BaseService, axios } from "@pigeonline/core"

export default class performanceService extends BaseService {
  constructor(core) {
    super(core)

    // define the api url
    this.URL = `${this.BASE_URL}/analysis/performance`
  }
  /**
   * Fetch performance analysis data to generate performance analysis charts.
   * @param {Array} request.data_set_id dataset id
   * @param {Array} request.client_question_ids array of client question ids
   * @param {Array} request.selected_segments array of segments
   * @returns {Object}
   *
   */
  async performanceAnalysis(request) {
    try {
      const response = await axios.post(this.URL, request, {
        headers: this.getAuthHeader()
      })
      return response
    } catch (e) {
      throw new Error(e.message)
    }
  }
}
