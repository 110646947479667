<template>
  <div class="register-main__content">
    <h1 class="register-main__title">
      <VisibleText>Create an account</VisibleText>
    </h1>
    <Register
      ref="register"
      class="register-main__form"
      @registered="registered"
    >
      <template v-slot:email="{ email, errors, emailBlurCheck, update }">
        <label for="email" class="auth-form__label">
          <VisibleText>Email</VisibleText>
          <input
            id="email"
            ref="email"
            :value="email"
            @input="update('email', $event.target.value)"
            type="text"
            class="auth-form__input"
            required
            aria-required="true"
            :aria-invalid="!!errors.length"
            @blur="emailBlurCheck"
          />
        </label>
      </template>

      <template
        v-slot:password="{
          password,
          errors,
          passwordInputCheck,
          passwordBlurCheck,
          update
        }"
      >
        <label for="password" class="auth-form__label">
          <VisibleText>Password</VisibleText>
          <input
            id="password"
            :value="password"
            @input="update('password', $event.target.value)"
            type="password"
            class="auth-form__input"
            required
            aria-required="true"
            :aria-invalid="!!errors.length"
            aria-describedby="password-desc"
            @blur="passwordBlurCheck"
          />
          <span
            id="password-desc"
            v-if="
              !errors.includes(
                'Passwords should be at least 10 characters long'
              )
            "
            :class="{ valid: password.length >= 10 }"
          >
            <VisibleText
              >Passwords should be at least 10 characters long
            </VisibleText>
          </span>
        </label>
      </template>

      <template
        v-slot:passwordConf="{
          passwordConf,
          errors,
          passwordConfBlurCheck,
          update
        }"
      >
        <label for="password-conf" class="auth-form__label">
          <VisibleText>Confirm Password</VisibleText>
          <input
            id="password-conf"
            ref="password-conf"
            :value="passwordConf"
            @input="update('passwordConf', $event.target.value)"
            type="password"
            class="auth-form__input"
            required
            aria-required="true"
            :aria-invalid="!!errors.length"
            @blur="passwordConfBlurCheck"
          />
        </label>
      </template>
    </Register>
    <p>
      <VisibleText>Already have an account? Use our </VisibleText>
      <router-link to="/login">
        <VisibleText>login page</VisibleText>
      </router-link>
    </p>
  </div>
</template>

<script>
import { Register } from "@pigeonline/core"

export default {
  name: "register",
  components: { Register },
  data() {
    return {
      email: null,
      c: null,
      role: null
    }
  },
  async created() {
    this.email = this.$route.query.email
    this.c = this.$route.query.c

    // check if c is valid else redirect to login
    if (this.c && !this.c.match(/^[0-9a-fA-F]{24}$/)) {
      this.$router.push({
        name: "login"
      })
    }
  },
  mounted() {
    if (this.email) {
      this.$refs.register.update("email", this.email)
      this.$refs.email.disabled = true
      this.$refs.email.classList.add("uneditable")
    }
  },
  methods: {
    async registered(registered) {
      // redirect to register verification page if is_email_verified = false
      if (!registered.is_email_verified) {
        this.$router.push({
          path: "register/verify",
          query: {
            email: registered.email,
            status: "account_created"
          }
        })
      } else {
        // login user automatically
        await this.$pigeonline.login(
          this.$refs.register.$data["email"],
          this.$refs.register.$data["password"]
        )
        this.$router.push(
          {
            path: this.$route.query.redirect || "account/profile"
          },
          () => this.$router.go(0)
        )
      }
    }
  }
}
</script>
