import { BaseService, axios } from "@pigeonline/core"

export default class ProfilesService extends BaseService {
  constructor(core) {
    super(core)

    // define the api url
    this.URL = `${this.BASE_URL}/rauth`
  }

  async users() {
    try {
      const response = await axios.get(
        `${this.URL}/users/`,
        this.getAuthHeader()
      )
      return response.data
    } catch (e) {
      throw new Error("profilesService.js:users " + e.message)
    }
  }
}
