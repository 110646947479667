<template>
  <UITappable ref="tappable" :options="options" @ban="ban" @similar="similar">
    <span>{{ value }}</span>
    <slot :ban="ban" :similar="similar" :remove="remove" />
  </UITappable>
</template>

<script>
import UITappable from "../UI/Tappable.vue"

export default {
  name: "KeywordsInputKeywordTappable",
  components: {
    UITappable
  },
  props: {
    value: {
      type: String,
      required: true
    },

    alsoFoundRef: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      options: {
        ban: {
          text: "ban"
        },
        similar: {
          text: "similar"
        }
      }
    }
  },
  computed: {
    tappableEl() {
      return this.$refs["tappable"]
    },
    alsoFoundEl() {
      return this.$parent.$refs[this.alsoFoundRef]
    }
  },
  methods: {
    ban(keyword) {
      this.tappableEl.setSelected(false)
      this.$root.$emit("ban-keyword", keyword)
    },

    similar(keyword) {
      this.alsoFoundEl.setKeyword(keyword)
      this.alsoFoundEl.setKeywordRef(this.tappableEl)
      this.alsoFoundEl.setVisible(true)
    },

    remove(index) {
      this.$parent.removeTag(index)

      // blur focused element
      document.activeElement.blur()
    }
  }
}
</script>
