<template>
  <article id="custom-topic-model-editor" class="section__content">
    <router-link :to="{ name: 'customTopicModel' }" class="btn-back">
      <SvgIconDecorative icon="arrowLeft" />
      <VisibleText>
        Back <span class="sr-only"> to custom topic model groups </span>
      </VisibleText>
    </router-link>
    <template v-if="fetchingComplete">
      <div class="custom-topic-model-editor__header">
        <InlineEditTextInput
          label="topic model group name"
          input-id="topic-model-group-name-input"
          input-name="topic-model-group-name"
          :input-value="topicModelGroup.name"
          @save="saveName"
        >
          <h2>
            {{ topicModelGroup.name }}
          </h2>
        </InlineEditTextInput>
      </div>

      <!--------------------------------------------
      |
      | TOPIC GROUP DETAILS
      |
      --------------------------------------------->
      <CustomTopicModelEditorDetails :topic-model-group="topicModelGroup" />
    </template>

    <!--------------------------------------------
    |
    | TOPIC MODEL OPTIONS
    |
    --------------------------------------------->
    <CustomTopicModelEditorOptions
      :selected-topic-models="selectedTopicModels"
      @add-theme="isAddThemeVisible = true"
      @deleted-themes="deletedThemes"
      @deselect="selectedTopicModels = []"
      @refresh-topic-models="refreshTopicModels"
      @select="selectAll"
      @toggle-editing="readOnlyTable = !readOnlyTable"
    />

    <!--------------------------------------------
    |
    | NEW TOPIC MODEL
    |
    --------------------------------------------->
    <CustomTopicModelEditorNewTheme
      :topic-model-group="topicModelGroup"
      v-if="isAddThemeVisible"
      @close="isAddThemeVisible = false"
      @new-theme-created="newThemeCreated"
    />

    <!--------------------------------------------
    |
    | THEMES GRID
    |
    --------------------------------------------->
    <ListGrid
      :custom-grid-style="{
        gridTemplateColumns: '4rem repeat(3, minmax(auto, 1fr))'
      }"
      :headers="headers"
      :list="topicModels"
      :key="themesGridKey"
    >
      <template slot-scope="{ item, index }">
        <ListGridItem col="0" :headers="headers" :row="index">
          <span class="form-checkbox-label">
            <input
              type="checkbox"
              tabindex="-1"
              :aria-label="item.name"
              :value="item._id.$oid"
              v-model="selectedTopicModels"
            />
          </span>
        </ListGridItem>
        <ListGridItem col="1" :headers="headers" :row="index">
          <ThemeName
            :oid="item._id.$oid"
            :read-only="readOnlyTable"
            :theme-name="item.name"
          />
        </ListGridItem>
        <ListGridItem col="2" :headers="headers" :row="index">
          <Keywords
            :oid="item._id.$oid"
            :keywords="item.keywords"
            :read-only="readOnlyTable"
          />
        </ListGridItem>
        <ListGridItem col="3" :headers="headers" :row="index">
          <ExampleQuestions
            :oid="item._id.$oid"
            :example-questions="item.example_questions"
            :read-only="readOnlyTable"
          />
        </ListGridItem>
      </template>
    </ListGrid>
  </article>
</template>

<script>
// Components
import CustomTopicModelEditorDetails from "./CustomTopicModelEditorDetails.vue"
import CustomTopicModelEditorNewTheme from "./CustomTopicModelEditorNewTheme.vue"
import CustomTopicModelEditorOptions from "./CustomTopicModelEditorOptions.vue"
import InlineEditTextInput from "@/components/UI/InlineEditTextInput.vue"
import ListGrid from "@/components/UI/ListGrid.vue"
import ListGridItem from "@/components/UI/ListGridItem.vue"
import SvgIconDecorative from "@/components/UI/Svg/SvgIconDecorative.vue"

import ThemeName from "./Components/ThemeName.vue"
import Keywords from "./Components/Keywords.vue"
import ExampleQuestions from "./Components/ExampleQuestions.vue"

// Services
import { fetchGroup, fetchTopicModels } from "../Utils/fetch.js"

export default {
  name: "CustomTopicModelEditor",
  components: {
    CustomTopicModelEditorDetails,
    CustomTopicModelEditorNewTheme,
    CustomTopicModelEditorOptions,
    InlineEditTextInput,
    ListGrid,
    ListGridItem,
    SvgIconDecorative,
    ThemeName,
    Keywords,
    ExampleQuestions
  },
  data() {
    return {
      fetchingComplete: false,
      headers: ["", "Theme Name", "Keywords", "Example Questions"],
      isAddThemeVisible: false,
      readOnlyTable: true,
      selectedTopicModels: [],
      topicModels: [],
      topicModelGroup: {},
      themesGridKey: this.$pigeonline.createUUID()
    }
  },
  beforeRouteEnter(to, from, next) {
    Promise.all([fetchGroup(to.params.id), fetchTopicModels(to.params.id)])
      .then(topicModelData => {
        next(vm => vm.setData(null, ...topicModelData))
      })
      .catch(err => next(vm => vm.setData(err)))
  },
  beforeRouteUpdate(to, from, next) {
    this.fetchingComplete = false
    this.topicModels = []
    this.topicModelGroup = {}
    Promise.all([fetchGroup(to.params.id), fetchTopicModels(to.params.id)])
      .then(topicModelData => {
        next()
        this.setData(null, ...topicModelData)
      })
      .catch(err => {
        next()
        this.setData(err)
      })
  },
  methods: {
    onError() {
      this.setConfirmText({
        btn: "okay",
        title: "Error Occured",
        message:
          "Sorry, there was a problem loading the page. Please try again."
      })
      this.setConfirmSourceComponent("topicModelEditor")
      this.setConfirmType("error")
      this.setConfirmIsVisible(true)
    },
    newThemeCreated(theme) {
      this.topicModels.unshift(theme)
      this.topicModelGroup.addNewTheme(theme._id.$oid)
      this.themesGridKey = this.$pigeonline.createUUID()
    },
    saveName(name) {
      this.topicModelGroup.name = name
      this.$pigeonline.projects.update(this.topicModelGroup)
    },
    selectAll() {
      this.selectedTopicModels = this.topicModels.map(topic => topic._id.$oid)
    },
    setData(err, topicModelGroup = [], topicModels = {}) {
      if (err) {
        this.onError()
      } else {
        this.topicModelGroup = topicModelGroup
        this.topicModels = topicModels
        this.fetchingComplete = true
      }
    },
    async deletedThemes() {
      this.selectedTopicModels = []
      this.topicModels = await fetchTopicModels(this.$route.params.id)
      this.topicModelGroup.themes = this.topicModels.map(
        model => model._id.$oid
      )
      this.themesGridKey = this.$pigeonline.createUUID()
    },
    async refreshTopicModels() {
      this.topicModels = await fetchTopicModels(this.$route.params.id)
    }
  },
  watch: {
    topicModelGroup: {
      deep: true,
      handler: function(value) {
        if (value && Object.keys(value).length > 0)
          this.$pigeonline.projects.update(value)
      }
    }
  }
}
</script>
