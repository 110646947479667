<template>
  <div class="profile__content">
    <p class="profile__description sub-title">
      <VisibleText>Please complete your profile information.</VisibleText>
    </p>
    <form class="profile__form" @submit.prevent="save">
      <label for="fullname" class="profile__form-label">
        <VisibleText>Full Name </VisibleText>*
        <input
          id="fullname"
          v-model="vModels.fullname"
          type="text"
          class="profile__form-input"
          required
          aria-required="true"
          autocomplete="off"
        />
      </label>
      <label for="position" class="profile__form-label">
        <VisibleText>Position</VisibleText>
        <input
          id="position"
          v-model="vModels.position"
          type="text"
          class="profile__form-input"
          aria-required="true"
          autocomplete="off"
        />
      </label>
      <button class="profile__form-btn" type="submit">
        <VisibleText>Next</VisibleText>
      </button>
    </form>
  </div>
</template>

<script>
import UserMixin from "@/utils/mixins/userMixin"

export default {
  name: "accountNextStepsProfile",
  mixins: [UserMixin],
  data() {
    return {
      vModels: {
        fullname: "",
        position: ""
      }
    }
  },
  mounted() {
    this.$watch(
      "profile",
      function(newVal) {
        if (!newVal) return
        this.vModels.fullname = newVal.fullname || ""
        this.vModels.position = newVal.position || ""
      },
      { deep: true, immediate: true }
    )
  },
  methods: {
    save: async function() {
      this.setLoading(true)
      try {
        const profile = await this.$pigeonline.profile.save({
          fullname: this.vModels.fullname,
          position: this.vModels.position
        })
        this.setProfile(profile)
      } catch (e) {
        alert(
          "An error occured when processing your request. Please try again later"
        )
        this.setLoading(false)
        return
      }
      this.setLoading(false)
      this.$router.push({ name: "accountNextStepsAccountType" })
    }
  }
}
</script>
