var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{staticClass:"project-tabs",attrs:{"aria-label":"project"}},[_c('ul',{staticClass:"project-tabs__list"},_vm._l((_vm.items),function(item){return _c('li',{key:item,class:[
        'project-tabs__list-item',
        {
          'project-tabs__list-item-active': _vm.$route.name
            .toLowerCase()
            .includes(item)
        }
      ]},[_c('router-link',{staticClass:"project-tabs__list-item-link",attrs:{"to":_vm.getPath(item)}},[_c('VisibleText',{staticClass:"project-tabs__list-item-text"},[_vm._v(" "+_vm._s(item)+" ")])],1)],1)}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }