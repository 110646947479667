import Vue from "vue"
import Router from "vue-router"

// Components
import ErrorView from "@/views/ErrorView.vue"
import Login from "@/views/Login.vue"

import Register from "@/views/Register.vue"
import RegisterMain from "@/components/Register/RegisterMain.vue"
import RegisterVerify from "@/components/Register/RegisterVerify.vue"
import RegisterDemo from "@/components/Register/RegisterDemo.vue"

import ResetPassword from "@/views/ResetPassword.vue"

import Account from "@/views/Account.vue"
import AccountProfile from "@/components/Account/AccountProfile.vue"
import AccountAdminSendInvites from "@/components/Account/AccountAdmin/SendInvites.vue"
import AccountAdminApproveUsers from "@/components/Account/AccountAdmin/ApproveUsers.vue"
import AccountNextSteps from "@/components/Account/AccountNextSteps.vue"
import AccountNextStepsProfile from "@/components/Account/AccountNextStepsProfile.vue"
import AccountNextStepsAccountType from "@/components/Account/AccountNextStepsAccountType.vue"
import AccountNextStepsOrganization from "@/components/Account/AccountNextStepsOrganization.vue"
import AccountNextStepsCongratulations from "@/components/Account/AccountNextStepsCongratulations.vue"

import Landing from "@/views/Landing.vue"
import Library from "@/views/Library.vue"
import Projects from "@/views/Projects.vue"
import Project from "@/views/Project.vue"
import ProjectData from "@/components/Project/ProjectData/ProjectData.vue"
import ProjectAnalysis from "@/components/Project/ProjectAnalysis/ProjectAnalysis.vue"
import ProjectAnalysisDrivers from "@/components/Project/ProjectAnalysis/ProjectAnalysisDrivers/ProjectAnalysisDrivers.vue"
import ProjectAnalysisBenchmarking from "@/components/Project/ProjectAnalysis/ProjectAnalysisBenchmarking/ProjectAnalysisBenchmarking.vue"
import ProjectAnalysisPerformance from "@/components/Project/ProjectAnalysis/ProjectAnalysisPerformance/ProjectAnalysisPerformance.vue"
import ProjectAnalysisText from "@/components/Project/ProjectAnalysis/ProjectAnalysisText/ProjectAnalysisText.vue"
import ProjectReport from "@/components/Project/ProjectReport/ProjectReport.vue"

import Datasets from "@/views/Datasets.vue"
import Dataset from "@/views/Dataset.vue"

import TranslationManager from "@/views/TranslationManager.vue"

import BenchmarkManager from "@/views/BenchmarkManager.vue"
import BenchmarkManagerGroups from "@/components/BenchmarkManager/BenchmarkManagerGroups.vue"
import BenchmarkManagerEditor from "@/components/BenchmarkManager/BenchmarkManagerEditor/BenchmarkManagerEditor.vue"

import CustomTopicModel from "@/views/CustomTopicModel.vue"
import CustomTopicModelGroups from "@/components/CustomTopicModel/CustomTopicModelGroups/CustomTopicModelGroups.vue"
import CustomTopicModelEditor from "@/components/CustomTopicModel/CustomTopicModelEditor/CustomTopicModelEditor.vue"

// Pigeonline modules
import { PigeonDoc } from "@pigeonline/pigeondoc"

Vue.use(Router)

const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "home",
      redirect: { name: "landing" }
    },
    {
      path: "/about",
      name: "landing",
      component: Landing,
      meta: {
        noRedirect: true
      }
    },
    {
      path: "/library*",
      name: "library",
      component: Library,
      meta: {
        noRedirect: true
      }
    },
    {
      path: "/login",
      name: "login",
      component: Login,
      props: true,
      meta: {
        redirectIfLoggedIn: true
      }
    },
    {
      path: "/register",
      component: Register,
      meta: {
        redirectIfLoggedIn: true
      },
      children: [
        {
          path: "",
          name: "register",
          component: RegisterMain
        },
        {
          path: "verify",
          name: "registerVerify",
          component: RegisterVerify
        },
        {
          path: "demo",
          name: "registerDemo",
          component: RegisterDemo
        }
      ]
    },
    {
      path: "/reset-password",
      component: ResetPassword,
      meta: {
        redirectIfLoggedIn: true
      }
    },
    {
      path: "/account",
      component: Account,
      meta: {
        requiresAuth: true
      },
      children: [
        {
          path: "",
          name: "account",
          beforeEnter: (to, from, next) => next({ name: "accountProfile" })
        },
        {
          path: "profile",
          name: "accountProfile",
          component: AccountProfile
        },
        {
          path: "send-invites",
          name: "accountAdminSendInvites",
          component: AccountAdminSendInvites
        },
        {
          path: "approve-users",
          name: "accountAdminApproveUsers",
          component: AccountAdminApproveUsers
        },
        {
          path: "next-steps",
          component: AccountNextSteps,
          children: [
            {
              path: "",
              name: "accountNextSteps",
              beforeEnter: (to, from, next) =>
                next({ name: "accountNextStepsProfile" })
            },
            {
              path: "profile",
              name: "accountNextStepsProfile",
              component: AccountNextStepsProfile
            },
            {
              path: "account-type",
              name: "accountNextStepsAccountType",
              component: AccountNextStepsAccountType
            },
            {
              path: "organization",
              name: "accountNextStepsOrganization",
              component: AccountNextStepsOrganization
            },
            {
              path: "congratulations",
              name: "accountNextStepsCongratulations",
              component: AccountNextStepsCongratulations
            }
          ]
        }
      ]
    },
    {
      path: "/projects",
      name: "projects",
      component: Projects,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/project/:projid",
      component: Project,
      props: true,
      meta: {
        requiresAuth: true
      },
      children: [
        {
          path: "",
          name: "data",
          props: true,
          component: ProjectData
        },
        {
          path: "analysis",
          component: ProjectAnalysis,
          children: [
            {
              path: "",
              name: "projectAnalysis"
            },
            {
              path: "drivers",
              name: "projectAnalysisDrivers",
              component: ProjectAnalysisDrivers
            },
            {
              path: "performance",
              name: "projectAnalysisPerformance",
              component: ProjectAnalysisPerformance
            },
            {
              path: "benchmarking",
              name: "projectAnalysisBenchmarking",
              component: ProjectAnalysisBenchmarking
            },
            {
              path: "text",
              name: "projectAnalysisText",
              component: ProjectAnalysisText
            }
          ]
        },
        {
          path: "report/empty",
          name: "report",
          props: true,
          component: ProjectReport
        },
        {
          path: "report/new",
          name: "report-new",
          component: PigeonDoc,
          props: route => {
            return {
              savedHandler: document => {
                router.push(
                  `/project/${route.params.projid}/report/${document.id}`
                )
              },
              value: "value" in route.params ? route.params.value : null
            }
          }
        },
        {
          path: "report/:id",
          name: "report-edit",
          component: PigeonDoc,
          props: route => {
            return {
              id: "id" in route.params ? route.params.id : null,
              pdfDownload: false
            }
          }
        }
      ]
    },
    {
      path: "/datasets",
      name: "datasets",
      component: Datasets,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/dataset/:id?",
      name: "dataset",
      component: Dataset,
      props: true,
      meta: {
        requiresAuth: true
      },
      beforeEnter: (to, form, next) => {
        if (
          !to.params.id &&
          !router.app.$pigeonline.permissions.hasPermission("create-dataset")
        ) {
          next({ path: "/datasets" })
        } else next()
      }
    },
    {
      path: "/benchmark-manager",
      component: BenchmarkManager,
      meta: {
        requiresAuth: true
      },
      beforeEnter: (to, form, next) => {
        if (
          router.app.$pigeonline.permissions.hasPermission(
            "benchmarking-manager"
          )
        ) {
          next()
        } else next({ path: "/projects" })
      },
      children: [
        {
          path: ":id",
          name: "benchmarkGroupEdit",
          component: BenchmarkManagerEditor
        },
        {
          path: "",
          name: "benchmarkManager",
          component: BenchmarkManagerGroups
        }
      ]
    },
    {
      path: "/translation-manager",
      component: TranslationManager,
      name: "translationManager",
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/custom-topic-model",
      component: CustomTopicModel,
      meta: {
        requiresAuth: true
      },
      children: [
        {
          path: ":id",
          name: "customTopicModelEdit",
          component: CustomTopicModelEditor
        },
        {
          path: "",
          name: "customTopicModel",
          component: CustomTopicModelGroups
        }
      ]
    },
    {
      path: "/logout",
      name: "logout",
      meta: {
        requiresAuth: true
      },
      beforeEnter: async function(to, from, next) {
        if (router.app.$pigeonline.userIsAuthenticated()) {
          await router.app.$pigeonline.logout()
          next({ name: "home" })
        } else {
          next()
        }
      }
    },
    {
      path: "/500",
      name: "serverError",
      component: ErrorView
    },
    {
      path: "*",
      name: "notFound",
      component: ErrorView
    }
  ]
})

router.beforeEach((to, from, next) => {
  // auth required
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // not logged in
    if (!router.app.$pigeonline.userIsAuthenticated()) {
      if (Vue.prototype.$theme.theme.name == "iccs") {
        next({ name: "login", query: { redirect: to.path } })
      } else {
        next({ name: "landing", query: { redirect: to.path } })
      }
    } else {
      next()
    }
  }
  // auth not required but direct to projects if already logged in
  if (to.matched.some(record => record.meta.redirectIfLoggedIn)) {
    if (router.app.$pigeonline.userIsAuthenticated()) {
      next({ name: "projects" })
    } else if (
      to.name !== "login" &&
      Vue.prototype.$theme.theme.name == "iccs"
    ) {
      next({ name: "login" })
    } else {
      next()
    }
  }
  // No redirect needed, except for white-labels
  if (to.matched.some(record => record.meta.noRedirect)) {
    if (to.name === "landing" && Vue.prototype.$theme.theme.name == "iccs") {
      next({ name: "login" })
    } else {
      next()
    }
  }
})

export default router
