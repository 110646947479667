<template>
  <div class="search__container">
    <div class="search__bar-wrapper">
      <div class="search__bar">
        <label for="search-input" class="search__bar-input-label">
          <span class="sr-only">
            <VisibleText>Search keywords and press enter</VisibleText>
          </span>
        </label>
        <input
          ref="search-input"
          class="search__bar-input"
          id="search-input"
          type="text"
          name="searchBar__generic"
          :placeholder="visiblePlaceholder"
          v-model="searchString"
          v-on:keyup.enter="handleSearch"
          :disabled="search.isSearching"
        />
        <label
          class="search__bar-advanced-chkbox-label"
          for="search-advanced-chkbox"
        >
          <input
            id="search-advanced-chkbox"
            class="search__bar-advanced-chkbox"
            type="checkbox"
            v-model="advancedSearchEnabled"
            :disabled="search.isSearching"
          />
          <VisibleText>Advanced search</VisibleText>
        </label>
      </div>
      <p class="search__searching-text" v-if="search.isSearching">
        <VisibleText>Searching</VisibleText>...
        <span v-if="advancedSearchEnabled">
          <VisibleText
            >Please note that performing advanced search can take longer than
            usual.
          </VisibleText>
        </span>
        <span class="search__searching-spinner spinner"></span>
      </p>
      <AlsoFound
        ref="search-also-found"
        class="search__also-found"
        @save="saveAlsoFoundKeyword"
      >
        <button class="also-found__save-all-btn" @click="saveAlsoFound">
          <VisibleText>Save all</VisibleText>
        </button>
      </AlsoFound>
    </div>
    <div class="search__rounded-pills-wrapper">
      <div
        class="search__rounded-pills search-keywords"
        v-if="isSearchPillsVisible"
      >
        <span class="search__rounded-pills-label">
          <VisibleText>Search keywords</VisibleText>:
        </span>
        <RoundedPill
          icon="search"
          :value="search.searchString"
          :css-styles="{
            backgroundColor: 'yellow',
            color: 'var(--base-colour)'
          }"
          @remove="resetSearch"
          v-if="isSearchMode"
        />
      </div>
      <div class="search__rounded-pills filter-by" v-if="isFilterPillsVisible">
        <span class="search__rounded-pills-label">
          <VisibleText>Filter by</VisibleText>:
        </span>
        <!-- REPLACE ICON BELOW WITH FILTER ICON -->
        <RoundedPill
          icon="analysis"
          :value="themes[selectedThemeIndex].name"
          :css-styles="{ backgroundColor: 'var(--secondary)' }"
          @remove="setSelectedThemeIndex(-1)"
          v-if="isThemeSelected"
        />
        <RoundedPill
          icon="list"
          value="Chart selection"
          :css-styles="{
            backgroundColor: '#56b4e9'
          }"
          @remove="setSelectedResponseIds([])"
          v-if="isVisFilterActivated"
        />
      </div>
    </div>
    <p class="search__result-text" v-if="!search.isSearching">
      <span v-html="resultText"></span>
      <a href="" v-if="isSearchMode" @click.prevent="reset()">
        <VisibleText>Reset search</VisibleText>
      </a>
    </p>
    <SaveAlsoFoundModal
      type="keyword"
      uid="save-also-found-modal"
      :selection="alsoFoundModal.selection"
      :autoCreateTheme="alsoFoundModal.autoCreateTheme"
      :autoCreateThemeTitle="alsoFoundModal.autoCreateThemeTitle"
      :autoCreateThemeKeywords="alsoFoundModal.autoCreateThemeKeywords"
      :show="isSaveAlsoFoundModalVisible"
      @closeModal="closeModal"
    />
  </div>
</template>

<script>
// Components
import AlsoFound from "./AlsoFound.vue"
import RoundedPill from "../UI/RoundedPill.vue"
import SaveAlsoFoundModal from "../Modals/ThemesAdd.vue"

// Mixins
import AnalysisTextMixin from "@/utils/mixins/analysisTextMixin.js"

export default {
  name: "Search",
  mixins: [AnalysisTextMixin],
  components: {
    AlsoFound,
    RoundedPill,
    SaveAlsoFoundModal
  },
  data() {
    return {
      searchString_: "",
      advancedSearchEnabled: false,
      isSaveAlsoFoundModalVisible: false,
      alsoFoundModal: {
        autoCreateTheme: false,
        autoCreateThemeTitle: "",
        autoCreateThemeKeywords: [],
        selection: null
      }
    }
  },
  computed: {
    searchString: {
      get() {
        return this.searchString_
      },
      set(val) {
        this.searchString_ = val
        if (val == "") {
          this.setSearch({
            searchString: "",
            isSearching: false,
            searchResults: []
          })
        }
      }
    },
    $alsoFound: function() {
      return this.$refs["search-also-found"]
    },
    visiblePlaceholder: function() {
      return this.checkTranslationCache(
        "Enter a search keyword and press enter.",
        "searchBar__generic",
        this.$store.getters.getLanguage
      )
    },
    isSearchPillsVisible: function() {
      return this.isSearchMode
    },
    isFilterPillsVisible: function() {
      return this.isThemeSelected || this.isVisFilterActivated
    }
  },
  created() {
    // search keyword event handler
    this.$root.$on("search-keyword", this.handleSearch.bind(this))

    // focus search input
    this.focusSearchInput = this.focusSearchInput.bind(this)
  },
  beforeDestroy() {
    this.$root.$off("search-keyword", this.handleSearch.bind(this))
  },
  methods: {
    async apiSearch(searchString, searchType = "simple") {
      if (!searchString) return []

      let searchStrategy
      if (searchType == "advanced") {
        searchStrategy = this.TEXT_SERVICE.searchAdvanced.bind(
          this.TEXT_SERVICE
        )
      } else {
        searchStrategy = this.TEXT_SERVICE.searchSimple.bind(this.TEXT_SERVICE)
      }

      this.setShowResponseListLoadingSpinner(true)
      try {
        const response = await searchStrategy({
          search_string: searchString,
          data_set_id: this._datasetId,
          project_id: this.project.id,
          client_question_id: this.selectedTextQuestion._id.$oid
        })
        return response.filter(el => el[1] > 0) // filter with scores > 0
      } catch (e) {
        throw new Error("Search.vue:apiSearch " + e.message)
      } finally {
        this.setShowResponseListLoadingSpinner(false)
      }
    },
    async handleSearch(keyword = null, focusSearch = false) {
      this.reset()
      if (keyword && typeof keyword === "string") {
        this.searchString = keyword.trim().toLowerCase()
      }
      this.setSearch({ searchString: this.searchString.trim().toLowerCase() })

      if (!this.searchString && !keyword) return
      this.setSearch({ isSearching: true })

      // focus search input
      if (focusSearch) this.focusSearchInput()

      // handle search
      if (this.advancedSearchEnabled) {
        this.setSearch({
          searchResults: await this.apiSearch(
            this.searchString.toLowerCase(),
            "advanced"
          )
        })
      } else {
        this.setSearch({
          searchResults: await this.apiSearch(this.searchString.toLowerCase())
        })
      }

      this.setSearch({ isSearching: false })

      // fetch word associations
      if (!this.search.excludeAssociations) {
        this.$alsoFound.setKeyword(this.searchString.toLowerCase())
      }
    },
    focusSearchInput() {
      if (this.$refs["search-input"]) {
        document.scrollingElement.scrollTop +=
          this.$refs["search-input"].getBoundingClientRect().top - 100
        this.$refs["search-input"].focus()
      }
    },
    reset() {
      this.resetSearch()
      this.$alsoFound.setVisible(false)
    },

    /* Save also found modal */
    saveAlsoFound() {
      this.alsoFoundModal.autoCreateTheme = true
      this.alsoFoundModal.autoCreateThemeTitle = this.search.searchString
      this.alsoFoundModal.autoCreateThemeKeywords = [
        this.search.searchString,
        ...this.$alsoFound.similar
      ]
      this.alsoFoundModal.selection = null
      this.$nextTick(() => (this.isSaveAlsoFoundModalVisible = true))
    },
    saveAlsoFoundKeyword(keyword) {
      this.alsoFoundModal.autoCreateTheme = false
      this.alsoFoundModal.autoCreateThemeTitle = ""
      this.alsoFoundModal.autoCreateThemeKeywords = []
      this.alsoFoundModal.selection = {
        content: keyword
      }
      this.$nextTick(() => (this.isSaveAlsoFoundModalVisible = true))
    },
    closeModal() {
      this.isSaveAlsoFoundModalVisible = false
    }
  },
  watch: {
    "search.searchString": function(val) {
      if (!val || val == "") {
        this.searchString_ = val
        this.$alsoFound.reset()
        return
      }
      this.searchString_ = val
    },
    selectedThemeIndex: function() {
      this.reset()
    }
  }
}
</script>
