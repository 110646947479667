<template>
  <div class="accordion-wrapper">
    <div :class="['accordion-header', { expanded: isExpanded }]">
      <component class="accordion-title" :is="titleElementType">
        <button
          class="accordion-title-btn"
          type="button"
          :aria-expanded="isExpanded ? 'true' : 'false'"
          @click.stop="toggleContent"
        >
          <SvgIconDecorative
            :icon="isExpanded ? icon.expanded : icon.collapsed"
            :class="[iconLocation, 'icon']"
          />
          <slot name="heading" :isExpanded="isExpanded"></slot>
        </button>
      </component>
      <slot name="additionalDetails"></slot>
    </div>
    <div class="accordion-content" v-show="isExpanded">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import SvgIconDecorative from "@/components/UI/Svg/SvgIconDecorative.vue"

export default {
  components: { SvgIconDecorative },
  name: "Accordion",
  props: {
    expand: {
      default: false,
      type: Boolean
    },
    titleElementType: {
      default: () => "h2",
      type: String
    },
    icon: {
      default: () => {
        return {
          expanded: "arrowUp",
          collapsed: "arrowDown"
        }
      },
      type: Object
    },
    iconLocation: {
      default: () => "left",
      type: String
    }
  },
  data() {
    return {
      isExpanded: false
    }
  },
  methods: {
    toggleContent() {
      this.isExpanded = !this.isExpanded
      this.$emit("toggled", this.isExpanded)
    }
  },
  watch: {
    expand: {
      immediate: true,
      handler: function(val) {
        this.isExpanded = val
      }
    }
  }
}
</script>
