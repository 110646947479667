<template>
  <Card subTitleTag="span">
    <template #title>
      {{ project.name }}
      <span class="project-status" v-if="projectStatus"
        >({{ projectStatus }})</span
      >
    </template>
    <template #subtitle>
      {{ project.last_modified_on.$date | formatDate(setLanguage) }}
    </template>

    <div class="card__text-content">
      <ProjectsItemNav :project="project" />
      <ProjectsItemProgress :project="project" v-if="datasetNotDeleted" />
      <ProjectsItemDatasetDeleted v-else />
    </div>
  </Card>
</template>

<script>
// Components
import Card from "@/components/UI/Card.vue"
import ProjectsItemDatasetDeleted from "./ProjectsItemDatasetDeleted.vue"
import ProjectsItemNav from "./ProjectsItemNav.vue"
import ProjectsItemProgress from "./ProjectsItemProgress.vue"

// Models
import BenchmarkingProject from "@/models/BenchmarkingProjectModel.js"

export default {
  name: "ProjectsGrid",
  components: {
    Card,
    ProjectsItemDatasetDeleted,
    ProjectsItemNav,
    ProjectsItemProgress
  },
  props: {
    project: { type: BenchmarkingProject }
  },
  computed: {
    datasetNotDeleted() {
      const datasetIds = this.$store.getters[
        "globalModule/getDatasetProjects"
      ].map(dataset => dataset.dataset_id)
      return this.project.datasets.every(dataset =>
        datasetIds.includes(dataset)
      )
    },
    projectStatus() {
      const id = this.$store.getters["user/getProfile"].django_ref_id
      const email = this.$pigeonline.getUserEmail()

      const shared =
        this.project.shared &&
        Array.isArray(this.project.shared) &&
        this.project.shared.map(r => this.bkCompatiblePk(r))
      if (shared && (shared.includes(id) || shared.includes(email))) {
        return "shared"
      }

      const access = this.project.access_type
      if (access && access == "public__all") return "public"
      if (access && access == "public__org") return "organization"
      if (access && access == "demo") return "demo"

      return null
    },
    setLanguage() {
      return this.$store.getters.getLanguage
    }
  },
  methods: {
    bkCompatiblePk(r) {
      return ["string", "number"].includes(typeof r)
        ? r
        : typeof r.pk !== "undefined"
        ? r.pk
        : typeof r.id !== "undefined"
        ? r.id
        : -1
    }
  }
}
</script>
