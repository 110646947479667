import logo from "@/styles/themes/eiryam/eiryam-logo.png"
export default {
  name: "eiryam",
  pageTitle: "Eiryam Analytics",
  colours: {
    "primary-brand-ui-colour": "#333333",
    "secondary-brand-ui-colour": "#be202e",
    primary: "#be202e",
    secondary: "#333333",
    "secondary-light": "#999999"
  },
  images: {
    favicon: require("@/styles/themes/eiryam/favicon.png"),
    header: logo
  }
}
