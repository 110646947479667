<template>
  <main class="register__wrapper">
    <div class="register__container">
      <router-view></router-view>
    </div>
  </main>
</template>

<script>
export default {
  name: "register",
  created() {
    document.title = `Register | ${this.$theme.theme.pageTitle}`
  }
}
</script>
