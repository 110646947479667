<template functional>
  <div
    :class="[
      `message__block--${props.messageType}`,
      `message__block`,
      data.class,
      data.staticClass
    ]"
  >
    <p class="message__block__details">
      <span class="icon-wrapper">
        <svg class="svg-icon icon" focusable="false" role="img">
          <title>
            {{ $options.methods.translate(props.messageType, "MessageBlock") }}
          </title>
          <use :xlink:href="`#${props.messageType}`" aria-hidden="true" />
        </svg>
      </span>
      <VisibleText class="title" v-if="props.title">
        {{ props.title }}
      </VisibleText>
      <span>
        <slot></slot>
      </span>
    </p>
  </div>
</template>

<script>
export default {
  props: {
    messageType: {
      default: "information",
      type: String
    },
    title: {
      default: null,
      type: String
    }
  }
}
</script>
