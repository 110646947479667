<template>
  <Modal
    class="project-analysis-text-modal"
    uid="modal-themes-edit"
    header-tag="h3"
    :ref="modalRef"
    :show="show"
    :reset-listener="resetListener"
    @close="closeModal"
  >
    <template #header class="modal-title">
      <VisibleText>Edit themes</VisibleText>
    </template>
    <ThemeList
      :ref="themeListRef"
      type="keyword"
      :themes="themeList"
      :should-focus-last-theme="shouldFocusLastTheme"
      :key="updateKey"
      @update-theme-title="
        updateThemeTitle(themeList[$event.index], $event.title)
      "
      @updated="unsavedChanges = true"
    />
    <template #footer class="modal-footer">
      <div class="modal-footer__options">
        <button
          class="btn-default"
          role="button"
          @click.stop="save"
          :disabled="!unsavedChanges || showSpinner"
        >
          <VisibleText>Save changes</VisibleText>
        </button>
        <button
          class="btn-create btn-secondary"
          role="button"
          @click.stop="createTheme"
        >
          <VisibleText>Create new theme</VisibleText>
        </button>
      </div>
    </template>
  </Modal>
</template>

<script>
// Mixins
import ThemeListMixin from "../Mixins/themeListMixin.js"

export default {
  name: "ThemesEdit",
  mixins: [ThemeListMixin],
  props: {
    show: {
      default: () => false,
      type: Boolean
    }
  }
}
</script>
