<template>
  <div>
    <Table
      class="datasets__table"
      :caption="'Datasets'"
      :hide-caption="true"
      :row-data="filteredDatasets"
      :column-headers="columnHeaders"
      :sort-config="sortConfig"
      @sort="sort"
    >
      <template slot-scope="{ row }">
        <td class="td-dataset-select">
          <input
            type="checkbox"
            class="datasets__table-checkbox"
            name="dataset"
            :id="`${row.id}`"
            :value="row.id"
            :checked="getSelectedDatasetIndex(row.id) > -1"
            @change="onChangeSelectDataset(row, $event)"
          />
          <label :for="`${row.id}`" class="sr-only">
            <VisibleText>select </VisibleText>{{ row.name }}
          </label>
        </td>
        <td class="td-dataset-name" data-label="dataset name">
          <router-link
            class="datasets__table-name"
            :to="`/dataset/${row.dataset_id}?project_id=${row.id}`"
          >
            {{ row.name }}
          </router-link>
          <span
            class="datasets__table-shared-status"
            v-if="checkIsReadOnly(row)"
          >
            <SvgIconMeaningful icon="eye" title="read only" />
          </span>
          <span
            class="datasets__table-shared-status"
            v-if="getDatasetSharedStatus(row) === 'shared'"
          >
            <SvgIconMeaningful icon="shared" title="shared" />
          </span>
        </td>
        <td data-label="owner">
          {{ getOwner(row) }}
        </td>
        <td data-label="file type">
          {{ row.file_type }}
        </td>
        <td data-label="last modified">
          {{ row.last_modified_on.$date | formatDate(setLanguage) }}
        </td>
        <td data-label="status">
          <VisibleText
            class="status"
            :class="row.currStatusText() | replaceSpaceWithDash"
            v-if="!row.isBenchmarksPending()"
          >
            {{ row.currStatusText() }}
          </VisibleText>
          <details class="datasets__table-status-details" v-else>
            <summary
              :class="row.currStatusText() | replaceSpaceWithDash"
              :data-parent="$options.name"
            >
              {{ translate(row.currStatusText(), $options.name) }}
            </summary>
            <p><VisibleText>Benchmarks are pending approval</VisibleText></p>
          </details>
        </td>
        <td>
          <button
            class="datasets__table-share-btn"
            @click="shareDataset(row)"
            v-if="isDatasetOwner(row)"
          >
            <SvgIconDecorative icon="share" />
            <VisibleText>Share</VisibleText>
          </button>
        </td>
      </template>
    </Table>
  </div>
</template>

<script>
// Componenets
import Table from "@/components/UI/Table.vue"
import SvgIconDecorative from "@/components/UI/Svg/SvgIconDecorative.vue"
import SvgIconMeaningful from "@/components/UI/Svg/SvgIconMeaningful.vue"

// Models
import BenchmarkingDataset from "@/models/BenchmarkingDatasetModel"

// Mixins
import SharedStatusMixin from "@/utils/mixins/sharedStatusMixin.js"

// Helpers
import { mapGetters, mapActions } from "vuex"

export default {
  name: "DatasetsTable",
  mixins: [SharedStatusMixin],
  components: {
    Table,
    SvgIconDecorative,
    SvgIconMeaningful
  },
  props: {
    queryTerm: {
      type: String,
      default: null
    },
    selected: {
      type: Array
    }
  },
  data() {
    return {
      columnHeaders: [
        "",
        "Dataset name",
        "Owner",
        "File type",
        "Last modified",
        "Status",
        ""
      ],
      sortableOrder: {
        ascending: 1,
        descending: -1
      },
      sortConfig: {
        "Dataset name": {
          options: ["ascending", "descending"],
          selected: ""
        },
        Owner: {
          options: ["ascending", "descending"],
          selected: ""
        },
        "File type": {
          options: ["ascending", "descending"],
          selected: ""
        },
        "Last modified": {
          options: ["ascending", "descending"],
          selected: ""
        },
        Status: {
          options: ["ascending", "descending"],
          selected: ""
        }
      },
      users: [],
      $_selected: []
    }
  },
  async created() {
    this.loadData().then(async ({ datasets, users }) => {
      this.users = users
      this.setDatasetProjects(datasets)
      this.fetchBenchmarkUpdates()
    })
  },
  computed: {
    ...mapGetters("user", {
      profile: "getProfile"
    }),
    ...mapGetters("globalModule", {
      datasets: "getDatasetProjects"
    }),
    filteredDatasets() {
      const keys = ["name"]
      return this.$options.filters.searchInObjectValues(
        this.datasets,
        keys,
        this.queryTerm
      )
    },
    setLanguage() {
      return this.$store.getters.getLanguage
    }
  },
  methods: {
    ...mapActions("loader", ["setLoading"]),
    ...mapActions("globalModule", ["setDatasetProjects"]),
    async loadData() {
      const [datasets, users] = await Promise.all([
        await this.$pigeonline.projects.get(BenchmarkingDataset),
        await this.$services.PROFILES_SERVICE.users()
      ])
      return { datasets, users }
    },
    fetchBenchmarkUpdates() {
      this.datasets.forEach(async datasetProject => {
        // not in progress, skip it
        if (datasetProject.currStatusText() != "in progress") return
        // benchmarks are sent & its in progress, check if benchmarks were approved
        if (datasetProject.status.benchmarksSent) {
          const benchmarks = await this.$services.BENCHMARKING_SERVICE.benchmarking(
            {
              client_data_set_id: datasetProject.dataset_id,
              approved: "False"
            }
          )
          // all benchmarks are approved, set it to complete
          if (benchmarks.length == 0) {
            datasetProject.updateStatus("benchmarksApproved")
            this.$pigeonline.projects.update(datasetProject)
          }
        }
      })
    },
    getDatasetSharedStatus(dataset) {
      const shared = dataset.shared && dataset.shared.map(r => r.pk)
      const access = dataset.access_type
      if (access && access == "public__all") return "public"
      if (access && access == "public__org") return "organization"
      if (access && access == "demo") return "demo"
      if (shared.length > 0) return "shared"
      return "private"
    },
    getSelectedDatasetIndex(projectID) {
      return this.$data.$_selected
        .map(dataset => dataset.project_id)
        .indexOf(projectID)
    },
    getOwner(dataset) {
      let owner = ""
      if (this.users.length == 0) return owner
      if (this.isDatasetOwner(dataset)) return "me"
      const user = this.users.find(user => user.id === dataset.owner)
      if (!user) return ""
      return user.fullname || ""
    },
    isDatasetOwner(dataset) {
      return dataset.owner === this.profile.django_ref_id
    },
    onChangeSelectDataset(row, $event) {
      if ($event.target.checked) {
        this.$data.$_selected.push({
          project_id: row.id,
          dataset_id: row.dataset_id,
          dataset_proj: row
        })
      } else if (this.getSelectedDatasetIndex(row.id) > -1) {
        let indx = this.getSelectedDatasetIndex(row.id)
        this.$data.$_selected.splice(indx, 1)
      }
      this.$emit("updateSelected", this.$data.$_selected)
    },
    shareDataset(dataset) {
      this.$emit("share-dataset", dataset)
    },

    /* Sort handlers */
    getValue(dataset, sortTitle) {
      if (sortTitle === "Dataset name") return dataset.name
      if (sortTitle === "Owner") return this.getOwner(dataset)
      if (sortTitle === "File type") return dataset.file_type
      if (sortTitle === "Last modified") return dataset.last_modified_on.$date
      if (sortTitle === "Status") return dataset.currStatusText()
    },
    sort(args) {
      this.sortConfig[args.sortTitle].selected = args.option

      // default sort func
      let sortFunc = (a, b) =>
        this.getValue(a, args.sortTitle).localeCompare(
          this.getValue(b, args.sortTitle)
        )

      // special cases
      if (args.sortTitle === "Last modified") {
        sortFunc = (a, b) =>
          this.getValue(a, args.sortTitle).valueOf() -
          this.getValue(b, args.sortTitle).valueOf()
      }

      this.datasets.sort(
        (a, b) => this.sortableOrder[args.option] * sortFunc(a, b)
      )
    }
  },
  watch: {
    selected: function(val) {
      if (val.length === 0) this.$data.$_selected = []
    }
  }
}
</script>
