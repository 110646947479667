<template>
  <div>
    <div
      class="message-container center-align-child"
      v-if="$route.name == 'notFound'"
    >
      <h1><VisibleText>404 Not Found</VisibleText></h1>
      <p>
        <VisibleText>Sorry, It seems this page does not exist.</VisibleText>
      </p>
      <router-link to="/">
        <VisibleText>Sorry, It seems this page does not exist.</VisibleText>
      </router-link>
    </div>
    <div
      class="message-container center-align-child"
      v-if="$route.name == 'serverError'"
    >
      <h1><VisibleText>Internal Server Error</VisibleText></h1>
      <p>
        <VisibleText
          >Sorry, there was some technical issues while processing your request.
          Try refreshing the page, or going back and attempting the action
          again.
        </VisibleText>
      </p>
      <p>
        <VisibleText
          >Please contact site administrators if this problem persists.
        </VisibleText>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "ErrorView",
  created() {
    document.title = `404  Error | ${this.$theme.theme.pageTitle}`
  }
}
</script>
