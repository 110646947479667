import Vue from "vue"
import Vuex from "vuex"

Vue.use(Vuex)

export default {
  namespaced: true,
  state() {
    return {
      profile: null,
      organization: null,
      license: null,
      notifications: [],
      valid: true,
      complete: true,
      accountNextStepsDisabled: false,
      loading: false
    }
  },
  getters: {
    getProfile: state => state.profile,
    getOrganization: state => state.organization,
    getLicense: state => state.license,
    getNotifications: state => state.notifications,
    getValid: state => state.valid,
    getComplete: state => state.complete,
    getAccountNextStepsDisabled: state => state.accountNextStepsDisabled,
    getLoading: state => state.loading
  },
  mutations: {
    SET_PROFILE(state, payload) {
      Vue.set(state, "profile", payload)
    },
    SET_ORGANIZATION(state, payload) {
      Vue.set(state, "organization", payload)
    },
    SET_LICENSE(state, payload) {
      Vue.set(state, "license", payload)
    },
    SET_NOTIFICATIONS(state, payload) {
      Vue.set(state, "notifications", payload)
    },
    SET_VALID(state, payload) {
      Vue.set(state, "valid", payload)
    },
    SET_COMPLETE(state, payload) {
      Vue.set(state, "complete", payload)
    },
    SET_ACCOUNT_NEXT_STEPS_DISABLED(state, payload) {
      Vue.set(state, "accountNextStepsDisabled", payload)
    },
    SET_LOADING(state, payload) {
      Vue.set(state, "loading", payload)
    },
    RESET_USER(state) {
      Vue.set(state, "profile", null)
      Vue.set(state, "organization", null)
      Vue.set(state, "license", null)
      Vue.set(state, "notifications", [])
      Vue.set(state, "loading", false)
    }
  },
  actions: {
    async setProfile({ commit }, payload) {
      await commit("SET_PROFILE", payload)
    },
    async setOrganization({ commit }, payload) {
      await commit("SET_ORGANIZATION", payload)
    },
    async setLicense({ commit }, payload) {
      await commit("SET_LICENSE", payload)
    },
    async setNotifications({ commit }, payload) {
      await commit("SET_NOTIFICATIONS", payload)
    },
    async setValid({ commit, state }) {
      if (state.profile == undefined) return

      // set valid to true for demo users
      if (state.profile.group_tag.includes("demo_user")) {
        await commit("SET_VALID", true)
        return
      }

      const isValid = Boolean(
        state.profile.organization_ref_id &&
          state.organization &&
          state.organization.verified_account
      )
      await commit("SET_VALID", isValid)
    },
    async setComplete({ commit, state }) {
      const isComplete = Boolean(
        state.profile &&
          (state.profile.organization_ref_id ||
            state.profile.is_account_complete)
      )
      await commit("SET_COMPLETE", isComplete)
    },
    async setAccountNextStepsDisabled({ commit, state }) {
      if (state.profile && state.profile.organization_ref_id) {
        await commit("SET_ACCOUNT_NEXT_STEPS_DISABLED", true)
      }
    },
    async setLoading({ commit }, payload) {
      await commit("SET_LOADING", payload)
    },
    resetUser({ commit }) {
      commit("RESET_USER")
    }
  }
}
