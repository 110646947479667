<template>
  <div class="library__article">
    <div v-if="isUserGuide">
      <p>
        <VisibleText>Go back to</VisibleText>
        <a
          href="javascript:void(0)"
          @click="$emit('new-page', 'GettingStarted')"
        >
          <VisibleText> Getting Started</VisibleText>
        </a>
      </p>
    </div>
    <div v-else>
      <p>
        <VisibleText>Go back to </VisibleText>
        <router-link to="/library">
          <VisibleText>Library</VisibleText>
        </router-link>
        ->
        <router-link to="/library/guide/getting-started-with-researchai">
          <VisibleText>Getting Started</VisibleText>
        </router-link>
      </p>
    </div>
    <h1>
      <VisibleText>
        What is thematic analysis?
      </VisibleText>
    </h1>
    <p>
      <VisibleText>
        One of the most difficult types of data to work with is text data. These
        can be customer or employee comments, complaints, responses to surveys,
        live-chats, and more. If the number of text responses is very high, it
        can be very challenging to analyze. What are they saying?
      </VisibleText>
    </p>
    <p>
      <VisibleText>
        ResearchAI has a text analytics / qualitative analytics tool that makes
        this much easier.
      </VisibleText>
    </p>
    <ul class="library__list">
      <li>
        <a
          href="javascript:void(0)"
          @click="goto('goto--traditional-research')"
        >
          <VisibleText>Traditional qualitative research</VisibleText>
        </a>
      </li>
      <li>
        <a href="javascript:void(0)" @click="goto('goto--overview-of-text')">
          <VisibleText>Overview of the tool</VisibleText>
        </a>
      </li>
      <li>
        <a
          href="javascript:void(0)"
          @click="goto('goto--autogenerating-themes')"
        >
          <VisibleText>
            Auto-generating themes using our thematic analysis tool
          </VisibleText>
        </a>
      </li>
      <li>
        <a
          href="javascript:void(0)"
          @click="goto('gotoo--final-text-comments')"
        >
          <VisibleText>
            Final comments about text analysis
          </VisibleText>
        </a>
      </li>
    </ul>
    <h2 ref="goto--traditional-research">
      <VisibleText>Traditional qualitative research</VisibleText>
    </h2>
    <p>
      <VisibleText>
        This analysis is typically done by qualitative researchers who are
        trained to “code” quotes as reflecting some concept or idea. This can
        include:
      </VisibleText>
    </p>
    <ul class="library__list">
      <li>
        <VisibleText>
          Topic analysis, which counts the presence of words to get a sense of
          what people are saying.
        </VisibleText>
      </li>
      <li>
        <VisibleText>
          Discourse analysis, where researchers are interpreting deeper meaning
          from text.
        </VisibleText>
      </li>
      <li>
        <VisibleText>
          Sentiment analysis, where researchers interpret emotion in particular.
        </VisibleText>
      </li>
    </ul>
    <p>
      <VisibleText>
        ResearchAI intends to make all three of these easier using AI and an
        easy-to-use interface.
      </VisibleText>
    </p>
    <h2 ref="goto--overview-of-text">
      <VisibleText>
        Overview of the text analytics tool
      </VisibleText>
    </h2>
    <p>
      <VisibleText>
        There are four main sections (or views) of ResearchAI's text analytics.
      </VisibleText>
    </p>
    <h3>
      <VisibleText>
        "Saved themes" section
      </VisibleText>
      :
    </h3>
    <p>
      <VisibleText>
        This view shows you any themes you may have saved. Themes are concepts
        that are reflected in the text. These themes can be made up out of
        keywords that are found in respondent text, excerpts which are
        interesting quotes from respondents, or notes left by researchers.
        Themes display a “coverage” which is the percentage of respondents who
        match either a keyword, excerpt, or had a note left by a researcher
        versus the entire population of responses. 25% coverage would imply 1/4
        respondents said something that match this theme.
      </VisibleText>
    </p>
    <p>
      <VisibleText>
        The saved theme views also include two charts for each theme. One called
        keyword sentiment shows if the keywords are particularly positive,
        negative, or neutral. Another is called respondent sentiment, which
        shows if a respondent as a whole was likely positive or negative.
      </VisibleText>
    </p>
    <img
      class="screenshot"
      style="width:60%;"
      src="/assets/userguide__exampletheme.png"
      alt="screenshot of a saved theme"
    />
    <p>
      <VisibleText>
        At any time if you click “show only this”, all subsequent views will be
        filtered to only be looking at those who match this theme.
      </VisibleText>
    </p>
    <h3>
      <VisibleText>
        "Filter" section
      </VisibleText>
      :
    </h3>
    <p>
      <VisibleText>
        In this section you can set which text question to run analysis on. You
        can also see if a filter has been applied, such as when a theme is
        shown. You can also search for a keyword here. Lastly, you can also set
        whether the next two sections will be in Sentiment Mode or Themes Mode.
      </VisibleText>
    </p>
    <img
      class="screenshot"
      style="width:60%;"
      src="/assets/userguide__textfilterview.png"
      alt="screenshot of the filter view for text analysis"
    />
    <h3>
      <VisibleText>
        "Summary of responses" section
      </VisibleText>
      :
    </h3>
    <p>
      <VisibleText>
        In this section you will see a summary of all respondents that are in
        view, displayed visually. If Sentiment Mode is applied, a lower bar
        score will imply a more negative respondent. A higher bar score will
        imply a more positive respondent.If you are in Themes Mode, a higher bar
        score will reflect a greater match to the theme. No bar score will
        reflect no match to the theme.
      </VisibleText>
    </p>
    <img
      class="screenshot"
      style="width:50%;"
      src="/assets/userguide__textsummaryview.png"
      alt="screenshot of summary view chart for text analysis"
    />
    <h3><VisibleText>"Responses" section</VisibleText>:</h3>
    <p>
      <VisibleText>
        In this last section you can navigate each respondent. If you are
        Sentiment Mode, some highly positive or negative keywords will be
        highlighted for your reference. These may or may not be interesting to
        you. In Themes Mode, you will see exactly which keyword, excerpt or note
        exists for this respondent.
      </VisibleText>
    </p>
    <p>
      <VisibleText>
        By clicking on the respondent number, you can see more information like
        if they answered any categorical questions. This helps understand who
        the respondent is. You may also see an overall performance score if you
        set one. For example, if overall satisfaction was set as a performance
        score, then their satisfaction score will be displayed here. You can
        also pin responses to the top, hide, or ban responses from your
        analysis.
      </VisibleText>
    </p>
    <img
      class="screenshot"
      style="width:50%;"
      src="/assets/userguide__textresponseview.png"
      alt="screenshot of the response view for text analysis"
    />
    <h2 ref="goto--autogenerating-themes">
      <VisibleText>
        Auto-generating themes using our thematic analysis tool
      </VisibleText>
    </h2>
    <p>
      <VisibleText>
        ResearchAI can speed up the finding of key themes in your text. This
        button will automatically generate themes for your consideration. This
        thematic analysis is also called topic modeling. These can come from a
        number of sources:
      </VisibleText>
    </p>
    <h3><VisibleText>Clusters of interesting words</VisibleText></h3>
    <p>
      <VisibleText>
        Using an approach called LDA, we find potentially interesting bundles of
        keywords. If you see strange keywords appearing in many of these
        clusters, there is a good chance it was mentioned a lot by your users.
        These are worth closer investigations.
      </VisibleText>
    </p>
    <p>
      <VisibleText>
        To make the most out of these, remove words that are not as interesting,
        or even ban words that are appearing too often because they are too
        obvious.
      </VisibleText>
    </p>
    <p>
      <VisibleText>
        For example, the theme below was generated using LDA. It seems to have
        found two separate but interesting topics, website and staff. One could
        remove staff keywords and add them to a new theme to make better sense
        of these interesting keywords.
      </VisibleText>
    </p>
    <img
      class="screenshot"
      style="width:50%;"
      src="/assets/userguide__lda-theme.png"
      alt="screenshot of a theme generated using LDA"
    />
    <h3><VisibleText>Pre-generated custom topic models</VisibleText></h3>
    <p>
      <VisibleText>
        ResearchAI has partnered with academics to pre-define common industry
        best-in-class models for you. For example, leading customer experience
        models built from service quality can find common service experience
        issues like timeliness, staff quality, and more.
      </VisibleText>
    </p>
    <h3><VisibleText>Custom topic models</VisibleText></h3>
    <p>
      <VisibleText>
        You can build your own thematic models as well. At any point, click your
        profile button in the top right, and click “Custom Topic Model”.
      </VisibleText>
    </p>
    <img
      class="screenshot"
      style="width:30%;"
      src="/assets/userguide__useroptions.png"
      alt="screenshot of user options when clicking on your profile avatar"
    />
    <p>
      <VisibleText>
        To create a new custom topic model, click “Create topic model”.
      </VisibleText>
    </p>
    <p>
      <VisibleText>
        Give your new model a name. For example, “Employee engagement”, or
        “common customer complaints”. If your model comes from a source, place
        it here.
      </VisibleText>
    </p>
    <img
      class="screenshot"
      style="width:60%;"
      src="/assets/userguide__customtopicmodelgroup.png"
      alt="screenshot of options when creating a custom topic model group"
    />
    <p>
      <VisibleText>
        Add a theme to go inside this model by clicking “add theme”. Give the
        theme a name, like, “leadership”, or, “took too long”. Example questions
        help you match the theme to survey questions. More importantly though,
        be sure to give the theme some keywords. These are what will inform the
        Custom Topic Model in future use.
      </VisibleText>
    </p>
    <img
      class="screenshot"
      style="width:60%;"
      src="/assets/userguide__customtopicmodel.png"
      alt="screenshot when creating a new theme in a custom topic model group"
    />
    <h2 ref="gotoo--final-text-comments">
      <VisibleText>
        Final comments about text analysis
      </VisibleText>
    </h2>
    <p>
      <VisibleText>
        Remember, language is tricky even for humans. Be sure to treat the
        automatic topics, sentiments, and other AI as suggestions to guide your
        research. As a helpful tip when reporting your findings to colleagues:
        you can always defend your themes based on coverage.
      </VisibleText>
    </p>
    <p>
      <VisibleText>
        This tool is under constant development. We are eager to learn from your
        experiences and to continue to evolve this tool. Please don’t hesitate
        to share your experience or make suggestions for improvement.
      </VisibleText>
    </p>
  </div>
</template>

<script>
export default {
  name: "what_is_thematic_analysis",
  props: {
    isUserGuide: { type: Boolean }
  },
  methods: {
    goto(refName) {
      this.$refs[refName].scrollIntoView()
    }
  }
}
</script>
