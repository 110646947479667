<template>
  <fieldset class="form-fieldset-group" :id="groupID">
    <label
      v-for="item in labels"
      :key="item.label"
      :for="`${groupID}-${item.label}`"
      class="form-checkbox-label"
    >
      <input
        type="checkbox"
        :id="`${groupID}-${item.label}`"
        :name="item.label"
        :value="item.value"
        :disabled="item.disabled"
        v-model="checked"
      />
      {{ item.label }}
    </label>
  </fieldset>
</template>

<script>
export default {
  name: "Checkboxes",
  props: {
    labels: {
      type: Array,
      required: true
    },
    values: {
      type: Array,
      default: function() {
        return []
      }
    },
    groupID: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      checked: null
    }
  },
  mounted() {
    this.checked = this.values
  },
  watch: {
    checked: function() {
      this.$emit("updateCheckboxVals", this.groupID, this.checked)
    }
  }
}
</script>
