<template>
  <div id="app" :style="borderStyle">
    <TranslationChecker />
    <SvgSprite />

    <AccountMessages
      :status-name="accountStatusName"
      v-if="accountStatusName.length > 0"
    />

    <!--------------------------------------------
    |
    | HEADER AND SKIP LINK
    |
    --------------------------------------------->
    <LoadingTopBar :isLoading="loading" />
    <a
      class="sr-only"
      href="#main-content"
      id="skip-link-main"
      :aria-hidden="modalOpen"
      :inert="modalOpen"
      @click.prevent="skipToMainContent"
    >
      <VisibleText> Skip to main content </VisibleText>
    </a>
    <Header
      :aria-hidden="modalOpen"
      :inert="modalOpen"
      :isAuthenticated="$pigeonline.userIsAuthenticated()"
      v-if="this.$router.currentRoute.path !== '/about'"
    />

    <!--------------------------------------------
    |
    | MAIN CONTENT
    |
    --------------------------------------------->
    <main id="main-content" tabindex="-1">
      <router-view></router-view>
    </main>

    <!--------------------------------------------
    |
    | CONFIMRATION MODAL
    |
    --------------------------------------------->
    <Modal
      uid="confirm-modal"
      :class="['confirmation-modal', `confirmation-modal--${confirmType}`]"
      :show="confirmIsVisible"
      @close="closeConfirmationModal"
    >
      <template #header>
        <VisibleText>{{ confirmText.title }}</VisibleText>
      </template>
      <VisibleText>{{ confirmText.message }}</VisibleText>
      <template #footer>
        <div class="confirmation-modal__footer">
          <button
            type="button"
            class="confirmation-modal__btn cancel"
            @click.stop="cancelled"
            v-if="confirmType === 'delete' || confirmType === 'warning'"
          >
            <VisibleText>
              {{ confirmText.secondaryBtn || "Cancel" }}
            </VisibleText>
          </button>
          <button
            type=" button"
            class="confirmation-modal__btn confirm"
            @click.stop="confirmed"
          >
            <VisibleText>{{ confirmText.btn }}</VisibleText>
          </button>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import Vue from "vue"
import SvgSprite from "@/components/UI/Svg/SvgSprite.vue"

// Components
import AccountMessages from "@/components/AccountMessages.vue"
import Header from "@/components/Structure/TheHeader.vue"
import LoadingTopBar from "@/components/UI/LoadingTopBar.vue"
import ReLoginModal from "@/components/ReLoginModal.vue"
import Modal from "@/components/UI/Modal.vue"
import TranslationChecker from "@/components/Translation/TranslationChecker"

// Helpers
import createAuthRefreshInterceptor from "axios-auth-refresh"
import { mapGetters } from "vuex"
import { axios } from "@pigeonline/core"

// Mixin
import ConfirmMixin from "@/utils/mixins/confirmMixin.js"
import UserMixin from "@/utils/mixins/userMixin.js"

export default {
  name: "app",
  mixins: [ConfirmMixin, UserMixin],
  components: {
    AccountMessages,
    SvgSprite,
    Header,
    LoadingTopBar,
    Modal,
    TranslationChecker
  },
  data() {
    return {
      borderStyle: "border-top-width: 0px",
      reloginModal: null,
      axiosResponseInterceptor: null
    }
  },
  async created() {
    // Function that will be called to refresh authorization
    const refreshAuthLogic = failedRequest => {
      // if a request failed without requiring auth headers,
      // simply resolve the promise
      if (failedRequest.config.skipAuthHeaders) return Promise.resolve()
      return this.$pigeonline
        .refresh()
        .catch(() => {
          const email = this.$pigeonline.getUserEmail()
          if (email == "") {
            // Log user out - user session already null
            alert("Expired session. Redirecting to login...")
            this.logUserOut()
          } else {
            return this.reLoginUser()
          }
        })
        .then(access => {
          if (!access) {
            throw new Error("App.vue:refreshAuthLogic: Access token null")
          }
          failedRequest.response.config.headers[
            "Authorization"
          ] = this.$pigeonline.getAuthHeader()
          return Promise.resolve()
        })
        .finally(() => {
          this.$store.dispatch("loader/setLoading", false)
        })
    }

    // Instantiate the interceptor (you can chain it as it returns the axios instance)
    createAuthRefreshInterceptor(axios, refreshAuthLogic, {
      skipWhileRefreshing: false
    })

    // enable error catching interceptor
    this.enableInterceptor()

    // update user store if already logged in
    if (this.$pigeonline.userIsAuthenticated()) {
      await this.setUserDetails()
    }
  },
  computed: {
    ...mapGetters("globalModule", {
      modalOpen: "getModalOpen"
    }),
    ...mapGetters("loader", {
      loading: "getLoading"
    }),
    accountStatusName() {
      if (!this.$pigeonline.userIsAuthenticated()) return ""
      if (!this.isValid && this.isComplete) return "notVerified"
      if (!this.isComplete && !this.$route.name.includes("accountNextSteps"))
        return "setupIncomplete"
      if (
        this.isDemoUser &&
        !this.isAccountNextStepsDisabled &&
        !this.showAccountSetupIncompleteMessage &&
        !this.$route.name.includes("accountNextSteps")
      )
        return "upgrade"
      return ""
    }
  },
  mounted() {
    // Live chat script from RocketChat
    ;(function(w, d, s, u) {
      w.RocketChat = function(c) {
        w.RocketChat._.push(c)
      }
      w.RocketChat._ = []
      w.RocketChat.url = u
      var h = d.getElementsByTagName(s)[0],
        j = d.createElement(s)
      j.async = true
      j.src =
        "https://chat.pigeonline.io/livechat/rocketchat-livechat.min.js?_=201903270000"
      h.parentNode.insertBefore(j, h)
    })(window, document, "script", "https://chat.pigeonline.io/livechat")
  },
  methods: {
    skipToMainContent() {
      document.getElementById("main-content").focus()
    },
    /************
     *
     * Confirmation modals
     *
     ***********/
    confirmed() {
      this.setConfirmIsVisible(false)
      this.setConfirmStatus(true)
      this.setConfirmClickedClose(false)
    },
    cancelled() {
      this.setConfirmIsVisible(false)
      this.setConfirmStatus(false)
      this.setConfirmClickedClose(false)
    },
    closeConfirmationModal() {
      this.setConfirmIsVisible(false)
      this.setConfirmClickedClose(true)
    },
    /************
     *
     * Login/logout
     *
     ***********/
    reLoginUser() {
      if (!this.reloginModal) {
        this.reloginModal = new (Vue.extend(ReLoginModal))({
          propsData: {
            email: this.$pigeonline.getUserEmail(),
            show: true
          },
          store: this.$store
        })
      }
      this.reloginModal.$mount()
      return new Promise((resolve, reject) => {
        var authenticated = function() {
          setTimeout(() => {
            this.destroyReloginModal()
            resolve(this.$pigeonline.getAuthToken())
          }, 1000)
        }.bind(this)
        this.reloginModal.$once("authenticated", authenticated)

        var goToLogin = function() {
          if (
            confirm(
              "You are about to go away from this page. All your unsaved changes will be lost. Do you want to proceed?"
            )
          ) {
            this.destroyReloginModal()
            this.logUserOut()
            reject(false)
          }
        }.bind(this)
        this.reloginModal.$on("goToLogin", goToLogin)
      })
    },
    destroyReloginModal() {
      this.reloginModal.$destroy()
      this.reloginModal.$el.remove()
      this.reloginModal = null
    },
    logUserOut() {
      this.$store.dispatch("loader/setLoading", false)
      this.$pigeonline.logout()
      this.$router.go({ name: "login" })
    },
    /************
     *
     * Interceptors
     *
     ***********/
    enableInterceptor() {
      this.axiosResponseInterceptor = axios.interceptors.response.use(
        response => {
          return response
        },
        error => {
          this.$store.dispatch("loader/setLoading", false)
          if (error.response) {
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            switch (error.response.status) {
              case 500:
                this.$router.replace({
                  path: "/500"
                })
                break
              case 403:
                if (error.response.data && error.response.data.detail) {
                  alert(error.response.data.detail)
                }
                break
            }
          } else {
            /*
             * The request was made but no response was received,
             * or something happened in setting up the request.
             */
            alert(
              `There was an error processing your request. Please try again later. [REASON:: ${error.message}]`
            )
          }
          return Promise.reject(error)
        }
      )

      axios.interceptors.request.use(
        function(config) {
          config.headers["Accept-Language"] =
            window.localStorage.getItem("apiLanguage") || "en"
          return config
        },
        function(error) {
          return Promise.reject(error)
        }
      )
    },
    disableInterceptor() {
      window.axios.interceptors.request.eject(this.axiosResponseInterceptor)
    }
  },
  watch: {
    loading: function(val) {
      if (val) {
        this.borderStyle = "border-top-width: 0px"
      } else {
        setTimeout(() => {
          this.borderStyle = "border-top-width: 6px"
        }, 200)
      }
    }
  }
}
</script>

<style lang="scss">
@import "./styles/global.scss";
</style>
