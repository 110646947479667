<template>
  <div class="also-found__wrapper" v-show="isVisible || showNoSimilarText">
    <div class="also-found__loading" v-if="isLoading">
      <p class="also-found__loading-text">
        <VisibleText>{{ loadingText }}</VisibleText>
        <span class="also-found__loading-spinner spinner"></span>
      </p>
    </div>
    <div v-else>
      <div class="also-found__no-similar" v-if="showNoSimilarText">
        <p class="also-found__loading-text">
          <VisibleText>No similar words found.</VisibleText>
        </p>
      </div>
      <div v-if="similar.length > 0">
        <ul class="also-found__list">
          <span class="also-found__list-label">
            <VisibleText>{{ label + ":" }}</VisibleText>
          </span>
          <li
            class="also-found__list-item"
            v-for="(keyword, index) in similar"
            :key="index"
          >
            <KeywordTappable
              class="keyword"
              :value="keyword"
              @save="$emit('save', keyword)"
            >
              <template v-slot="{ remove }">
                <ButtonIconOnly
                  icon="remove"
                  :id="`${_uid}-also-found-keyword-${index}`"
                  class="keyword-remove"
                  @click-handler="remove(keyword)"
                >
                  <VisibleText>remove </VisibleText>{{ keyword }}
                </ButtonIconOnly>
              </template>
            </KeywordTappable>
          </li>
        </ul>
        <div class="also-found__options">
          <slot>
            <button class="also-found__save-all-btn">
              <VisibleText>Save all</VisibleText>
            </button>
          </slot>
        </div>
      </div>
    </div>
    <ButtonIconOnly
      icon="remove"
      class="also-found__remove-btn"
      id="alsofound-close"
      @click-handler="close"
    >
      <VisibleText>close</VisibleText>
    </ButtonIconOnly>
  </div>
</template>

<script>
import KeywordTappable from "../Components/AlsoFoundKeywordTappable.vue"
import ButtonIconOnly from "@/components/UI/Button/ButtonIconOnly.vue"

// Mixins
import AnalysisTextMixin from "@/utils/mixins/analysisTextMixin.js"

export default {
  name: "AlsoFound",
  mixins: [AnalysisTextMixin],
  components: {
    ButtonIconOnly,
    KeywordTappable
  },
  props: {
    label: {
      type: String,
      default: function() {
        return "Also found"
      }
    },
    loadingText: {
      type: String,
      default: function() {
        return "Finding similar words..."
      }
    }
  },
  data() {
    return {
      keyword: null,
      keywordRef: null,
      similar_: [],
      isVisible: false,
      isLoading: false,
      showNoSimilarText: false
    }
  },
  computed: {
    similar: {
      get: function() {
        return this.filterBannedKeywords(this.similar_)
      },
      set: function(val) {
        this.similar_ = this.filterBannedKeywords(
          val.map(item => item.trim().toLowerCase())
        )
      }
    }
  },
  methods: {
    async setKeyword(keyword) {
      if (!keyword) return

      this.keyword = keyword

      // fetch similar keywords
      this.setVisible(true)
      this.setLoading(true)
      this.setSimilar(Array.from(await this.fetchWordAssociations(keyword)))
      this.setLoading(false)
    },
    setKeywordRef(ref) {
      if (this.keywordRef) {
        this.keywordRef.setSelected(false)
      }

      // set new ref
      this.keywordRef = ref
      this.keywordRef.setSelected(true)
    },
    setSimilar(keywords) {
      if (!Array.isArray(keywords)) return
      this.showNoSimilarText = false
      if (keywords.length == 0) this.showNoSimilarText = true
      this.similar = keywords
    },
    setVisible(visible) {
      this.isVisible = visible
    },
    setLoading(loading) {
      this.isLoading = loading
    },
    reset() {
      this.keyword = null
      this.keywordRef = null
      this.similar = []
      this.isVisible = false
      this.isLoading = false
      this.showNoSimilarText = false
    },
    close() {
      if (this.keywordRef) {
        this.keywordRef.setSelected(false)
      }

      this.reset()
    },

    /* Fetch word associations api */
    async fetchWordAssociations(searchString) {
      if (!searchString) return []
      try {
        const response = await this.TEXT_SERVICE.wordAssociations({
          user_string: searchString,
          data_set_id: this._datasetId,
          client_question_id: this.selectedTextQuestion._id.$oid
        })
        return response
      } catch (e) {
        throw new Error("AlsoFound.vue:fetchWordAssociations " + e.message)
      }
    }
  },
  watch: {
    similar_: function(val) {
      if (val && val.length === 0) this.setVisible(false)
    }
  }
}
</script>
