var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('section',{class:[{ hide_list: _vm.selectedComponent == null }],attrs:{"id":"projects","aria-hidden":_vm.modalOpen,"inert":_vm.modalOpen}},[_c('div',{staticClass:"projects__search-bar center-align-parent"},[_c('SearchBar',{staticClass:"center-align-child",attrs:{"name":"searchBar__projects","placeholder":"Search any projects"},on:{"search":_vm.search}})],1),_c('div',{staticClass:"section__top-bar"},[_c('h1',{staticClass:"section__top-bar-title"},[_c('VisibleText',[_vm._v("Projects ")]),_vm._v("( "+_vm._s(_vm.totalProjects)+" ) ")],1),_c('div',{staticClass:"section__top-bar-actions-wrapper"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.saveUIPreferences($event)}}},[_c('ButtonIconOnly',{class:[
              {
                'projects__view-select-active':
                  _vm.selectedComponent == 'ProjectsGrid'
              },
              'projects__view-select-btn'
            ],attrs:{"icon":"grid","id":"grid-view"},on:{"click-handler":function($event){return _vm.saveUIPreferences('ProjectsGrid')}}},[_c('VisibleText',[_vm._v("grid view")])],1),_c('ButtonIconOnly',{class:[
              {
                'projects__view-select-active':
                  _vm.selectedComponent == 'ProjectsList'
              },
              'projects__view-select-btn'
            ],attrs:{"icon":"list","id":"list-view"},on:{"click-handler":function($event){return _vm.saveUIPreferences('ProjectsList')}}},[_c('VisibleText',[_vm._v("list view")])],1)],1)])]),_c('div',{class:_vm.createItemsWrapperClass},[_c('div',{staticClass:"create-content",attrs:{"id":"create-content"}},[_c('button',{staticClass:"create-btn",attrs:{"type":"button"},on:{"click":function($event){_vm.isProjectModalVisible = true}}},[_c('span',{staticClass:"center-align-parent",attrs:{"id":"create-icon-wrapper"}},[_c('SvgIconDecorative',{staticClass:"center-align-child",attrs:{"icon":"add","id":"create-icon"}})],1),_c('span',{staticClass:"create-title"},[_c('VisibleText',[_vm._v("Create new project")])],1),_c('span',{staticClass:"create-text"},[_c('VisibleText',[_vm._v(" Analyze data and create reports in just a few clicks ")])],1)])])]),_c('PastProjects',{class:_vm.itemsWrapperClass,attrs:{"models":[_vm.BenchmarkingProject],"search":_vm.query,"sort":_vm.sort},on:{"pastProjectsRetreived":_vm.pastProjectsRetreived,"pastProjectsFiltered":_vm.projectsFiltered},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var project = ref.project;
return [_c(_vm.selectedComponent,{tag:"component",attrs:{"project":project}})]}}])})],1),_c('ProjectsCreateModal',{attrs:{"show":_vm.isProjectModalVisible},on:{"close":function($event){_vm.isProjectModalVisible = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }