<template>
  <div>
    <div class="table__toolbar table__toolbar--nonsticky" v-if="!hideToolbar">
      <!--------------------------------------
      | select box
      ---------------------------------------->
      <label
        class="form-label form-checkbox-label table__toolbar-checkbox-select"
        :data-parent="$options.name"
      >
        <input
          class="table__toolbar-checkbox-select-input"
          type="checkbox"
          name="select-all-questions"
          v-model="selectAll"
        />
        {{ translate("Select All", $options.name) }}
      </label>
    </div>
    <ListGrid
      class="dataset-table__data"
      :aria-label="`${dataset.name} data`"
      :custom-grid-style="{ gridTemplateColumns: '15% 1fr 1fr 20% 15%' }"
      :headers="headers"
      :list="clientQuestionItems"
    >
      <template slot-scope="{ item, index }">
        <ListGridItem col="0" :headers="headers" :row="index">
          <label
            class="form-label form-checkbox-label"
            :id="`row-${item._id.$oid}`"
          >
            <input
              type="checkbox"
              tabindex="-1"
              :disabled="isDisabled(item)"
              :value="item._id.$oid"
              v-model="selectedQuestions"
            />
            {{ item.question_title }}
          </label>
        </ListGridItem>
        <ListGridItem
          class="question-text"
          col="1"
          :headers="headers"
          :row="index"
          :rowuid="`row-${item._id.$oid}`"
        >
          {{ item.question_text }}
        </ListGridItem>
        <ListGridItem
          class="global-match dataset-table__match-wrapper"
          col="2"
          :headers="headers"
          :row="index"
          :rowuid="`row-${item._id.$oid}`"
          :focus-cell="true"
        >
          <span class="dataset-table__match-title">
            {{
              globalMatch(item._id.$oid)
                ? globalMatch(item._id.$oid).global_question_title
                : ""
            }}
          </span>
          <span class="dataset-table__match-text">
            {{
              globalMatch(item._id.$oid)
                ? globalMatch(item._id.$oid).global_question_text
                : ""
            }}
          </span>
        </ListGridItem>
        <ListGridItem
          col="3"
          :headers="headers"
          :row="index"
          :rowuid="`row-${item._id.$oid}`"
        >
          <DataType :client-question="item" :read-only="true" />
        </ListGridItem>
        <ListGridItem
          col="4"
          style="overflow: hidden"
          :headers="headers"
          :row="index"
          :rowuid="`row-${item._id.$oid}`"
          v-if="forDrivers"
        >
          <button
            class="drivers__select-set-dependent-btn"
            tabindex="-1"
            :disabled="isDisabled(item)"
            @click="$emit('set-dependent', item._id.$oid)"
          >
            <VisibleText>Set as dependent variable</VisibleText>
          </button>
        </ListGridItem>
      </template>
    </ListGrid>
  </div>
</template>

<script>
// Components
import DataType from "@/components/Dataset/Components/DatasetTable/Components/DataType.vue"
import ListGrid from "@/components/UI/ListGrid.vue"
import ListGridItem from "@/components/UI/ListGridItem.vue"

// Mixins
import DatasetDetailsMixin from "@/utils/mixins/datasetDetailsMixin.js"
import ProjectMixin from "@/utils/mixins/projectMixin.js"

export default {
  name: "SelectQuestionsTable",
  mixins: [DatasetDetailsMixin, ProjectMixin],
  props: {
    // a function to check for any questions you want to disable
    disableInput: {
      default: () => null,
      type: Function
    },
    forDrivers: {
      default: false,
      type: Boolean
    },
    hideToolbar: {
      default: false,
      type: Boolean
    },
    questions: {
      default: () => [],
      type: Array
    },
    selected: {
      default: () => [],
      type: Array,
      required: true
    }
  },
  components: {
    DataType,
    ListGrid,
    ListGridItem
  },
  data() {
    return {
      headers: [
        "question code",
        "question text",
        "global match",
        "data type",
        ""
      ],
      selectAll: false,
      selectedQuestions: []
    }
  },
  created() {
    this.selectedQuestions = this.selected
  },
  computed: {
    clientQuestionItems() {
      // if questions were passed, use those
      if (this.questions.length > 0) return this.questions
      // no questions passed so use all client questions
      else return this.clientQuestions
    }
  },
  methods: {
    globalMatch(questionId) {
      return this.matchesDetails[questionId]
    },
    isDisabled(item) {
      if (this.disableInput) return this.disableInput(item)
      else return false
    }
  },
  watch: {
    selectAll: function(select) {
      if (select) {
        this.selectedQuestions = this.clientQuestionItems
          .filter(q => !this.disableInput(q))
          .map(q => q._id.$oid)
        this.$emit("update-selected-questions", this.selectedQuestions)
      } else {
        this.$emit("update-selected-questions", [])
      }
    },
    selectedQuestions: function() {
      this.$emit("update-selected-questions", this.selectedQuestions)
    }
  }
}
</script>
