<template>
  <section id="project-analysis-text-main">
    <div class="main__navbar" :aria-hidden="modalOpen" :inert="modalOpen">
      <div class="main__navbar-item select-question">
        <label class="select-question__info-text">
          <VisibleText>Showing results from </VisibleText>
        </label>
        <select
          aria-label="select question"
          class="select-question__input select"
          id="select-question-text-analysis"
          v-model="selected"
          @change="selectQuestionChanged"
        >
          <option disabled value="" :data-parent="$options.name">
            {{ translate("Please select a question", $options.name) }}
          </option>
          <option
            class="select-question__input-option"
            v-for="(question, index) in textQuestions"
            :key="`option-${index}`"
            :value="question._id.$oid"
            :data-parent="$options.name"
          >
            {{ question.question_title }}
          </option>
        </select>
      </div>
      <div class="main__navbar-item current-view">
        <label class="current-view__info-text sr-only">
          <VisibleText>Current view mode</VisibleText>
        </label>
        <ul
          class="current-view__tabs-list"
          :aria-hidden="modalOpen"
          :inert="modalOpen"
        >
          <li
            class="current-view__tabs-list-item"
            v-for="item in tabs"
            :key="item"
          >
            <button
              :class="[
                'current-view__tabs-list-item-btn',
                {
                  active: activeTab === item
                }
              ]"
              :title="item"
              :data-parent="$options.name"
              @click="activeTab = item"
            >
              {{ translate(item, $options.name) }}
            </button>
          </li>
        </ul>
      </div>
    </div>
    <Search :aria-hidden="modalOpen" :inert="modalOpen" />
    <Accordion
      class="main__collapsible-chart"
      title-element-type="h3"
      icon-location="right"
      :aria-hidden="modalOpen"
      :inert="modalOpen"
      :expand="false"
      @toggled="shouldRenderPosNegChart($event)"
    >
      <template #heading>
        <span class="main__collapsible-chart-title">
          <VisibleText>Summary of </VisibleText>
          <em class="chart-type">
            <VisibleText>{{ activePosNegChartType }}</VisibleText>
          </em>
          {{ `(${textResponsesFiltered.length}/${textResponses.length})` }}
        </span>
      </template>
      <p class="main__collapsible-chart-heading">
        <VisibleText>Showing </VisibleText>
        {{ textResponsesFiltered.length }} <VisibleText> out of </VisibleText>
        {{ textResponses.length }} <VisibleText> responses.</VisibleText>
      </p>
      <PositiveNegativeBarChart
        ref="pos-neg-chart"
        class="main__collapsible-chart-content"
        v-if="barChartNode"
        :value="barChartNode"
        @change="selectionChanged"
      />
      <span
        class="info-msg__inline"
        v-if="!isActiveTabSentiment && !isThemeSelected"
      >
        <VisibleText>Select a theme to view scores.</VisibleText>
      </span>
    </Accordion>
    <Accordion
      class="main__tabs"
      title-element-type="h3"
      icon-location="right"
      :aria-hidden="modalOpen"
      :inert="modalOpen"
      :expand="true"
    >
      <template #heading>
        <span class="main__tabs-title">
          <VisibleText>Responses</VisibleText>
          {{ `(${textResponsesFiltered.length}/${textResponses.length})` }}
        </span>
      </template>
      <div class="main__tabs-container">
        <Spinner
          class="main__tabs-spinner"
          :is-loading="showResponseListLoadingSpinner"
          :componentStyle="true"
          message="loading response items"
          v-if="showResponseListLoadingSpinner"
        />
        <div class="main__tabs-container-options">
          <SwitchToggle
            ref="switch-toggle"
            class="main__tabs-container-options-curr-view switch--small switch--monochromatic"
            title="set current view mode"
            op1="Explore all sentiments"
            op2="Highlight keywords"
            :default="0"
            @onToggle="handleToggleUpdate"
          />
          <fieldset class="main__tabs-container-options-highlight">
            <legend>Select highlight options</legend>
            <div
              class="checkbox-group"
              v-for="(option, index) in tabHighlightFilters[activeTab]"
              :key="`highlight-option-${option.id}-${index}`"
            >
              <input
                type="checkbox"
                :id="`highlight-option-${option.id}`"
                :value="option.id"
                v-model="tabHighlightFiltersSelected[activeTab]"
                :disabled="!isActiveTabSentiment && selectedThemeIndex === -1"
              />
              <label
                :for="`highlight-option-${option.id}`"
                :data-parent="$options.name"
              >
                {{ translate(option.label, $options.name) }}
              </label>
            </div>
          </fieldset>
        </div>
        <template v-for="tab in tabs">
          <component
            :key="'tab-' + tab"
            :ref="'tab-' + tab"
            :is="tab"
            :highlight-filters="tabHighlightFiltersSelected[activeTab]"
            :show="activeTab == tab"
            v-show="activeTab == tab"
          >
          </component>
        </template>
      </div>
    </Accordion>
  </section>
</template>

<script>
// Components
import Search from "./Components/Search.vue"
import Sentiment from "./ProjectAnalysisTextMainSentiment.vue"
import Themes from "./ProjectAnalysisTextMainThemes.vue"
import Accordion from "@/components/UI/Accordion.vue"
import SvgIconDecorative from "@/components/UI/Svg/SvgIconDecorative.vue"
import SwitchToggle from "@/components/UI/SwitchToggle.vue"
import {
  PigeonDocNodeModel,
  PositiveNegativeBarChart
} from "@pigeonline/pigeondoc"

// Mixins
import AnalysisTextMixin from "@/utils/mixins/analysisTextMixin.js"
import SelectTextMixin from "./Mixins/selectTextMixin.js"

// Consts
import { HIGHLIGHT_TYPES } from "./Utils/constants.js"

const SENTIMENT_FILTER_BY = ["positive", "negative", "neutral"]
const SIMILARITY_SCORE_FILTER_BY = []

export default {
  name: "ProjectAnalysisTextMain",
  mixins: [AnalysisTextMixin, SelectTextMixin],
  components: {
    Search,
    Themes,
    Sentiment,
    Accordion,
    SvgIconDecorative,
    SwitchToggle,
    PositiveNegativeBarChart
  },
  data() {
    return {
      tabs: ["Sentiment", "Themes"],
      toggle: 0,
      selected: null, // selected client question
      isAutoGenerateModalVisible: false,
      isMounted: false,
      tabHighlightFilters: {
        Sentiment: [
          HIGHLIGHT_TYPES["pos-keyword"],
          HIGHLIGHT_TYPES["neg-keyword"]
        ],
        Themes: [
          HIGHLIGHT_TYPES["theme-keyword"],
          HIGHLIGHT_TYPES["theme-excerpt"],
          HIGHLIGHT_TYPES["theme-note"]
        ]
      },
      tabHighlightFiltersSelected: {
        Sentiment: ["pos-keyword", "neg-keyword"],
        Themes: ["theme-keyword", "theme-excerpt", "theme-note"]
      }
    }
  },
  computed: {
    activeTab: {
      get() {
        return this._activeTab
      },
      set(value) {
        this.setActiveTab(value)
      }
    },
    $activeTab() {
      if (!this.isMounted) return false
      return this.$refs["tab-" + this.activeTab][0]
    },
    activePosNegChartType() {
      return this.activeTab.toLowerCase() === "sentiment"
        ? "Response Sentiment"
        : "Response Theme Strength"
    },
    barChartResponsesAll() {
      return [
        ...this.$activeTab.textResponsesFilteredWithoutVisSelection
      ].map(el => this.mapResponseToBarChartNodeItem(el))
    },
    barChartResponsesFilteredAndSorted() {
      return [...this.$activeTab.textResponsesSorted].map(el =>
        this.mapResponseToBarChartNodeItem(el)
      )
    },
    barChartNode() {
      if (
        !this.isMounted ||
        this.textResponsesFiltered.length < 1 ||
        (!this.isActiveTabSentiment && !this.isThemeSelected)
      ) {
        return false
      }

      const title = this.activePosNegChartType
      const tableColumns = ["respondent", "response", "score", this.activeTab]
      const tableFilterByLabel =
        this.activeTab.toLowerCase() === "sentiment"
          ? SENTIMENT_FILTER_BY
          : SIMILARITY_SCORE_FILTER_BY

      return new PigeonDocNodeModel({
        type: "positiveNegativeBarChart",
        content: {
          title: `${title}`,
          data: this.barChartResponsesFilteredAndSorted,
          data__ALL: this.barChartResponsesAll
        },
        meta: {
          positiveColour: "#117733",
          negativeColour: "#cc6677",
          highlightColour: "#56b4e9",
          selectedColour: "#0072b2",
          table: {
            columns: tableColumns,
            filterByLabel: tableFilterByLabel
          }
        }
      })
    }
  },
  mounted() {
    this.isMounted = true

    // update selected text option
    if (this.selectedTextQuestion && this.selectedTextQuestion._id) {
      this.selected = this.selectedTextQuestion._id.$oid
    }
  },
  methods: {
    mapResponseToBarChartNodeItem(el) {
      return {
        id: el.id,
        responseID: el.idx,
        response: el.response,
        score: this.$activeTab.findResponseScore(el),
        label: this.getScoreLabel(el.scores)
      }
    },
    shouldRenderPosNegChart(bool) {
      if (!bool) return
      if (!this.$refs["pos-neg-chart"]) return
      this.$refs["pos-neg-chart"].renderChart()
    },
    selectionChanged(selection) {
      const ids = selection.map(el => el.id)
      const selectedResponses = this.selectedTextQuestionResponses.filter(el =>
        ids.includes(el.id)
      )
      this.setSelectedResponseIds(selectedResponses.map(el => el.id))
    },
    selectQuestionChanged() {
      if (this.selected !== this.selectedTextQuestion._id.$oid) {
        this.selectTextQuestion(
          this.textQuestions.find(c => c._id.$oid === this.selected)
        )
      }
    },
    getScoreLabel(scores) {
      if (this.activeTab.toLowerCase() === "sentiment") {
        if (scores.sentiment >= 0.05) return "positive"
        if (scores.sentiment <= -0.05) return "negative"
        if (scores.sentiment > -0.05 && scores.sentiment < 0.05)
          return "neutral"
      } else {
        return this.themes[this.selectedThemeIndex].name
      }
    },

    /* Switch toggle methods */
    handleToggleUpdate(val) {
      this.toggle = val
    }
  },
  watch: {
    toggle: function(val) {
      if (val === 0 && this.activeTab !== "Sentiment")
        this.activeTab = "Sentiment"
      if (val === 1 && this.activeTab !== "Themes") this.activeTab = "Themes"
    },
    activeTab: function(val) {
      if (!val) return
      if (val === "Sentiment" && this.toggle === 1)
        this.$refs["switch-toggle"].value = 0
      if (val === "Themes" && this.toggle === 0)
        this.$refs["switch-toggle"].value = 1
    }
  }
}
</script>
