const SECTION_TRANSLATIONS = {
  benchmarkHeading: {
    en: "BENCHMARK SUMMARY",
    fr: "SOMMAIRE DE L'ANALYSE COMPARATIVE"
  },
  benchmarkCaption: {
    en: "Overall averages",
    fr: "Moyennes globales"
  },
  driversDetailsText: {
    en: "To compute this driver we used:",
    fr: "Pour le calcul de ce déterminant, nous avons utilisé:"
  },
  methodology: {
    en: "Methodology",
    fr: "Méthodologie"
  },
  methodologyAppendix: {
    en: "APPENDIX 1",
    fr: "ANNEXE 1"
  },
  methodologyDesc: {
    en:
      "All organizations included in this report have been approved by the Institute for Citizen-Centred Service. Only Common Measurement Tool questions (5 point scale) are used for comparison. While use of the CMT helps ensure the questions and response scales are consistent, many other methodological factors can affect the comparability of survey results. This section is included for analysts who want to assess more closely the various methodological factors that might affect comparability",
    fr:
      "Toutes les organisations en question dans le présent rapport ont été approuvées par l’Institut des services axés sur les citoyens. Seules les questions de l’Outil de mesures communes (qui sont assorties d’une échelle de cinq points) sont utilisées dans le cadre de la comparaison. Tandis que le recours à l’OMC contribue à assurer la cohérence des questions et des échelles de réponses, un grand nombre d’autres facteurs méthodologiques peuvent influer sur la comparabilité des résultats de sondage. La présente section s’adresse aux analystes souhaitant évaluer plus attentivement les divers facteurs méthodologiques susceptibles d'avoir un effet sur la comparabilité des résultats."
  },
  performanceHeading: {
    en: "Performance Analysis",
    fr: "Analyse du rendement"
  },
  performanceDesc: {
    en:
      "Performance analysis shows you how well your categorical questions score, and finds unique stories by finding outliers and difference in performance across segments.",
    fr:
      "L’analyse du rendement révèle les résultats liés aux questions assorties de catégories et permet de cerner des constatations uniques après avoir repéré les aberrations ainsi que les différences entre les segments sur le plan du rendement."
  },
  performanceTitle: {
    en: "Question performance",
    fr: "Rendement des questions"
  },
  performanceChart: {
    en: "Question Performance Chart",
    fr: "Graphique du rendement des questions"
  },
  qByQHeading: {
    en: "QUESTION BY QUESTION RESULTS",
    fr: "RÉSULTATS PAR QUESTION"
  },
  qByQDesc: {
    en:
      "For every CMT question, this section provides summary information on how your clients responded. It also provides comparison data against each benchmarking group.",
    fr:
      "Pour chaque question de l'OMC, cette section présente des renseignements sommaires sur les réponses de vos clients. Elle produit aussi des données de comparaison pour chaque groupe d'analyse comparative."
  }
}

const HELPER_TRANSLATIONS = {
  comparisonGreater: {
    en: "Greater or equal to mean (outlier)",
    fr: "Supérieur ou égal à la moyenne (aberration)"
  },
  comparisonLower: {
    en: "Lower than mean (outlier)",
    fr: "Inférieur à la moyenne (aberration)"
  },
  comporisonP: {
    en: "positive",
    fr: "positif"
  },
  comparisonN: {
    en: "negative",
    fr: "négatif"
  },
  driversHeader: {
    en: "PRIORITIES FOR SERVICE IMPROVEMENT",
    fr: "PRIORITÉS POUR L'AMÉLIORATION DES SERVICES"
  },
  driversChatTitle: {
    en: "Drivers of",
    fr: "Déterminants de"
  },
  driversXAxis: {
    en:
      "This value represents the importance of each feature. These numbers will add to 1. A feature with importance above 0.5 explains over half of the outcome.",
    fr:
      "Cette valeur représente l’importance de chaque caractéristique. Ces résultats totaliseront 1. Une caractéristique dont l’importance s’établit au-dessus de 0,5 explique plus de la moitié du résultat."
  },
  methodologyTableCaption: {
    en: "Survey details",
    fr: "Détails du sondage"
  },
  methodologyCols: {
    en: ["ID", "Date Collected", "Sample Size", "Response Rate"],
    fr: ["ID", "Données recueillies", "Taille d'échantillon", "Taux de réponse"]
  },
  performanceChartX: {
    en: "% Favorable",
    fr: "% de réponses favorables"
  },
  priorityChartTitle: {
    en: "Priority Matrix for",
    fr: "Matrice des priorités pour "
  },
  priorityXAxis: {
    en:
      "This value represents the average score from all respondents. The higher the number, the higher your users rated your performance.",
    fr:
      "Cette valeur représente le score moyen de tous les répondants. Plus le résultat est élevé, meilleure est l’évaluation de votre rendement par vos utilisateurs."
  },
  qByQChartAllTitle: {
    en: "All Groups",
    fr: "Tous les groupes"
  },
  qByQChartAllPerf: {
    en: "All Performers",
    fr: "Tous les résultats"
  },
  qByQAllPerfCols: {
    en: ["ID", "Average", "Rank", "Percentile", "Segments"],
    fr: ["ID", "Score moyen", "Classement", "Percentile", "Segments"]
  },
  qByQTopPerfCaption: {
    en: "Top and Lowest Performers",
    fr: "Meilleurs et moins bon rendements"
  },
  qByQTopPerfsCols: {
    en: ["Category", "ID", "Average", "Rank", "Percentile", "Segments"],
    fr: [
      "Catégorie",
      "ID",
      "Score moyen",
      "Classement",
      "Percentile",
      "Segments"
    ]
  },
  qByQTotalBenchmarks: {
    en: "Total # of benchmarks",
    fr: "Nbre total de mesures de référence"
  },
  qByQNoOrg: {
    en: "There are no other organizations to compare with.",
    fr:
      "Il n'y a aucune autre organisation avec laquelle établir une comparaison."
  },
  qByQResponseTableCols: {
    en: ["Response", "Count", "Percent"],
    fr: ["Réponse", "Nombre", "Proportion en pourcentage"]
  },
  qByQResponseSummary: {
    en: "Response summary",
    fr: "Résumé de réponse"
  }
}

const LABEL_TRANSLATIONS = {
  avgScore: {
    en: "Average score",
    fr: "Score moyen"
  },
  count: {
    en: "Count",
    fr: "Nombre"
  },
  drivers: {
    en: "drivers",
    fr: "déterminants"
  },
  importance: {
    en: "importance",
    fr: "importance"
  },
  improve: {
    en: "improve",
    fr: "à améliorer"
  },
  lowestPerformers: {
    en: "Lowest performers",
    fr: "Moins bon rendements"
  },
  maintain: {
    en: "maintain",
    fr: "à maintenir"
  },
  monitor: {
    en: "monitor",
    fr: "à surveiller"
  },
  org: {
    en: "org",
    fr: "organisation"
  },
  overallAvg: {
    en: "Overall average:",
    fr: "Moyenne globale:"
  },
  response: {
    en: "Response",
    fr: "Réponse"
  },
  performance: {
    en: "performance",
    fr: "rendement"
  },
  protect: {
    en: "protect",
    fr: "à protéger"
  },
  yourOrg: {
    en: "Your org",
    fr: "Votre organisation"
  },
  total: {
    en: "Total",
    fr: "Total"
  },
  topPerformers: {
    en: "Top performers",
    fr: "Meilleurs rendements"
  }
}

export { SECTION_TRANSLATIONS, HELPER_TRANSLATIONS, LABEL_TRANSLATIONS }
