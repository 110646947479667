// Components
import SelectedTextModal from "../Modals/ThemesAdd.vue"
import CreateNoteModal from "../Modals/CreateNote.vue"

export default {
  data() {
    return {
      selection: null,
      isSelectedTextModalVisible: false,
      isCreateNoteModalVisible: false,
      selectedTextModalType: "keyword"
    }
  },
  components: {
    SelectedTextModal,
    CreateNoteModal
  },
  methods: {
    onExcerpt(selection) {
      this.selection = selection
      this.isSelectedTextModalVisible = true
      this.selectedTextModalType = "excerpt"
    },
    onKeyword(selection) {
      this.selection = selection
      this.isSelectedTextModalVisible = true
      this.selectedTextModalType = "keyword"
    },
    onNote(selection) {
      this.selection = selection
      this.isCreateNoteModalVisible = true
    },
    onBan(selection) {
      this.$root.$emit("ban-keyword", selection.content.trim())
    },
    onSearch(selection) {
      this.$root.$emit("search-keyword", selection.content.trim())
    },
    closeModal() {
      this.isSelectedTextModalVisible = false
      this.isCreateNoteModalVisible = false
      // reset to default
      this.selectedTextModalType = "keyword"
    }
  }
}
