<template>
  <div
    :class="['theme__popup', { show: showDialog }]"
    v-click-outside="forceClose"
  >
    <button type="button" class="open-btn" @click="onClickOpen">
      {{ keywords.length }}
      <VisibleText>keywords</VisibleText>
    </button>
    <DialogPopup
      class="theme__popup-dialog"
      :show="showDialog"
      @close="showDialog = false"
      v-if="keywords.length > 0"
    >
      <template #header>
        {{ themeName }} - {{ translate("keywords", $options.name) }}
      </template>
      <template #body>
        <ul class="theme__popup-list theme__popup-list--inline">
          <li
            v-for="keyword in keywords"
            :key="keyword"
            class="item keyword-pill"
          >
            <button
              class="keyword-pill__btn"
              @click.prevent="$root.$emit('search-keyword', keyword, true)"
            >
              {{ keyword }}
            </button>
          </li>
        </ul>
        <p class="theme__popup-hint">
          <VisibleText>Click a keyword to perform search.</VisibleText>
        </p>
      </template>
    </DialogPopup>
  </div>
</template>

<script>
import DialogPopup from "@/components/UI/DialogPopup.vue"

export default {
  name: "KeywordsPopup",
  components: {
    DialogPopup
  },
  props: {
    themeName: {
      default: () => "theme",
      type: String
    },
    keywords: {
      default: () => [],
      type: Array
    }
  },
  data() {
    return {
      showDialog: false
    }
  },
  methods: {
    forceClose() {
      if (this.showDialog) this.showDialog = false
    },
    onClickOpen() {
      if (this.keywords.length > 0) this.showDialog = true
    }
  }
}
</script>
