// Models
import { PigeonDocModel } from "@pigeonline/pigeondoc"

// Helpers
import { mapGetters, mapActions } from "vuex"

export default {
  data() {
    return {
      isMounted: false,
      currViewIdx: 0,
      unsavedChanges: false,
      users: [],
      resetListener: false,
      shareStatus: null
    }
  },
  computed: {
    PROFILES_SERVICE() {
      return this.$services.PROFILES_SERVICE
    },
    ...mapGetters("user", {
      profile: "getProfile"
    }),
    modalGroups() {
      if (!this.isMounted) return
      return this.$refs["share-modal"].$el.getElementsByClassName("modal-group")
    },
    usersShared() {
      return this.users
        .filter(user => user.type == "shared" || user.type == "selected")
        .sort((a, b) => new String(a.label).localeCompare(new String(b.label)))
    },
    DEFAULT_PERMS() {
      return {
        project: ["read"],
        dataset: ["read"]
      }
    },
    DEFAULT_PERMS_LABELS() {
      return {
        project: [
          { label: "Read", value: "read", disabled: true },
          { label: "Edit", value: "edit" }
        ],
        dataset: [
          { label: "Read", value: "read", disabled: true },
          { label: "Edit", value: "edit" }
        ]
      }
    },
    SHARING_KEYS() {
      return Object.keys(this.DEFAULT_PERMS).filter(key => this.IS_OWNER[key])
    },
    SHARING_PK() {
      return "project" // modify this to `dataset` if it's the main project being shared
    },
    PRIMARY_SHARED() {
      return (this[this.SHARING_PK] && this[this.SHARING_PK].shared) || []
    },
    IS_OWNER() {
      let obj = {}
      Object.keys(this.DEFAULT_PERMS).map(
        function(key) {
          if (!this[key]) return
          obj[key] =
            this.profile && this.profile.django_ref_id === this[key].owner
        }.bind(this)
      )
      return obj
    },
    isSaving() {
      return this.shareStatus === "saving"
    },
    isSaveBtnDisabled() {
      return !this.unsavedChanges || this.isSaving
    },
    shareUrl() {
      const domain = `${window.location.protocol}//${window.location.hostname}`
      const port = window.location.port ? `:${window.location.port}` : ""
      const path = this.$route.path
      return `${domain}${port}${path}`
    }
  },
  methods: {
    ...mapActions("project", ["setProject"]),
    ...mapActions("datasetWizard", ["setDatasetProject"]),
    async mount() {
      this.isMounted = true
      this.users = await this.fetchUsers()
      this.goToView(0)
    },
    bkCompatiblePk(r) {
      return ["string", "number"].includes(typeof r)
        ? r
        : typeof r.pk !== "undefined"
        ? r.pk
        : typeof r.id !== "undefined"
        ? r.id
        : -1
    },
    find(user, arr) {
      return arr.filter(r =>
        [user.id, user.email].includes(this.bkCompatiblePk(r))
      )[0]
    },
    sharingPerms(user) {
      let perms = { ...this.DEFAULT_PERMS }
      this.SHARING_KEYS.map(
        function(key) {
          if (!this[key]) return
          if (this.find(user, this[key].shared)) {
            perms[key] = this.find(user, this[key].shared).perms || perms[key]
          }
        }.bind(this)
      )
      return perms
    },
    async fetchUsers() {
      try {
        let response = await this.PROFILES_SERVICE.users()
        let internalUsers = response.reduce((users, user) => {
          let temp = {}
          temp["id"] = user.id
          temp["label"] =
            !user.fullname || user.fullname == "" ? user.email : user.fullname
          temp["value"] = user.email
          temp["type"] = this.find(user, this.PRIMARY_SHARED)
            ? "shared"
            : "suggested"
          temp["permissions"] = this.sharingPerms(user)
          users.push(temp)
          return users
        }, [])
        return internalUsers
          .concat(
            this.PRIMARY_SHARED.filter(
              r =>
                !this.find(
                  { id: this.bkCompatiblePk(r), email: this.bkCompatiblePk(r) },
                  internalUsers
                )
            ).map(row => {
              return {
                id: this.bkCompatiblePk(row),
                label: this.bkCompatiblePk(row),
                value: this.bkCompatiblePk(row),
                type: "shared",
                permissions: this.sharingPerms({ id: row.pk, email: row.pk })
              }
            })
          )
          .sort((a, b) =>
            new String(a.label).localeCompare(new String(b.label))
          )
      } catch (e) {
        throw e
      }
    },
    addNewUser(user) {
      user["permissions"] = this.DEFAULT_PERMS
      this.users = [...this.users, user]
    },
    copy() {
      // copy project url to clipboard
      const copyText = document.getElementById("share-url")
      copyText.select()
      copyText.setSelectionRange(0, 99999)
      document.execCommand("copy")

      this.$refs["copy-url"].textContent = "Successfully copied!"
    },
    closeModal() {
      this.$emit("closeModal")
    },
    async saveChanges(reloadOnSaveSuccess = false) {
      const users = [...this.usersShared]
      const sharingTypes = [...this.SHARING_KEYS]
      const shared = {}
      sharingTypes.map(function(type) {
        shared[type] = []
      })
      for (let i = 0; i < users.length; i++) {
        let user = users[i]
        for (const [type, permissions] of Object.entries(user.permissions)) {
          shared[type] &&
            shared[type].push([user.id, user.notify || false, permissions])
        }
      }
      if (Object.keys(shared).length === 0) return

      this.shareStatus = "saving"
      for (const [type, value] of Object.entries(shared)) {
        let _shared = this[type] && this[type].shared

        try {
          switch (type) {
            case "dataset":
              this.dataset.shared = value
              try {
                await this.setDatasetProject(
                  await this.$pigeonline.projects.update(this.dataset)
                )
              } catch (e) {
                if (_shared) this.dataset.shared = _shared
                throw new Error(e)
              }
              break
            case "project":
              this.project.shared = value
              try {
                await this.setProject(
                  await this.$pigeonline.projects.update(this.project)
                )

                // save shared value to project report as well
                if (this.project.report) {
                  const report = await this.$pigeonline.projects.getByID(
                    PigeonDocModel,
                    this.project.report
                  )
                  report.shared = value
                  await this.$pigeonline.projects.update(report)
                }
              } catch (e) {
                if (_shared) this.project.shared = _shared
                throw new Error(e)
              }
              break
            default:
              continue
          }
        } catch (e) {
          this.shareStatus = "error"
          throw new Error("shareMixin.js:saveChanges:: " + e)
        }
      }
      if (this.shareStatus === "error") {
        this.$refs["share-modal"].showMessage(
          "error",
          "There was an error processing your request. Please try again later."
        )
      }
      if (this.shareStatus === "saving") {
        this.$refs["share-modal"].showMessage(
          "success",
          "Your changes have been saved successfully."
        )
        // reset share status
        this.shareStatus = null
        this.unsavedChanges = false

        // reload page
        if (reloadOnSaveSuccess) window.location.reload()
      }
    },

    // Go to view
    goToView(index) {
      if (index >= this.modalGroups.length || index < 0) return
      if (index > 0) {
        this.modalGroups[index - 1].style.display = "none"
        this.$nextTick(() => this.modalGroups[index].focus())
      }
      if (index < this.modalGroups.length - 1)
        this.modalGroups[index + 1].style.display = "none"
      this.modalGroups[index].style.display = "grid"
      this.modalGroups[index].parentElement.scrollTop = 0
      this.currViewIdx = index
      this.resetListener = !this.resetListener
    },
    back() {
      this.goToView(this.currViewIdx - 1)
    }
  }
}
