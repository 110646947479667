var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"register-main__content"},[_c('h1',{staticClass:"register-main__title"},[_c('VisibleText',[_vm._v("Create an account")])],1),_c('Register',{ref:"register",staticClass:"register-main__form",on:{"registered":_vm.registered},scopedSlots:_vm._u([{key:"email",fn:function(ref){
var email = ref.email;
var errors = ref.errors;
var emailBlurCheck = ref.emailBlurCheck;
var update = ref.update;
return [_c('label',{staticClass:"auth-form__label",attrs:{"for":"email"}},[_c('VisibleText',[_vm._v("Email")]),_c('input',{ref:"email",staticClass:"auth-form__input",attrs:{"id":"email","type":"text","required":"","aria-required":"true","aria-invalid":!!errors.length},domProps:{"value":email},on:{"input":function($event){return update('email', $event.target.value)},"blur":emailBlurCheck}})],1)]}},{key:"password",fn:function(ref){
      var password = ref.password;
      var errors = ref.errors;
      var passwordInputCheck = ref.passwordInputCheck;
      var passwordBlurCheck = ref.passwordBlurCheck;
      var update = ref.update;
return [_c('label',{staticClass:"auth-form__label",attrs:{"for":"password"}},[_c('VisibleText',[_vm._v("Password")]),_c('input',{staticClass:"auth-form__input",attrs:{"id":"password","type":"password","required":"","aria-required":"true","aria-invalid":!!errors.length,"aria-describedby":"password-desc"},domProps:{"value":password},on:{"input":function($event){return update('password', $event.target.value)},"blur":passwordBlurCheck}}),(
            !errors.includes(
              'Passwords should be at least 10 characters long'
            )
          )?_c('span',{class:{ valid: password.length >= 10 },attrs:{"id":"password-desc"}},[_c('VisibleText',[_vm._v("Passwords should be at least 10 characters long ")])],1):_vm._e()],1)]}},{key:"passwordConf",fn:function(ref){
      var passwordConf = ref.passwordConf;
      var errors = ref.errors;
      var passwordConfBlurCheck = ref.passwordConfBlurCheck;
      var update = ref.update;
return [_c('label',{staticClass:"auth-form__label",attrs:{"for":"password-conf"}},[_c('VisibleText',[_vm._v("Confirm Password")]),_c('input',{ref:"password-conf",staticClass:"auth-form__input",attrs:{"id":"password-conf","type":"password","required":"","aria-required":"true","aria-invalid":!!errors.length},domProps:{"value":passwordConf},on:{"input":function($event){return update('passwordConf', $event.target.value)},"blur":passwordConfBlurCheck}})],1)]}}])}),_c('p',[_c('VisibleText',[_vm._v("Already have an account? Use our ")]),_c('router-link',{attrs:{"to":"/login"}},[_c('VisibleText',[_vm._v("login page")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }