<template>
  <div class="account-profile__content">
    <section class="account-profile__demo-account" v-if="isDemoUser">
      <h1 class="account-profile__demo-account-title title">
        <VisibleText>Free account</VisibleText>
        <small class="text-muted">
          <VisibleText>Demo version</VisibleText>
        </small>
      </h1>
      <VisibleText
        >You are currently using a free account (demo version). As a result,
        your access will be limited.
      </VisibleText>
    </section>
    <section class="account-profile__organization" v-if="organization">
      <h1 class="account-profile__organization-title title">
        {{ organization.organization_name }}
        <small class="text-muted">
          <VisibleText>Organization</VisibleText>
        </small>
      </h1>
      <p class="account-profile__organization-desc">
        <VisibleText>You are currently connected to </VisibleText>
        <span class="account-profile__organization-info">
          {{ organizationWithDepartmentName }}
        </span>
      </p>
      <div class="account-profile__organization-admin" v-if="isAdmin">
        <p>
          <VisibleText>You are also an </VisibleText>
          <strong><VisibleText>admin </VisibleText></strong>
          <VisibleText
            >of the organization. As an organization admin, you can:
          </VisibleText>
        </p>
        <div class="account-profile__organization-admin-options">
          <router-link to="/account/send-invites" class="clickable-box">
            <span><VisibleText>Invite new users</VisibleText></span>
          </router-link>
          <router-link to="/account/approve-users" class="clickable-box">
            <span><VisibleText>Approve new users</VisibleText></span>
          </router-link>
          <HasPermission to="benchmarking-manager">
            <router-link to="/benchmark-manager" class="clickable-box">
              <span><VisibleText>Build your own benchmarks</VisibleText></span>
            </router-link>
          </HasPermission>
        </div>
      </div>
    </section>
    <section v-if="profile">
      <h1 class="account-profile__organization-title title">
        <VisibleText>Edit your profile</VisibleText>
        <small class="text-muted"><VisibleText>Profile</VisibleText></small>
      </h1>
      <form class="account-profile__form" @submit.prevent="save" novalidate>
        <p>
          <VisibleText>All fields marked with </VisibleText>
          <span class="required-indicator"> * </span>
          <VisibleText>are required.</VisibleText>
        </p>
        <label for="fullname" class="form-label">
          <VisibleText>Full Name </VisibleText>
          <span class="required-indicator"> * </span>
        </label>
        <p
          class="error-msg__inline form__err--block"
          id="full-name-err"
          v-show="errMsg.fullName"
        >
          {{ errMsg.fullName }}
        </p>
        <input
          aria-describedby="full-name-err"
          id="fullname"
          v-model="vModels.fullname.new"
          type="text"
          class="account-profile__form-input"
          required
          ref="full-name"
          autocomplete="on"
          :aria-invalid="errMsg.fullName ? 'true' : 'false'"
        />

        <label for="position" class="form-label">
          <VisibleText>Position</VisibleText>
          <input
            id="position"
            v-model="vModels.position.new"
            type="text"
            class="account-profile__form-input"
            autocomplete="off"
          />
        </label>
        <button
          class="account-profile__form-btn"
          type="submit"
          :disabled="disableSubmit"
        >
          <VisibleText>Save</VisibleText>
        </button>
      </form>
    </section>
  </div>
</template>

<script>
import UserMixin from "@/utils/mixins/userMixin"

export default {
  name: "accountProfile",
  mixins: [UserMixin],
  data() {
    return {
      errMsg: {
        fullName: null
      },
      vModels: {
        fullname: {
          original: "",
          new: ""
        },
        position: {
          original: "",
          new: ""
        }
      }
    }
  },
  computed: {
    disableSubmit: function() {
      return Object.values(this.vModels).every(v => v.original === v.new)
    }
  },
  mounted() {
    this.$watch(
      "profile",
      function(newVal) {
        if (!newVal) return
        this.setValue("fullname", newVal.fullname)
        this.setValue("position", newVal.position)
      },
      { deep: true, immediate: true }
    )
  },
  methods: {
    setValue(property, val) {
      if (val) {
        this.vModels[property].original = val
        this.vModels[property].new = val
      }
    },
    validate() {
      let firstErr = null
      if (!this.vModels.fullname.new) {
        this.errMsg.fullName = "Error: full name is required"
        if (!firstErr) firstErr = "full-name"
      }
      if (firstErr) this.$refs[firstErr].focus()
      return !(firstErr && firstErr.length > 0)
    },
    async save() {
      if (!this.validate()) return
      this.setLoading(true)
      try {
        const profile = await this.$pigeonline.profile.save({
          fullname: this.vModels.fullname.new,
          position: this.vModels.position.new
        })
        this.setProfile(profile)
      } catch (e) {
        alert(
          "An error occured when processing your request. Please try again later"
        )
        return
      }
      alert("Your profile has been updated successfully.")
      this.setLoading(false)
    }
  }
}
</script>
