<template>
  <Modal
    class="project-analysis-text-modal"
    uid="modal-themes-auto-generate"
    header-tag="h3"
    :ref="modalRef"
    :show="show"
    @close="closeModal"
  >
    <template #header class="modal-title">
      <VisibleText>Auto-generate themes</VisibleText>
    </template>

    <!-- Auto-generated Themes -->
    <div class="suggested-themes__wrapper">
      <div class="suggested-themes__loading" v-if="isLoading">
        <p class="suggested-themes__loading-text">
          <VisibleText>{{ loadingText }}</VisibleText>
          <span class="suggested-themes__loading-spinner spinner"></span>
        </p>
      </div>
      <template v-else>
        <div class="suggested-themes__empty" v-if="suggestedThemes.length == 0">
          <button
            class="suggested-themes__empty-auto-generate-btn btn-default"
            role="button"
            @click.stop="autoGenerateThemes()"
          >
            <VisibleText>Auto-generate themes</VisibleText>
          </button>
        </div>
        <template v-else>
          <h3 class="suggested-themes__heading">
            <VisibleText>Suggested themes for selected respondents</VisibleText>
          </h3>
          <ThemeList
            class="suggested-themes__list"
            ref="suggested-themes"
            type="keyword"
            :themes="suggestedThemes"
            @update-theme-title="
              updateThemeTitle(
                suggestedThemes[$event.index],
                $event.title,
                getConsolidatedThemeTitles()
              )
            "
          />
          <div class="suggested-themes__options">
            <button
              class="btn-default"
              role="button"
              @click.stop="addToSavedThemes()"
              :disabled="showSpinner"
            >
              <SvgIconDecorative icon="add" />
              <VisibleText>Add to saved themes</VisibleText>
            </button>
            <button
              class="btn-secondary"
              role="button"
              @click.stop="autoGenerateThemes()"
            >
              <VisibleText>Auto-generate themes</VisibleText>
            </button>
          </div>
        </template>
      </template>
    </div>

    <!-- Saved Themes -->
    <Accordion
      class="saved-themes__accordion"
      title-element-type="span"
      icon-location="right"
    >
      <template #heading>
        <span>
          <VisibleText>Saved themes</VisibleText>
          ({{ themeList.length }})
        </span>
      </template>
      <ThemeList
        :ref="themeListRef"
        type="keyword"
        :themes="themeList"
        :should-focus-last-theme="shouldFocusLastTheme"
        :key="updateKey"
        @update-theme-title="
          updateThemeTitle(themeList[$event.index], $event.title)
        "
        @updated="unsavedChanges = true"
      />
    </Accordion>

    <template #footer class="modal-footer">
      <div class="modal-footer__options">
        <button
          class="btn-save btn-default"
          role="button"
          @click.stop="save"
          :disabled="!unsavedChanges || showSpinner"
        >
          <VisibleText>Save changes</VisibleText>
        </button>
        <button
          class="btn-create btn-secondary"
          role="button"
          @click.stop="createTheme"
        >
          <VisibleText>Create new theme</VisibleText>
        </button>
      </div>
    </template>
  </Modal>
</template>

<script>
// Components
import Accordion from "@/components/UI/Accordion.vue"
import SvgIconDecorative from "@/components/UI/Svg/SvgIconDecorative.vue"

// Mixins
import ThemeListMixin from "../Mixins/themeListMixin.js"

export default {
  name: "ThemesAutoGenerate",
  mixins: [ThemeListMixin],
  components: {
    Accordion,
    SvgIconDecorative
  },
  props: {
    show: {
      default: () => false,
      type: Boolean
    }
  },
  data() {
    return {
      isLoading: false,
      loadingText: "Generating themes... please wait a minute.",
      suggestedThemes: []
    }
  },
  async mounted() {
    await this.autoGenerateThemes()
  },
  methods: {
    getConsolidatedThemeTitles() {
      // for checking duplicates

      return [...this.themeList, ...this.suggestedThemes].map(el =>
        el.name.trim().toLowerCase()
      )
    },
    async autoGenerateThemes() {
      this.isLoading = true
      this.suggestedThemes = []
      try {
        this.suggestedThemes = await this.generateThemes()
        if (this.suggestedThemes.length === 0) {
          this.showModalMessage(
            "error",
            "Sorry, we could not find any themes to suggest. Please try using a different dataset or create themes manually."
          )
          return
        }

        // validate duplicate theme titles
        this.suggestedThemes.map(
          el =>
            (el.name = this.getValidatedThemeTitle(
              el.name,
              el.name,
              this.getConsolidatedThemeTitles()
            ))
        )
      } catch (e) {
        this.showModalMessage(
          "error",
          "An error occured processing your request. Please try again later."
        )
      } finally {
        this.isLoading = false
      }
    },
    async addToSavedThemes() {
      this.setShowSpinner(true)
      try {
        await this.saveThemes([...this.themeList, ...this.suggestedThemes])
        this.suggestedThemes = []

        // success
        this.showModalMessage("success", "Themes added successfully.")
      } catch (e) {
        // error
        this.showModalMessage(
          "error",
          "An error occured processing your request. Please try again later."
        )
        console.error("ThemesAutoGenerate.vue:addToSavedThemes: " + e)
      } finally {
        this.setShowSpinner(false)
      }
    }
  }
}
</script>
