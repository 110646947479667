<template>
  <section id="custom-topic-model">
    <div class="section__top-bar">
      <h1 class="section__top-bar-title">
        <router-link to="/custom-topic-model">
          <VisibleText>Custom Topic Model</VisibleText>
        </router-link>
      </h1>
      <div class="section__top-bar-actions-wrapper">
        <CreateProject
          class="btn-default"
          :project="newGroup"
          @projectCreated="newGroupCreated"
          @projectCreationError="newGroupCreationError"
        >
          <VisibleText>Create Topic Model</VisibleText>
        </CreateProject>
      </div>
    </div>
    <router-view></router-view>
  </section>
</template>

<script>
// Components
import { CreateProject } from "@pigeonline/core"

// Models
import CustomTopicModelGroup from "@/components/CustomTopicModel/Models/CustomTopicModelGroup.js"

export default {
  name: "CustomTopicModel",
  components: {
    CreateProject
  },
  data() {
    return {
      newGroup: new CustomTopicModelGroup()
    }
  },
  created() {
    document.title = `Custom Topic Model | ${this.$theme.theme.pageTitle}`
    if (this.userInformation) this.setOwnerInfo()
  },
  computed: {
    userInformation() {
      return (
        this.$store.getters["user/getProfile"] &&
        this.$store.getters["user/getOrganization"]
      )
    }
  },
  methods: {
    async newGroupCreated(group) {
      this.$router.push({
        name: "customTopicModelEdit",
        params: { id: group.id }
      })
    },
    newGroupCreationError() {
      this.setConfirmText({
        btn: "okay",
        title: "Error Occured",
        message:
          "Sorry, there was a problem creating a group. Please try again."
      })
      this.setConfirmSourceComponent("topicModel")
      this.setConfirmType("error")
      this.setConfirmIsVisible(true)
    },
    setOwnerInfo() {
      this.newGroup.setCreatedBy(
        this.$store.getters["user/getProfile"].fullname,
        this.$store.getters["user/getOrganization"].organization_name
      )
    }
  },
  watch: {
    userInformation: function(profile) {
      if (profile) {
        this.setOwnerInfo()
      }
    }
  }
}
</script>
