import Vue from "vue"

/**
 * Store any objects that are used in a global scope but cannot be
 * categorized or grouped into its own module.
 */

export default {
  namespaced: true,
  state() {
    return {
      modalOpen: false,
      datasetProjects: []
    }
  },
  getters: {
    getModalOpen: state => state.modalOpen,
    getDatasetProjects: state => state.datasetProjects
  },
  mutations: {
    SET_DATASET_PROJECTS(state, payload) {
      Vue.set(state, "datasetProjects", payload)
    },
    SET_MODAL_OPEN(state, payload) {
      Vue.set(state, "modalOpen", payload)
    },
    RESET_GLOBAL_MODULE(state) {
      Vue.set(state, "datasetProjects", [])
      Vue.set(state, "modalOpen", false)
    }
  },
  actions: {
    async setDatasetProjects({ commit }, datasetProjects) {
      await commit("SET_DATASET_PROJECTS", datasetProjects)
    },
    async setModalOpen({ commit }, payload) {
      await commit("SET_MODAL_OPEN", payload)
    },
    async resetGlobalModule({ commit }) {
      await commit("RESET_GLOBAL_MODULE")
    }
  }
}
