<template>
  <div>
    <article class="project-analysis-item__wrapper">
      <div class="project-analysis-item__header-wrapper">
        <h3 class="project-analysis-item__header-wrapper-title">
          {{ dependentVariableTitle }}
        </h3>
        <!--------------------------------------------
        |
        | ACTIONS
        |
        --------------------------------------------->
        <button
          class="project-analysis-item__header-wrapper-btn"
          @click="isSelectVarVisible = true"
          :disabled="statusDisableActionBtns"
        >
          <SvgIconDecorative
            class="project-analysis-item__header-wrapper-icon"
            icon="edit-alt"
          />
          <VisibleText>Modify Variables</VisibleText>
        </button>
        <button
          class="project-analysis-item__header-wrapper-btn"
          @click="save"
          :disabled="statusDisableActionBtns"
        >
          <SvgIconDecorative
            class="project-analysis-item__header-wrapper-icon"
            icon="report"
          />
          <VisibleText>Save To Report</VisibleText>
        </button>
        <button
          class="project-analysis-item__header-wrapper-btn delete"
          @click="onClickDelete"
          :disabled="statusDisableActionBtns"
        >
          <SvgIconDecorative
            class="project-analysis-item__header-wrapper-icon-delete"
            icon="delete"
          />
          <VisibleText>Delete</VisibleText>
        </button>
      </div>

      <!--------------------------------------------
      |
      | CHARTS
      |
      --------------------------------------------->
      <DriversCharts
        :dependent-variable-title="dependentVariableTitle"
        :chart-data="chartData"
        :isLoading="isLoadingChartData"
      />
    </article>
    <!--------------------------------------------
    |
    | VARIABLES MODAL
    |
    --------------------------------------------->
    <DriversVariables
      :dependent-variable-title="dependentVariableQuestionTitle"
      :dependent-questions="dependentQuestions"
      :independent-questions="independentQuestions"
      :show-drivers-variables="isSelectVarVisible"
      @modify-vars="modifyVars"
      @close-drivers-variables="isSelectVarVisible = false"
    />
  </div>
</template>

<script>
// Components
import DriversCharts from "./Components/DriversCharts.vue"
import DriversVariables from "./Components/DriversVariables.vue"
import SvgIconDecorative from "@/components/UI/Svg/SvgIconDecorative.vue"

// Mixins
import DatasetDetailsMixin from "@/utils/mixins/datasetDetailsMixin.js"
import DriversAnalysisMixin from "./Mixins/driversAnalysisMixin.js"
import ProjectMixin from "@/utils/mixins/projectMixin.js"
import ProjectReportMixin from "@/utils/mixins/projectReportMixin.js"

// Helpers
import moment from "moment"
import _ from "lodash"

export default {
  name: "ProjectAnalysisDriversItem",
  mixins: [
    DatasetDetailsMixin,
    DriversAnalysisMixin,
    ProjectMixin,
    ProjectReportMixin
  ],
  props: {
    index: {
      type: Number,
      required: true
    }
  },
  components: {
    DriversCharts,
    DriversVariables,
    SvgIconDecorative
  },
  data() {
    return {
      driversAnalysisObject: {},
      dependentVariableTitle: "overall satisfaction",
      dependentQuestions: null, /// used to build var table
      independentQuestions: null, // used to build var table
      isSelectVarVisible: false,
      statusCanRunDrivers: true,
      selectedDependentVars: null,
      selectedIndependentVars: null,
      isDatasetChanged: false
    }
  },
  async created() {
    this.driversAnalysisObject = _.cloneDeep(
      this.project.driversAnalysis[this.index]
    )
    this.setVariables(
      this.driversAnalysisObject.dependent_variable_id,
      this.driversAnalysisObject.independent_variables_ids,
      this.driversAnalysisObject.dependent_variable_title
    )
    // if no chart data saved or performance type
    // was recently updated fetch chart data & update project
    if (
      !this.driversAnalysisObject.chart_data ||
      (this.projectPerformanceType &&
        this.projectPerformanceType !==
          this.driversAnalysisObject.saved_performance_type)
    ) {
      await this.setChartData(
        this.driversAnalysisObject.dependent_variable_id,
        this.driversAnalysisObject.independent_variables_ids
      )
      await this.saveDriversToProject(this.driversAnalysisObject.uuid)
    } else {
      this.chartData = this.driversAnalysisObject.chart_data
    }
  },
  computed: {
    statusDisableActionBtns() {
      return (
        ["notEnoughScale", "noCmt", "orgNotVerified"].includes(
          this.statusName
        ) ||
        this.isLoadingChartData ||
        this.propReadOnly
      )
    },
    dependentVariableQuestionTitle() {
      if (Object.keys(this.matchesDetails).includes(this.selectedDependentVars))
        return this.matchesDetails[this.selectedDependentVars]
          .global_question_title
      else {
        const question = this.clientQuestions.find(
          q => q._id.$oid === this.selectedDependentVars
        )
        return question.question_title
      }
    }
  },
  methods: {
    filterClientQuestions(questionIDs) {
      return this.clientQuestions.filter(q => questionIDs.includes(q._id.$oid))
    },
    /**
     * backwards compatability: project.driversAnalysis updated from object
     * to array, replace it with an array
     */
    async handleBackwardsCompatibility() {
      if (!this.project.driversAnalysis) {
        this.project.driversAnalysis = []
        await this.saveProject(this.project)
      } else if (
        !Array.isArray(this.project.driversAnalysis) &&
        Object.keys(this.project.driversAnalysis).length > 0
      ) {
        this.project.driversAnalysis = new Array(this.driversAnalysisObject)
        await this.saveProject(this.project)
      }
    },
    /************
     *
     * Setters
     *
     ***********/
    setVariables(dependentVar, independentVars, dependentVarTitle) {
      this.dependentVariableTitle = dependentVarTitle
      this.selectedDependentVars = dependentVar
      this.dependentQuestions = this.filterClientQuestions(dependentVar)
      this.selectedIndependentVars = independentVars
      this.independentQuestions = this.filterClientQuestions(independentVars)
    },
    async setChartData(dependentVar, independentVars) {
      this.isLoadingChartData = true
      this.chartData = await this.$_driversAnalysisMixin_fetchChartData(
        this.project.id,
        this.dataset._id.$oid,
        dependentVar,
        independentVars
      )
      this.isLoadingChartData = false
    },
    /************
     *
     * Saving methods
     *
     ***********/
    async modifyVars(dependent, independent) {
      this.statusCanRunDrivers = true
      this.setVariables(
        dependent,
        independent,
        this.getDependentVarTitle(dependent)
      )
      await this.setChartData(dependent, independent)
      this.saveDriversToProject(this.driversAnalysisObject.uuid)
    },
    async save() {
      if (this.propReadOnly) return
      const uuid = await this.saveDriversToReport()
      this.project.updateStatus("analysisCompleted")
      this.saveDriversToProject(uuid)
    },
    async saveDriversToProject(uuid = null) {
      if (this.propReadOnly) return
      const newObject = {
        saved_performance_type: this.projectPerformanceType || "scale-average",
        dependent_variable_id: this.selectedDependentVars,
        dependent_variable_title: this.dependentVariableTitle,
        independent_variables_ids: this.selectedIndependentVars,
        chart_data: this.chartData,
        last_modified_on: moment().valueOf(),
        uuid: uuid || this.$pigeonline.createUUID()
      }
      await this.handleBackwardsCompatibility()

      // find it in currently saved drivers, if there update, if not add it
      const indx = this.project.driversAnalysis.findIndex(
        d => d.uuid == this.driversAnalysisObject.uuid
      )
      if (indx > -1)
        this.project.driversAnalysis.splice(indx, 1, _.cloneDeep(newObject))
      else this.project.driversAnalysis.unshift(_.cloneDeep(newObject))

      await this.saveProject(this.project)
      this.driversAnalysisObject = _.cloneDeep(newObject)
    },
    async saveDriversToReport() {
      try {
        this.isLoadingChartData = true
        const organization = this.$store.getters["user/getOrganization"]
          ? this.$store.getters["user/getOrganization"].organization_name
          : ""

        await this.createReportIfEmpty(
          this.$options.filters.formatDate(this.project.last_modified_on),
          this.dataset.name,
          organization
        )
        const uuid = await this.updateReportWithDrivers(
          this.driversAnalysisObject.uuid,
          this.project.report,
          this.chartData,
          this.dependentVariableTitle
        )
        this.isLoadingChartData = false
        this.onSuccessfulSave()
        return uuid
      } catch (e) {
        throw new Error(
          "ProjectAnalysisDrivers:saveDriversToReport " + e.message
        )
      }
    },
    /************
     *
     * Deleting
     *
     ***********/
    async deleteDriver() {
      const indx = this.project.driversAnalysis.findIndex(
        d => d.uuid == this.driversAnalysisObject.uuid
      )
      this.project.driversAnalysis.splice(indx, 1)
      await this.saveProject(this.project)
    },
    /************
     *
     * Confirmation modals
     *
     ***********/
    onClickDelete() {
      const indx = this.project.driversAnalysis.findIndex(
        d => d.uuid == this.driversAnalysisObject.uuid
      )
      this.setConfirmText({
        btn: "Delete Drivers",
        title: "Delete drivers analysis?"
      })
      this.setConfirmType("delete")
      this.setConfirmTarget(this.driversAnalysisObject.uuid)
      this.setConfirmSourceComponent("drivers")
      this.setConfirmStatus(false)
      this.setConfirmIsVisible(indx > -1)
    },
    onSuccessfulSave() {
      this.setConfirmText({
        btn: "okay",
        title: "Drivers saved",
        message: "Drivers successfully saved to report."
      })
      this.setConfirmType("success")
      this.setConfirmSourceComponent("drivers")
      this.setConfirmIsVisible(true)
    }
  },
  watch: {
    confirmStatus: async function(val) {
      if (
        val &&
        this.confirmSourceComponent === "drivers" &&
        this.confirmType === "delete" &&
        this.confirmTarget === this.driversAnalysisObject.uuid
      ) {
        await this.deleteDriver()
      }
    },
    projectUpdatedPerformanceType: async function(type) {
      if (type && type !== this.driversAnalysisObject.saved_performance_type) {
        await this.setChartData(
          this.driversAnalysisObject.dependent_variable_id,
          this.driversAnalysisObject.independent_variables_ids
        )
        await this.saveDriversToProject(this.driversAnalysisObject.uuid)
      }
    }
  }
}
</script>
