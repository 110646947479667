import { BaseService, axios } from "@pigeonline/core"

export default class becnhmarkingService extends BaseService {
  constructor(core) {
    super(core)

    // define the api url
    this.URL = `${this.BASE_URL}/analysis/benchmarking`
  }

  async benchmarking(params = {}) {
    try {
      let config = {}
      config["headers"] = this.getAuthHeader()
      config["params"] = params ? params : ""
      const response = await axios.get(`${this.URL}`, config)
      return response.data
    } catch (e) {
      throw new Error("becnhmarkingService.js:benchmarking " + e.message)
    }
  }

  async deleteBenchmark(id) {
    try {
      const response = await axios.delete(`${this.URL}/${id}`, {
        headers: this.getAuthHeader()
      })
      return response.data
    } catch (e) {
      throw new Error("becnhmarkingService.js:deleteBenchmark " + e.message)
    }
  }

  async update(id, data) {
    try {
      const response = await axios.patch(`${this.URL}/${id}`, data, {
        headers: this.getAuthHeader()
      })
      return response.data
    } catch (e) {
      throw new Error("becnhmarkingService.js:update " + e.message)
    }
  }

  async recommendedGlobalQuestions(data) {
    try {
      const response = await axios.post(
        `${this.URL}/recommended_global_questions`,
        data
      )
      return response.data
    } catch (e) {
      throw new Error("becnhmarkingService.js:suggested " + e.message)
    }
  }

  async request(data) {
    try {
      const response = await axios.post(`${this.URL}`, data, {
        headers: this.getAuthHeader()
      })
      return response.data
    } catch (e) {
      throw new Error("becnhmarkingService.js:request " + e.message)
    }
  }

  async summaryResults(data) {
    try {
      const response = await axios.post(`${this.URL}/summary_results`, data, {
        headers: this.getAuthHeader()
      })
      return response.data
    } catch (e) {
      throw new Error("becnhmarkingService.js:summaryResults " + e.message)
    }
  }

  async clientQuestionsDetails(data) {
    try {
      const response = await axios.post(
        `${this.URL}/client_questions_analysis`,
        data,
        {
          headers: this.getAuthHeader()
        }
      )
      return response.data
    } catch (e) {
      throw new Error(
        "becnhmarkingService.js:clientQuestionsDetails " + e.message
      )
    }
  }

  async detailedResult(data) {
    try {
      const response = await axios.post(`${this.URL}/detailed_results`, data, {
        headers: this.getAuthHeader()
      })
      return response.data
    } catch (e) {
      throw new Error("becnhmarkingService.js:detailedResult " + e.message)
    }
  }

  async segmentsDetails(params = {}) {
    try {
      let config = {}
      config["headers"] = this.getAuthHeader()
      config["params"] = params ? params : ""
      const response = await axios.get(`${this.URL}/segments_counts`, config)
      return response.data
    } catch (e) {
      throw new Error("becnhmarkingService.js:segmentsDetails " + e.message)
    }
  }
}
