<template>
  <div class="account-send-invites__content">
    <h1 class="send-invites__title title">
      <VisibleText>Send Invites</VisibleText>
    </h1>
    <form class="send-invites__form">
      <p class="send-invites__form-legend">
        <VisibleText
          >Add email addresses with corresponding roles to prompt users to
          create accounts.
        </VisibleText>
      </p>
      <span id="enter-email-input-label" class="send-invites__form-label">
        <VisibleText>Enter email address</VisibleText>
      </span>
      <div
        v-for="(input, index) in inputs"
        :key="index"
        :class="[
          'send-invites__form-email-wrapper',
          { 'margin-bottom__2rem': input.error !== '' }
        ]"
      >
        <span>
          <input
            :class="{
              'send-invites__form-input': true,
              error: input.status == 'error' || input.error !== '',
              success: input.status == 'success'
            }"
            v-model="input.email"
            :name="`send-invites__form-input-${index}`"
            type="text"
            required
            aria-labelledby="enter-email-input-label"
            aria-required="true"
            @input="onInput(index)"
            :disabled="input.status != 'new'"
          />
          <span
            class="send-invites__form-label-txt error"
            v-if="input.error != ''"
            >{{ input.error }}</span
          >
        </span>
        <select
          v-model="input.role"
          required
          aria-label="user role"
          class="send-invites__form-select"
          aria-required="true"
          :disabled="input.status != 'new'"
        >
          <option
            selected="selected"
            value="team_member"
            :data-parent="$options.name"
          >
            {{ translate("Team member", $options.name) }}
          </option>
        </select>
        <span
          v-if="input.status == 'error'"
          class="send-invites__form-status error"
        >
          <VisibleText>Error</VisibleText>
        </span>
        <span
          v-if="input.status == 'success'"
          class="send-invites__form-status success"
        >
          <VisibleText>Success</VisibleText>
        </span>
        <ButtonIconOnly
          icon="remove"
          id="sendinvites-remove"
          v-if="input.status == 'new'"
          @click-handler="deleteRow(index)"
        >
          <VisibleText>Remove email</VisibleText>
        </ButtonIconOnly>
      </div>
      <ButtonIconOnly icon="add" id="sendinvites-add" @click-handler="addRow">
        <VisibleText>Add email</VisibleText>
      </ButtonIconOnly>
      <button
        type="submit"
        class="send-invites__form-btn"
        @click.prevent="sendInvites"
        :disabled="disableSubmit"
      >
        <VisibleText>Send Invites</VisibleText>
      </button>
    </form>
  </div>
</template>

<script>
import { axios } from "@pigeonline/core"
import ButtonIconOnly from "@/components/UI/Button/ButtonIconOnly.vue"

import UserMixin from "@/utils/mixins/userMixin.js"

export default {
  name: "sendInvites",
  mixins: [UserMixin],
  components: { ButtonIconOnly },
  data() {
    return {
      inputs: [{ email: "", role: "team_member", error: "", status: "new" }]
    }
  },
  created() {
    document.title = `Account - Send Invites | ${this.$theme.theme.pageTitle}`
  },
  computed: {
    allEmailsEmpty: function() {
      return this.inputs.every(item => item.email.trim() === "")
    },
    newEmails: function() {
      return this.inputs.filter(
        item => item.status === "new" && item.error === ""
      )
    },
    containsDuplicate: function() {
      return this.inputs.some(item => this.isDuplicate(item.email))
    },
    disableSubmit: function() {
      return (
        this.allEmailsEmpty ||
        this.inputs.some(item => !this.isEmptyOrValid(item.email)) ||
        this.newEmails.length === 0 ||
        this.containsDuplicate
      )
    }
  },
  methods: {
    clean(email) {
      return String(email)
        .toLowerCase()
        .trim()
    },
    isEmptyOrValid(email) {
      const _email = this.clean(email)

      // eslint-disable-next-line no-useless-escape
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return _email === "" || re.test(_email)
    },
    isDuplicate(email) {
      if (this.clean(email) === "") return
      return (
        this.inputs.filter(item => this.clean(item.email) === this.clean(email))
          .length > 1
      )
    },
    onInput(index) {
      this.inputs[index].error = ""

      const email = this.inputs[index].email
      if (!this.isEmptyOrValid(email)) {
        this.inputs[index].error = "Please enter a valid email."
        return
      }
      this.duplicateInputsRevalidate()
    },
    duplicateInputsRevalidate() {
      this.inputs.map(
        function({ email }, index) {
          if (this.isDuplicate(email)) {
            this.inputs[index].error = "This email address is a duplicate."
            return
          }
          if (this.inputs[index].error.includes("duplicate")) {
            this.inputs[index].error = ""
          }
        }.bind(this)
      )
    },
    addRow() {
      this.inputs.push({
        email: "",
        role: "team_member",
        error: "",
        status: "new"
      })
    },
    deleteRow(index) {
      this.inputs.splice(index, 1)
      this.duplicateInputsRevalidate()
    },
    async sendInvites() {
      const invitees = this.newEmails.map(item => [item.email, item.role])

      try {
        const res = await axios.post(
          `${this.$pigeonline.organizations.URL}/${this.organization.id}/send-invites`,
          {
            invitees
          }
        )
        if (typeof res.data === "object") {
          res.data.map(
            a =>
              (this.inputs.filter(b => b.email === a.email)[0].status =
                a.status)
          )
        }
      } catch (e) {
        throw new Error("SendInvites.vue:sendInvites: " + e.message)
      }
    }
  }
}
</script>
