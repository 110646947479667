<template>
  <div class="congratulations__content">
    <h1 class="congratulations__heading title">
      {{ congratulationsHeading }}
    </h1>
    <p class="congratulations__description">
      {{ congratulationsDescription }}
    </p>
    <div class="congratulations__admin" v-if="isAdmin">
      <p class="congratulations__admin-legend sub-title">
        <VisibleText>What can an admin do?</VisibleText>
      </p>
      <div class="congratulations__admin-options">
        <router-link to="/account/send-invites" class="clickable-box">
          <span><VisibleText>Invite new users</VisibleText></span>
        </router-link>
        <router-link to="/" class="clickable-box">
          <span><VisibleText>Build your own benchmarks</VisibleText></span>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import UserMixin from "@/utils/mixins/userMixin.js"

export default {
  name: "accountNextStepsCongratulations",
  mixins: [UserMixin],
  computed: {
    congratulationsHeading: function() {
      return this.isAdmin
        ? "Congratulations. You are now the admin of your organization."
        : "Congratulations! You have finished setting up your account."
    },
    congratulationsDescription: function() {
      return this.isDemoUser
        ? "Thank you for creating a free account. As a free user, your access will be somewhat restricted. To upgrade, please contact us at support@pigeonline.io, or using the live chat below."
        : "Your account is being verified. You will be contacted for next steps. For support, please contact us at support@pigeonline.io, or using the live chat below."
    }
  },
  async created() {
    // set account setup complete
    this.setProfile(
      await this.$pigeonline.profile.save({
        is_account_complete: true
      })
    )
  },
  beforeDestroy() {
    this.setUserDetails()
  }
}
</script>
