<template>
  <main class="login__wrapper">
    <div class="login__content">
      <h1 class="login__title">Login</h1>
      <p>
        <VisibleText>
          Welcome! Please login below to access your analytics platform.
        </VisibleText>
      </p>
      <div class="message" v-if="message">{{ message }}</div>
      <Login class="login__form" @authenticated="afterLogin">
        <template v-slot="{ email, password, update }">
          <label for="email" class="form-style-group-label">
            <span class="form-style-group-text">
              <VisibleText>Email Address</VisibleText>
            </span>
            <div class="form-style-group-input-wrapper">
              <EmailIcon class="form-style-group-input-icon" />
              <input
                id="email"
                @input="update('email', $event.target.value)"
                type="text"
                :value="email"
              />
            </div>
          </label>

          <label for="password" class="form-style-group-label">
            <span class="form-style-group-text">
              <VisibleText>Password</VisibleText>
            </span>
            <div class="form-style-group-input-wrapper">
              <PasswordIcon class="form-style-group-input-icon" />
              <input
                id="password"
                @input="update('password', $event.target.value)"
                type="password"
                :value="password"
              />
            </div>
          </label>
        </template>
      </Login>
      <p>
        <VisibleText>Don't have an account? </VisibleText>
        <router-link to="/register">
          <VisibleText>Register Here</VisibleText>
        </router-link>
      </p>
    </div>
    <div
      class="login__splash"
      :style="{ backgroundImage: `url(${require('@/assets/splash.png')})` }"
    ></div>
  </main>
</template>

<script>
// Components
import { Login } from "@pigeonline/core"

// Mixins
import UserMixin from "@/utils/mixins/userMixin.js"

// Icons
import EmailIcon from "@/assets/icons/Login/email.svg"
import PasswordIcon from "@/assets/icons/Login/pswd.svg"

export default {
  name: "login",
  mixins: [UserMixin],
  props: ["message"],
  components: {
    Login,
    //icons
    EmailIcon,
    PasswordIcon
  },
  created() {
    document.title = `Login | ${this.$theme.theme.pageTitle}`
  },
  methods: {
    async afterLogin() {
      // update user store
      await this.setUserDetails()
      await this.setUserNotifications()

      // go to home page or redirect path if it exists
      const redirectPath = this.$route.query.redirect || "/projects"
      this.$router.push({ path: redirectPath })
    }
  }
}
</script>
