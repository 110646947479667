<template>
  <div class="account-approve-users__content">
    <section class="approve-users">
      <h1 class="approve-users__title title">
        <VisibleText>Approve new users</VisibleText>
      </h1>
      <div class="approve-users__no-users" v-if="noPendingUsers">
        <p class="sub-title">
          <VisibleText
            >Currently, there are no new users pending your approval. Should any
            new user join your organization, you will need to approve them
            before they can start using their account.
          </VisibleText>
        </p>
      </div>
      <div class="approve-users__users" v-else>
        <table>
          <tr>
            <th><VisibleText>Email address</VisibleText></th>
            <th><VisibleText>Full name</VisibleText></th>
            <th><VisibleText>Date joined</VisibleText></th>
            <th><VisibleText>Status</VisibleText></th>
          </tr>
          <tr v-for="member in membersPendingApproval" :key="member.id">
            <td>{{ member.email }}</td>
            <td>{{ member.fullname }}</td>
            <td>{{ formatDate(member.date_joined.$date) }}</td>
            <td v-if="member.status === 'approved'">
              <VisibleText>Approved</VisibleText>
            </td>
            <td v-else>
              <VisibleText>Pending approval</VisibleText>
              <button
                class="approve-users__users-approve-btn"
                @click="approve(member.id)"
              >
                <VisibleText>Approve</VisibleText>
              </button>
            </td>
          </tr>
        </table>
      </div>
    </section>
  </div>
</template>

<script>
import { axios } from "@pigeonline/core"

import UserMixin from "@/utils/mixins/userMixin.js"

export default {
  name: "approveUsers",
  mixins: [UserMixin],
  data() {
    return {
      membersPendingApproval: []
    }
  },
  computed: {
    noPendingUsers: function() {
      return this.membersPendingApproval.length == 0
    }
  },
  async created() {
    try {
      const res = await axios.get(
        `${this.$pigeonline.organizations.URL}/${this.organization.id}/members?type=all`
      )
      if (Array.isArray(res.data)) {
        this.membersPendingApproval = res.data.filter(
          user => !this.isCurrentUser(user)
        )
      }
    } catch (e) {
      throw new Error("ApproveUsers.vue:created: " + e.message)
    }
    document.title = `Account - Approve Users | ${this.$theme.theme.pageTitle}`
  },
  methods: {
    isCurrentUser(user) {
      return user.id == this.$store.getters["user/getProfile"].django_ref_id
    },
    formatDate(timestamp) {
      return new Date(timestamp).toLocaleDateString("en-US")
    },
    async approve(member_id) {
      try {
        const res = await axios.patch(
          `${this.$pigeonline.organizations.URL}/${this.organization.id}/members/${member_id}/approve`,
          null
        )
        if (typeof res.data === "object") {
          this.membersPendingApproval.filter(
            item => item.id === member_id
          )[0].status = res.data.admin_approved
            ? "approved"
            : "pending_approval"
        }
      } catch (e) {
        throw new Error("ApproveUsers.vue:approve: " + e.message)
      }
    }
  }
}
</script>
