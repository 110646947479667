<template>
  <div
    class="ui-selectable"
    ref="selectable-container"
    v-click-outside="closeTools"
  >
    <div
      v-show="showTools"
      aria-label="text tools"
      class="ui-selectable__tools"
      role="dialog"
      ref="selectable-tools"
      tabindex="-1"
      :data-opened-tools="showTools"
      :style="{
        left: `${x}px`,
        top: `${y}px`
      }"
    >
      <button
        class="ui-selectable__tools-item"
        v-for="item of selectedToolOptions"
        :key="`selectable-tools-button-${item.id}`"
        @click.prevent="handleAction(`${item.id}`)"
      >
        <VisibleText>{{ item.label }}</VisibleText>
      </button>
    </div>
    <div
      v-show="tooltip.isVisible"
      aria-label="text tooltip"
      class="ui-selectable__tooltip"
      role="dialog"
      tabindex="-1"
      :style="{
        left: `${tooltip.x}px`,
        top: `${tooltip.y}px`
      }"
    >
      {{ tooltip.tooltipText }}
    </div>
    <slot />
  </div>
</template>

<script>
import HighlightMixin from "../Mixins/highlightMixin.js"

export default {
  name: "Selectable",
  mixins: [HighlightMixin],
  mounted() {
    this.isMounted = true
    this.$refs["selectable-container"].addEventListener(
      "mouseup",
      () => setTimeout(this.onMouseUp.bind(this), 10),
      false
    )
  },
  methods: {
    handleAction(action) {
      this.showTools = false
      this.$emit(action, this.getSelectedTextInfo())
    }
  },
  watch: {
    showTools: function(val) {
      if (!val) return
      this.$nextTick(
        function() {
          this.clipTools()
        }.bind(this)
      )
    }
  }
}
</script>
