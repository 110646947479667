const headerSection = (date, datasetName, org = "") => {
  return [
    {
      type: "text",
      content: date,
      id: "header-date",
      meta: {
        pdfConfig: {
          style: "header-date"
        }
      }
    },
    {
      type: "text",
      content: datasetName,
      id: "header-dataset-name",
      meta: {
        pdfConfig: {
          style: "header-dataset-name"
        }
      }
    },
    {
      type: "text",
      content: org,
      id: "header-org",
      meta: {
        pdfConfig: {
          style: "header-org"
        }
      }
    }
  ]
}

export default headerSection
