<template>
  <section id="dataset">
    <div class="section__top-bar">
      <h1 class="section__top-bar-title">
        <VisibleText>Dataset Wizard</VisibleText>
      </h1>
    </div>
    <!-- Main content -->
    <div class="dataset__content">
      <div class="dataset__demo-info" v-if="isDemoUser || isReadOnly">
        <MessageBlock message-type="information">
          This is a read-only dataset.
        </MessageBlock>
      </div>
      <div class="dataset__menu-description" v-else>
        <VisibleText>
          You can clean and label your data in just a few clicks
        </VisibleText>
      </div>
      <DatasetProgress />
      <keep-alive>
        <component
          :is="activeSection"
          :read-only="isReadOnly"
          @open-template-modal="isShowingTemplateModal = true"
        ></component>
      </keep-alive>
    </div>
    <!-- Template modal -->
    <DatasetUploaderTemplateModal
      :show="isShowingTemplateModal"
      @close="isShowingTemplateModal = false"
    />
  </section>
</template>

<script>
// Components
import DatasetProgress from "@/components/Dataset/DatasetProgress/DatasetProgress.vue"
import DatasetUploader from "@/components/Dataset/DatasetUploader/DatasetUploader.vue"
import DatasetUploaderTemplateModal from "@/components/Dataset/DatasetUploader/DatasetUploaderTemplateModal.vue"
import DatasetSegments from "@/components/Dataset/DatasetSegments/DatasetSegments.vue"
import DatasetBenchmarks from "@/components/Dataset/DatasetBenchmarks/DatasetBenchmarks.vue"
import DatasetCleaner from "@/components/Dataset/DatasetCleaner/DatasetCleaner.vue"
import DatasetTableView from "@/components/Dataset/DatasetTableView/DatasetTableView.vue"
import MessageBlock from "@/components/UI/Message/MessageBlock.vue"

// Mixins
import ConfirmMixin from "@/utils/mixins/confirmMixin.js"
import DatasetMixin from "@/utils/mixins/datasetMixin.js"
import DatasetDetailsMixin from "@/utils/mixins/datasetDetailsMixin.js"
import SharedStatusMixin from "@/utils/mixins/sharedStatusMixin.js"
import UserTypeMixin from "@/utils/mixins/userTypeMixin.js"

// Models
import BenchmarkingDataset from "@/models/BenchmarkingDatasetModel.js"
import BenchmarkGroup from "@/models/BenchmarkGroup.js"

// Utils
import {
  fetchDataset,
  fetchDatasetProject
} from "@/components/Dataset/Utils/fetch.js"

const LICENSE_TO_BENCHMARK_GROUP = {
  free: "free",
  "iccs-admin": "iccs",
  iccs: "iccs",
  basic: "free"
}

export default {
  name: "Dataset",
  mixins: [
    ConfirmMixin,
    DatasetMixin,
    DatasetDetailsMixin,
    SharedStatusMixin,
    UserTypeMixin
  ],
  components: {
    DatasetProgress,
    DatasetUploader,
    DatasetSegments,
    DatasetBenchmarks,
    DatasetCleaner,
    DatasetTableView,
    MessageBlock,
    DatasetUploaderTemplateModal
  },
  data() {
    return {
      isShowingTemplateModal: false,
      toRouteCache: null
    }
  },
  beforeRouteEnter(to, from, next) {
    if (to.params.id) {
      Promise.all([
        fetchDataset(to.params.id),
        fetchDatasetProject(to.query.project_id)
      ])
        .then(datasetData => {
          next(vm => vm.setData(null, ...datasetData))
        })
        .catch(err => next(vm => vm.setData(err)))
    } else {
      next()
    }
  },
  beforeRouteUpdate(to, from, next) {
    if (to.params.id) {
      Promise.all([
        fetchDataset(to.params.id),
        fetchDatasetProject(to.query.project_id)
      ])
        .then(datasetData => {
          this.setData(null, ...datasetData)
          next()
        })
        .catch(err => {
          this.setData(err, null)
          next()
        })
    } else {
      next()
    }
  },
  async beforeRouteLeave(to, from, next) {
    if (this.deleteIncompleteUpload) {
      await this.$pigeonline.projects.delete(this.deleteIncompleteUpload)
      this.resetDatasetWizard()
      this.resetDatasetDetails()
      this.resetConfirm()
      next()
    } else if (this.unsavedChanges.length > 0) {
      this.onUnsavedChanges()
      this.toRouteCache = to
    } else {
      this.resetDatasetWizard()
      this.resetDatasetDetails()
      this.resetConfirm()
      next()
    }
  },
  async created() {
    // no id in url so setup empty dataset project
    if (!this.$route.params.id && typeof this.$route.params.id == "undefined") {
      this.setDatasetProject(new BenchmarkingDataset({ name: null }))
      this.setActiveSection("DatasetUploader")
    }
  },
  computed: {
    // FIXME: move fetching user details before enter in App.vue
    userLicense() {
      return this.$store.getters["user/getLicense"]
        ? this.$store.getters["user/getLicense"].name
        : null
    }
  },
  methods: {
    onFetchError() {
      this.setConfirmText({
        btn: "okay",
        title: "Error Occured",
        message:
          "Sorry, there was a problem loading the page. Please try again."
      })
      this.setConfirmSourceComponent("dataset")
      this.setConfirmType("error")
      this.setConfirmIsVisible(true)
    },
    onUnsavedChanges() {
      this.setConfirmText({
        btn: "Stay on page",
        secondaryBtn: "Leave page",
        title: "Unsaved Changes",
        message: `You have unsaved changes in ${this.unsavedChanges.join(
          ","
        )}. Are you sure you want to leave this page?`
      })
      this.setConfirmType("warning")
      this.setConfirmSourceComponent("dataset")
      this.setConfirmIsVisible(true)
    },
    async setData(err, dataset = {}, datasetProject = {}) {
      if (err) {
        this.onFetchError()
      } else {
        this.setDatasetProject(datasetProject)
        this.setDataset(dataset)
        await this.setDatasetBenchmarkGroup(dataset, datasetProject)
        await this.loadDatasetDetails(this.dataset)

        // if we just uploaded a file, go to next step otherwise go to data table
        const component =
          this.$route.query.from === "datasetUploader"
            ? "DatasetCleaner"
            : "DatasetTableView"

        this.isReadOnly = this.checkIsReadOnly(datasetProject)
        this.setActiveSection(component)
      }
    },
    async setDatasetBenchmarkGroup(dataset, datasetProject) {
      /**
       * set chosen group (backwards compatibility - beta had benchmark group
       * in datasetProject but v1.2+ it is in dataset object)
       **/
      if (dataset.benchmark_group || datasetProject.benchmark_group) {
        const benchmarkGroupId =
          dataset.benchmark_group || datasetProject.benchmark_group
        const benchmarkGroup = await this.$pigeonline.projects.getByID(
          BenchmarkGroup,
          benchmarkGroupId
        )
        this.setBenchmarkGroup(benchmarkGroup)
      } else if (
        this.userLicense &&
        ["iccs-admin", "iccs", "free"].includes(this.userLicense)
      ) {
        const defaultBenchmarkGroup = await this.$pigeonline.projects.get(
          BenchmarkGroup,
          {
            official_group: LICENSE_TO_BENCHMARK_GROUP[this.userLicense]
          }
        )
        this.setBenchmarkGroup(defaultBenchmarkGroup[0])
        // update dataset with default group
        await this.updateDataset(this.dataset._id.$oid, {
          benchmark_group: defaultBenchmarkGroup[0].id
        })
      }
    }
  },
  watch: {
    confirmIsVisible: function(val) {
      // user clicked "leave page"
      if (
        !val &&
        !this.confirmClickedClose &&
        !this.confirmStatus &&
        this.confirmSourceComponent === "dataset" &&
        this.confirmType === "warning"
      ) {
        this.setUnsavedChanges([])
        this.$router.push({ path: this.toRouteCache.fullPath })
      }
    },
    datasetDetailsLoaded: function(loaded) {
      if (loaded && this.dataset.name) {
        document.title = `${this.dataset.name} - Dataset Wizard | ${this.$theme.theme.pageTitle}`
      } else {
        document.title = `Dataset Wizard | ${this.$theme.theme.pageTitle}`
      }
    },
    userLicense: function() {
      if (!this.benchmarkGroup)
        this.setDatasetBenchmarkGroup(this.dataset, this.datasetProject)
    }
  }
}
</script>
