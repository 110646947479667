<template>
  <Modal
    class="project-analysis-text-modal"
    header-tag="h3"
    uid="modal-create-note"
    :ref="modalRef"
    :show="show"
    @close="closeModal"
  >
    <template #header class="modal-title"
      ><VisibleText>Add a note</VisibleText></template
    >
    <fieldset>
      <label class="create-note__label" for="create-note-textarea">
        <VisibleText
          >Please enter your text below that you want to save as a
          note.</VisibleText
        >
      </label>
      <textarea
        id="create-note-textarea"
        class="create-note__textarea"
        v-model="noteText"
      >
      </textarea>
    </fieldset>
    <fieldset>
      <label class="select-theme__label" for="select-theme-input">
        <VisibleText
          >Select the theme you want to save the note to.</VisibleText
        >
      </label>
      <select
        :id="`select-theme-input-${_uid}`"
        class="select-theme__select"
        v-model="noteSelectTheme"
      >
        <option
          v-for="(theme, index) in themes"
          :key="`select-theme-input-${_uid}-option-${index}`"
          :value="index"
        >
          {{ theme.name }}
        </option>
      </select>
    </fieldset>
    <template #footer>
      <button
        class="create-note__save-btn btn-default"
        type="button"
        @click.prevent="save"
        :disabled="!isSaveBtnEnabled"
      >
        <VisibleText>Create note</VisibleText>
      </button>
      <button class="btn-cancel" type="button" @click="closeModal">
        <VisibleText>Close</VisibleText>
      </button>
      <span class="create-note__hint" v-show="isHintVisible">
        <SvgIconDecorative icon="info" />
        <VisibleText>All fields required.</VisibleText>
      </span>
    </template>
  </Modal>
</template>

<script>
// Components
import Modal from "@/components/UI/Modal.vue"
import SvgIconDecorative from "@/components/UI/Svg/SvgIconDecorative.vue"

// Mixins
import AnalysisTextMixin from "@/utils/mixins/analysisTextMixin.js"

export default {
  name: "CreateNote",
  mixins: [AnalysisTextMixin],
  components: {
    Modal,
    SvgIconDecorative
  },
  props: {
    show: {
      default: () => false,
      type: Boolean
    },
    selection: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      unsavedChanges: false,
      updateKey: Date.now(),
      noteText: "",
      noteSelectTheme: -1
    }
  },
  computed: {
    text: function() {
      return JSON.stringify(this.selection)
    },
    isSaveBtnEnabled: function() {
      return (
        this.noteText.trim() !== "" &&
        this.noteSelectTheme > -1 &&
        this.unsavedChanges &&
        !this.showSpinner
      )
    },
    isHintVisible: function() {
      return this.noteText.trim() === "" || this.noteSelectTheme < 0
    }
  },
  methods: {
    async save() {
      // show spinner
      this.setShowSpinner(true)

      // add note to themes
      this.themes[this.noteSelectTheme].notes.push({
        ...this.selection,
        text: this.noteText
      })

      // save themes
      try {
        await this.saveThemes(this.themes)

        // success
        this.showModalMessage(
          "success",
          "Your changes have been saved successfully."
        )
        this.unsavedChanges = false
      } catch (e) {
        // revert changes
        this.themes[this.selectedThemeIndex].notes.pop()

        // error
        this.showModalMessage(
          "error",
          "An error occured processing your request. Please try again later."
        )
        console.error("CreateNote.vue:save: " + e)
      } finally {
        this.setShowSpinner(false)
      }
    },
    closeModal() {
      this.unsavedChanges = false
      this.$emit("closeModal")
    }
  },
  watch: {
    noteText: function(val) {
      if (!val || val.trim() === "") {
        this.unsavedChanges = false
        return
      }
      this.unsavedChanges = true
    },
    noteSelectTheme: function(val) {
      if (!val) return
      this.unsavedChanges = true
    },
    selectedThemeIndex: {
      immediate: true,
      handler: function(val) {
        if (val < 0) return
        this.noteSelectTheme = this.selectedThemeIndex
      }
    },
    show: function(val) {
      if (val) {
        this.noteText = ""
        this.noteSelectTheme = this.selectedThemeIndex
      }
    }
  }
}
</script>
