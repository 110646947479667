import Vue from "vue"
import Vuex from "vuex"

Vue.use(Vuex)

export default {
  namespaced: true,
  state() {
    return {
      project: null,
      projectUpdatedPerformanceType: null,
      benchmarkSummaryResults: null
    }
  },
  getters: {
    getProject: state => state.project,
    getProjectUpdatedPerformanceType: state =>
      state.projectUpdatedPerformanceType,
    getBenchmarkSummaryResults: state => state.benchmarkSummaryResults
  },
  mutations: {
    SET_PROJECT(state, payload) {
      Vue.set(state, "project", payload)
    },
    SET_PROJECT_UPDATED_PERFORMANCE_TYPE(state, payload) {
      Vue.set(state, "projectUpdatedPerformanceType", payload)
    },
    SET_BENCHMARK_SUMMARY_RESULTS(state, payload) {
      Vue.set(state, "benchmarkSummaryResults", payload)
    },
    RESET_PROJECT(state) {
      Vue.set(state, "project", null)
      Vue.set(state, "projectUpdatedPerformanceType", null)
      Vue.set(state, "benchmarkSummaryResults", null)
    }
  },
  actions: {
    async setProject({ commit }, payload) {
      await commit("SET_PROJECT", payload)
    },
    async setProjectUpdatedPerformanceType({ commit }, payload) {
      await commit("SET_PROJECT_UPDATED_PERFORMANCE_TYPE", payload)
    },
    async setBenchmarkSummaryResults({ commit }, payload) {
      await commit("SET_BENCHMARK_SUMMARY_RESULTS", payload)
    },
    resetProject({ commit }) {
      commit("RESET_PROJECT")
    }
  }
}
