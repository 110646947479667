import Vue from "vue"
import BenchmarkingDataset from "@/models/BenchmarkingDatasetModel.js"

const fetchDataset = async datasetId => {
  try {
    const response = await Vue.prototype.$services.DATASETS_SERVICE.dataset(
      datasetId
    )
    return response
  } catch (e) {
    throw new Error("Dataset:fetch.js:fetchDataset " + e.message)
  }
}

const fetchDatasetProject = async id => {
  try {
    const response = await Vue.prototype.$pigeonline.projects.getByID(
      BenchmarkingDataset,
      id
    )
    return response
  } catch (e) {
    throw new Error("DatasetMixin:fetchProject " + e.message)
  }
}

const fetchDatasetProjectByDatasetId = async datasetId => {
  try {
    const response = await Vue.prototype.$pigeonline.projects.get(
      BenchmarkingDataset,
      {
        dataset_id: datasetId
      }
    )
    return response
  } catch (e) {
    throw new Error("projectMixin:fetchDatasetProject " + e.message)
  }
}

export { fetchDataset, fetchDatasetProject, fetchDatasetProjectByDatasetId }
