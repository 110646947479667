import SurveyMethodologyTable from "../helpers/surveyMethodology.js"
import { SECTION_TRANSLATIONS } from "../translations.js"
const lang = window.localStorage.getItem("apiLanguage") || "en"

const surveyMethodologySection = (data, orgs) => {
  const headings = [
    {
      type: "heading",
      content: `${SECTION_TRANSLATIONS.methodologyAppendix[lang]} <span class='report__subtitle'>${SECTION_TRANSLATIONS.methodology[lang]}</span>`,
      id: "section-title-appendix",
      meta: {
        level: "h1",
        pdfConfig: {
          pageBreak: "before"
        }
      }
    }
  ]

  const description = {
    type: "text",
    content: SECTION_TRANSLATIONS.methodologyDesc[lang]
  }

  const table = SurveyMethodologyTable(data, orgs)

  return [...headings, description, table]
}

export default surveyMethodologySection
