<template>
  <div id="dataset-table-data" class="light-scroll-bar dataset__table-view">
    <!--------------------------------------------
    | 
    | OPTIONS
    |
    --------------------------------------------->
    <DatasetTableOptions
      :selected-client-questions="selectedClientQuestions"
      @select="selectAll"
      @deselect="deselectAll"
      @toggle-editing="toggleEditing"
      v-if="!hideOptions"
    />

    <!--------------------------------------------
    | 
    | DATA TABLE
    |
    --------------------------------------------->
    <ListGrid
      class="dataset-table__data"
      :aria-label="`${dataset.name} data`"
      :id="uuid"
      :custom-grid-style="{ gridTemplateColumns: '15% 1fr 1fr 20% 6%' }"
      :headers="headers"
      :list="clientQuestionItems"
      :sort-config="sortConfig"
      @sort="sort"
    >
      <template slot-scope="{ item, index }">
        <ListGridItem col="0" :headers="headers" :row="index">
          <span class="form-label form-checkbox-label" v-if="!hideOptions">
            <input
              type="checkbox"
              tabindex="-1"
              :id="`input-${item._id.$oid}`"
              :value="item._id.$oid"
              v-model="selectedClientQuestions"
            />
            <label :for="`input-${item._id.$oid}`" :id="`row-${item._id.$oid}`">
              {{ item.question_title }}
            </label>
          </span>
          <span :id="`row-${item._id.$oid}`" v-else>
            {{ item.question_title }}
          </span>
        </ListGridItem>
        <ListGridItem
          class="question-text"
          col="1"
          :headers="headers"
          :row="index"
          :rowuid="`row-${item._id.$oid}`"
        >
          <QuestionText
            :client-question="item"
            :index="index"
            :read-only="readOnly && !isEditing"
            @updateClientQuestion="updateClientQuestion"
          />
        </ListGridItem>
        <ListGridItem
          class="global-match"
          col="2"
          :headers="headers"
          :row="index"
          :rowuid="`row-${item._id.$oid}`"
          :focus-cell="true"
        >
          <GlobalMatch
            :client-question="item"
            :get-suggested-matches="getSuggestedMatches"
            :index="index"
            :read-only="readOnlyGlobalMatch"
            @modifyGlobalMatch="modifyGlobalMatch(item)"
            @finishFetchingSuggestedMatches="finishFetchingSuggestedMatches"
          />
        </ListGridItem>
        <ListGridItem
          col="3"
          :headers="headers"
          :row="index"
          :rowuid="`row-${item._id.$oid}`"
        >
          <DataType :client-question="item" :read-only="readOnlyDataType" />
        </ListGridItem>
        <ListGridItem
          style="overflow: hidden"
          col="4"
          :headers="headers"
          :row="index"
          :rowuid="`row-${item._id.$oid}`"
        >
          <DatasetTableValues
            :client-question="item"
            :index="index"
            :hide-options="hideOptions"
            @details-toggled="detailsToggled"
          />
        </ListGridItem>
      </template>
    </ListGrid>

    <!--------------------------------------------
    | 
    | BENCHMARK SELECTOR
    |
    --------------------------------------------->
    <BenchmarksSelectorPannel
      uid="benchmarks-selector"
      :client-question="modifyingMatchClientQuestion"
      :show="isShowingSelector"
      @close-selector="closeSelector"
      v-if="!hideOptions"
    />

    <!--------------------------------------------
    | 
    | COPYING DETAILS
    |
    --------------------------------------------->
    <CopyDetailsPannel
      :client-question-id="selectedClientQuestions[0]"
      @update-questions="updateClientQuestions(dataset._id.$oid)"
      v-if="!hideOptions"
    />
  </div>
</template>

<script>
// Components
import DatasetTableOptions from "./DatasetTableOptions/DatasetTableOptions.vue"
import DatasetTableValues from "./DatasetTableValues/DatasetTableValues.vue"
import DataType from "./Components/DataType.vue"
import GlobalMatch from "./Components/GlobalMatch.vue"
import QuestionText from "./Components/QuestionText.vue"
import ListGrid from "@/components/UI/ListGrid.vue"
import ListGridItem from "@/components/UI/ListGridItem.vue"
import LoadingSpinner from "@/components/UI/Spinner.vue"

const BenchmarksSelectorPannel = () => ({
  component: import("./Components/BenchmarksSelectorPannel.vue"),
  loading: LoadingSpinner
})

const CopyDetailsPannel = () => ({
  component: import("./Components/CopyDetailsPannel.vue"),
  loading: LoadingSpinner
})

// Mixins
import DatasetDetailsMixin from "@/utils/mixins/datasetDetailsMixin.js"

const SORT_BY = {
  ascending: 1,
  descending: -1
}

export default {
  name: "DatasetTableData",
  mixins: [DatasetDetailsMixin],
  props: {
    customClientQuestions: {
      default: () => [],
      type: Array
    },
    fetchSuggestedMatches: {
      default: false,
      type: Boolean
    },
    hideOptions: {
      default: false,
      type: Boolean
    },
    readOnly: {
      default: false,
      type: Boolean
    }
  },
  components: {
    BenchmarksSelectorPannel,
    CopyDetailsPannel,
    DataType,
    GlobalMatch,
    DatasetTableOptions,
    QuestionText,
    DatasetTableValues,
    ListGrid,
    ListGridItem
  },
  data() {
    return {
      headers: [
        "question code",
        "question text",
        "global match",
        "data type",
        "values"
      ],
      clientQuestionItems: [],
      getSuggestedMatches: false,
      modifyingMatchClientQuestion: {},
      selectedClientQuestions: [],
      uuid: this.$pigeonline.createUUID(),
      isEditing: false,
      isShowingSelector: false,
      sortConfig: {
        "question code": {
          options: ["default", "ascending", "descending"],
          selected: "default"
        }
      }
    }
  },
  created() {
    if (!this.customClientQuestions) this.clientQuestionItems = []
    else if (this.customClientQuestions.length > 0)
      this.clientQuestionItems = this.customClientQuestions
    else this.clientQuestionItems = this.clientQuestions
  },
  computed: {
    readOnlyDataType() {
      return (
        !this.isEditing &&
        this.readOnly &&
        this.$store.getters["datasetWizard/getActiveSection"] !==
          "DatasetCleaner"
      )
    },
    readOnlyGlobalMatch() {
      return (
        !this.isEditing &&
        this.readOnly &&
        this.$store.getters["datasetWizard/getActiveSection"] !==
          "DatasetBenchmarks"
      )
    }
  },
  methods: {
    closeSelector() {
      this.modifyingMatchClientQuestion = {}
      this.isShowingSelector = false
    },
    deselectAll() {
      this.selectedClientQuestions = []
    },
    detailsToggled(expanded, index) {
      expanded
        ? document
            .getElementById(this.uuid)
            .querySelector(`div.list-grid-item-${index}`)
            .classList.add("expanded")
        : document
            .getElementById(this.uuid)
            .querySelector(`div.list-grid-item-${index}`)
            .classList.remove("expanded")
    },
    finishFetchingSuggestedMatches() {
      this.getSuggestedMatches = false
    },
    modifyGlobalMatch(clientQuestion) {
      this.modifyingMatchClientQuestion = clientQuestion
      this.isShowingSelector = true
    },
    selectAll() {
      this.selectedClientQuestions = this.clientQuestionItems.map(
        question => question._id.$oid
      )
    },
    async sort(args) {
      this.sortConfig[args.sortTitle].selected = args.option
      if (args.option === "default") {
        this.clientQuestionItems = await this.fetchClientQuestions(
          this.dataset._id.$oid
        )
        return
      }
      let sortTitle =
        args.sortTitle === "question code" ? "question_title" : args.sortTitle
      this.clientQuestionItems.sort(
        (a, b) =>
          SORT_BY[args.option] * a[sortTitle].localeCompare(b[sortTitle])
      )
    },
    toggleEditing() {
      this.isEditing = !this.isEditing
    },
    updateClientQuestion(index, clientQuestion) {
      this.$set(this.clientQuestionItems, index, clientQuestion)
    }
  },
  watch: {
    clientQuestions: {
      handler(questions) {
        this.clientQuestionItems = questions
      },
      deep: true
    },
    customClientQuestions: {
      handler(customQuestions) {
        this.clientQuestionItems = customQuestions
      },
      deep: true
    },
    fetchSuggestedMatches: function(val) {
      this.getSuggestedMatches = val
    }
  }
}
</script>
