const RATINGS_LANG = {
  en: {
    A: "Outstanding",
    B: "Good",
    C: "Area of Improvement",
    D: "No other organization"
  },
  fr: {
    A: "Exceptionnel",
    B: "Bon",
    C: "Domaine à améliorer",
    D: "Aucune autre organisation"
  }
}

export { RATINGS_LANG }
