import language_cache from "@/components/Translation/LanguageCache.json"

const lang = window.localStorage.getItem("apiLanguage") || "en"
const findTranslation = english => {
  const translation = language_cache.find(
    translation =>
      translation.text_en.trim().toLowerCase() === english.trim().toLowerCase()
  )
  return translation ? translation.text_fr : english
}

/*******************************
 *
 * Generate nodes required for summary table section
 * @param {Object} data summaryResultsTable from benchmarkAnalysisMixin
 * @returns {Object} pigeondoc table node
 *
 ******************************/
const SummaryTable = (data, columnNames) => {
  const rowNames = data.map(item => item["question"])

  const tableData = data.reduce((array, item) => {
    let row = []
    columnNames.forEach(col => {
      if (col === "question") return
      else row.push(item[col])
    })
    array.push(row)
    return array
  }, [])

  const capitalizeColNames = columnNames.reduce((capitalized, colName) => {
    let col = colName.replace(/(^\w|\s\w)/g, m => m.toUpperCase())
    col = lang != "en" ? findTranslation(colName) : col
    capitalized.push(col)
    return capitalized
  }, [])

  return {
    columnNames: capitalizeColNames,
    rowNames: rowNames,
    data: tableData
  }
}

export default SummaryTable
