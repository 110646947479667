<template>
  <fieldset class="switch">
    <legend class="sr-only">{{ title }}</legend>
    <input
      class="input"
      type="radio"
      :name="uuid"
      :id="`op1-${uuid}`"
      :value="0"
      @focus="isFocused = true"
      @blur="isFocused = false"
      v-model="value"
    />
    <label
      :class="['label', { focused: isFocused }, { checked: value == 0 }]"
      :for="`op1-${uuid}`"
    >
      <span class="text">{{ op1 }}</span>
    </label>
    <input
      class="input"
      type="radio"
      :name="uuid"
      :id="`op2-${uuid}`"
      :value="1"
      @focus="isFocused = true"
      @blur="isFocused = false"
      v-model="value"
    />
    <label :class="['label', { checked: value == 1 }]" :for="`op2-${uuid}`">
      <span class="text"> {{ op2 }} </span>
    </label>
  </fieldset>
</template>

<script>
export default {
  name: "SwitchToggle",
  props: {
    default: {
      default: () => 0,
      type: Number
    },
    title: {
      type: String,
      requiried: true
    },
    op1: {
      type: String,
      requiried: true
    },
    op2: {
      type: String,
      requiried: true
    }
  },
  data() {
    return {
      value: null,
      uuid: "0",
      isFocused: false
    }
  },
  created() {
    this.value = this.default
    this.uuid = this.createUuid()
  },
  methods: {
    //FIXME: update when pigeon-core is updated
    createUuid() {
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(
        c
      ) {
        var r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8
        return v.toString(16)
      })
    }
  },
  watch: {
    value: {
      immediate: true,
      handler: function(val) {
        this.$emit("onToggle", val)
      }
    }
  }
}
</script>
