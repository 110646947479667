<template>
  <Modal
    class="drivers__select-wrapper"
    :uid="uuid"
    :show="showDriversVariables"
    @close="$emit('close-drivers-variables')"
  >
    <template #header>
      <VisibleText>
        {{ dependentVariableTitle }} {{ newDriver ? "" : "Variables" }}
      </VisibleText>
    </template>
    <p>
      <VisibleText
        >Drivers analysis only accepts questions with scale data type
      </VisibleText>
      <HasPermission to="benchmarking-group-iccs">
        <VisibleText>
          and have a global match to the CMT
        </VisibleText></HasPermission
      >.
    </p>
    <span class="drivers__select-table-flex">
      <h2 class="drivers__select-table-title">
        <VisibleText>Dependent Variable</VisibleText>
      </h2>
      <button
        class="btn-secondary"
        type="button"
        @click="onClickGetSuggestedVars"
        v-if="newDriver"
      >
        <VisibleText> Get suggested variables </VisibleText>
      </button>
    </span>
    <SelectQuestionsTable
      :questions="dependentVariableTableQs"
      :selected="selectedDependentQuestionId"
      :hide-toolbar="true"
      @update-selected-questions="newDependentQuestionId = $event"
      v-if="dependentVariableTableQs.length > 0"
    />
    <p class="error-msg__inline" v-else>
      <VisibleText>Please select a dependent variable.</VisibleText>
    </p>
    <h2 class="drivers__select-table-title" style="margin-top: 2rem">
      <VisibleText>Independent Variables</VisibleText>
    </h2>
    <SelectQuestionsTable
      class="drivers__select-table"
      :disableInput="disableInput"
      :for-drivers="true"
      :questions="independentVariableTableQs"
      :selected="selectedIndependentQuestionIds"
      :key="indepTableKey"
      @update-selected-questions="newIndependentQuestionIds = $event"
      @set-dependent="setDependentVariable"
    />
    <template #footer>
      <button class="btn-default" @click="onClickSaveVariables">
        <VisibleText>Save Variables</VisibleText>
      </button>
      <button class="btn-cancel" @click="$emit('close-drivers-variables')">
        <VisibleText>Close</VisibleText>
      </button>
      <MessageInline :message="message.text" :message-type="message.type" />
    </template>
  </Modal>
</template>

<script>
import MessageInline from "@/components/UI/Message/MessageInline.vue"
import Modal from "@/components/UI/Modal.vue"
import SelectQuestionsTable from "../../Components/SelectQuestionsTable.vue"

export default {
  name: "ProjectAnalysisDriversVariables",
  components: {
    MessageInline,
    Modal,
    SelectQuestionsTable
  },
  props: {
    dependentVariableTitle: {
      default: () => "Drivers",
      type: String
    },
    // pre-selected dependent questions (used when modifying vars)
    dependentQuestions: {
      default: () => [],
      type: Array
    },
    // pre-selected independent questions (used when modifying vars)
    independentQuestions: {
      default: () => [],
      type: Array
    },
    newDriver: {
      defualt: () => false,
      type: Boolean
    },
    showDriversVariables: {
      default: () => false,
      type: Boolean
    }
  },
  data() {
    return {
      message: {
        text: "",
        type: ""
      },
      newDependentQuestionId: [],
      newIndependentQuestionIds: [],
      suggestedVariables: {},
      useSuggestedVars: false,
      indepTableKey: 2,
      uuid: Date.now()
    }
  },
  computed: {
    // local copy of client questions with just dependent variable
    dependentVariableTableQs() {
      return this.$store.getters["datasetDetails/getClientQuestions"].filter(
        q =>
          q._id.$oid === this.newDependentQuestionId[0] ||
          (this.dependentQuestions[0] &&
            q._id.$oid === this.dependentQuestions[0]._id.$oid)
      )
    },
    // local copy of client questions without dependent variable
    independentVariableTableQs() {
      return this.$store.getters["datasetDetails/getClientQuestions"].filter(
        q => q._id.$oid !== this.newDependentQuestionId[0]
      )
    },
    selectedDependentQuestionId() {
      // dependent question should be the same as dependent variable table
      return this.dependentVariableTableQs.map(q => q._id.$oid)
    },
    selectedIndependentQuestionIds() {
      return this.useSuggestedVars
        ? this.suggestedVariables.independent_variables_ids
        : this.independentQuestions.map(q => q._id.$oid)
    }
  },
  methods: {
    /**
     * Disable inputs for questions that are non-scale
     * - for iccs also disable if it does not have a global match
     * - do not disable inputs that were previously selected / questions that
     * are in in this.selectedIndependentQuestionIds array
     */
    disableInput(item) {
      const invalidTypes = [
        "OPEN_ENDED",
        "NUMERIC",
        "ID",
        "CAT",
        "DATE",
        "OTHER"
      ]
      // if iccs, disable any non-benchmarked items
      if (
        this.$pigeonline.permissions.hasPermission("benchmarking-group-iccs")
      ) {
        return (
          !item.approved_data_type ||
          !item.benchmarking_id ||
          (invalidTypes.includes(item.approved_data_type) &&
            !this.selectedIndependentQuestionIds.includes(item._id.$oid))
        )
      }
      return (
        !item.approved_data_type ||
        (invalidTypes.includes(item.approved_data_type) &&
          !this.selectedIndependentQuestionIds.includes(item._id.$oid))
      )
    },
    async onClickGetSuggestedVars() {
      this.suggestedVariables = await this.fetchSuggestedVariables()
      this.setDependentVariable(this.suggestedVariables.dependent_variable_id)
      this.setIndependentVariable()
    },
    onClickSaveVariables() {
      this.resetMessage()
      if (this.validate()) return
      this.saveVariables()
      this.$emit("close-drivers-variables")
    },
    setDependentVariable(questionId) {
      this.newDependentQuestionId = [questionId]
    },
    setIndependentVariable() {
      this.useSuggestedVars = true
      this.indepTableKey++
    },
    saveVariables() {
      // when we are changing dependent variable - remove it from independent
      let indx = this.newIndependentQuestionIds.indexOf(
        this.newDependentQuestionId[0]
      )
      if (indx > -1) {
        this.newIndependentQuestionIds.splice(indx, 1)
      }
      this.$emit(
        "modify-vars",
        this.newDependentQuestionId[0],
        this.newIndependentQuestionIds
      )
    },
    resetMessage() {
      this.message = {
        text: "",
        type: ""
      }
    },
    validate() {
      if (this.newDependentQuestionId.length === 0) {
        this.message.text = "Please select a dependent variable."
        this.message.type = "error"
      } else if (this.newIndependentQuestionIds.length === 0) {
        this.message.text = "Please select independent variables."
        this.message.type = "error"
      }
      return this.message.text.length > 0
    },
    async fetchSuggestedVariables() {
      try {
        const datasetId = this.$store.getters["datasetDetails/getDataset"]._id
          .$oid
        const response = await this.$services.DRIVERS_SERVICE.suggestedVariables(
          {
            data_set_id: datasetId
          }
        )
        return response
      } catch (e) {
        throw new Error("DatasetVariables:fetchSuggestedVariables" + e.message)
      }
    }
  }
}
</script>
