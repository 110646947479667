<template>
  <Modal
    class="create-new-analysis"
    uid="create-new-analysis-modal"
    :key="updateKey"
    :show="show"
    :reset-listener="resetListener"
    @close="$emit('close-modal')"
  >
    <template v-slot:header>
      <VisibleText>Create new analysis</VisibleText>
    </template>
    <TableQuestions
      ref="questions-table"
      :caption="'Select questions to perform analysis.'"
      :questionsList="dataPreprocessed"
      @updated="tableUpdated"
    />
    <template v-slot:footer>
      <button
        class="create-new-analysis__confirm-select-btn"
        @click="confirmSelection"
        :disabled="isConfirmSelectDisabled"
      >
        <SvgIconDecorative
          icon="check"
          class="create-new-analysis__confirm-select-btn-icon"
        />
        <VisibleText>Confirm selection</VisibleText>
      </button>
    </template>
  </Modal>
</template>

<script>
// Components
import Modal from "@/components/UI/Modal.vue"
import SvgIconDecorative from "@/components/UI/Svg/SvgIconDecorative.vue"
import TableQuestions from "../Components/TableQuestions.vue"

import _ from "lodash"

export default {
  name: "CreateNewAnalysis",
  components: {
    Modal,
    SvgIconDecorative,
    TableQuestions
  },
  props: {
    data: {
      type: Array
    },
    show: {
      default: () => false,
      type: Boolean
    }
  },
  data() {
    return {
      isConfirmSelectDisabled: true,
      updateKey: Date.now(),
      resetListener: false
    }
  },
  computed: {
    dataPreprocessed() {
      return _.cloneDeep(this.data).map(function(el) {
        el.options.disabled = el.approved_data_type
          ? !(
              el.approved_data_type.match(/(SCALE_\d+_TO_\d+)+/) ||
              el.approved_data_type === "OPEN_ENDED"
            )
          : true
        return el
      })
    }
  },
  methods: {
    tableUpdated() {
      this.isConfirmSelectDisabled =
        this.$refs["questions-table"].selectedQuestions.length == 0
    },
    confirmSelection() {
      this.$emit(
        "confirmSelection",
        this.$refs["questions-table"].selectedQuestions.map(el => [
          el.id,
          el.question_title
        ])
      )
    }
  },
  watch: {
    show: function(val) {
      if (!val) return
      this.isConfirmSelectDisabled = true
      this.updateKey = Date.now()
      this.resetListener = !this.resetListener
    }
  }
}
</script>
