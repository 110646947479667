<template>
  <div class="modal-group" tabindex="-1">
    <div class="modal-group-header">
      <button
        v-if="showBackBtn"
        type="button"
        @click.stop="back"
        class="modal-group-back-btn"
      >
        &#8592; <VisibleText>Back</VisibleText>
      </button>
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "ModalGroupLayout",
  props: {
    showBackBtn: {
      type: Boolean,
      default: function() {
        return true
      }
    }
  },
  methods: {
    back() {
      this.$emit("back")
    }
  }
}
</script>
