<template>
  <article id="benchmark-manager-editor" class="section__content">
    <div class="benchmark-manager__editor-header">
      <InlineEditTextInput
        label="benchmark group name"
        input-id="benchmark-group-name-input"
        input-name="benchmark-group-name"
        :input-value="benchmarkGroup.name"
        @save="saveName"
      >
        <h2 class="benchmark-manager__editor-title">
          {{ benchmarkGroup ? benchmarkGroup.name : "" }}
        </h2>
      </InlineEditTextInput>
      <button
        class="benchmark-manager__editor-add"
        @click="isAddQuestionVisible = true"
      >
        <VisibleText>Add Queston</VisibleText>
      </button>
    </div>
    <!--------------------------------------------
    |
    | BENCHMARK GROUP DETAILS
    |
    --------------------------------------------->
    <BenchmarkManagerEditorDetails
      :benchmark-group="benchmarkGroup"
      :key="benchmarkGroup.id"
      v-if="benchmarkGroup"
    />

    <!--------------------------------------------
    |
    | NEW QUESTION
    |
    --------------------------------------------->
    <BenchmarkManagerNewQuestion
      :benchmark-group="benchmarkGroup"
      v-if="isAddQuestionVisible"
      @close-new-question="isAddQuestionVisible = false"
      @add-global-question="addGlobalQuestion"
    />

    <!--------------------------------------------
    |
    | PENDING REQUESTS MESSAGE
    |
    --------------------------------------------->
    <MessageBlock
      message-type="warning"
      :title="`You have ${pending.total} pending benchmark requests.`"
      v-if="pending.total"
    >
      <ul class="benchmark-manager__editor-pending-list">
        <li
          v-for="question in pending.globalQuestions"
          :key="`pending-${question._id.$oid}`"
        >
          <a
            class="benchmark-manager__editor-pending-list-a"
            :href="`#${question._id.$oid}`"
            @click.prevent="scrollToAnchor(question._id.$oid)"
          >
            {{ question.question_title }}
          </a>
        </li>
      </ul>
    </MessageBlock>

    <h2 class="benchmark-manager__editor-questions">
      <VisibleText> Benchmarking Questions </VisibleText>
    </h2>
    <section
      class="benchmark-manager__editor-question"
      v-for="(question, index) in globalQuestions"
      :key="question._id.$oid"
      :id="question._id.$oid"
    >
      <BenchmarkManagerEditorQuestion
        :question="question"
        :index="index"
        :benchmark-group="benchmarkGroup"
        @remove-global-question="removeGlobalQuestion"
        @update-global-question="updateGlobalQuestion"
      />
    </section>
  </article>
</template>

<script>
// Components
import BenchmarkManagerEditorDetails from "./BenchmarkManagerEditorDetails.vue"
import BenchmarkManagerEditorQuestion from "./BenchmarkManagerEditorQuestion/BenchmarkManagerEditorQuestion.vue"
import BenchmarkManagerNewQuestion from "./BenchmarkManagerEditorNewQuestion.vue"
import MessageBlock from "@/components/UI/Message/MessageBlock.vue"
import InlineEditTextInput from "@/components/UI/InlineEditTextInput.vue"

// Helpers
import { mapActions } from "vuex"

// Models
import BenchmarkGroup from "@/models/BenchmarkGroup.js"

// Services
import DatasetsService from "@/services/datasetsService.js"

export default {
  name: "BenchmarkManagerEditor",
  components: {
    BenchmarkManagerEditorDetails,
    BenchmarkManagerNewQuestion,
    BenchmarkManagerEditorQuestion,
    MessageBlock,
    InlineEditTextInput
  },
  data() {
    return {
      DATASETS_SERVICE: new DatasetsService(this.$pigeonline),
      benchmarkGroup: new BenchmarkGroup(),
      globalQuestions: null,
      isAddQuestionVisible: false,
      pending: {
        total: null,
        globalQuestions: []
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => vm.setData())
  },
  created() {
    this.setLoading(true)
    this.loadData().then(({ benchmarkGroup, globalQuestions }) => {
      this.benchmarkGroup = benchmarkGroup
      this.globalQuestions = globalQuestions
      this.setTotalPending(globalQuestions)
      this.setLoading(false)
    })
  },
  methods: {
    ...mapActions("loader", ["setLoading"]),
    // FIXME: temp hotfix, use proper pre-loading pattern
    async setData() {
      this.loadData().then(({ benchmarkGroup, globalQuestions }) => {
        this.benchmarkGroup = benchmarkGroup
        this.globalQuestions = globalQuestions
        this.setTotalPending(globalQuestions)
      })
    },
    async loadData() {
      const [benchmarkGroup, globalQuestions] = await Promise.all([
        this.fetchGroup(),
        this.fetchQuestions()
      ])
      return { benchmarkGroup, globalQuestions }
    },
    /**
     * Fetching and saving project
     * note that vue-core converts projects to apporpriate model so
     * response is of BenchmarkGroup type.
     */
    async fetchGroup() {
      try {
        const response = await this.$pigeonline.projects.getByID(
          BenchmarkGroup,
          this.$route.params.id
        )
        return response
      } catch (e) {
        throw new Error("BenchmarkManagerEditor:fetchProject " + e.message)
      }
    },
    /**
     * Fetching and saving global questions
     */
    async fetchQuestions() {
      try {
        const response = await this.DATASETS_SERVICE.globalQuestions(null, {
          benchmark_group: this.$route.params.id
        })
        return response
      } catch (e) {
        throw new Error("BenchmarkManagerEditor:fetchProject " + e.message)
      }
    },
    saveName(name) {
      this.benchmarkGroup.name = name
      this.$pigeonline.projects.update(this.benchmarkGroup)
    },
    setTotalPending(globalQuestions) {
      this.pending = globalQuestions.reduce(
        (pendingObj, question) => {
          if (question.matches.pending > 0) {
            pendingObj.total += question.matches.pending
            pendingObj.globalQuestions.push(question)
          }
          return pendingObj
        },
        { total: 0, globalQuestions: [] }
      )
    },
    scrollToAnchor(hash) {
      const el = document.getElementById(hash)
      if (el) {
        el.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "center"
        })
      }
    },
    addGlobalQuestion(newQuestion) {
      this.globalQuestions.unshift(newQuestion)
      this.isAddQuestionVisible = false
    },
    updateGlobalQuestion(index, question) {
      this.globalQuestions.splice(index, 1, question)
    },
    removeGlobalQuestion(index) {
      this.globalQuestions.splice(index, 1)
    }
  },
  watch: {
    benchmarkGroup: {
      deep: true,
      handler: function(value) {
        if (value && Object.keys(value).length > 0)
          this.$pigeonline.projects.update(value)
      }
    }
  }
}
</script>
