/**
 * An object representing a benchmarking project
 * @typedef {Object} Project
 */

import { ProjectModel } from "@pigeonline/core"

export default class BenchmarkingProject extends ProjectModel {
  constructor(data = {}) {
    super(data)

    this.status = {
      datasetSelected: false,
      analysisCompleted: false,
      reportCreated: false
    }
    this.datasets = null // id of dataset object (not dataset project object)
    this.report = null
    this.driversAnalysis = null
    this.benchmarkAnalysis = null
    this.textAnalysis = {}
    this.performanceAnalysis = []
    this.settings = {
      performanceMeasure: null,
      performanceType: null
    }

    this.setProps(data)
  }

  /**
   * Get the project type
   * @returns {String} string representation of this project type
   */
  static PROJECT_TYPE() {
    return "service_benchmarking"
  }

  /**
   * Adds a new dataset to datasets array
   * @param {String} newDataset dataset ID
   * TODO: in the future we want multiple datasets, for now it assumes
   * the dataset used is the first dataset in the array
   */
  addDataset(newDataset) {
    this.datasets = this.datasets ? this.datasets : []
    this.datasets.splice(0, 1, newDataset)
  }

  /**
   * Returns a string representation of the current status
   * @returns {String} current project status
   */
  currStatus() {
    if (this.status.reportCreated) return "reportCreated"
    else if (this.status.analysisCompleted) return "analysisCompleted"
    else if (this.status.datasetSelected) return "datasetSelected"
    else return "started"
  }

  /**
   * Returns a percentage representation of the current status
   * @returns {String} current project status
   */
  percentStatus() {
    let completed = 0
    let total = 0
    Object.keys(this.status).forEach(key => {
      if (this.status[key]) completed += 1
      total += 1
    })
    return `${((completed * 100) / total).toFixed(0)}%`
  }

  /**
   * Updates the status of the project by setting status[status] to true
   * (mark status as complete)
   * @param {String} status name of status
   */
  updateStatus(status) {
    if (this.status[status]) return
    this.status[status] = true
  }
}
