<template>
  <div class="spinner__container">
    <div
      :class="[
        { 'spinner__wrapper-component': componentStyle },
        'spinner__wrapper'
      ]"
      v-show="isLoading"
    >
      <div class="spinner" :class="{ done: !isLoading }"></div>
    </div>
    <p
      class="spinner__message visually-hidden"
      role="alert"
      aria-live="assertive"
      aria-busy="false"
      tabindex="-1"
    ></p>
  </div>
</template>

<script>
export default {
  name: "Spinner",
  props: {
    completeMessage: {
      type: String,
      default: "Loading Complete"
    },
    componentStyle: {
      //apply at component level or not
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    message: {
      type: String,
      default: "Loading"
    },
    shouldFocusMessage: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      lastFocusedElement: null
    }
  },
  watch: {
    isLoading: function(newValue) {
      const message = document.querySelector(".spinner__message")
      if (newValue) {
        this.lastFocusedElement = document.activeElement
        message.setAttribute("aria-busy", true)
        message.textContent = this.message

        // HOTFIX: removes jerky scroll to spinner DOM position
        // from the last clicked element
        if (this.shouldFocusMessage) message.focus()
      } else {
        message.textContent = this.completeMessage
        message.setAttribute("aria-busy", false)
      }
    }
  }
}
</script>
