<template functional>
  <span
    dataset-parent="MessageInline"
    :class="['message__inline', data.staticClass]"
    :role="props.role"
    :style="data.staticStyle"
    v-bind="data.attrs"
  >
    <svg
      focusable="false"
      role="img"
      :class="[`message__inline-icon--${props.messageType}`, 'icon']"
      v-if="props.messageType.length > 0 && props.message.length > 0"
    >
      <title>
        {{ $options.methods.translate(props.messageType, "MessageInline") }}
      </title>
      <use :xlink:href="`#${props.messageType}`" aria-hidden="true" />
    </svg>
    {{ $options.methods.translate(props.message, "MessageInline") }}
  </span>
</template>

<script>
export default {
  props: {
    messageType: {
      default: "", // warning, error, information, success
      type: String
    },
    role: {
      default: "status",
      type: String
    },
    message: {
      default: "",
      type: String
    }
  }
}
</script>
