<template>
  <div :class="['dataset-segments__tag-item', { disabled: isDisabled }]">
    <span class="dataset-segments__tag-item-title">
      <button
        title="remove"
        class="dataset-segments__list-remove"
        v-if="allowRemove"
        @click="$emit('remove')"
      >
        <RemoveIcon class="dataset-segments__list-remove-icon" />
      </button>
      <strong>{{ translate(segmentName, $options.name) }}</strong>
      <span
        class="dataset-segments__tag-item-count"
        v-if="segmentDetailsAvailable"
      >
        {{ segmentDetails[segmentName] || 0 }}
        <VisibleText> benchmarks</VisibleText>
      </span>
    </span>
    <span class="dataset-segments__tag-item-options">
      <span
        :class="[
          'dataset-segments__tag-item-options-btn',
          { selected: !isDisabled }
        ]"
        v-if="
          !!segments[segmentName] && segments[segmentName].client_question_id
        "
      >
        <VisibleText>some respondents</VisibleText>
      </span>
      <span
        :class="[
          'dataset-segments__tag-item-options-btn',
          { selected: !isDisabled }
        ]"
        v-else
      >
        <VisibleText>all respondents</VisibleText>
      </span>
    </span>
    <span
      class="dataset-segments__list-seg-details"
      v-if="!!segments[segmentName] && segments[segmentName].client_question_id"
    >
      <VisibleText>Those who responeded to</VisibleText>
      : "
      {{ getClientQuestion(segments[segmentName]).question_title }} -
      {{ getClientQuestion(segments[segmentName]).question_text }}
      "
      <VisibleText> and answered</VisibleText>
      : "
      {{ segments[segmentName].value }}
      "
      <VisibleText>are in this segment.</VisibleText>
    </span>
  </div>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  name: "DatasetSegmentsItemReadOnly",
  props: {
    allowRemove: {
      type: Boolean,
      default: () => false
    },
    parent: {
      default: () => "",
      type: String
    },
    segmentName: {
      default: () => {},
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGetters("datasetDetails", {
      clientQuestions: "getClientQuestions",
      dataset: "getDataset",
      segmentDetails: "getSegmentDetails"
    }),
    isDisabled() {
      return (
        !this.segments[this.segmentName] &&
        this.parent === "DatasetSegmentsItem"
      )
    },
    segments() {
      return this.dataset.segments
    },
    segmentDetailsAvailable() {
      return (
        this.dataset.benchmark_group && this.dataset.benchmark_group.length > 0
      )
    }
  },
  methods: {
    /**
     * Get client question that is labelled as segment for that dataset
     * eg.
     *  {"segments":{"External Services":{},"Relational":{ client_question_id: 123 }}}
     *  getClientQuestion('Relational')
     *  returns client_question with id 123
     */
    getClientQuestion(segment) {
      return this.clientQuestions.find(
        q => q._id.$oid === segment.client_question_id
      )
    }
  }
}
</script>
