import Vue from "vue"

import { PigeonDocModel } from "@pigeonline/pigeondoc"

import headerSection from "./sections/headerSection.js"
const lang = window.localStorage.getItem("apiLanguage") || "en"

const ReportDoc = (date, datasetName, org = "", sections = []) => {
  const header = headerSection(date, datasetName, org)
  const allSections = sections.concat(header)
  const reportTitle =
    lang === "en"
      ? "Research AI Report"
      : "Recherche dans le rapport sur l’intelligence artificielle"

  const doc = new PigeonDocModel({
    name: reportTitle,
    nodes: allSections,
    schemaVersion: "2.0",
    pdfConfig: {
      pageMargins: [40, 130, 40, 50],
      footer: {
        text: reportTitle,
        alignment: "right",
        bold: true
      },
      styles: {
        title: {
          fontSize: 28,
          alignment: "right",
          bold: true,
          margin: [0, 400, 0, 0]
        },
        "header-date": {
          color: "#ff9428",
          fontSize: 20,
          alignment: "right"
        },
        "header-dataset-name": {
          fontSize: 20,
          alignment: "right",
          margin: [0, 40, 0, 0]
        },
        "header-org": {
          fontSize: 18,
          alignment: "right"
        },
        "table__secondary-data": {
          fontSize: 10,
          color: "#747474"
        },
        "drivers-chart-title": {
          fontSize: 16,
          bold: false,
          color: "#747474",
          margin: [0, 5],
          font: "Open Sans Condensed",
          alignment: "center"
        },
        "performance-chart-title": {
          fontSize: 16,
          bold: false,
          color: "#747474",
          margin: [0, 5],
          font: "Open Sans Condensed",
          alignment: "center"
        },
        "q-group-heading": {
          bold: true,
          background: "#ebf1f8",
          color: "#1254a6",
          fontSize: 18,
          margin: [0, 20]
        },
        "q-group-b-chart-title": {
          bold: false,
          fontSize: 14,
          margin: [0, 20]
        },
        "q-group-b-chart-title-strong": {
          bold: true,
          background: "#ebf1f8",
          color: "#1254a6"
        },
        "benchmarking__rating-A": {
          background: "#8dc63f",
          color: "#2D2E2C"
        },
        "benchmarking__rating-B": {
          background: "#F1CD17",
          color: "#2D2E2C"
        },
        "benchmarking__rating-C": {
          background: "#d42828",
          color: "#fff"
        },
        "benchmarking__rating-D": {
          background: "#7E7E7E",
          color: "#fff"
        },
        "pdf-hidden-text": {
          color: "#fff"
        }
      }
    }
  }) // end of PigeonDocModel

  // change for ICCS theme
  if (Vue.prototype.$theme.theme.name == "iccs") {
    doc.name = "CMT BENCHMARKING REPORT"

    const headerlocation = "/assets/pdfHeader.png"
    doc.pdfConfig.images = {
      headerimg: headerlocation
    }
    doc.pdfConfig.header = {
      image: "headerimg",
      height: 120,
      margin: [0, 0],
      alignment: "right"
    }
    doc.pdfConfig.footer.text = "CMT BENCHMARKING REPORT"
  }
  return doc
}

export default ReportDoc
