// Helpers
import { PigeonDocModel } from "@pigeonline/pigeondoc"
import ReportDoc from "@/components/Reports/reportModel"
import sections from "@/components/Reports/sections/sections.js"
import DriversSectionHeader from "@/components/Reports/helpers/driversSectionHeader.js"

export default {
  methods: {
    addNodesStartingAtIndex(report, nodes, startIndex) {
      for (let i = 0; i < nodes.length; i++) {
        report.insertNodeAtIndex(nodes[i], startIndex + i)
      }
    },
    createDate() {
      return {
        type: "text",
        content: this.$options.filters.formatDate(Date.now()),
        id: "header-date",
        meta: {
          pdfConfig: {
            style: "header-date"
          }
        }
      }
    },
    lastIndexOfNode(nodes, key, value) {
      for (let i = nodes.length - 1; i >= 0; i--) {
        if (nodes[i][key] === value) return i
      }
      return -1
    },
    async updateReportWithDrivers(
      driversUUID,
      id,
      chartData,
      driversChartTitle
    ) {
      const uuid = driversUUID
        ? driversUUID
        : (Math.random() * Date.now()).toFixed(0)
      const report = await this.$pigeonline.projects.getByID(PigeonDocModel, id)
      let driversSection = sections.driversSection(
        driversChartTitle,
        chartData,
        uuid
      )

      // find the last added drivers
      const lastIndex = this.lastIndexOfNode(
        report.nodes,
        "id",
        "drivers-priority-matrix"
      )

      // find benchmark summary table
      const summaryTableIndex = report.nodes.findIndex(
        node => node.id === "benchmark-summary-table"
      )

      // find current index of driver (if exsits)
      let currIndex = report.nodes.findIndex(
        node => node.meta.driversUUID === uuid
      )

      // drivers section does not exist
      if (lastIndex < 0) {
        const sectionHeader = DriversSectionHeader()
        const driversSectionWithHeader = [sectionHeader, ...driversSection]

        // if benchmark exists, add the header then append it before question by question
        // else just add it to the report after header info
        const headerIndex = report.nodes.findIndex(
          node => node.id == "header-date"
        )
        const startIndex =
          summaryTableIndex >= 0 ? summaryTableIndex + 1 : headerIndex + 3
        this.addNodesStartingAtIndex(
          report,
          driversSectionWithHeader,
          startIndex
        )

        await this.updateReport(report)
        return uuid
      }

      // updating drivers thats already in report
      if (currIndex > -1) {
        for (let i = 0; i < driversSection.length; i++) {
          report.replaceNodeAtIndex(driversSection[i], currIndex + i)
        }
      } else {
        this.addNodesStartingAtIndex(report, driversSection, lastIndex + 1)
      }
      const date = this.createDate()
      report.replaceNodeAtIndex(date, 0)
      await this.updateReport(report)
      return uuid
    },
    async updateReportWithBenchmarking(
      id,
      summaryTable,
      summaryTableColNames,
      clientQuestionDetails,
      matchesDetails,
      segments,
      details,
      summaryResults
    ) {
      const report = await this.$pigeonline.projects.getByID(PigeonDocModel, id)
      const benchmarkSummarySection = sections.benchmarkSection(
        summaryTable,
        summaryTableColNames,
        summaryResults.overall_dynamic_summary[1]
      )

      const headerIndex = report.nodes.findIndex(
        node => node.id == "header-date"
      )
      const benchmarkingIndex = report.nodes.findIndex(
        node => node.id == "section-title-benchmark-summary"
      )

      // benchmarks already exists
      if (benchmarkingIndex >= 0) {
        // replace summary table
        for (let i = 0; i < benchmarkSummarySection.length; i++) {
          report.replaceNodeAtIndex(
            benchmarkSummarySection[i],
            benchmarkingIndex + i
          )
        }

        // delete everything after the last performance (question by question & methodology)
        // or if no performance, everything after the summary table
        const lastPerformanceIndex = this.lastIndexOfNode(
          report.nodes,
          "id",
          "performance-analysis-chart"
        )
        if (lastPerformanceIndex > -1)
          report.nodes.length = lastPerformanceIndex + 1
        else report.nodes.length = benchmarkingIndex + 3
      }
      // add summary table after header
      else {
        for (let i = 0; i < benchmarkSummarySection.length; i++) {
          report.insertNodeAtIndex(
            benchmarkSummarySection[i],
            headerIndex + 3 + i
          )
        }
      }

      // add question by question & methodology
      const questionByQuestionSection = sections.questionByQuestionSection(
        clientQuestionDetails,
        matchesDetails,
        segments,
        details,
        this.$store.getters["datasetDetails/getClientQuestions"]
      )
      const methodology = sections.surveyMethodologySection(
        summaryResults.data_set_meta_data,
        questionByQuestionSection.orgs
      )

      report.nodes.push(...questionByQuestionSection.nodes)
      report.nodes.push(...methodology)

      await this.updateReport(report)
    },
    async updateReportWithPerformance(
      reportID,
      performanceUUID,
      data,
      pChartType,
      cChartType,
      cChartNodes,
      cChartNodesTitles
    ) {
      const report = await this.$pigeonline.projects.getByID(
        PigeonDocModel,
        reportID
      )
      const CURR_NODE_INDEX = report.nodes.findIndex(
        node => node.meta.report && node.meta.report.uuid == performanceUUID
      )
      const ADD_NEW_SECTION = !performanceUUID || CURR_NODE_INDEX === -1

      // find the last added performance
      const lastIndex = this.lastIndexOfNode(
        report.nodes,
        "id",
        "subsection-title-performance"
      )

      // check if a node is h1, h2
      const isTopHeadingAtIndex = function(index) {
        if (!report.nodes[index]) return true // null nodes (report endpoints) fulfill the function of top headings for usecases below
        return ["h1", "h2"].includes(report.nodes[index].meta.level)
      }

      let performanceSection
      if (ADD_NEW_SECTION) {
        // generate new performance UUID
        performanceUUID = (Math.random() * Date.now()).toFixed(0)
        performanceSection = sections.performanceSection.performanceSection(
          data,
          pChartType,
          cChartType,
          cChartNodes,
          cChartNodesTitles,
          performanceUUID,
          lastIndex >= 0 ? report.nodes[lastIndex].meta.report.index + 1 : 1
        )

        if (lastIndex < 0) {
          // no performance sections, add WITH HEADER
          const performanceSectionHeader = sections.performanceSection.performanceSectionHeader()
          const performanceSectionWithHeader = [
            ...performanceSectionHeader,
            ...performanceSection
          ]

          const lastDriversIndex = this.lastIndexOfNode(
            report.nodes,
            "id",
            "drivers-priority-matrix"
          )
          const summaryTableIndex = report.nodes.findIndex(
            node => node.id === "benchmark-summary-table"
          )
          const headerIndex = report.nodes.findIndex(
            node => node.id == "header-date"
          )

          // add the nodes afterwards on following order: last drivers, summary table, report header
          let startIndex
          if (lastDriversIndex >= 0) {
            startIndex = lastDriversIndex + 1
          } else if (summaryTableIndex >= 0) {
            startIndex = summaryTableIndex + 1
          } else {
            startIndex = headerIndex + 3
          }
          this.addNodesStartingAtIndex(
            report,
            performanceSectionWithHeader,
            startIndex
          )
        } else {
          // prev performance sections exists, add WITHOUT HEADER
          let offset = 1
          while (!isTopHeadingAtIndex(lastIndex + offset)) {
            offset = offset + 1
          }
          this.addNodesStartingAtIndex(
            report,
            performanceSection,
            lastIndex + offset
          )
        }
      } else {
        // performance section ALREADY EXISTS! update it.
        performanceSection = sections.performanceSection.performanceSection(
          data,
          pChartType,
          cChartType,
          cChartNodes,
          cChartNodesTitles,
          performanceUUID,
          report.nodes[CURR_NODE_INDEX].meta.report.index
        )

        // replace existing performance nodes
        for (let i = 0; i < performanceSection.length; i++) {
          if (i != 0 && isTopHeadingAtIndex(CURR_NODE_INDEX + i)) {
            // insert if top heading reached (a.k.a. other sections)
            report.insertNodeAtIndex(performanceSection[i], CURR_NODE_INDEX + i)
          }
          // replace otherwise
          report.replaceNodeAtIndex(performanceSection[i], CURR_NODE_INDEX + i)
        }

        // remove all nodes until heading reached after the newly updated perf section
        while (
          !isTopHeadingAtIndex(CURR_NODE_INDEX + performanceSection.length)
        ) {
          report.nodes.splice(CURR_NODE_INDEX + performanceSection.length, 1)
        }
      }
      await this.updateReport(report)
      return performanceUUID
    },
    async updateReport(report) {
      try {
        await this.$pigeonline.projects.update(report)
      } catch (e) {
        throw new Error("projectMixin:updateReport " + e.message)
      }
    },
    async createReportIfEmpty(date, datasetName, org = "") {
      if (!this.project.report) {
        const doc = ReportDoc(date, datasetName, org)
        const reportProject = await this.$pigeonline.projects.create(doc)
        const id = reportProject.id

        this.project.report = id
        this.project.updateStatus("reportCreated")
        this.$emit("updateProject", await this.saveProject(this.project))
      }
    }
  }
}
