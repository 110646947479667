<template>
  <ul class="tagging-list">
    <li v-for="tag in tags" :key="tag" class="tagging-list__item">
      <span class="content">
        {{ tag }}
        <ButtonIconOnly
          class="button"
          icon="remove"
          :id="$pigeonline.createUUID()"
          :data-parent="$options.name"
          @click-handler="$emit('remove', tag)"
        >
          {{ translate(buttonTitle, $options.name) }}
        </ButtonIconOnly>
      </span>
    </li>
  </ul>
</template>

<script>
import ButtonIconOnly from "@/components/UI/Button/ButtonIconOnly.vue"

export default {
  name: "TaggingList",
  components: {
    ButtonIconOnly
  },
  props: {
    buttonTitle: {
      default: () => "remove keyword",
      type: String
    },
    tags: {
      type: Array
    }
  }
}
</script>
