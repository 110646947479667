<template>
  <section>
    <Modal
      header-tag="h1"
      :uid="`video-clip-modal-${uuid}`"
      :show="isVideoClipExpanded"
      :resetListener="resetListener"
      @close="$emit('close')"
      :wider-format="true"
    >
      <template slot="close">
        <form
          class="user-guide__form"
          id="video_clip_do_not_show_form"
          @submit.prevent="close_and_save"
        >
          <ButtonIconOnly
            icon="remove"
            :id="`close-${uuid}`"
            class="modal-close-btn"
            @click-handler="close_and_save"
          >
            <VisibleText>close</VisibleText>
          </ButtonIconOnly>
        </form>
      </template>
      <video class="video-clip" ref="video" controls>
        <source src="/assets/landing/intro.webm" type="video/webm" />
        <source src="/assets/landing/intro.mp4" type="video/mp4" />
        <VisibleText
          >Sorry, your browser doesn't support embedded videos.
        </VisibleText>
      </video>
      <button
        class="landing-page__button landing-page__button--small"
        @click="showTranscript = !showTranscript"
      >
        <VisibleText>Show transcript</VisibleText>
      </button>
      <div class="landing-page__font" v-if="showTranscript">
        <p>
          <span class="landing-page__font--bold">
            <VisibleText>Introduction</VisibleText> (0 to 0:26):
          </span>
          <VisibleText
            >My name is Cody Dodd, co-founder of PigeonLine. We're dedicated to
            making privacy based and accessible AI solutions. Many of you have
            access to your customers' voice through surveys, or your employees.
            Rich data, but you don't know what to do with it. Many of you have
            to turn to analysts or experts. Well, ResearchAI hopes to bring the
            best practices in measurement directly to your fingertips. No
            expertise required. Let me show you how.
          </VisibleText>
        </p>
        <p>
          <span class="landing-page__font--bold">
            <VisibleText>Cleaning data - Data types</VisibleText> (0:27 to
            0:56):
          </span>
          <VisibleText
            >At first you can see our various projects and datasets. We're going
            to go to datasets and upload a new one. In this case, a public
            sector satisaction survey that's saved to XLS, or Excel format. It
            will then run us through a number of cleaning steps. The first is to
            identify question types. It will make suggestions for us. It's found
            scales, but it's missed a few categorical data which is similar to
            demographics. Let's confirm the rest and continue.
          </VisibleText>
        </p>
        <p>
          <span class="landing-page__font--bold">
            <VisibleText>Cleaning the data - Matching benchmarks</VisibleText>
            (0:55 to 1:20):
          </span>
          <VisibleText
            >In this next section we're going to see if our questions match
            questions from a benchmarking group to compare to peers. We're going
            to use the ICCS Benchmarking Group but your organization can create
            their own. It's going to take our question wording and make a
            suggested match to see if questions are similar to it in the global
            database. Let's spend a few minutes to make some matches.
          </VisibleText>
        </p>
        <p>
          <span class="landing-page__font--bold">
            <VisibleText>Cleaning the data - Dataset labeling</VisibleText>
            (1:21 to 1:35)
          </span>
          <VisibleText
            >And in this final step let's label a little bit of information
            about our customers who answered the survey. What type of customers
            were they? What channels did they use? what date was the survey run?
            This helps make results more accurate and easier to search for
            results later.
          </VisibleText>
        </p>
        <p>
          <span class="landing-page__font--bold">
            <VisibleText>Analysis - Drivers</VisibleText> (1:36 to 2:10)
          </span>
          <VisibleText
            >Great. Our dataset is clean and we're ready to make a report. Let's
            give it a name. And we'll be brought to a page that shows us an
            analysis option. This first type of analysis is drivers analysis,
            what drivers certain outcomes. We're going to change the suggested
            model and look for what drives overall satisfaction. When the
            results come in, it will list the top most important drivers of
            satisfaction, and in the chart below we can see that importance
            score, as well as performance: those questions where those were jus
            t not happy. You will want to tackle those areas with high
            importance but low performance.
          </VisibleText>
        </p>
        <p>
          <span class="landing-page__font--bold">
            <VisibleText>Analysis - Performance</VisibleText> (2:11 to 2:38)
          </span>
          <VisibleText
            >The next type of analysis is performance, to see how individual
            questions are actually scored by users. But we're going to use it to
            do something interesting. At the bottom you will see a button that
            says "get suggested comparisons". This will find significant
            outliers than all the rest, either above or below. In this case if
            found a group that is significantly lower on the question "staff
            knowledgeability" than their peers. We can also check manually to
            confirm if we'd like.
          </VisibleText>
        </p>
        <p>
          <span class="landing-page__font--bold">
            <VisibleText>Analysis - Benchmarking</VisibleText> (2:39 to 2:51)
          </span>
          <VisibleText
            >Benchmarking is even easier thanks to the data cleaning stage.
            It'll automatically compare us to the groups from the dataset we
            matched ourselves to in an earlier step. We can see how we perform
            to individual questions across different groups.
          </VisibleText>
        </p>
        <p>
          <span class="landing-page__font--bold">
            <VisibleText>Analysis - Text analysis</VisibleText> (2:52 to 3:57)
          </span>
          <VisibleText
            >Some of you may have open-ended text questions. We can help with
            that too. One way we can help is by autosuggesting themes that may
            be hiding in your text. In this case it found a number of random
            clustered themes. We can clean those up and we found something
            interesting about our website actually. Let's label that. But it
            will also look to some of our pre-trained models, some of the best
            in class models out there. To look for customer experience factors
            as well. It's found a number, like staff knowledgeability. Isn't
            that interesting, we talked about that earlier. If we take a closer
            look at staff knowledgeability, we can see and switch to sentiment.
            And we can see a range of negative feelings about staff
            knowledeability. So across a number of levels of analysis we've
            learned something about what we've done well and what we've not done
            well. We can also continue our search, typing staff, and notice it's
            expanded our criteria, suggesting employees as well. People who say
            'staff' sometimes say 'employees' as well. Just some smartness for
            us.
          </VisibleText>
        </p>
        <p>
          <span class="landing-page__font--bold">
            <VisibleText>Reporting </VisibleText>(3:58 to 4:28):
          </span>
          <VisibleText
            >And now we're ready for a report. Switching over to the report view
            carries forward all of our analysis in an interactive format with a
            little bit more information, such as exact benchmarking groups, how
            we compare. We can save to pdf. We can invite our colleagues to
            participate to edit and interactive with the datasets and report.
            And to continue with our insights and improvement agenda.
          </VisibleText>
        </p>
      </div>
    </Modal>
  </section>
</template>

<script>
import Modal from "@/components/UI/Modal.vue"
import ButtonIconOnly from "@/components/UI/Button/ButtonIconOnly.vue"

export default {
  name: "VideoClip",
  components: {
    Modal,
    ButtonIconOnly
  },
  props: ["status"],
  data() {
    return {
      newStatus: false,
      firstLoad: true,
      resetListener: false,
      showTranscript: false
    }
  },
  methods: {
    close_and_save() {
      this.isVideoClipExpanded = false
      this.$emit("close")
    }
  },
  computed: {
    isVideoClipExpanded: {
      get() {
        return this.newStatus
      },
      set(value) {
        this.newStatus = value
      }
    },
    uuid() {
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(
        c
      ) {
        var r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8
        return v.toString(16)
      })
    }
  },
  watch: {
    status(newVal) {
      this.newStatus = newVal
    },
    isVideoClipExpanded(newVal) {
      if (newVal) {
        this.$refs["video"].play()
      } else {
        this.$refs["video"].pause()
      }
    }
  }
}
</script>
