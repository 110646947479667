<template>
  <div class="ui-rounded-pill">
    <div
      class="ui-rounded-pill__wrapper"
      :style="cssStyles"
      @click.stop="() => {}"
    >
      <SvgIcon
        class="ui-rounded-pill__icon"
        :icon="icon"
        :aria-hidden="true"
        :focusable="false"
        v-if="icon"
      />
      <span class="ui-rounded-pill__content">
        {{ value }}
      </span>
      <ButtonIconOnly
        class="ui-rounded-pill__close-btn"
        icon="remove"
        :id="`${_uid}-rounded-pill-close-btn`"
        @click-handler="remove"
      >
        <VisibleText>remove</VisibleText>
      </ButtonIconOnly>
    </div>
  </div>
</template>

<script>
import ButtonIconOnly from "@/components/UI/Button/ButtonIconOnly.vue"
import SvgIcon from "@/components/UI/Svg/SvgIcon.vue"

export default {
  name: "RoundedPill",
  props: ["value", "icon", "cssStyles"],
  components: {
    ButtonIconOnly,
    SvgIcon
  },
  methods: {
    remove: function(event) {
      event.stopPropagation()
      this.$emit("remove", true)
    }
  }
}
</script>
