<template>
  <span>
    <label
      :class="[{ 'sr-only': srOnlyLabel }, 'select-label']"
      :for="`dt-${id}`"
    >
      <slot></slot>
    </label>
    <div class="select-wrapper">
      <select
        class="select dataset-cleaner__select-type"
        :id="`dt-${id}`"
        v-model="dataType"
      >
        <option disabled value="">
          <VisibleText>Set Data Type</VisibleText>
        </option>
        <optgroup label="Data Types">
          <option
            v-for="type in Object.keys(TYPE_TO_READABLE)"
            :key="type"
            :value="type"
            :data-parent="$options.name"
          >
            {{ translate(TYPE_TO_READABLE[type], $options.name) }}
          </option>
        </optgroup>
        <optgroup label="Scale Types">
          <option
            v-for="scale in Object.keys(SCALE_TYPE_TO_READABLE)"
            :key="scale"
            :value="scale"
            :data-parent="$options.name"
          >
            {{ translate(SCALE_TYPE_TO_READABLE[scale], $options.name) }}
          </option>
        </optgroup>
      </select>
    </div>
  </span>
</template>

<script>
import {
  TYPE_TO_READABLE,
  SCALE_TYPE_TO_READABLE
} from "@/utils/consts/constsDataTypes.js"

export default {
  name: "DatasetSelectType",
  props: {
    id: {
      default: () => "select",
      type: String
    },
    initDataType: {
      default: () => null,
      type: String
    },
    srOnlyLabel: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      // readable / UI friendly version of data types
      TYPE_TO_READABLE: TYPE_TO_READABLE,
      SCALE_TYPE_TO_READABLE: SCALE_TYPE_TO_READABLE,
      dataType: ""
    }
  },
  watch: {
    dataType: function(dataType) {
      if (dataType.length == 0) return
      // can update data type if you have permission or its different from approved type
      if (
        (this.$pigeonline.permissions.hasPermission("update-dataset") &&
          !["CAT_OR_SCALE"].includes(this.dataType)) ||
        this.dataType != this.initDataType
      ) {
        this.$emit("set-data-type", dataType)
      }
    }
  }
}
</script>
