<template>
  <Accordion title-element-type="h3" :icon="accordionIcon">
    <!--------------------------------------------
    | 
    | Question heading
    |
    --------------------------------------------->
    <template #heading>
      {{ question.question_title }}
    </template>

    <!--------------------------------------------
    | 
    | Question details
    |
    --------------------------------------------->
    <template #additionalDetails>
      <button
        class="editor-question__remove"
        icon="remove"
        :id="`${question._id.$oid}-del`"
        @click="deleteQuestion"
      >
        <VisibleText> delete </VisibleText>
      </button>

      <!-- Question text -->
      <div class="editor-question__text">
        <ul class="list">
          <li>
            <VisibleText class="lang">English</VisibleText>
            <template v-if="!isEditingEng">
              {{ question.question_text }}
              <ButtonIconOnly
                class="action-icon"
                icon="edit"
                :id="$pigeonline.createUUID()"
                @click-handler="isEditingEng = true"
              >
                edit
              </ButtonIconOnly>
            </template>
            <template v-if="isEditingEng">
              <input
                aria-label="english question wording"
                class="form-input--margin--none input"
                type="text"
                @keydown.enter.stop="isEditingEng = false"
                v-model="question.question_text"
              />
              <ButtonIconOnly
                class="action-icon"
                icon="check"
                :id="$pigeonline.createUUID()"
                @click-handler="isEditingEng = false"
              >
                save
              </ButtonIconOnly>
            </template>
          </li>
          <li>
            <VisibleText class="lang" v-if="question.question_text_fr">
              French
            </VisibleText>
            <template v-if="question.question_text_fr && !isEditingFrench">
              {{ question.question_text_fr }}
              <ButtonIconOnly
                class="action-icon"
                icon="edit"
                :id="$pigeonline.createUUID()"
                @click-handler="isEditingFrench = true"
              >
                edit
              </ButtonIconOnly>
            </template>
            <template v-if="isEditingFrench">
              <input
                aria-label="french question wording"
                class="form-input--margin--none input"
                type="text"
                @keydown.enter.stop="isEditingFrench = false"
                v-model="question.question_text_fr"
              />
              <ButtonIconOnly
                class="aciton-icon"
                icon="check"
                :id="$pigeonline.createUUID()"
                @click-handler="isEditingFrench = false"
              >
                save
              </ButtonIconOnly>
            </template>
          </li>
        </ul>
        <!-- add question wording -->
        <QuestionWording
          :question="$data.$_question"
          :init-array="false"
          @update-question="updateQuestionWording"
        >
          <template #btn-text>
            <SvgIconDecorative icon="add" title="add language" />
            <span class="sr-only" :data-parent="$options.name">
              {{ translate("add language", $options.name) }}
            </span>
          </template>
        </QuestionWording>
      </div>

      <!-- Details -->
      <div class="editor-question__details">
        <span class="editor-question__type">
          <SvgIconDecorative
            icon="analysis"
            class="editor-question__type-icon"
          />
          <span
            class="editor-question__type-text"
            :data-parent="$options.name"
            v-show="question.data_type && !isEditingDataType"
            @click.stop="isEditingDataType = true"
          >
            {{
              translate(
                DATA_TYPE_TO_READABlE[question.data_type],
                $options.name
              )
            }}
          </span>
          <SelectDataType
            class="editor-question__select"
            :id="question._id.$oid"
            :init-data-type="question.data_type"
            :sr-only-label="true"
            @set-data-type="setDataType"
            v-show="!question.data_type || isEditingDataType"
          >
            <VisibleText>select data type</VisibleText>
          </SelectDataType>
        </span>
        <span class="editor-question__approved">
          <SvgIconDecorative
            icon="approved"
            class="editor-question__approved-icon"
          />
          <strong>{{ question.matches.approved }}</strong>
          <VisibleText> approved</VisibleText>
        </span>
        <span class="editor-question__pending">
          <SvgIconDecorative
            icon="pending"
            class="editor-question__pending-icon"
          />
          <strong>{{ question.matches.pending }}</strong>
          <VisibleText> pending</VisibleText>
        </span>
      </div>
    </template>

    <!-- Matches -->
    <h4 style="font-size: 1.05rem; margin-bottom: 2rem">
      {{ `${question.question_title} - Benchmark Matches` }}
    </h4>
    <Matches
      :global-question-id="question._id.$oid"
      @updateGlobalQuestionMatches="updateGlobalQuestionMatches"
    />
  </Accordion>
</template>

<script>
// Components
import Accordion from "@/components/UI/Accordion.vue"
import ButtonIconOnly from "@/components/UI/Button/ButtonIconOnly.vue"
import LoadingSpinner from "@/components/UI/Spinner.vue"
import QuestionWording from "../Components/QuestionWording.vue"
import SelectDataType from "@/components/Dataset/Components/DatasetSelectType.vue"
import SvgIconDecorative from "@/components/UI/Svg/SvgIconDecorative.vue"

const Matches = () => ({
  component: import("./Matches.vue"),
  loading: LoadingSpinner
})

// Consts
import { DATA_TYPE_TO_READABlE } from "@/utils/consts/constsDataTypes.js"

// Models
import BenchmarkGroup from "@/models/BenchmarkGroup.js"

// Services
import DatasetsService from "@/services/datasetsService.js"

export default {
  name: "BenchmarkManagerEditorQuestion",
  components: {
    Accordion,
    ButtonIconOnly,
    Matches,
    QuestionWording,
    SelectDataType,
    SvgIconDecorative
  },
  props: {
    benchmarkGroup: {
      type: BenchmarkGroup,
      required: true
    },
    index: {
      default: null,
      type: Number,
      required: true
    },
    question: {
      default: () => {},
      type: Object,
      required: true
    }
  },
  data() {
    return {
      DATASETS_SERVICE: new DatasetsService(this.$pigeonline),
      DATA_TYPE_TO_READABlE: DATA_TYPE_TO_READABlE,
      accordionIcon: {
        expanded: "chevronUp",
        collapsed: "chevronDown"
      },
      isEditingDataType: false,
      isEditingEng: false,
      isEditingFrench: false,
      $_question: {}
    }
  },
  created() {
    this.$data.$_question = Object.assign({}, this.question)
  },
  methods: {
    deleteQuestion() {
      if (confirm("Are you sure you want to delete this question?")) {
        this.benchmarkGroup.questions = Math.max(
          0,
          this.benchmarkGroup.questions - 1
        )
        this.benchmarkGroup.approved_matches = Math.max(
          0,
          this.benchmarkGroup.approved_matches - this.question.matches.approved
        )
        this.$pigeonline.projects.update(this.benchmarkGroup)

        this.DATASETS_SERVICE.deleteGlobalQuestion(this.question._id.$oid)
        this.$emit("remove-global-question", this.index)
      }
    },
    updateGlobalQuestionMatches(type) {
      if (type === "approved") {
        this.question.matches.approved += 1
        this.question.matches.pending = Math.max(
          0,
          this.question.matches.pending - 1
        )
        this.benchmarkGroup.approved_matches += 1
      }

      if (type === "rejected") {
        this.question.matches.approved = Math.max(
          0,
          this.question.matches.approved - 1
        )
        this.question.matches.pending = Math.max(
          0,
          this.question.matches.pending - 1
        )
        this.benchmarkGroup.approved_matches = Math.max(
          0,
          this.benchmarkGroup.approved_matches - 1
        )
      }

      this.DATASETS_SERVICE.updateGlobalQuestion(this.question._id.$oid, {
        matches: this.question.matches
      })
      this.$pigeonline.projects.update(this.benchmarkGroup)
    },
    async updateQuestionWording() {
      const updated = await this.DATASETS_SERVICE.updateGlobalQuestion(
        this.question._id.$oid,
        {
          question_text: this.$data.$_question.question_text,
          question_text_fr: this.$data.$_question.question_text_fr
        }
      )
      this.$emit(
        "update-global-question",
        this.index,
        updated.global_question_object
      )
    },
    setDataType(dataType) {
      if (this.question.data_type === dataType) return
      this.isEditingDataType = false
      this.$set(this.question, "data_type", dataType)
      this.DATASETS_SERVICE.updateGlobalQuestion(this.question._id.$oid, {
        data_type: dataType
      })
    }
  }
}
</script>
