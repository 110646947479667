<template>
  <div class="library__article">
    <div v-if="isUserGuide">
      <p>
        <VisibleText>
          Go back to
        </VisibleText>
        <a
          href="javascript:void(0)"
          @click="$emit('new-page', 'GettingStarted')"
        >
          <VisibleText> Getting Started</VisibleText>
        </a>
      </p>
    </div>
    <div v-else>
      <p>
        <VisibleText>Go back to</VisibleText>
        <router-link to="/library">
          <VisibleText> Library</VisibleText>
        </router-link>
        ->
        <router-link to="/library/guide/getting-started-with-researchai">
          <VisibleText> Getting Started</VisibleText>
        </router-link>
      </p>
    </div>
    <h1>
      <VisibleText>
        What is benchmarking analysis?
      </VisibleText>
    </h1>
    <p>
      <VisibleText>
        Benchmarking analysis allows you to compare your performance on certain
        measures to peers. Many institutes, governments and universities produce
        benchmarking data that they make available to the international
        community.
      </VisibleText>
    </p>
    <h2><VisibleText>How it works</VisibleText></h2>
    <p>
      <VisibleText>You learned in the </VisibleText>
      <router-link to="/library/guide/uploading-files-into-researchai">
        <VisibleText>Upload your data </VisibleText>
      </router-link>
      <VisibleText>
        article that in Step 3 you can optionally “match your questions to a
        global question”. ResearchAI makes it easy to create your own groups and
        this allows your peers to upload data and easily match their questions
        to your questions to facilitate comparisons.
      </VisibleText>
    </p>
    <p>
      <VisibleText>You also learned in </VisibleText>
      <router-link to="/library/guide/basic-analysis-in-researchai">
        <VisibleText>conducting basic analysis </VisibleText>
      </router-link>
      <VisibleText>
        that after matching these questions, they will automatically produce
        detailed reports.
      </VisibleText>
    </p>
    <h2>
      <VisibleText>
        What benchmarking groups can I use?
      </VisibleText>
    </h2>
    <h3>
      <VisibleText>ICCS benchmarking data</VisibleText>
    </h3>
    <p>
      <VisibleText>We have partnered with the </VisibleText>
      <a href="https://citizenfirst.ca/">
        <VisibleText>Institute of Citizen-Centred Service </VisibleText>
      </a>
      <VisibleText>to make their </VisibleText>
      <a href="https://citizenfirst.ca/measure-and-benchmark">
        <VisibleText>benchmarking data </VisibleText>
      </a>
      <VisibleText>
        for public sector service experience available to users in a digital and
        self-service way, using ResearchAI. If you are registered for this
        service, you will see ICCS Benchmarking appear when clicking Step 3
        during data cleaning.
      </VisibleText>
    </p>
    <h3>
      <VisibleText>Public and sample data</VisibleText>
    </h3>
    <p>
      <VisibleText>
        A demo dataset will be available for all users. This copies the shape
        and format of the ICCS benchmarking service, but is not real data. Feel
        free to use this when experimenting. Other organizations may or may not
        set their groups to public, or may share their groups with you directly.
      </VisibleText>
    </p>
    <h3><VisibleText>Create your own benchmarking group</VisibleText></h3>
    <p>
      <VisibleText>
        You can easily create a benchmarking group that you can share with
        colleagues. For example, maybe you work with other departments or other
        regions. Setting a list of common questions to track in studies becomes
        easy to manage.
      </VisibleText>
    </p>
    <p>
      <VisibleText>
        To create your own group, click your profile icon in the top right. Then
        click “Benchmark Manager”.
      </VisibleText>
    </p>
    <img
      class="screenshot"
      style="width:30%;"
      src="/assets/userguide__useroptions.png"
      alt="screenshot of user options when clicking on your profile avatar"
    />
    <p>
      <VisibleText>
        Tip: If you do not see this option it is because your account has not
        unlocked this yet. Please contact the ResearchAI team if you are
        interested in this.
      </VisibleText>
    </p>
    <p>
      <VisibleText>
        Here you will see a list of benchmarking groups created by you, or
        shared with you. First, let’s create a new group. Click “Create new
        group” in the top right.
      </VisibleText>
    </p>
    <img
      class="screenshot"
      style="width:60%;"
      src="/assets/userguide__benchmarkmanager.png"
      alt="screenshot of the view of your benchmarking groups"
    />
    <p>
      <VisibleText>
        Start by naming it. Then, click “Add question”. Give it a question code,
        like, “overall satisfaction”. Give it question wording if it came from a
        survey, like, “Overall I was satisfied with the experience”. Set a
        language, and data type (e.g., what kind of scale question?).
      </VisibleText>
    </p>
    <img
      class="screenshot"
      style="width:60%;"
      src="/assets/userguide__benchmarkmanager--addquestion.png"
      alt="screenshot of the add question view for the benchmarking manager"
    />
    <p>
      <VisibleText>
        Add as many questions as you think will be useful for your peers to
        consider measuring.
      </VisibleText>
    </p>
    <h3><VisibleText>Managing your benchmarks</VisibleText></h3>
    <p>
      <VisibleText>
        If you are the creator of a benchmarking group, you may want to control
        its quality. ResearchAI gives you a number of tools for this. In the
        list of benchmarking groups you can see a toggle that says, “Auto
        approve” on or off. Setting this to on will allow users to submit
        matches and proceed to reporting immediately. Benchmark managers will be
        notified automatically. Setting this to off will mean users will have to
        wait for their questions to be approved before they can create
        benchmarking reports.
      </VisibleText>
    </p>
  </div>
</template>

<script>
export default {
  name: "what_is_benchmarking",
  props: {
    isUserGuide: { type: Boolean }
  },
  methods: {
    goto(refName) {
      this.$refs[refName].scrollIntoView()
    }
  }
}
</script>
