<template>
  <Modal
    id="share-project"
    ref="share-modal"
    uid="share-project-modal"
    :show="show"
    :reset-listener="resetListener"
    @close="closeModal"
  >
    <Spinner id="share-spinner" :component-style="true" :isLoading="isSaving" />

    <template #header>
      <VisibleText>
        {{ currViewIdx == 0 ? "Share Project" : "Manage Permissions" }}
      </VisibleText>
    </template>

    <ModalGroupLayout
      :showBackBtn="false"
      @back="back"
      @changed="unsavedChanges = true"
    >
      <Sharing
        :label="'Add people to start sharing your project.'"
        :shared="users"
        ref="sharing"
        @addNewUser="addNewUser($event)"
      />
    </ModalGroupLayout>

    <ModalGroupLayout @back="back" @changed="unsavedChanges = true">
      <ManagePermissions
        :shared="usersShared"
        :types="SHARING_KEYS"
        :perm-labels="DEFAULT_PERMS_LABELS"
      />
    </ModalGroupLayout>

    <template #footer>
      <div class="share-copy-url">
        <a
          role="button"
          ref="copy-url"
          class="share__copy-url-btn"
          @click="copy"
        >
          <VisibleText>Copy link</VisibleText>
        </a>
        <input
          aria-label="share url"
          type="text"
          id="share-url"
          class="share__copy-url"
          readonly
          :value="shareUrl"
        />
      </div>
      <button
        class="share__next-btn"
        @click="goToView(1)"
        v-if="modalGroups && currViewIdx == 0"
      >
        <VisibleText>Next: Manage Permissions</VisibleText>
      </button>
      <button
        class="share__save-changes-btn"
        :aria-disabled="isSaveBtnDisabled"
        :disabled="isSaveBtnDisabled"
        @click="saveChanges(false)"
        v-if="modalGroups && currViewIdx == modalGroups.length - 1"
      >
        <VisibleText>Save changes</VisibleText>
      </button>
    </template>
  </Modal>
</template>

<script>
// Components
import Modal from "@/components/UI/Modal.vue"
import ModalGroupLayout from "./Base/ModalGroupLayout.vue"
import Sharing from "./Base/Sharing.vue"
import ManagePermissions from "./Base/ManagePermissions.vue"
import Spinner from "@/components/UI/Spinner.vue"

// Mixins
import ShareMixin from "./Mixins/shareMixin.js"

export default {
  name: "ShareProject",
  mixins: [ShareMixin],
  components: {
    Modal,
    ModalGroupLayout,
    Sharing,
    ManagePermissions,
    Spinner
  },
  props: {
    project: {
      type: Object
    },
    dataset: {
      type: Object
    },
    show: {
      defult: () => false,
      type: Boolean
    }
  },
  watch: {
    dataset: function() {
      this.mount()
    }
  }
}
</script>
