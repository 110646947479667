<template>
  <span class="table__sort">
    <button
      aria-haspopup="listbox"
      class="table__sort-btn"
      type="button"
      ref="sort-btn"
      :aria-expanded="isSortOptionsExpanded()"
      :data-parent="$options.name"
      @click="onClickSortBtn()"
      @keydown.enter="onClickSortBtn()"
      @keydown.down="onClickSortBtn()"
    >
      {{ translate(sortTitle, $options.name) }}
      <SvgIconDecorative class="icon" icon="sort" />
    </button>
    <div
      aria-activedescendant=""
      aria-label="sort by"
      class="table__sort-listbox"
      role="listbox"
      tabindex="-1"
      ref="options"
      @keydown="onOptionKeydown($event)"
    >
      <span
        v-for="(option, indx) in sortConfig.options"
        role="option"
        class="item"
        :key="option"
        :aria-selected="(sortConfig.selected === option).toString()"
        :id="`option-${indx}-${uuid}`"
        :data-option="option"
        :data-parent="$options.name"
        @click="selectOption(option, `option-${indx}-${uuid}`)"
      >
        {{ translate(option, $options.name) }}
      </span>
    </div>
  </span>
</template>

<script>
import SvgIconDecorative from "@/components/UI/Svg/SvgIconDecorative.vue"

export default {
  name: "SortPopup",
  components: {
    SvgIconDecorative
  },
  props: {
    sortTitle: {
      type: String,
      required: true
    },
    /**
     * sortConfig: {
     *    options: <array of sort options>,
     *    selected: <string, currently selected option>
     * }
     */
    sortConfig: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      uuid: this.$pigeonline.createUUID()
    }
  },
  methods: {
    getActiveDescendant() {
      let activeDescendant = this.$refs[`options`].getAttribute(
        "aria-activedescendant"
      )
      if (activeDescendant.length === 0) {
        let indx = this.sortConfig.options.indexOf(this.sortConfig.selected)
        if (indx < 0) indx = 0
        return document.getElementById(`option-${indx}-${this.uuid}`)
      } else {
        return document.getElementById(activeDescendant)
      }
    },
    isSortOptionsExpanded() {
      return this.$refs[`options`] &&
        this.$refs[`options`].classList.contains("open")
        ? "true"
        : "false"
    },
    onOptionKeydown($e) {
      let listitem = this.getActiveDescendant()
      switch ($e.key) {
        case "Down":
        case "ArrowDown":
          if (listitem && listitem.nextElementSibling) {
            listitem.classList.remove("active")
            this.setActiveOption(listitem.nextElementSibling)
          }
          break
        case "Up":
        case "ArrowUp":
          if (listitem && listitem.previousElementSibling) {
            listitem.classList.remove("active")
            this.setActiveOption(listitem.previousElementSibling)
          }
          break
        case "Enter":
          if (listitem) {
            this.selectOption(listitem.dataset.option, listitem.id)
          }
          break
        case "Esc":
        case "Escape":
          this.$refs[`sort-btn`].focus()
          this.$refs[`options`].classList.remove("open")

          break
        case "Tab":
          this.$refs[`options`].classList.remove("open")
          break
        default:
          return
      }
      $e.preventDefault()
    },
    onClickSortBtn() {
      if (this.$refs[`options`].classList.contains("open")) {
        this.$refs[`options`].classList.remove("open")
      } else {
        this.$refs[`options`].classList.add("open")
        this.$refs[`options`].focus()
        this.setActiveOption(this.getActiveDescendant())
      }
    },
    setActiveOption(element) {
      element.classList.add("active")
      this.$refs[`options`].setAttribute("aria-activedescendant", element.id)
    },
    selectOption(option, id) {
      let sortTitle = this.sortTitle
      this.$emit("sort", { option, sortTitle })

      // update classes
      this.$refs[`options`].classList.remove("open")
      this.$refs[`options`]
        .querySelectorAll("[role=option")
        .forEach(option => option.classList.remove("active"))
      this.setActiveOption(document.getElementById(id))

      this.$refs[`sort-btn`].focus()
    }
  }
}
</script>
