<template>
  <div class="organization__content">
    <form class="organization__form" @submit.prevent="save">
      <div v-if="suggestedOrganizations.length > 0">
        <p class="organization__form-legend">
          <VisibleText
            >Based on your email, we found the following registered
            organization.
          </VisibleText>
        </p>
        <label for="organization_name" class="organization__form-label">
          <input
            id="organization_name"
            class="organization__form-input"
            type="text"
            name="organization_name"
            :value="extractOrganizationName(suggestedOrganizations[0])"
            required
            aria-required="true"
            disabled="disabled"
          />
          <span class="organization__form-label-txt">
            <VisibleText
              >An admin from your company will be contacted for approval.
            </VisibleText>
          </span>
        </label>
      </div>
      <div v-else>
        <p class="organization__form-legend">
          <VisibleText
            >We could not find an organization matching your email. Please
            register your organization:
          </VisibleText>
        </p>
        <label for="organization_name" class="organization__form-label">
          <VisibleText>Name of the organization </VisibleText>*
          <input
            id="organization_name"
            class="organization__form-input"
            type="text"
            name="organization_name"
            required
            aria-required="true"
            v-model="vModels.organization.name"
            @blur="findMatchingOrganizations"
          />
          <div
            class="organization__form-label-txt"
            v-if="matchingOrganizations.length > 0"
          >
            <p style="color: red;">
              <VisibleText
                >An organization with the following name already exists.
              </VisibleText>
            </p>
            <p>
              <VisibleText
                >Please click on the organization link below to request approval
                from your portal admin OR use a different name.
              </VisibleText>
            </p>
            <ul>
              <li v-for="item in matchingOrganizations" :key="item.id">
                <a href="" @click.prevent="selectOrganization(item)">
                  {{ extractOrganizationName(item) }}
                </a>
              </li>
            </ul>
          </div>
        </label>
        <label for="organization_department" class="organization__form-label">
          <VisibleText>Department</VisibleText>
          <input
            id="organization_department"
            class="organization__form-input"
            type="text"
            name="organization_department"
            v-model="vModels.organization.department"
          />
          <span class="organization__form-label-txt"></span>
        </label>
      </div>
      <button
        class="organization__form-btn"
        type="submit"
        :disabled="disableSubmit"
      >
        <VisibleText>Continue</VisibleText>
      </button>
    </form>

    <div class="organization__footer">
      <p class="organization__footer-txt">
        {{ footerText }}
      </p>
    </div>
  </div>
</template>

<script>
import { axios } from "@pigeonline/core"

import { OrganizationModel } from "@pigeonline/core"
import UserMixin from "@/utils/mixins/userMixin.js"

export default {
  name: "accountNextStepsOrganization",
  mixins: [UserMixin],
  data() {
    return {
      vModels: {
        organization: {
          name: "",
          department: ""
        }
      },
      matchingOrganizations: [],
      suggestedOrganizations: [],
      status: {
        create: false,
        fetchingMatch: false
      }
    }
  },
  computed: {
    showSuggestedOrganization: function() {
      return this.suggestedOrganizations.length > 0
    },
    footerText: function() {
      return this.showSuggestedOrganization
        ? "Is this organization incorrect? Contact us and we will see if we can help."
        : "Is your organization registered but not listed? Contact us and we will see if we can help."
    },
    disableSubmit: function() {
      return (
        !this.showSuggestedOrganization &&
        (this.vModels.organization.name.trim() === "" ||
          this.matchingOrganizations.length > 0 ||
          this.status.create ||
          this.status.fetchingMatch)
      )
    }
  },
  async created() {
    this.setLoading(true)
    try {
      const res = await axios.get(
        `${this.$pigeonline.organizations.URL}/suggested`
      )
      if (Array.isArray(res.data)) {
        this.suggestedOrganizations = res.data
      }
    } catch (e) {
      throw new Error("AccountNextStepsOrganization.vue:created: " + e.message)
    }
    this.setLoading(false)
  },
  methods: {
    extractOrganizationName(org) {
      return org.department_name && org.organization_name.trim() !== ""
        ? org.organization_name + " (" + org.department_name + ")"
        : org.organization_name
    },
    async selectOrganization(organization) {
      if (
        confirm(
          `You are about to choose [${this.extractOrganizationName(
            organization
          )}] as your organization. Are you sure you want to continue?`
        )
      ) {
        // save profile
        const profile = await this.$pigeonline.profile.save({
          organization_ref_id: organization.id || organization._id.$oid
        })
        this.setProfile(profile)
        this.setOrganization(organization)
        this.$router.push({ name: "accountNextStepsCongratulations" })
      }
    },
    async findMatchingOrganizations(event) {
      this.status.fetchingMatch = true
      this.setLoading(true)

      const organizationName = event.target.value.trim().toLowerCase()
      if (organizationName !== "") {
        let params = {
          search: {
            term: organizationName,
            fields: ["organization_name"]
          },
          sort: {
            verified_account: "desc"
          }
        }
        this.matchingOrganizations = (
          await this.$pigeonline.organizations.get(OrganizationModel, params)
        ).filter(
          item =>
            item.organization_name.trim().toLowerCase() == organizationName
        )
      }
      this.status.fetchingMatch = false
      this.setLoading(false)
    },
    save: async function() {
      if (this.showSuggestedOrganization) {
        this.selectOrganization(this.suggestedOrganizations[0])
        return
      }

      this.status.create = true
      this.setLoading(true)
      try {
        const organization = await this.$pigeonline.organizations.create(
          new OrganizationModel({
            organization_name: this.vModels.organization.name,
            department_name: this.vModels.organization.department
          })
        )
        this.setOrganization(organization)
        this.setProfile(await this.$pigeonline.profile.get())
      } catch (e) {
        alert(
          "An error occured when processing your request. Please try again later."
        )
        this.status.create = false
        this.setLoading(false)
        return
      }
      alert("New organization created successfully.")
      this.setLoading(false)
      this.$router.push({ name: "accountNextStepsCongratulations" })
    }
  }
}
</script>
